import React from 'react';

export const ContactPageSuccess = () => {
    return (
        <div className="text-center">
            <img
                src="/assets/check-circle.svg"
                alt="Contact form submitted successfully"
                className="ContactPage__success-image"
            />
            <div className="ContactPage__success-title">Your inquiry is submitted successfully.</div>
            <div className="ContactPage__success-description">
                Thanks for contacting us. You'll hear back from us about your inquiry within 24 hours.
            </div>
        </div>
    );
};
