import axios from 'axios';

import {
    INotification,
    INotificationPatch,
    INotificationUpdate,
    INotificationCountResult,
    INotificationListResult,
} from 'modules/notification/models';
import {getAccessTokenSilently} from 'shared/utils/token';
import {Config} from 'config';
import {getAuthHeaders} from 'shared/utils/auth';

export interface INotificationListQuery {
    userId: string;
    cursor?: string;
    limit?: number;
}

const getURLForNotificationAPI = (path: string) => {
    return `${Config.notificationServiceURL}/notifications${path}`;
};

export const readNotificationList = async ({
    userId,
    cursor,
    limit,
}: INotificationListQuery): Promise<INotificationListResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForNotificationAPI('/'), {
        params: {
            user_id: userId,
            cursor,
            limit,
        },
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const readNotificationCount = async (userId: string): Promise<INotificationCountResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForNotificationAPI('/count'), {
        params: {
            user_id: userId,
            read: false,
        },
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const updateNotification = async (
    notificationId: string,
    params: INotificationPatch,
): Promise<INotification> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(getURLForNotificationAPI(`/${notificationId}`), params, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const updateNotificationBatch = async (notificationUpdates: INotificationUpdate[]): Promise<INotification[]> => {
    const notifications = notificationUpdates.map(
        notificationUpdate => updateNotification(notificationUpdate.id, notificationUpdate),
    );
    return await Promise.all(notifications);
};
