import React from 'react';

import classNames from 'classnames';

import './style.scss';

interface IProfilePageSectionHeaderProps {
    className?: string;
    children?: React.ReactNode;
    title: React.ReactNode;
    description?: string;
}

export const ProfilePageSectionHeader = ({children, className, title, description}: IProfilePageSectionHeaderProps) => {

    return (
        <div className={classNames('ProfilePageSectionHeader', className)}>
            {!!children && (
                <div className="ProfilePageSectionHeader__left-col"/>
            )}
            <div
                className={classNames('text-center d-flex justify-content-center align-items-center', {
                    'ProfilePageSectionHeader__center-col': children,
                    'mx-auto': !children,
                })}
            >
                <h2 className={classNames('ProfilePageSectionHeader__title')}>
                    {title}
                </h2>
                {/* {!!description?.length && (
                    <p className="ProfilePageSectionHeader__description text-center">{description}</p>
                )} */}
            </div>
            {!!children && (
                <div className="ProfilePageSectionHeader__right-col">
                    {children}
                </div>
            )}
        </div>
    );
};
