import React from 'react';

import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { Form } from 'react-bootstrap';

import { StarRating } from 'shared/components/StarRating/StarRating';

interface IStarRatingStarRatingControllerProps<FormType> extends UseControllerProps<FormType> {
    isEditable?: boolean;
    disabled?: boolean;
}

export const StarRatingController = <FormType extends FieldValues>({
    name,
    control,
    defaultValue,
    disabled,
}: IStarRatingStarRatingControllerProps<FormType>) => {

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({
                field: {onChange, value},
                fieldState: {invalid, isDirty, error},
            }) => (
                <>
                    <StarRating
                        value={value}
                        isEditable={!disabled}
                        onChange={onChange}
                    />
                    {invalid && error && (
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {error.message}
                        </Form.Control.Feedback>
                    )}
                </>
            )}
        />
    );
};
