import React from 'react';

import {ICaseChartBranch, CaseChartUser} from 'modules/case-chart/models';

interface ICaseEstimatorPreviewBranchProps {
    branch: ICaseChartBranch;
}

export const CaseEstimatorPreviewBranch = ({branch}: ICaseEstimatorPreviewBranchProps) => {
    const className =
        branch.owner === CaseChartUser.Barrister ? branch.owner.toLowerCase() : CaseChartUser.Paralegal.toLowerCase();

    return (
        <div className={`CaseEstimatorPreview__activity CaseEstimatorPreview__activity--${className}`}>
            <div className="arrow">
                <span className={`CaseEstimatorPreview__arrow-main CaseEstimatorPreview__arrow-main--${className}`}>
                    <span className="arrow-head" />
                    <span className="arrow-tail" />
                    <span className="arrow-ball" />
                </span>
            </div>
            <div className="text">
                <span className="CaseEstimator-preview__text">{branch.name}</span>
            </div>
            <div className="cost">
                {branch.price && <span className="CaseEstimator-preview__cost">${branch.price}</span>}
            </div>
        </div>
    );
};
