import React from 'react';

import {Container} from 'react-bootstrap';

import {ISkill} from 'modules/profile/models/ISkill';
import {IProfile} from 'modules/profile/models/IProfile';

import {ProfilePageSkillsItem} from 'shared/components/ProfilePageContent/ProfilePageSkills/ProfilePageSkillsItem';
import {EditSkillsModal} from 'shared/components/ProfilePageContent/ProfilePageSkills/EditSkillsModal';
import {ProfilePageEmpty} from 'shared/components/ProfilePageContent/shared/ProfilePageEmpty/ProfilePageEmpty';
import {ProfilePageSectionHeader} from 'shared/components/ProfilePageContent/shared/ProfilePageSectionHeader/ProfilePageSectionHeader';
import {ProfilePageEditButton} from 'shared/components/ProfilePageContent/shared/ProfilePageEditButton/ProfilePageEditButton';

import './style.scss';

interface IProfilePageSkillsProps {
    skills: ISkill[];
    profile: IProfile;
    isEditable?: boolean;
    firstname?: string;
}

export const ProfilePageSkills = ({skills, profile, isEditable, firstname}: IProfilePageSkillsProps) => {
    const [showEditModal, setShowEditModal] = React.useState<boolean>(false);

    return (
        <Container className="ProfilePageSkills">
            <ProfilePageSectionHeader title="Skills" description={`What ${firstname} is great at`}>
                {isEditable && <ProfilePageEditButton onClick={() => setShowEditModal(true)} />}
            </ProfilePageSectionHeader>
            {skills && !!skills?.length && (
                <div className="ProfilePageSkills__row">
                    {skills.map((skill) => (
                        <ProfilePageSkillsItem name={skill.name} level={skill.level} key={skill.name} />
                    ))}
                </div>
            )}
            {(!skills || !skills.length) && (
                <ProfilePageEmpty>
                    <div className="ProfilePageAbout--empty justify-content-center align-content-center">
                        <p className="mb-0 NoData">{firstname ? `${firstname} has not yet specified their skills` : 'No skills'}</p>
                        <img src="/assets/profileIcons/skills.svg" className="AboutmeIMG" alt="" />
                    </div>
                    {/* {firstname ? `${firstname} has not yet specified their skills` : 'No skills'} */}
                </ProfilePageEmpty>
            )}
            {isEditable && (
                <EditSkillsModal open={showEditModal} onHide={() => setShowEditModal(false)} profile={profile} />
            )}
        </Container>
    );
};
