import React, {ElementType} from 'react';

import {Form} from 'react-bootstrap';

interface IInputControlProps {
    name: string;
    disabled?: boolean;
    placeholder?: string;
    type?: string;
    max?: number;
    min?: number;
    as?: ElementType;
    className?: string;
    autoFocus?: boolean;
    rows?: number;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
    isInvalid?: boolean;
    custom?: boolean;
    children?: React.ReactNode;
    value?: string | number | string[];
    autoComplete?: string;
    id?: string;
    size?: 'sm' | 'lg';
    readOnly?: boolean;
    maxlength?: number;
}

export const InputControl = React.forwardRef(({
    disabled,
    className,
    autoFocus,
    name,
    placeholder,
    type = 'text',
    max,
    min,
    as,
    rows,
    children,
    id,
    onChange,
    onBlur,
    isInvalid,
    custom,
    value,
    autoComplete,
    size,
    readOnly,
    maxlength,
}: IInputControlProps, ref) => {

    return (
        <Form.Control
            name={name}
            as={as}
            ref={ref}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            isInvalid={isInvalid}
            type={type}
            max={max}
            min={min}
            id={id}
            className={className}
            autoFocus={autoFocus}
            rows={rows}
            custom={custom}
            value={value}
            autoComplete={autoComplete ?? 'off'}
            size={size}
            readOnly={readOnly}
            maxlength={maxlength}
        >
            {children}
        </Form.Control>
    );
});
