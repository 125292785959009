import { z } from 'zod';

export const formatInputValueAsNumber = (value: string) => {
    const valueAsNumber = Number(value);
    if (isNaN(valueAsNumber)) {
        return value;
    } else {
        return valueAsNumber;
    }
};

export const zodStringNumber = z.string().refine(value => {
    const valueAsNumber = parseInt(value);
    return !isNaN(valueAsNumber) && valueAsNumber >= 0;
}).transform(value => parseInt(value)).or(z.number());
