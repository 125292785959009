import React from 'react';
import classNames from 'classnames';

import {IWorkExperience} from 'modules/profile/models/IWorkExperience';
import {IProfile} from 'modules/profile/models/IProfile';
import {chunk} from 'shared/array-utils/chunk';
import {useWindowSize} from 'shared/hooks/useWindowSize';

import {AddEditExperience} from './AddEditExperience';
import {DeleteIcon, Edit} from 'shared/icons/EditProfile';
import {DeleteExperience} from './DeleteExperience';
import {ReactComponent as CancelIcon} from 'shared/icons/legalmatters/cancel.svg';

import './style.scss';

interface IWorkExperienceProps {
    workExperience?: IWorkExperience[];
    profile: IProfile;
    isEditable?: boolean;
}

interface IWorkExperienceRow {
    key: string;
    items: IWorkExperience[];
}

export const Experiences = ({workExperience, profile, isEditable}: IWorkExperienceProps) => {
    const windowWidth = useWindowSize();

    const workExperienceRows: IWorkExperienceRow[] = chunk<IWorkExperience>(workExperience ?? [], 1).map(
        (workExperienceRow, rowIndex) => ({
            key: `${rowIndex}.${workExperienceRow.map((item) => item.name).join('.')}`,
            items: workExperienceRow,
        }),
    );
    const [ExperienceData, setExperienceData] = React.useState<IWorkExperience>({name: '', description: ''});
    const [AddExperienceModal, setAddExperienceModal] = React.useState<boolean>(false);
    const [isEditExperience, setIsEditExperience] = React.useState<boolean>(false);
    const [EditIndex, setEditIndex] = React.useState<number | null>(null);
    const [DeleteExperienceModal, setDeleteExperienceModal] = React.useState<boolean>(false);

    const workExperienceRowsLimit = 6;
    const isAddExperienceDisable = workExperienceRows.length >= workExperienceRowsLimit;

    return (
        <>
            {workExperienceRows && workExperienceRows.length !== 0 && (
                <div className="ml-3 pl-3 ProfilePageDetails__flex--space-between">
                    <div className=" SelectedCases">
                        <p className="m-0" />
                    </div>
                    {isEditable && (
                        <div
                            className={classNames('ProfilePageDetails__icon--add-icon', {
                                disableAdd: isAddExperienceDisable,
                            })}
                            onClick={() => {
                                if (!isAddExperienceDisable) {
                                    setAddExperienceModal(true);
                                }
                            }}
                        >
                            <CancelIcon />
                        </div>
                    )}
                </div>
            )}
            {isEditable && (
                <>
                    <AddEditExperience
                        openModal={AddExperienceModal}
                        onHideModal={() => {
                            setAddExperienceModal(false);
                            setEditIndex(null);
                            setIsEditExperience(false);
                        }}
                        profile={profile}
                        isEdit={isEditExperience}
                        EditIndex={EditIndex}
                        ExperienceData={ExperienceData}
                    />
                    <DeleteExperience
                        onHide={() => {
                            setDeleteExperienceModal(false);
                            setExperienceData({
                                name: '',
                                description: '',
                            });
                            setEditIndex(null);
                        }}
                        open={DeleteExperienceModal}
                        EditIndex={EditIndex}
                        ExperienceData={ExperienceData}
                        profile={profile}
                    />
                </>
            )}
            <div className={workExperienceRows.length > 1 ? 'innerExpriences' : 'innerExprience'}>
                {workExperienceRows &&
                    workExperienceRows.map((workExperienceRow, workExperienceIndex) => (
                        <div key={workExperienceRow.key}>
                            {workExperienceRow.items.map((item) => (
                                <div className={`${windowWidth.width < 769 ? 'my-3' : 'm-3'} col`} key={item.name}>
                                    <div
                                        className={`${
                                            windowWidth.width < 769 ? 'mb-2' : 'mb-3'
                                        } ProfilePageDetails__flex--space-between`}
                                    >
                                        <div
                                            className={`HeadExpriences ${
                                                isEditable && 'ProfilePageDetails__width--minus80 ProfilePageDetails__fontSize'
                                            }`}
                                        >
                                            <p className="m-0">{item.name}</p>
                                        </div>
                                        {isEditable && (
                                            <div className=" ProfilePageDetails__width--px80 ProfilePageDetails__flex--row">
                                                <div
                                                    className="ProfilePageDetails__icon--medium-edit"
                                                    onClick={() => {
                                                        setExperienceData(item);
                                                        setIsEditExperience(true);
                                                        setEditIndex(workExperienceIndex);
                                                        setAddExperienceModal(true);
                                                    }}
                                                >
                                                    <Edit />
                                                </div>
                                                <div className="ProfilePageDetails__icon--medium-delete ProfilePageDetails__padding--l12">
                                                    <DeleteIcon
                                                        onClick={() => {
                                                            setExperienceData(item);
                                                            setEditIndex(workExperienceIndex);
                                                            setDeleteExperienceModal(true);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="text-preserve-whitespace ProfilePageDetails__fontSize">{item.description}</div>
                                </div>
                            ))}
                        </div>
                    ))}
            </div>

            {!workExperienceRows.length && (
                <>
                    {isEditable && (
                        <div
                            className="ProfilePageDetails__icon--add-icon text-right"
                            onClick={(e) => {
                                e.stopPropagation();
                                setAddExperienceModal(true);
                            }}
                        >
                            <CancelIcon />
                        </div>
                    )}
                    <div className="text-center">
                        <img
                            src="/assets/profileIcons/experiences.svg"
                            alt="experiences"
                            className="exceptions-image"
                        />
                    </div>
                </>
            )}
        </>
    );
};
