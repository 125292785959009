import React from 'react';

import {Container} from 'react-bootstrap';

import {IProfile} from 'modules/profile/models/IProfile';

import {EditAboutUs} from './EditAboutUs';
import {
    ProfilePageSectionHeader,
} from 'shared/components/ProfilePageContent/shared/ProfilePageSectionHeader/ProfilePageSectionHeader';
import {
    ProfilePageEditButton,
} from 'shared/components/ProfilePageContent/shared/ProfilePageEditButton/ProfilePageEditButton';

import './style.scss';

interface IProfilePageAboutProps {
    summary: string;
    isEditable?: boolean;
    profile: IProfile;
}

export const ProfilePageAbout = ({summary, isEditable, profile}: IProfilePageAboutProps) => {
    const [showEditModal, setShowEditModal] = React.useState<boolean>(false);

    return (
        <>
            <Container>
                <ProfilePageSectionHeader title="About me">
                    {isEditable && (
                        <ProfilePageEditButton onClick={() => setShowEditModal(true)}/>
                    )}
                </ProfilePageSectionHeader>
            </Container>
            <div className="container-fluid">
                <div className="ProfilePageAbout mb-0 pb-4">
                    <div className="ProfilePageAbout__content">
                        {summary && !!summary.length ? (
                            <div className="text-preserve-whitespace">
                                {summary}
                            </div>
                        ) : (
                            <div className="ProfilePageAbout--empty justify-content-center align-content-center">
                                <p className="NoData mb-0">No details available</p>
                                <img src="/assets/profileIcons/Aboutme.svg" className="AboutmeIMG" alt=""/>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {isEditable && (
                <EditAboutUs
                    open={showEditModal}
                    onHide={() => setShowEditModal(false)}
                    profile={profile}
                />
            )}
        </>
    );
};
