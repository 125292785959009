import {Profession} from 'modules/profile/models/profession';
import {IContactDetails} from 'modules/profile/models/IContactDetails';
import {ISkill} from 'modules/profile/models/ISkill';
import {IWorkExperience} from 'modules/profile/models/IWorkExperience';
import {ISocialLink} from 'modules/profile/models/ISocialLink';
import {ServiceType} from 'modules/profile/models/serviceType';
import {IServiceDetails} from 'modules/profile/models/IServiceDetails';

export enum Role {
    System = 'SYSTEM',
    Client = 'CLIENT',
    Provider = 'PROVIDER',
}

export interface IProfile {
    id: string;
    firstname: string;
    lastname: string;
    is_featured: boolean;
    featured_at: string;
    is_published: boolean;
    published_at: string;
    created_at: string;
    updated_at: string;
    tag_ids: string[];
    title?: string;
    image_id?: string;
    image_url?: string;
    profession: Profession;
    contact_details: IContactDetails;
    contact_details_display: IContactDetails;
    summary?: string;
    claim_to_fame?: string;
    experience_level?: string;
    skills: ISkill[];
    work_experience: IWorkExperience[];
    extra_experience: IWorkExperience[];
    social_links: ISocialLink[];
    service_types: ServiceType[];
    resume_id?: string;
    is_owner?: boolean;
    owner?: string;
    referred_by?: string;
    service_details?: IServiceDetails;
    role: Role;
}

