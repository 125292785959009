import React from 'react';

import DOMPurify from 'dompurify';

interface IHtmlContentProps {
    className?: string;
    children: string;
    as: React.ElementType;
}

export const HtmlComponent = ({className, children, as}: IHtmlContentProps) => {
    const ElementType: React.ElementType = as;
    const sanitizedHtml = {
        __html: DOMPurify.sanitize(children),
    };

    return (
        <ElementType className={className} dangerouslySetInnerHTML={sanitizedHtml}/>
    );
};

HtmlComponent.defaultProps = {
    as: 'div',
};
