import React, {useState} from 'react';

import {Navigate, Route, Routes} from 'react-router-dom';

import {PostKind} from 'modules/post/models/postKind';
import {useNavbar} from 'shared/hooks/useNavbar';
import {useDebounce} from 'shared/hooks/useDebounce';

import {AppFooter} from 'shared/components/AppFooter/AppFooter';
import {ResourcesPageNav} from 'public/ResourcesPage/ResourcesPageNav/ResourcesPageNav';
import {PostsPage} from 'public/ResourcesPage/PostsPage/PostsPage';
import {ResourceSearchBar} from 'public/ResourcesPage/ResourceSearchBar/ResourceSearchBar';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';

import './style.scss';

export const ResourcesPage = () => {
    useNavbar(true);

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [tagId, setTagId] = useState<string | undefined>(undefined);

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const postsPageProps = {
        searchTerm: debouncedSearchTerm,
        tagId,
    };

    return (
        <>
            <PageHelmet
                title="Resources"
                description="Browse articles, podcasts and other resources submitted by the best legal service providers in Australia."
            />
            <div className="container">
                <div className="d-flex row justify-content-center align-items-center PostsHead">Posts</div>
            </div>
            <ResourceSearchBar removeMargin value={searchTerm} onChange={(value) => setSearchTerm(value)} />
            <ResourcesPageNav tagId={tagId} onChange={(tagId) => setTagId(tagId)} />
            <div className="container mt-5">
                <div className="">
                    <Routes>
                        <Route path="/articles" element={<PostsPage kind={PostKind.Article} {...postsPageProps} />} />
                        <Route path="/webinars" element={<PostsPage kind={PostKind.Webinar} {...postsPageProps} />} />
                        <Route path="/podcasts" element={<PostsPage kind={PostKind.Podcast} {...postsPageProps} />} />
                        <Route
                            path="/tools-and-self-help"
                            element={<PostsPage kind={PostKind.Faq} {...postsPageProps} />}
                        />
                        <Route path="/" element={<PostsPage {...postsPageProps} />} />
                        <Route path="*" element={<Navigate to="" replace />} />
                    </Routes>
                </div>
            </div>
            <AppFooter />
        </>
    );
};
