import React from 'react';
import {useSearchParams} from 'react-router-dom';

import {useAuth} from 'shared/auth/hooks/useAuth';

import {Loading} from 'shared/components/loading/Loading/Loading';

export const LogoutPage = () => {
    const {signOut} = useAuth();

    // Read the redirect param
    const [searchParams] = useSearchParams();
    const redirectParam = searchParams.get('redirect') ?? undefined;

    React.useEffect(() => {
        signOut({
            returnTo: redirectParam,
        });
    });

    return (
        <Loading />
    );
};
