import React from 'react';

import classNames from 'classnames';

import { CardDeck } from 'react-bootstrap';

import './style.scss';

interface IMediaCardDeckProps {
    children?: React.ReactNode;
    className?: string;
}

export const MediaCardDeck = ({className, children}: IMediaCardDeckProps) => {
    return (
        <CardDeck className={classNames(className || 'default-desk-class', 'flex-grow-1 justify-content-center')}>
            {children}
        </CardDeck>
    );
};
