import {IFilterOption} from 'shared/models/filter-option/IFilterOption';

export enum RateType {
    Hourly = 'HOURLY',
    Daily = 'DAILY',
    FixedFee = 'FIXED_FEE',
}

export const rateFilters: IFilterOption<RateType | undefined>[] = [
    {
        title: 'All',
        value: undefined,
    },
    {
        title: 'Hourly Rate',
        value: RateType.Hourly,
    },
    {
        title: 'Day Rate',
        value: RateType.Daily,
    },
    {
        title: 'Fixed Fee',
        value: RateType.FixedFee,
    },
];
