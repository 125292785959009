import React from 'react';

import {Container} from 'react-bootstrap';

export const JusticeEcosystemSection = () => {
    return (
        <>
            <div className="AboutUsPage__heading">
                <h2 className="AboutUsPage__heading--text AboutUsPage__margin--t82-b48">
                    Legal Practice, re-imagined for the 21st century
                </h2>
            </div>
            <Container>
                <div className="AboutUsPage__paragraph AboutUsPage__margin--b60">
                    <p className="m-0 AboutUsPage__paragraph--text ">
                        We started with the conviction that the legal system as we knew it, was no longer serving either
                        side of the market effectively. Traditional legal practice hasn't changed much in hundreds of
                        years, but the world <i>has</i>. New technologies now offer a better way to practice law.
                    </p>
                </div>
                <div className="AboutUsPage__paragraph AboutUsPage__margin--b60">
                    <p className="m-0 AboutUsPage__paragraph--text ">
                        Rightful isn't just a simple marketplace to find a lawyer swimming in an out-dated sea. Nor are
                        we just a tech product trying to plug into an ancient system. That would be akin to sticking a
                        bandaid on a sinking ship. We're a submarine: same body of water, new way of navigating it.
                        We're an online legal community underpinned by technology.
                    </p>
                </div>
                <div className="AboutUsPage__paragraph AboutUsPage__margin--b120">
                    <p className="m-0 AboutUsPage__paragraph--text ">
                        We're legal practice, re-imagined for the 21st century.
                    </p>
                </div>
                {/* <div className="ecosystem_container">
                    <div className="about_us_info_text mb_0px">
                        That is what we set out to achieve in 2020 when we first dreamed up Rightful. A platform for
                        clients and legal advocacy teams to find each other. A place where legal projects could be
                        managed securely online.
                    </div>
                    <div>
                        <GifInEcoSystem />
                    </div>
                    <div className="about_us_info_text ">
                        We started with the conviction that the legal system as we know it, no longer serves either side
                        of its market effectively. Traditional legal advocacy hasn't changed much in hundreds of years,
                        but the world has. We're done with hierachy and out-dated office practices. There's a better
                        way.
                    </div>
                    <div className="about_us_info_text">
                        Rightful isn't just a simple marketplace to find a lawyer swimming in an out-dated sea. Nor are
                        we just a tech product trying to plug into an ancient system. That would be akin to sticking a
                        bandaid on a sinking ship. We're a submarine: same body of water, new way of navigating it.
                        We're an online legal community underpinned by technology.
                    </div>
                    <div className="about_us_info_text">We're legal advocacy, re-imagined for the 21st century.</div>
                </div> */}
            </Container>
        </>
    );
};
