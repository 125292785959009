import {atomFamily, selector, selectorFamily, atom} from 'recoil';
import {throwWriteOnlySelectorError, guardRecoilDefaultValue} from 'shared/utils/recoil';
import {chatIsOnlineAtom} from 'modules/chat-websocket/state/chat-online-state';

export interface IChatTypingUpdate {
    userId: string;
    chatId: string;
    isTyping: boolean;
}

export interface IChatUserKey {
    userId: string;
    chatId: string;

    [key: string]: string;
}

export const chatTypingAtom = atomFamily<string[], string>({
    key: 'chatTypingAtom',
    default: [],
});

export const chatTypingChatIdsAtom = atom<{ [key: string]: null }>({
    key: 'chatTypingChatIdsAtom',
    default: {},
});

export const otherUsersTypingChatSelector = selectorFamily<string[], IChatUserKey>({
    key: 'otherUsersTypingChatSelector',
    get: (key) => ({get}) => {
        const atomValue = get(chatTypingAtom(key.chatId));
        if (!atomValue) {
            return [];
        }
        const otherUserIds = (atomValue ?? []).filter(userId => userId !== key.userId);
        return otherUserIds.filter(userId => get(chatIsOnlineAtom(userId)));
    },
});

/**
 * Use this to update the typing status of a user.
 */
export const chatTypingInsertSelector = selector<IChatTypingUpdate>({
    key: 'chatTypingInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const atom = chatTypingAtom(newValue.chatId);
        const atomValue = get(atom);

        // update the list of users who are typing in chat
        let newAtomValue: string[] = atomValue ? [...atomValue] : [];
        if (newValue.isTyping) {
            if (!newAtomValue.includes(newValue.userId)) {
                newAtomValue.push(newValue.userId);
            }
        } else {
            newAtomValue = newAtomValue.filter(userId => userId !== newValue.userId);
        }

        // store a list of chat ids that have typing users
        const chatTypingChatIdsValue = {...get(chatTypingChatIdsAtom)};
        chatTypingChatIdsValue[newValue.chatId] = null;
        set(chatTypingChatIdsAtom, chatTypingChatIdsValue);

        set(atom, newAtomValue);
    },
});

/**
 * Use this to clear the typing status of a user when they disconnect.
 */
export const chatTypingClearSelector = selector<string>({
    key: 'chatTypingClearSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, userId) => {
        if (guardRecoilDefaultValue(userId)) {
            return;
        }
        const chatIds = get(chatTypingChatIdsAtom);

        for (const chatId in chatIds) {
            set(chatTypingInsertSelector, {
                userId,
                chatId,
                isTyping: false,
            });
        }
    },
});
