import React from 'react';

import {useSetRecoilState} from 'recoil';
import useWebSocket from 'react-use-websocket';

import {IChatWsServerMessage, ChatWsServerMessageType} from 'modules/chat-websocket/models/server-message';
import {IChatWsClientMessage} from 'modules/chat-websocket/models/client-message';
import {IUseChatWebSocketValue} from 'modules/chat-websocket/context/chatWebsocketContext';
import {chatMessageInsertSelector} from 'modules/chat-message/state';
import {chatTypingInsertSelector, chatTypingClearSelector} from 'modules/chat-websocket/state/chat-typing-state';
import {chatIsOnlineInsertSelector} from 'modules/chat-websocket/state/chat-online-state';
import {Config} from 'config';
import {useRefreshUnreadChatStatus} from 'modules/chat/hooks/useRefreshUnreadChatStatus';

// Provider hook that creates auth object and handles state
export function useChatWebsocketProvider(wsAccessToken: string): IUseChatWebSocketValue {
    const insertChatMessage = useSetRecoilState(chatMessageInsertSelector);
    const insertChatTyping = useSetRecoilState(chatTypingInsertSelector);
    const insertChatIsOnline = useSetRecoilState(chatIsOnlineInsertSelector);
    const clearChatTypingUser = useSetRecoilState(chatTypingClearSelector);
    const refreshUnreadStatus = useRefreshUnreadChatStatus();

    // const didUnmount = React.useRef<boolean>(false);
    const [didUnmount, setDidUnmount] = React.useState<boolean>(false);

    // set up the websocket connection
    const chatWsUrl = `${Config.socketConnection}ws/?token=${wsAccessToken}`;
    const {sendMessage, lastMessage} = useWebSocket(
        chatWsUrl,
        {
            onOpen: (e) => {
                // console.log('Chat WebSocket Opened: ', e);
            },
            onError: (e) => {
                // console.error('Chat WebSocket Error: ', e);
            },
            onClose: (e) => {
                // console.log('Chat WebSocket Closed: ', e);
            },
            shouldReconnect: (e) => {
                // console.log('Chat WebSocket Reconnecting: ', e);
                return !didUnmount;
            },
            reconnectInterval: 1000,
        },
    );

    // Handle new websocket messages from the server
    React.useEffect(() => {
        if (!lastMessage) {
            return;
        }

        const message: IChatWsServerMessage = JSON.parse(lastMessage.data);
        switch (message.message.type) {
            case ChatWsServerMessageType.NewMessage:
                insertChatMessage(message.message.chat_message);
                refreshUnreadStatus();
                return;
            case ChatWsServerMessageType.Typing:
                insertChatTyping({
                    userId: message.message.user_id,
                    chatId: message.message.chat_id,
                    isTyping: message.message.is_typing,
                });
                return;
            case ChatWsServerMessageType.UserOnline:
                insertChatIsOnline({
                    userId: message.message.user_id,
                    isOnline: true,
                });
                return;
            case ChatWsServerMessageType.UserOffline:
                insertChatIsOnline({
                    userId: message.message.user_id,
                    isOnline: false,
                });
                clearChatTypingUser(message.message.user_id);
                return;
            default:
                console.warn('not supported yet');
                return;
        }
    }, [lastMessage, insertChatMessage, insertChatIsOnline, insertChatTyping, clearChatTypingUser, refreshUnreadStatus]);

    React.useEffect(() => {
        setDidUnmount(false);
        return () => {
            setDidUnmount(true);
        };
    }, []);

    /**
     * Called when a user's typing status changes. Sends a message to notify other users in the chat.
     */
    const sendClientMessage = React.useMemo(() => (clientMessage: IChatWsClientMessage) => {
        sendMessage(JSON.stringify(clientMessage));
    }, [sendMessage]);

    return {
        isOnline: true,
        sendMessage: sendClientMessage,
        lastMessage,
    };
}
