import {IAvailabilitySettingsLocations} from 'modules/availability-settings/models';
import {capitalizeFirstLetterSimple, makeCommaSeparatedString} from 'shared/utils/string';

export const getLocationDescription = (
    location: IAvailabilitySettingsLocations,
    capitalize: boolean = false,
): string | undefined => {
    const locationTypes = [];
    if (location.virtual) {
        locationTypes.push('virtually');
    }
    if (location.physical) {
        locationTypes.push('in-person');
    }
    if (location.callback) {
        locationTypes.push('via callback');
    }

    if (!locationTypes.length) {
        return undefined;
    } else {
        let result = makeCommaSeparatedString(locationTypes);
        if (capitalize) {
            result = capitalizeFirstLetterSimple(result);
        }
        return result;
    }
};
