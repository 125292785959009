import {Params, useParams} from 'react-router-dom';

import {useCategory} from './useCategory';
import {useArticle} from './useArticle';

export const useArticleFromPath = () => {
    const params = useParams() as Params<'categoryId' | 'articleId'>;
    const category = useCategory(params.categoryId as string);
    const article = useArticle(params.articleId as string);

    return {
        category,
        article,
    };
};
