import {atom, selector} from 'recoil';

import {readIssueList} from 'modules/issue/api';
import {IIssue} from 'modules/issue/models';

export const issueListAtom = atom<IIssue[] | undefined>({
    key: 'issueListAtom',
    default: undefined,
});

export const issueListReadSelector = selector<IIssue[]>({
    key: 'issueListReadSelector',
    get: async ({get}) => {
        const atomValue = get(issueListAtom);
        if (atomValue) {
            return atomValue;
        }
        const issueListResult = await readIssueList({
            limit: 1000,
        });
        return issueListResult.issues;
    },
});
