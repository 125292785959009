import React from 'react';

import {Button} from 'react-bootstrap';
import {useSetRecoilState} from 'recoil';

import {FolderPath} from 'modules/file/utils/folder';
import {updateProfile} from 'modules/profile/api/profile';
import {toast} from 'shared/utils/toast';
import {insertProfileSelector} from 'modules/profile/state/profile-insert';
import {createFileFromBlob} from 'modules/file/utils';
import {toastAxiosError} from 'shared/utils/error';
import {fileListInsertSelector} from 'modules/file/state/file-list';

import {Cropper, ICropperSharedState} from 'shared/components/Cropper/Cropper';
import {Modal} from 'shared/components/modals/Modal/Modal';
import {ModalBody} from 'shared/components/modals/Modal/ModalBody';
import {ModalFooter} from 'shared/components/modals/Modal/ModalFooter';
import {Spinner} from 'shared/components/Spinner/Spinner';

interface IUploadProfilePictureModalProps {
    fileName: string;
    fileData: string;
    show?: boolean;
    userId: string;
    onClose?: () => void;
}

export const UploadProfilePictureModal = ({
    show,
    onClose,
    fileName,
    fileData,
    userId,
}: IUploadProfilePictureModalProps) => {
    const [cropperState, setCropperState] = React.useState<ICropperSharedState | undefined>(undefined);
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const insertProfile = useSetRecoilState(insertProfileSelector);
    const insertFile = useSetRecoilState(fileListInsertSelector);

    const handleClose = () => {
        onClose?.();
    };

    const saveChanges = () => {
        if (!cropperState || !userId) {
            return;
        }

        setIsSubmitting(true);

        (async () => {
            try {
                const blob = await cropperState.getImage();
                if (!blob) {
                    return;
                }
                const file = await createFileFromBlob({
                    blob,
                    filename: fileName,
                    contentType: blob.type,
                    size: blob.size,
                    userId,
                    folder: FolderPath.userPicture(userId),
                });
                const profile = await updateProfile(userId, {
                    image_id: file.id,
                });
                insertProfile(profile);
                insertFile(file);
                toast.success('Upload Profile Image', 'Profile image updated successfully');
                onClose?.();
            } catch (e) {
                toastAxiosError(e, 'Error uploading profile image');
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    const onReady = React.useMemo<(newCropperState?: ICropperSharedState) => void>(() => {
        return (newCropperState?: ICropperSharedState) => {
            setCropperState(newCropperState);
        };
    }, [setCropperState]);

    return (
        <Modal
            show={!!show}
            onHide={handleClose}
            size={'lg'}
            isSubmitting={isSubmitting}
            title="Upload Profile Picture"
        >
            <ModalBody>
                {fileName && fileData && <Cropper fileData={fileData} onReady={onReady} width={810} height={540}/>}
            </ModalBody>
            <ModalFooter>
                <Button variant="secondary" onClick={() => handleClose()} disabled={isSubmitting}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    type="button"
                    disabled={isSubmitting}
                    onClick={saveChanges}
                    className="d-flex align-items-center"
                >
                    {isSubmitting && (
                        <Spinner size="sm" className="mr-1"/>
                    )}
                    Save changes
                </Button>
            </ModalFooter>
        </Modal>
    );
};
