import React from 'react';

import {useRecoilState, useRecoilValueLoadable, RecoilState, RecoilValueReadOnly} from 'recoil';

interface IUseRecoilLoadableProps<StateType> {
    state: RecoilState<StateType | undefined>;
    loadable: RecoilValueReadOnly<StateType> | RecoilState<StateType>;
    alwaysSet?: boolean;
}

export const useRecoilSuspense = <StateType>({
    state,
    loadable,
    alwaysSet,
}: IUseRecoilLoadableProps<StateType>) => {
    const [data, setData] = useRecoilState(state);
    const dataLoadable = useRecoilValueLoadable(loadable);

    React.useEffect(() => {
        if ((alwaysSet || !data) && dataLoadable.state === 'hasValue') {
            setData(dataLoadable.contents);
        }
    }, [dataLoadable, setData, data, alwaysSet]);

    if (data) {
        return data;
    } else if (dataLoadable.state === 'hasValue') {
        return dataLoadable.contents;
    } else {
        throw dataLoadable.toPromise();
    }
};
