import React from 'react';

import {Form} from 'react-bootstrap';
import {Control} from 'react-hook-form';

import {IBookingModalConfirmationForm} from 'shared/components/BookingModal/schema';

import {InputController} from 'shared/components/form/InputController/InputController';

import './style.scss';

interface IBookingModalConfirmationSelectionProps {
    text: string;
    type: 'virtual' | 'address' | 'phone';
    placeholder: string;
    isClientCanEdit: boolean;
    disabled?: boolean;
    control: Control<IBookingModalConfirmationForm>;
    location?: string;
}

export const BookingModalConfirmationSelection = ({
    text,
    type,
    placeholder,
    isClientCanEdit,
    disabled,
    control,
    location,
}: IBookingModalConfirmationSelectionProps) => {

    return (
        <>
            {isClientCanEdit ? (
                <>
                    <Form.Text>
                        {text}
                    </Form.Text>
                    <InputController
                        className="mt-2"
                        name={type}
                        control={control}
                        placeholder={placeholder}
                        disabled={disabled}
                        readOnly={!isClientCanEdit}
                    />
                </>
            ) : (
                <>
                    <Form.Text>
                        {text}
                    </Form.Text>
                    <div className="BookingModalConfirmationSelection--text">
                        <b>
                            {location}
                        </b>
                    </div>
                </>
            )}
        </>
    );
};
