import React from 'react';

import {Dropdown, NavItem, NavLink, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRedo} from '@fortawesome/free-solid-svg-icons';

import {INotification} from 'modules/notification/models';
import {updateNotificationBatch} from 'modules/notification/api';
import {useNotificationListLoadable} from 'modules/notification/hooks/useNotificationListLoadable';
import {useBool} from 'shared/hooks/useBool';
import {useSetRecoilState} from 'recoil';
import {notificationInsertSelector} from 'modules/notification/state/notification-insert';

import {Bell} from 'shared/icons/navbar';
import {
    NavbarNotificationsItem,
} from 'shared/components/AppNavbar/NavbarNotifications/NavbarNotificationsItem/NavbarNotificationsItem';
import {NavbarNotificationBadge} from 'shared/components/AppNavbar/NavbarNotifications/NavbarNotificationsBadge';
import {Loading} from 'shared/components/loading/Loading/Loading';

import './style.scss';

export const NavbarNotifications = () => {
    const {notificationList, loading, error, reload} = useNotificationListLoadable();
    const insertNotification = useSetRecoilState(notificationInsertSelector);
    const [isMarkingUnseen, setIsMarkingUnseen] = useBool();
    const [isClosed, setIsClosed] = React.useState<boolean>(true);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [FilteredNotification, setFilteredNotification] = React.useState<INotification[]>([]);

    const handleToggle = () => {
        setIsOpen(!isOpen);
        if (isOpen) {
            setIsClosed(false);
        }
    };

    React.useEffect(() => {
        if (notificationList && notificationList.notifications.length > 0) {
            const filteredNotifications: INotification[] = notificationList.notifications?.filter((itm) => !itm.seen);
            setFilteredNotification(filteredNotifications);
            if (!isClosed && !isOpen && notificationList && !isMarkingUnseen) {
                setIsMarkingUnseen(true);
                const unseen: INotification[] = notificationList.notifications.filter(
                    (notification) => !notification.seen,
                );
                (async () => {
                    const updatedNotifications = await updateNotificationBatch(
                        unseen.map((notification) => ({
                            id: notification.id,
                            seen: true,
                        })),
                    );
                    updatedNotifications.map(insertNotification);
                })();
            } else {
                return;
            }
        }
    }, [isMarkingUnseen, setIsMarkingUnseen, notificationList, insertNotification, isOpen, isClosed]);

    return (
        <Dropdown show={isOpen} onToggle={handleToggle} as={NavItem}>
            <Dropdown.Toggle as={NavLink} className="text-dark">
                <div className="NavbarNotifications__icon-wrapper">
                    <Bell className="NavbarNotifications__icon" />
                    <NavbarNotificationBadge />
                </div>
            </Dropdown.Toggle>

            <Dropdown.Menu align="right" className="NavbarNotifications__dropdown-menu">
                {FilteredNotification.map((notification) => (
                    <NavbarNotificationsItem key={notification.id} notification={notification} />
                ))}
                {!loading && !FilteredNotification.length && (
                    <div className="NavbarNotifications__empty">You have no notifications</div>
                )}
                {loading && <Loading />}
                {error && (
                    <div className="text-center text-danger py-3">
                        Error loading notifications
                        <div>
                            <Button variant="link" onClick={reload} className="text-danger">
                                <FontAwesomeIcon icon={faRedo} />
                            </Button>
                        </div>
                    </div>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};
