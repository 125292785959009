import React from 'react';

import './style.scss';

interface IEmptyTableProps {
    children?: React.ReactNode;
}

export const EmptyTable = ({children}: IEmptyTableProps) => (
    <div className="EmptyTable">
        {children}
    </div>
);
