import React from 'react';

import InputRange, {Range} from 'react-input-range';

import {IPriceRange} from 'shared/state/profiles-page';

import 'react-input-range/lib/css/index.css';
import './style.scss';

interface IRangeSliderProps {
    value: IPriceRange;
    widthSlider?: string;
    onChange: (value: IPriceRange) => void;
}

export const RangeSlider = ({value, widthSlider, onChange}: IRangeSliderProps) => {
    const handleChange = (value: Range) => {
        onChange({
            min: value.min,
            max: value.max,
        });
    };

    return (
        <div className="RangeSlider" style={{width: widthSlider || '200px', height: '36px'}}>
            <InputRange
                draggableTrack
                onChangeComplete={(value) => handleChange((value as unknown) as Range)}
                formatLabel={(value) => `$ ${value}`}
                maxValue={10000}
                step={1}
                minValue={0}
                onChange={(value) => handleChange((value as unknown) as Range)}
                value={value}
            />
        </div>
    );
};
