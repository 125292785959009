import React from 'react';

import Croppie from 'croppie';

export type CropperGetImage = () => Promise<Blob>;

export interface ICropperSharedState {
    getImage: CropperGetImage;
}

interface ICropperProps {
    fileData: string;
    onReady?: (cropperState?: ICropperSharedState) => void;
    width: number;
    height: number;
}

export const Cropper = ({fileData, onReady, width, height}: ICropperProps) => {
    const cropperDivRef = React.useRef<HTMLDivElement>(null);
    const [croppie, setCroppie] = React.useState<Croppie | null>(null);

    React.useEffect(() => {
        if (croppie) {
            const getImage: CropperGetImage = () => {
                return new Promise<Blob>((resolve, reject) => {
                    croppie
                        .result({
                            type: 'blob',
                            size: {
                                width,
                                height,
                            },
                            circle: false,
                        })
                        .then((blob) => resolve(blob))
                        .catch((error) => reject(error));
                });
            };
            onReady?.({
                getImage,
            });
        } else {
            onReady?.(undefined);
        }
    }, [croppie, width, height, onReady]);

    React.useEffect(() => {
        const innerWidth = window.innerWidth;
        const widthPositoin = innerWidth - 40;
        const heightPosition = (widthPositoin * 2) / 3;

        if (cropperDivRef && cropperDivRef.current && fileData) {
            const croppieInstance = new Croppie(cropperDivRef.current, {
                enableExif: false,
                viewport: {
                    height: innerWidth < 360 ? widthPositoin - 50 : 360,
                    width: heightPosition < 520 ? heightPosition - 50 : innerWidth < 992 ? 400 : 520,
                },
                boundary: {
                    height: innerWidth < 360 ? widthPositoin - 20 : 380,
                    width: heightPosition < 520 ? heightPosition - 20 : innerWidth < 992 ? 458 : 580,
                },
            });
            // noinspection JSIgnoredPromiseFromCall
            croppieInstance.bind({
                url: fileData,
                zoom: 0,
            });
            setCroppie(croppieInstance);
        }
    }, [fileData]);
    return <div ref={cropperDivRef} />;
};
