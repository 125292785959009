import React from 'react';

import {useSetRecoilState} from 'recoil';
import {Container} from 'react-bootstrap';

import {IProfile} from 'modules/profile/models/IProfile';
import {useAuth} from 'shared/auth/hooks/useAuth';
import {useNavbar} from 'shared/hooks/useNavbar';
import {useProfile} from 'modules/profile/hooks/useProfile';
import {insertProfileSelector} from 'modules/profile/state/profile-insert';

import {AppFooter} from 'shared/components/AppFooter/AppFooter';
import {ProfilePageContent} from 'shared/components/ProfilePageContent/ProfilePageContent';
import {UpdateProfile} from 'shared/components/UpdateProfile/UpdateProfile';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';

export const MyProfilePage = () => {
    useNavbar(true);

    // ensure user is signed in. This component should be nested in a `ProtectedRoute` so it should never happen that
    // they aren't, but the check ensures that the linter knows `user` is not undefined below this block.
    const {tokenData} = useAuth();
    if (!tokenData) {
        throw new Error('You need to sign in again');
    }

    const profile = useProfile(tokenData.id);
    const insertProfile = useSetRecoilState(insertProfileSelector);
    const [isEdit, setIsEdit] = React.useState<boolean>(false);

    const onComplete = (updatedProfile: IProfile) => {
        insertProfile(updatedProfile);
        setIsEdit(false);
    };

    const onCancel = () => {
        setIsEdit(false);
    };

    return (
        <>
            <PageHelmet noindex title="My Profile"/>
            {isEdit ? (
                <Container>
                    <UpdateProfile profile={profile} onComplete={onComplete} onCancel={onCancel}/>
                </Container>
            ) : (
                <ProfilePageContent profile={profile}/>
            )}
            <AppFooter/>
        </>
    );
};
