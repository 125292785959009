import React from 'react';
import {MediaCard} from '../MediaCard/MediaCard';
import Skeleton from 'react-loading-skeleton';
import {Card} from 'react-bootstrap';
import {MediaCardFooter} from '../MediaCard/MediaCardFooter/MediaCardFooter';

interface IProps {
    className?: string;
}

const PostCardSkeleton = ({className}: IProps) => {
    return (
        <>
            <MediaCard className={`${className ? className : 'PostCardViws '} `}>
                <div
                    style={{
                        paddingBottom: '56%',
                    }}
                    className="MediaCardPicture"
                >
                    <div
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        <Skeleton
                            style={{
                                borderRadius: '1rem',
                                lineHeight: '2',
                                height: '100%',
                            }}
                        />
                    </div>
                </div>

                <Card.Body className="PostCard__card-body">
                    <div className="postCard_title_class" style={{cursor: 'pointer'}}>
                        <Skeleton />
                    </div>

                    <div className="PostCard__content">
                        <div className="PostCard__summary">
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            columnGap: '3px',
                            overflow: 'hidden',
                            paddingRight: '0px',
                            marginRight: '20px',
                            marginBottom: '18px',
                        }}
                        className="tag_div_main_class"
                    >
                        {/* <MediaCardTags tagIds={tagIds} /> */}
                        {[...Array(5)].map((_, index) => (
                            <Skeleton key={index} width={100} height={36} style={{borderRadius: '16px'}} />
                        ))}
                    </div>
                    <div className="PostCard_Footer_Margin_Alternative" />
                    <div className="PostCard_Footer">
                        <MediaCardFooter>
                            <div style={{columnGap: '8px'}} className="PostCard__author">
                                <Skeleton width={41} height={41} style={{borderRadius: '50%'}} />
                                <Skeleton width={140} />
                            </div>
                        </MediaCardFooter>
                    </div>
                </Card.Body>
            </MediaCard>
        </>
    );
};

export default PostCardSkeleton;
