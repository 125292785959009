import React from 'react';

import {useAuth} from 'shared/auth/hooks/useAuth';

import {FileTokenRefresherContent} from './FileTokenRefresherContent';

export const FileTokenRefresher = () => {
    const {isAuthenticated} = useAuth();

    if (isAuthenticated) {
        return (
            // Suspense is required to prevent the whole app going into suspense
            <React.Suspense fallback={null}>
                <FileTokenRefresherContent/>
            </React.Suspense>
        );
    } else {
        return null;
    }
};
