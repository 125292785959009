import axios from 'axios';

import {IReview, IReviewCreate, IReviewListQuery, IReviewListResult, IReviewUpdate} from 'modules/review/models';
import {getAccessTokenSilently} from 'shared/utils/token';
import {Config} from 'config';
import {getAuthHeaders} from 'shared/utils/auth';

const getURLForReviewAPI = (path: string) => {
    return `${Config.mainServiceURL}/reviews${path}`;
};

export const readReviewList = async (reviewListQuery: IReviewListQuery): Promise<IReviewListResult> => {
    const response = await axios.get(getURLForReviewAPI('/'), {
        params: {
            user_id: reviewListQuery.userId,
            cursor: reviewListQuery.cursor,
            limit: reviewListQuery.limit,
        },
    });
    return {
        reviews: response.data.reviews,
        nextCursor: response.data.next_cursor,
    };
};

export const createReview = async (reviewCreate: IReviewCreate): Promise<IReview> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForReviewAPI('/'), reviewCreate, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const updateReview = async (reviewId: string, reviewUpdate: IReviewUpdate): Promise<IReview> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(getURLForReviewAPI(`/${reviewId}`), reviewUpdate, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const deleteReview = async (reviewId: string): Promise<void> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.delete(getURLForReviewAPI(`/${reviewId}`), {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};
