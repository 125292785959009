import {useRecoilState} from 'recoil';

import {
    notificationListReadSelector,
    notificationListSelector,
    notificationListResetAtom,
} from 'modules/notification/state/notification-list';
import {useGuaranteedAuth} from 'shared/auth/hooks/useAuth';
import {useRecoilLoadable} from 'shared/hooks/useRecoilLoadable';

export const useNotificationListLoadable = () => {
    const {tokenData} = useGuaranteedAuth();
    const {data, loading, error} = useRecoilLoadable({
        state: notificationListSelector(tokenData.id),
        loadable: notificationListReadSelector(tokenData.id),
    });
    const [resetVersion, setResetVersion] = useRecoilState(notificationListResetAtom);
    return {
        notificationList: data,
        loading,
        error,
        reload: () => setResetVersion(resetVersion + 1),
    };
};
