import {useRecoilLoadable} from 'shared/hooks/useRecoilLoadable';
import {issueListAtom, issueListReadSelector} from 'modules/issue/state';

export const useIssueListLoadable = () => {
    const {data, loading, error} = useRecoilLoadable({
        state: issueListAtom,
        loadable: issueListReadSelector,
    });
    return {
        issues: data,
        loading,
        error,
    };
};
