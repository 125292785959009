import {atomFamily, selector} from 'recoil';

import {throwWriteOnlySelectorError, guardRecoilDefaultValue} from 'shared/utils/recoil';

interface IChatIsOnlineUpdate {
    userId: string;
    isOnline: boolean;
}

/**
 * Use this to read whether someone is online or offline.
 */
export const chatIsOnlineAtom = atomFamily<boolean, string>({
    key: 'chatIsOnlineAtom',
    default: false,
});

/**
 * Use this to change whether someone is online or offline.
 */
export const chatIsOnlineInsertSelector = selector<IChatIsOnlineUpdate>({
    key: 'chatIsOnlineInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        set(chatIsOnlineAtom(newValue.userId), newValue.isOnline);
    },
});
