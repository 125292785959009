import React from 'react';

import classNames from 'classnames';

interface IDaySelectDayProps {
    title: string;
    onClick: () => void;
    active: boolean;
    day: number;
    inactive: boolean;
    disabled?: boolean;
    invalid?: boolean;
}

export const DaySelectDay = ({day, onClick, active, title, inactive, disabled, invalid}: IDaySelectDayProps) => {
    const className = classNames('ml-1 DaySelect__day', {
        'DaySelect__day--active': active,
        'DaySelect__day--inactive': inactive,
        'DaySelect__day--disabled': disabled,
        'DaySelect__day--invalid': invalid,
    });

    return (
        <div className={className} onClick={() => onClick()}>
            <div style={{fontSize: 20}}>{day}</div>
            <div style={{fontSize: 14}}>{title}</div>
        </div>
    );
};
