export enum Profession {
    BARRISTER = 'BARRISTER',
    SOLICITOR = 'SOLICITOR',
    LAWYER = 'LAWYER',
    PARALEGAL = 'PARALEGAL',
    SENIOR_IN_HOUSE_COUNSEL = 'SENIOR_IN_HOUSE_COUNSEL',
    LEGAL_COUNSEL = 'LEGAL_COUNSEL',
    ATTORNEY = 'ATTORNEY',
    PATENT_ATTORNEY = 'PATENT_ATTORNEY',
    COMPANY_SECRETARY = 'COMPANY_SECRETARY',
    COMPLIANCE_MANAGER = 'COMPLIANCE_MANAGER',
    RISK_PROFESSIONAL = 'RISK_PROFESSIONAL',
    POLICY_WRITER = 'POLICY_WRITER',
    EXPERT_WITNESS = 'EXPERT_WITNESS',
    LEGAL_TECH_PROFESSIONAL = 'LEGAL_TECH_PROFESSIONAL',
    LEGAL_OPERATIONS = 'LEGAL_OPERATIONS',
    REGULATORY_AFFAIRS = 'REGULATORY_AFFAIRS',
    SPECIALIST = 'SPECIALIST',
    AI_EXPERT = 'AI_EXPERT',
    BARRISTERS_CLERK = 'BARRISTERS_CLERK',
    PRACTICE_MANAGER = 'PRACTICE_MANAGER',
    GOVERNANCE_EXPERT = 'GOVERNANCE_EXPERT',
    JUNIOR_LAWYER_4_YEARS_PQE = 'JUNIOR_LAWYER_4Y_PQE',
    EXTERNAL_SUPPORT_SERVICE = 'EXTERNAL_SUPPORT_SERVICE',
    CONVEYANCER = 'CONVEYANCER',
    OTHER = 'OTHER',
}
