import React from 'react';

import { Container } from 'react-bootstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { AppFooter } from 'shared/components/AppFooter/AppFooter';

import './style.scss';

interface IAuthPageProps {
    title: string;
    description?: string;
    className?: string;
    terms?: boolean;
    size?: 'sm' | 'md' | 'lg'
}

export const AuthPage = ({title, description, terms, className, children, size}: React.PropsWithChildren<IAuthPageProps>) => {
    const showTerms = terms !== false; // if `terms` is undefined it defaults to `true`

    const containerClassName = classNames('AuthPage__container', {
        [`AuthPage__container--${size}`]: size,
    });

    return (
        <div className={classNames('AuthPage', className)}>
            <h1 className="AuthPage__title">{title}</h1>
            <Container className={containerClassName}>
                {description && (
                    <p className="AuthPage__description">{description}</p>
                )}
                {children}
                {showTerms && (
                    <p className="AuthPage__terms">
                        Proceeding means you agree to our<br /><Link to="/terms">Terms and
                        Conditions</Link> and <Link to="/privacy">Privacy Policy</Link>.
                    </p>
                )}
            </Container>
            <AppFooter />
        </div>
    );
};


