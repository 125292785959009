import React from 'react';

import {ErrorBoundary} from 'react-error-boundary';

import {IPendingFile} from 'shared/models/pending-file/IPendingFile';
import {FolderPath} from 'modules/file/utils/folder';

import {FileNameLoaded} from 'shared/components/form/ResumeController/FileNameLoaded';
import {FileDropzone} from 'shared/components/form/FileDropzone/FileDropzone';
import {DeleteIcon, PDFIcon, UploadIcon} from 'shared/icons/EditProfile';

// TODO: import from a better location
import {
    ImageUploadedContainer,
} from 'shared/components/project/CreateProjectModal/ImageUploadedContainer/ImageUploadedContainer';

import './style.scss';

interface IResumeUploaderProps {
    userId: string;
    disabled?: boolean;
    onChange: (fileId?: string) => void;
    fileId?: string;
}

export const ResumeUploader = ({
    userId,
    fileId,
    disabled,
    onChange,
}: IResumeUploaderProps) => {
    const [pendingFiles, setPendingFiles] = React.useState<IPendingFile[]>([]);
    const [completedFiles, setCompletedFiles] = React.useState<IPendingFile[]>([]);

    const onDeleteClick = (e: React.MouseEvent<SVGElement>) => {
        e.stopPropagation();

        onChange('');
        setCompletedFiles([]);
    };
    const onDeleteFile = (file?: IPendingFile) => {
        onChange('');
        setCompletedFiles([]);
    };

    return (
        <>
            <FileDropzone
                userId={userId}
                onFileAdded={(file) => {
                    onChange(file.id);
                }}
                onSuccessFilesChanges={(PendingFiles) => {
                    setCompletedFiles(PendingFiles);
                }}
                onPendingFilesChange={(newPendingFiles) => setPendingFiles(newPendingFiles)}
                disabled={disabled}
                multiple={false}
                className="ResumeController__resume--upload"
                folder={FolderPath.userPublic(userId)}
            >
                <div className="ResumeController__resume--upload--flex-center">
                    <div className="ResumeController__resume--upload--left">
                        <PDFIcon/>
                    </div>
                    <div className="ResumeController__resume--upload--left--center text-left pl-4">
                        <ErrorBoundary
                            fallback={
                                <p className={'m-0 text-left ResumeController__resume--place-holder'}>
                                    Upload Resume...
                                </p>
                            }
                        >
                            {fileId || !!pendingFiles.length ? (
                                fileId && (
                                    <FileNameLoaded fileId={fileId} pendingFiles={pendingFiles}/>
                                )
                            ) : (
                                <p className={'m-0 text-left ResumeController__resume--place-holder'}>
                                    Upload Resume...
                                </p>
                            )}
                        </ErrorBoundary>
                    </div>
                    <div className="ResumeController__resume--upload--right--center">
                        <UploadIcon/>
                    </div>
                    {!!fileId && (
                        <div className="ResumeController__resume--upload--right ml-3">
                            <DeleteIcon className="ResumeController__resume--upload__deleteBtn" onClick={onDeleteClick}/>
                        </div>
                    )}
                </div>
            </FileDropzone>
            {completedFiles.map(file => (
                <ImageUploadedContainer
                    key={file.id}
                    onDelete={() => onDeleteFile(file)}
                    name={file.filename}
                    percentage={file.isComplete}
                />
            ))}
        </>
    );
};
