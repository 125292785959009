import React from 'react';

import {Container} from 'react-bootstrap';

import './style.scss';

export const GifInEcoSystem = () => {
    const gifRef = React.useRef<HTMLDivElement | null>(null);
    const mainDivRef = React.useRef<HTMLDivElement | null>(null);
    const lastScrollTop = React.useRef<number>(0);
    const gifTimeout = React.useRef<ReturnType<typeof setTimeout> | null>(null);
    const [showSplashGif, setShowSplashGif] = React.useState<boolean>(false);
    const [showAllGif, setShowAllGif] = React.useState<boolean>(true);

    React.useEffect(() => {
        const clearGifTimeout = () => {
            if (gifTimeout.current) {
                clearTimeout(gifTimeout.current);
            }
        };
        const handleScrollUpDown = () => {
            if (mainDivRef && 'current' in mainDivRef && mainDivRef.current) {
                const positionVal = mainDivRef.current.getBoundingClientRect();
                const st = window.pageYOffset || document.documentElement.scrollTop;
                if (st > lastScrollTop.current) {
                    if (window.innerWidth < 463) {
                        if (positionVal.top < window.innerHeight && positionVal.top > window.innerHeight - 300) {
                            setShowAllGif(true);
                            setShowSplashGif(false);
                            clearGifTimeout();
                            gifTimeout.current = setTimeout(() => {
                                setShowSplashGif(true);
                            }, 9000);
                        }
                    } else if (window.innerWidth < 767) {
                        if (positionVal.top < window.innerHeight && positionVal.top > window.innerHeight - 300) {
                            setShowAllGif(true);
                            setShowSplashGif(false);
                            clearGifTimeout();
                            gifTimeout.current = setTimeout(() => {
                                setShowSplashGif(true);
                            }, 9000);
                        }
                    } else if (window.innerWidth < 992) {
                        if (positionVal.top < window.innerHeight && positionVal.top > window.innerHeight - 300) {
                            setShowAllGif(true);
                            setShowSplashGif(false);
                            clearGifTimeout();
                            gifTimeout.current = setTimeout(() => {
                                setShowSplashGif(true);
                            }, 9000);
                        }
                    } else if (window.innerWidth < 1199) {
                        if (positionVal.top < window.innerHeight && positionVal.top > window.innerHeight - 300) {
                            setShowAllGif(true);
                            setShowSplashGif(false);
                            clearGifTimeout();
                            gifTimeout.current = setTimeout(() => {
                                setShowSplashGif(true);
                            }, 9000);
                        }
                    } else {
                        if (positionVal.top < window.innerHeight && positionVal.top > window.innerHeight - 300) {
                            setShowAllGif(true);
                            setShowSplashGif(false);
                            clearGifTimeout();
                            gifTimeout.current = setTimeout(() => {
                                setShowSplashGif(true);
                            }, 9000);
                        }
                    }
                } else {
                    if (positionVal.top >= window.innerHeight + 25 && positionVal.bottom >= window.innerHeight) {
                        setShowSplashGif(true);
                        setShowAllGif(false);
                    }
                }
                lastScrollTop.current = st <= 0 ? 0 : st;
            }
        };

        window.addEventListener('scroll', handleScrollUpDown, true);
        return () => {
            clearGifTimeout();
            window.removeEventListener('scroll', handleScrollUpDown, true);
        };
    }, []);

    return (
        <div className="mainDivRef" ref={mainDivRef}>
            <Container>
                <div className="HomePageFeatures__item HomePageFeatures__item--reverse">
                    <div className="HomePageFeatures__item-image-col">
                        <div className="HomePageFeatures__item-image-wrapper AboutUsPage__gif--Container" ref={gifRef}>
                            {showAllGif &&
                                (showSplashGif ? (
                                    <img
                                        className="img-fluid"
                                        src="/assets/homepage/RIGHTFUL_Animation_B_MASTER.gif"
                                        alt="second gif"
                                    />
                                ) : (
                                    <img
                                        className="img-fluid"
                                        id="first_img"
                                        src={'/assets/homepage/rightful_a_compress_img.gif?t='}
                                        alt="first gif"
                                    />
                                ))}
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};
