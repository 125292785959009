import React from 'react';

import './style.scss';

interface IGuideToChoosingQNAProps {
    title: string;
    children: React.ReactNode;
}

export const GuideToChoosingQNA = ({title, children}: IGuideToChoosingQNAProps) => {

    return (
        <div className="GuideToChoosingQNA">
            <div className="GuideToChoosingQNA__question">
                {title}
            </div>
            <div className="GuideToChoosingPage__paragraph">
                {children}
            </div>
        </div>
    );
};
