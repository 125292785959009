import React, {useReducer, useState} from 'react';

import {Col, Container, Image, Row} from 'react-bootstrap';
import classNames from 'classnames';

import {IFile} from 'modules/file/models/IFile';
import {getFileDownloadUrl} from 'modules/file/utils';

import './style.scss';
import {useProfilePictureFileListLoadable} from 'shared/hooks/useProfilePictureFileListLoadable';
import {EmptyTable} from 'shared/components/EmptyTable/EmptyTable';
import {PageError} from 'shared/components/PageError/PageError';
import {Loading} from 'shared/components/loading/Loading/Loading';

interface IProfileImageListProps {
    userId: string;
    onChange: (file: IFile) => void;
    className?: string;
}

export const ProfileImageList = ({userId, onChange, className}: IProfileImageListProps) => {
    const [selected, setSelected] = useState<string>('');
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const {fileList, loading, error} = useProfilePictureFileListLoadable(userId);

    function getFileClassName(file: IFile) {
        return classNames('ProfileImageList__image dropdown-item', {
            'ProfileImageList__image--selected': file.id === selected,
        });
    }

    const onFileClick = (file: IFile) => {
        setSelected(file.id);
        onChange(file);
        forceUpdate();
    };

    return (
        <Container className={className}>
            {loading && (
                <Loading/>
            )}
            {error && (
                <PageError description="Error loading profile images"/>
            )}
            {fileList && !fileList?.files?.length && (
                <EmptyTable>
                    You do not have any previously uploaded profile images.
                </EmptyTable>
            )}
            {fileList && !!fileList?.files?.length && (
                <Row xs={2} sm={4}>
                    {fileList.files.map(file => (
                        <Col key={file.id}>
                            <Image
                                src={getFileDownloadUrl({
                                    id: file.id,
                                    filename: file.filename,
                                    public_url: file.public_url,
                                })}
                                className={getFileClassName(file)}
                                onClick={() => onFileClick(file)}
                                thumbnail
                            />
                        </Col>
                    ))}
                </Row>
            )}
        </Container>
    );
};
