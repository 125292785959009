import React from 'react';

import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {Form, Dropdown} from 'react-bootstrap';
import {useCombobox} from 'downshift';
import classNames from 'classnames';
import {useNavigate} from 'react-router-dom';

import {IHelpSearchDocument} from 'modules/help/models';
import {readHelpSearch} from 'modules/help/api';
import {getURLForHelpSearchDocument} from 'modules/help/utils/help-search-utils';

import {HelpSearchResult} from 'public/help-center/components/HelpSearch/HelpSearchResult';

import './style.scss';

export const HelpSearch = () => {
    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const navigate = useNavigate();
    const [resultsToDisplay, setResultsToDisplay] = React.useState<IHelpSearchDocument[]>([]);
    const [hasSuccess, setHasSuccess] = React.useState<boolean>(false);
    const cancelToken = axios.CancelToken.source();

    React.useEffect(() => {
        (async () => {
            if (searchTerm.length) {
                try {
                    const result = await readHelpSearch({
                        search_term: searchTerm,
                        is_published: true,
                        limit: 20,
                        cancelToken: cancelToken.token,
                    });
                    setResultsToDisplay(result.documents);
                    setHasSuccess(true);
                } catch (e) {
                    if (e instanceof axios.Cancel) {
                        console.debug('Search cancelled');
                    } else {
                        console.error(e);
                    }
                }
            } else {
                setResultsToDisplay([]);
                setHasSuccess(false);
            }
        })();
        return () => {
            cancelToken.cancel();
        };
        // IMPORTANT: do not add cancelToken to the hook dependencies array otherwise it will trigger an infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    const {
        isOpen,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        highlightedIndex,
        getItemProps,
        selectedItem,
    } = useCombobox({
        inputValue: searchTerm,
        items: resultsToDisplay,
        onSelectedItemChange: ({selectedItem}) => {
            if (!selectedItem) {
                return;
            }
            const url = getURLForHelpSearchDocument(selectedItem);
            if (url) {
                navigate(url);
                setSearchTerm('');
            }
        },
        isOpen: !searchTerm.length ? false : undefined,
    });

    const dropdownMenuClassNameComputed = classNames('dropdown-menu dropdown-menu-right w-100', {
        'show': isOpen && hasSuccess,
    });

    return (
        <div className="HelpSearch">
            <Dropdown show={isOpen} className="HelpSearch__dropdown">
                <div {...getComboboxProps({
                    className: 'HelpSearch__control-wrapper',
                })}>
                    <FontAwesomeIcon className="HelpSearch__icon" icon={faSearch}/>
                    <Form.Control
                        name="search_term"
                        type="text"
                        className="HelpSearch__control"
                        placeholder="Find an answer to your question"
                        autoFocus
                        {...getInputProps({
                            onChange: (e) => setSearchTerm((e.target as HTMLInputElement).value),
                        })}
                    />
                </div>
                <ul {...getMenuProps({className: dropdownMenuClassNameComputed})}>
                    {isOpen && !!resultsToDisplay.length && resultsToDisplay.map((document, index) => (
                        <HelpSearchResult
                            key={`${document.id}${index}`}
                            {...getItemProps({
                                key: document.id,
                                index,
                                item: document,
                                style: {
                                    backgroundColor: highlightedIndex === index ? 'lightgray' : 'white',
                                    fontWeight: selectedItem === document ? 'bold' : 'normal',
                                },
                            })}
                            document={document}
                            highlighted={highlightedIndex === index}
                        />
                    ))}
                    {isOpen && hasSuccess && !resultsToDisplay.length && (
                        <Dropdown.Item>No Results</Dropdown.Item>
                    )}
                </ul>
            </Dropdown>
        </div>
    );
};
