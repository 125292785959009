import {IProfile} from 'modules/profile/models/IProfile';
import {IContactDetails} from 'modules/profile/models/IContactDetails';
import {IServiceDetails} from 'modules/profile/models/IServiceDetails';
import {Profession} from 'modules/profile/models/profession';
import {ServiceType} from 'modules/profile/models/serviceType';
import {ISkill} from 'modules/profile/models/ISkill';
import {IWorkExperience} from 'modules/profile/models/IWorkExperience';
import {ISocialLink} from 'modules/profile/models/ISocialLink';

export const getContactDetailsFormDefaults = (contactDetails: IContactDetails | undefined) => {
    return {
        email: contactDetails?.email || '',
        phone_number: contactDetails?.phone_number || '',
        address: contactDetails?.address || '',
        country: contactDetails?.country || '',
        state: contactDetails?.state || '',
        city: contactDetails?.city || '',
    };
};

export const getServiceDetailsFormDefaults = (serviceDetails: IServiceDetails | undefined) => {
    return {
        day_rate: serviceDetails?.day_rate || 0,
        hour_rate: serviceDetails?.hour_rate || 0,
        service_types: serviceDetails?.service_types || [],
        fixed_fee_services: serviceDetails?.fixed_fee_services || [],
    };
};

export const getFormDefaultsFromProfile = (profile: IProfile) => {
    return {
        firstname: profile.firstname || '',
        lastname: profile.lastname || '',
        title: profile.title || '',
        password: '',
        contact_details: getContactDetailsFormDefaults(profile.contact_details),
        contact_details_display: getContactDetailsFormDefaults(profile.contact_details_display),
        resume_id: profile.resume_id,
        profession: profile.profession,
        summary: profile.summary || '',
        claim_to_fame: profile.claim_to_fame || '',
        service_types: profile.service_types,
        service_details: getServiceDetailsFormDefaults(profile.service_details),
        skills: profile.skills || [],
        work_experience: profile.work_experience || [],
        social_links: profile.social_links || [],
        tag_ids: profile.tag_ids,
    };
};

export interface IProfileSettingsForm {
    firstname: string;
    lastname: string;
    title?: string;
    email: string;
    password: string;
    contact_details: IContactDetails;
    contact_details_display: IContactDetails;
    profession: Profession;
    summary?: string;
    claim_to_fame?: string;
    resume_id?: string;
    service_details: IServiceDetails;
    service_types: ServiceType[];
    skills: ISkill[];
    work_experience: IWorkExperience[];
    tag_ids: string[];
    social_links: ISocialLink[];
}
