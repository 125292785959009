export enum ProjectStatus {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
}

export interface IProjectLinkCreate {
    url: string;
    name?: string;
}

export interface IProjectLinkUpdate {
    name: string;
}

export interface IProjectLink {
    id: string;
    url: string;
    created_by: string;
    created_at: string;
    name?: string;
}

export interface IProject {
    id: string;
    status: ProjectStatus;
    title: string;
    description?: string;
    member_ids: string[];
    is_deleted: boolean;
    created_at: string;
    updated_at: string;
    links: IProjectLink[];
    owner_id: string;
    created_by_id: string;
    // DEPRECATED: replace with owner_id and created_by_id
    // user_id: string;
}

export interface IProjectUpdate {
    status?: ProjectStatus
    title?: string;
    description?: string;
    member_ids?: string[];
}

export interface IProjectCreate {
    status: ProjectStatus;
    title: string;
    shareLink:string;
    image:File;
    description?: string;
    member_ids: string[];
}

export interface IProjectListQuery {
    owner_id?: string;
    created_by_id?: string;
    member_id?: string;
    status?: ProjectStatus;
    cursor?: string;
    limit?: number

    [key: string]: string | boolean | undefined | number | ProjectStatus;
}

export interface IProjectListResult {
    projects: IProject[];
    next_cursor?: string;
}

export interface IProjectInviteCreate {
    rightful_member_ids: string[];
    public_emails: string[];
    invited_by_id: string;
}

export interface IProjectOwnerUpdate {
    new_owner_id: string;
}