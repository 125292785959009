import React from 'react';
import {Card} from 'react-bootstrap';

interface Props {
    personImage: string;
    name: string;
}

export const AdvisoryCard = (props: Props) => {
    const {personImage, name} = props;

    return (
        <>
            <Card className="AdvisoryCard">
                <Card.Img className="AdvisoryCard__img" variant="top" src={personImage} />
                <Card.Body className="AdvisoryCard__body">
                    <div className="AdvisoryCard__body__name">{name}</div>
                </Card.Body>
            </Card>
        </>
    );
};
