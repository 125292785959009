import React from 'react';

import {Form, FormControlProps} from 'react-bootstrap';
import {Controller, FieldValues, Path, Control} from 'react-hook-form';

import {InputControl} from 'shared/components/form/InputControl/InputControl';

interface IFormControllerProps<FormType> extends FormControlProps {
    control: Control<FormType>;
    name: Path<FormType>;
    id?: string;
    disabled?: boolean;
    label?: React.ReactNode;
    className?: string;
    labelClassName?: string;
    inputClassName?: string;
    children?: React.ReactNode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formatValue?: (value: string) => any;
    rows?: number;
    placeholder?: string;
    autoFocus?: boolean;
    after?: React.ReactNode;
    maxlength?: number;
}

export const FormController = <FormType extends FieldValues>({
    disabled,
    className,
    name,
    control,
    id,
    label,
    labelClassName,
    inputClassName,
    formatValue,
    after,
    ...rest
}: IFormControllerProps<FormType>) => {

    const getFormattedValue = (value: string) => {
        return formatValue ? formatValue(value) : value;
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({field: {value, onChange, ...field}, fieldState}) => (
                <Form.Group controlId={id ?? name} className={className}>
                    {label && (
                        <Form.Label className={labelClassName}>
                            {label}
                        </Form.Label>
                    )}
                    <InputControl
                        className={inputClassName}
                        disabled={disabled}
                        isInvalid={fieldState.invalid}
                        value={value ?? ''}
                        {...field}
                        {...rest}
                        onChange={(e) => onChange(getFormattedValue(e.target.value))}
                    />
                    {after}
                    {fieldState.error && (
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {fieldState.error.message}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
            )}
        />
    );
};
