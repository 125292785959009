import React, {useRef, useState} from 'react';
import Slick, {Settings} from 'react-slick';
import classNames from 'classnames';

import {PostKind} from 'modules/post/models/postKind';
import {IPost} from 'modules/post/models/IPost';
import {useWindowSize} from 'shared/hooks/useWindowSize';

import {PostCard} from 'shared/components/PostCard/PostCard';
import {CardCarousel} from 'shared/components/CardCarousel/CardCarousel';
import {MediaCardDeck} from 'shared/components/MediaCard/MediaCardDeck/MediaCardDeck';
import {CardCarouselEmpty} from 'shared/components/CardCarousel/CardCarouselEmpty/CardCarouselEmpty';
import {ProfilePageEmpty} from '../ProfilePageContent/shared/ProfilePageEmpty/ProfilePageEmpty';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.scss';
import 'animate.css';

interface IPostCarouselProps {
    firstname?: string;
    kind?: PostKind;
    posts?: IPost[];
    loading?: boolean;
    error?: string;
}

export const PostCarousel = ({loading, firstname, kind, error, posts}: IPostCarouselProps) => {
    const windowSize = useWindowSize();
    const sliderRef = useRef<Slick | null>(null);
    const [slideIndex, setSlideIndex] = useState<number>(0);

    const filteredPosts = React.useMemo(() => {
        return posts ? posts.filter((post) => !kind || post.kind === kind) : [];
    }, [kind, posts]);

    // define the number of slides to show based on the screen size
    const slidesToShow = React.useMemo(() => {
        if (windowSize.width < 1400) {
            return 1;
        } else if (windowSize.width < 1800) {
            return 2;
        } else {
            return 3;
        }
    }, [windowSize.width]);

    const slickSettings: Settings = {
        draggable: true,
        arrows: false,
        slidesToShow: filteredPosts.length > 2 ? 3 : filteredPosts.length,
        slidesToScroll: filteredPosts.length > 1 ? 1 : filteredPosts.length,
        infinite: false,
        speed: 1000,
        beforeChange: (prev, next) => {
            setSlideIndex(next);
        },
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: filteredPosts.length > 1 ? 2 : 1,
                    slidesToScroll: 1,
                },
            },
        ],
        lazyLoad: 'ondemand',
    };

    const cardDeckClassName = classNames({
        'default-desk-class-1': filteredPosts?.length === 1,
        'default-desk-class-2': filteredPosts?.length === 2,
        'default-desk-class-3': filteredPosts?.length === 3,
        'default-desk-class': filteredPosts?.length !== 1 && filteredPosts?.length !== 2 && filteredPosts?.length !== 3,
    });

    const cardClassName = classNames('PostCard', {
        'm-auto': filteredPosts?.length !== 2 || windowSize.width < 1400,
    });

    return (
        <CardCarousel
            onNext={() => sliderRef && sliderRef.current && sliderRef.current.slickNext()}
            onPrev={() => sliderRef && sliderRef.current && sliderRef.current.slickPrev()}
            hasPrev={slideIndex > 0}
            hasMore={filteredPosts && slideIndex < filteredPosts.length - slidesToShow}
            loading={loading}
            isChevrons={true}
            error={error}
            skeleton={
                // DISPLAYING EMPTY PLACEHOLDER
                <ProfilePageEmpty>
                    <div className="ProfilePageAbout--empty justify-content-center align-content-center">
                        <p className="NoData mb-0">
                            {firstname ? `${firstname} has not yet uploaded any posts` : 'There are no resources.'}
                        </p>
                        <img src="/assets/profileIcons/resource.svg" className="AboutmeIMG" alt="" />
                    </div>
                </ProfilePageEmpty>
            }
        >
            {!!filteredPosts.length ? (
                <MediaCardDeck className={cardDeckClassName}>
                    <Slick ref={sliderRef} {...slickSettings}>
                        {filteredPosts &&
                            filteredPosts.map((post, index) => (
                                <div
                                    key={post.id}
                                    className="m-auto PostCard__wrap--div mobilePostView mb-3 animate__animated animate__fadeIn "
                                >
                                    <PostCard
                                        kind={post.kind}
                                        postId={post.id}
                                        userId={post.user_id}
                                        title={post.title}
                                        summary={post.summary}
                                        tagIds={post.tag_ids}
                                        media={post.media}
                                        className={cardClassName}
                                        isTruncated={true}
                                    />
                                </div>
                            ))}
                    </Slick>
                </MediaCardDeck>
            ) : (
                <CardCarouselEmpty>
                    {firstname ? `${firstname} has not yet uploaded any posts` : 'There are no resources.'}
                </CardCarouselEmpty>
            )}
        </CardCarousel>
    );
};
