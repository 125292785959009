import React from 'react';

import {IUseChatWebSocketValue, chatWebsocketContext} from 'modules/chat-websocket/context/chatWebsocketContext';

interface IChatWebSocketProviderNotAuthenticatedProps {
    children?: React.ReactNode;
}

export const ChatWebSocketProviderNotAuthenticated = ({children}: IChatWebSocketProviderNotAuthenticatedProps) => {
    const notAuthenticatedValue: IUseChatWebSocketValue = {
        isOnline: false,
        lastMessage: null,
        // The user is not authenticated, do nothing:
        sendMessage: () => {
            return;
        },
    };

    return (
        <chatWebsocketContext.Provider value={notAuthenticatedValue}>
            {children}
        </chatWebsocketContext.Provider>
    );
};
