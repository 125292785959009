import React from 'react';

import {Scope} from 'shared/auth/models';

import {RequireSignup} from 'auth/RequireSignup/RequireSignup';
import {RequireAuthContent} from 'auth/RequireAuth/RequireAuthContent';

interface IRequireAuthProps {
    scopes?: Scope[];
}

export const RequireAuth = ({scopes, children}: React.PropsWithChildren<IRequireAuthProps>) => {
    return (
        <RequireSignup>
            <RequireAuthContent scopes={scopes}>
                {children}
            </RequireAuthContent>
        </RequireSignup>
    );
};
