import {atom, selector, selectorFamily} from 'recoil';

import {IReview, IReviewListQuery} from 'modules/review/models';
import {CursorList} from 'shared/types/cursor-list';
import {readReviewList} from 'modules/review/api';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/utils/recoil';

export interface IReviewListSelectorKey {
    filters: IReviewListQuery;
    page: number;

    [key: string]: number | IReviewListQuery;
}

interface IReviewListAtom {
    filters: IReviewListQuery;
    reviews: IReview[];
    cursors: CursorList;
    page: number;
    more: boolean;
    resetVersion: number;

    [key: string]: number | boolean | IReview[] | CursorList | IReviewListQuery;
}

const compareFilters = (filters1: IReviewListQuery, filters2: IReviewListQuery) => {
    return (
        filters1.userId === filters2.userId &&
        filters1.limit === filters2.limit &&
        filters1.cursor === filters2.cursor
    );
};

export const reviewListAtom = atom<IReviewListAtom | undefined>({
    key: 'reviewListAtom',
    default: undefined,
});

export const resetReviewList = atom<number>({
    key: 'resetReviewList',
    default: 1,
});

export const reviewListSelector = selectorFamily<IReviewListAtom | undefined, IReviewListSelectorKey>({
    key: 'reviewListSelector',
    get: ({page, filters}) => ({get}) => {
        const atomValue = get(reviewListAtom);
        const resetVersion = get(resetReviewList);
        if (
            atomValue &&
            page === atomValue.page &&
            compareFilters(filters, atomValue.filters) &&
            atomValue.resetVersion === resetVersion
        ) {
            return {...atomValue};
        }

        return undefined;
    },
    set: () => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        set(reviewListAtom, newValue);
    },
});

export const reviewListReadSelector = selectorFamily<IReviewListAtom, IReviewListSelectorKey>({
    key: 'reviewListReadSelector',
    get: ({page, filters}) => async ({get}) => {
        const currentValue = get(reviewListSelector({page, filters}));
        if (currentValue) {
            return currentValue;
        }

        const atomValue = get(reviewListAtom);
        const resetVersion = get(resetReviewList);

        // load a fresh page from the server
        const result = await readReviewList({
            ...filters,
            cursor: page >= 0 && atomValue ? atomValue.cursors[page - 1] : undefined,
        });

        // add the cursor to the list of cursors so we can paginate backwards
        const newCursors = atomValue ? Array.from(atomValue.cursors) : [];
        newCursors[page] = result.nextCursor;

        return {
            filters,
            reviews: result.reviews,
            cursors: newCursors,
            page,
            more: !!result.nextCursor,
            hasLoaded: true,
            resetVersion: resetVersion,
        } as IReviewListAtom;
    },
});

export const reviewListRemoveSelector = selector<string>({
    key: 'reviewListRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        // TODO: update totals once we have "counts"
        const atomValue = get(reviewListAtom);
        if (atomValue) {
            set(reviewListAtom, {
                ...atomValue,
                reviews: atomValue.reviews.filter((review) => review.id !== newValue),
            });
        }
    },
});

export const reviewListInsertSelector = selector<IReview>({
    key: 'reviewListInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        // if the review is present in the store then update it. Otherwise force a reload.
        const atomValue = get(reviewListAtom);
        if (atomValue) {
            const newReviews = Array.from(atomValue.reviews);
            const reviewIndex = newReviews.findIndex((review) => review.id === newValue.id);
            if (reviewIndex !== -1) {
                newReviews.splice(reviewIndex, 1, newValue);
                set(reviewListAtom, {
                    ...atomValue,
                    reviews: newReviews,
                });
                return;
            }
        }
        set(resetReviewList, get(resetReviewList) + 1);
    },
});
