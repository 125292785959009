import {FolderPath} from 'modules/file/utils/folder';
import {useFileListLoadable} from 'modules/file/hooks/useFileListLoadable';

export const useProfilePictureFileListLoadable = (userId: string) => {
    return useFileListLoadable({
        userId,
        limit: 100,
        folder: FolderPath.userPicture(userId),
        page: 0,
    });
};
