import React from 'react';

import Skeleton from 'react-loading-skeleton';

import './style.scss';

export const BookingModalFormSkeleton = () => {

    return (
        <div className="SkeletonModal">
            <div className="SkeletonModal__header">
                <div className="SkeletonModal__header__profile">
                    <Skeleton
                        style={{
                            height: '100%',
                        }}
                        circle={true}
                    />
                </div>
                <div className="SkeletonModal__header__heading">
                    <Skeleton className="my-3" style={{height: '25px'}} />
                    <Skeleton style={{height: '25px'}} />
                </div>
                <div className="SkeletonModal__header__timing">
                    <Skeleton style={{height: '25px', marginTop: '17px'}} />
                </div>
            </div>
            <div className="SkeletonModal__deviderLine" />
            <div className="SkeletonModal__timeslote">
                <Skeleton style={{width: '200px', height: '25px'}} />
                <div className="SkeletonModal__timeslote__selectiontime">
                    <Skeleton style={{width: '299px', height: '35px'}} />
                    <Skeleton style={{width: '120px', height: '25px'}} />
                </div>
                <div style={{position: 'relative'}} className="SkeletonModal__timeslote__timeslot">
                    <Skeleton circle={true} style={{width: '30px', height: '30px'}} />
                    {[...Array(10).keys()].map((itm: number, index: number) => (
                        <div key={index} className="SkeletonModal__timeslote__selectiontime__dates">
                            <Skeleton
                                style={{
                                    width: '60px',
                                    height: '71px',
                                    borderRadius: '8px',
                                }}
                            />
                        </div>
                    ))}
                    <div
                        className="SkeletonModal__timeslote__timeslot__rightArrow"
                        style={{
                            position: 'absolute',
                            right: '-2px',
                        }}
                    >
                        <Skeleton
                            circle={true}
                            style={{
                                width: '30px',
                                height: '30px',
                            }}
                        />
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center p-3">
                    {[...Array(14).keys()].map((itm: number, index: number) => (
                        <React.Fragment key={index}>
                            <Skeleton
                                style={{
                                    margin: '5px',
                                    width: '102px',
                                    height: '40px',
                                    borderRadius: '13px',
                                }}
                            />
                        </React.Fragment>
                    ))}
                </div>
                <Skeleton
                    style={{
                        marginTop: '25px',
                        width: '40%',
                        height: '25px',
                    }}
                />
                <Skeleton
                    style={{
                        marginTop: '25px',
                        width: '100%',
                        height: '100px',
                    }}
                />
                <Skeleton
                    style={{
                        marginTop: '25px',
                        width: '60%',
                        height: '25px',
                    }}
                />
                <div className="w-100 d-flex" style={{columnGap: '15px'}}>
                    <Skeleton
                        style={{
                            marginTop: '25px',
                            width: '100px',
                            height: '25px',
                        }}
                    />
                    <Skeleton
                        style={{
                            marginTop: '25px',
                            width: '100px',
                            height: '25px',
                        }}
                    />
                </div>
                <div className="w-100 d-flex justify-content-end">
                    <Skeleton
                        style={{
                            marginTop: '25px',
                            width: '300px',
                            height: '40px',
                            borderRadius: '10px',
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
