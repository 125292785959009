import axios from 'axios';

import {getAccessTokenSilently} from 'shared/utils/token';
import {
    IProject,
    IProjectCreate,
    IProjectListQuery,
    IProjectListResult,
    IProjectUpdate,
    IProjectInviteCreate, IProjectLinkCreate, IProjectLinkUpdate,
    IProjectOwnerUpdate,
} from 'modules/project/models';
import {Config} from 'config';
import {getAuthHeaders} from 'shared/utils/auth';
import {IFileToken} from 'modules/file/models/IFileToken';

export const getURLForProjectAPI = (path: string): string => {
    return `${Config.mainServiceURL}/projects${path}`;
};

export const readProjectList = async (params: IProjectListQuery): Promise<IProjectListResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForProjectAPI('/'), {
        params,
        headers: getAuthHeaders(accessToken),
    });
    return response.data as IProjectListResult;
};

export const readProject = async (projectId: string): Promise<IProject> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForProjectAPI(`/${projectId}`), {
        headers: getAuthHeaders(accessToken),
    });
    return response.data as IProject;
};

export const createProject = async (projectCreate: IProjectCreate): Promise<IProject> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForProjectAPI('/'), projectCreate, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const deleteProject = async (projectId: string) => {
    const accessToken = await getAccessTokenSilently();
    await axios.delete(getURLForProjectAPI(`/${projectId}`), {
        headers: getAuthHeaders(accessToken),
    });
};

export const updateProject = async (projectId: string, projectUpdate: IProjectUpdate): Promise<IProject> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(getURLForProjectAPI(`/${projectId}`), projectUpdate, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data as IProject;
};

export const createFileTokenForProject = async (projectId: string): Promise<IFileToken> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForProjectAPI(`/${projectId}/file_token`), null, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const createProjectInvite = async (
    projectId: string,
    projectInviteCreate: IProjectInviteCreate,
): Promise<IProject> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForProjectAPI(`/${projectId}/invite`), projectInviteCreate, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const createProjectLink = async (
    projectId: string,
    projectLinkCreate: IProjectLinkCreate,
): Promise<IProject> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForProjectAPI(`/${projectId}/links`), projectLinkCreate, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const deleteProjectLink = async (
    projectId: string,
    projectLinkId: string,
): Promise<IProject> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.delete(getURLForProjectAPI(`/${projectId}/links/${projectLinkId}`), {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const updateProjectLink = async (
    projectId: string,
    projectLinkId: string,
    projectLinkUpdate: IProjectLinkUpdate,
): Promise<IProject> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(getURLForProjectAPI(`/${projectId}/links/${projectLinkId}`), projectLinkUpdate, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const removeMember = async (
    projectId: string,
    memberid: string,
): Promise<IProject> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForProjectAPI(`/${projectId}/remove`), {member_ids: [memberid]}, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const updateProjectOwner = async (
    projectId: string,
    projectOwnerUpdate: IProjectOwnerUpdate,
): Promise<IProject> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(getURLForProjectAPI(`/${projectId}/owner`), projectOwnerUpdate, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};