import React from 'react';
import {Container} from 'react-bootstrap';

import {BreadCrumbs} from 'public/help-center/HelpCenterCategory/BreadCrumbs/BreadCrumbs';
import {useNavbar} from 'shared/hooks/useNavbar';
import {useArticleFromPath} from 'modules/help/hooks/useArticleFromPath';

import {HelpCenterHeader} from 'public/help-center/components/HelpCenterHeader/HelpCenterHeader';
import {AppFooter} from 'shared/components/AppFooter/AppFooter';
import {HelpCenterArticleSection} from './HelpCenterArticleSection/HelpCenterArticleSection';
import {
    HelpCenterArticleHeader,
} from 'public/help-center/HelpCenterArticle/HelpCenterArticleHeader/HelpCenterArticleHeader';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';

import './style.scss';

export const HelpCenterArticle = () => {
    useNavbar(true);

    const {article, category} = useArticleFromPath();

    const breadcrumbs = [
        {
            title: 'Help Center',
            url: '/help-center',
        },
        {
            title: category.name,
            url: `/help-center/${category.id}`,
        },
        {
            title: article.name,
        },
    ];

    return (
        <>
            <PageHelmet
                title={`${article.name} - ${category.name} - Help Center`}
                description={article.description}
            />
            <div className="HelpCenterAnswer">
                <Container>
                    <HelpCenterHeader/>
                    <BreadCrumbs breadcrumbs={breadcrumbs}/>
                    <HelpCenterArticleHeader article={article}/>
                    {article.sections.map((section, index) => (
                        <React.Fragment key={index}>
                            <div className="HelpCenterAnswer__break-line"/>
                            <HelpCenterArticleSection section={section}/>
                        </React.Fragment>
                    ))}
                </Container>
            </div>
            <AppFooter/>
        </>
    );
};
