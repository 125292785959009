import {z} from 'zod';

export interface IReviewForm {
    content: string;
    rating: number;
    author_name: string;
}

export const reviewForm = z.object({
    content: z.string(),
    rating: z.number(),
    author_name: z.string(),
});
