import React from 'react';
import {useRecoilValue} from 'recoil';
import {Spinner} from 'react-bootstrap';
import {readFileSelector} from 'modules/file/state/file-manager';
import {IPendingFile} from 'shared/models/pending-file/IPendingFile';

interface IFileNameLoadedProps {
    fileId: string;
    pendingFiles: IPendingFile[];
}

export const FileNameLoaded = ({fileId, pendingFiles}: IFileNameLoadedProps) => {
    const file = useRecoilValue(readFileSelector(fileId));
    return (
        <>
            {pendingFiles && pendingFiles.length > 0 ? (
                <Spinner animation="border" variant="primary" />
            ) : file.filename ? (
                <p className={file.filename ? 'm-0 text-left' : 'm-0 text-left ResumeController__resume--place-holder'}>
                    {file.filename ? file.filename : 'Upload Resume...'}
                </p>
            ) : (
                <p className={'m-0 text-left ResumeController__resume--place-holder'}>Upload Resume...</p>
            )}
        </>
    );
};
