import {selector} from 'recoil';

import {IPost} from 'modules/post/models/IPost';
import {insertPostListSelector} from 'modules/post/state/post-list';
import {resetFeaturedPosts} from 'modules/post/state/featured-post';
import {postLookupSelector} from 'modules/post/state/post-lookup';
import {throwWriteOnlySelectorError, guardRecoilDefaultValue} from 'shared/utils/recoil';

export const insertPostSelector = selector<IPost>({
    key: 'insertPostSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        set(postLookupSelector(newValue.id), newValue);
        set(insertPostListSelector, newValue);
        set(resetFeaturedPosts, get(resetFeaturedPosts) + 1);
    },
});
