import {selectorFamily} from 'recoil';

import {IProfileSummary} from 'modules/profile/models/IProfileSummary';
import {guardRecoilDefaultValue} from 'shared/utils/recoil';
import {profileSummarySelector, profileSummaryReadSelector} from 'modules/profile/state/profile-summary';

export const profileSummaryMaybeSelector = selectorFamily<IProfileSummary | undefined, string | undefined>({
    key: 'profileSummaryMaybeSelector',
    get: (profileId) => ({get}) => {
        if (!profileId) {
            return undefined;
        } else {
            return get(profileSummarySelector(profileId));
        }
    },
    set: (profileId) => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue || !profileId) {
            return;
        }
        set(profileSummarySelector(profileId), newValue);
    },
});

/**
 * The same as profileSummarySelector except it accepts undefined as the profile ID.
 */
export const profileSummaryMaybeReadSelector = selectorFamily<IProfileSummary | undefined, string | undefined>({
    key: 'profileSummaryMaybeReadSelector',
    get: (profileId) => async ({get}): Promise<IProfileSummary | undefined> => {
        if (profileId) {
            return get(profileSummaryReadSelector(profileId));
        } else {
            return undefined;
        }
    },
});
