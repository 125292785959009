import React from 'react';

import {useRecoilValue} from 'recoil';

import {getFileDownloadUrl, IGetFileDownloadUrlProps} from '../utils';
import {fileTokenSelector} from '../state/file-token';

export const useDownloadURL = (props: Omit<IGetFileDownloadUrlProps, 'attachment'>): string => {
    const token = useRecoilValue(fileTokenSelector);

    return React.useMemo<string>(() => {
        return getFileDownloadUrl({
            ...props,
            token: props.token || token,
        });
    }, [props, token]);
};
