import axios from 'axios';

import {getAccessTokenMaybe} from 'shared/utils/token';
import {IContactForm} from 'modules/contact/models';
import {Config} from 'config';
import {getAuthHeaders} from 'shared/utils/auth';

const getURLForContactAPI = (path: string) => {
    return `${Config.contactServiceURL}${path}`;
};

export const createContactAsync = async (contactForm: IContactForm) => {
    const accessToken = await getAccessTokenMaybe();
    const response = await axios.post(getURLForContactAPI('/submit'), contactForm, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};
