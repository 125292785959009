import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

interface ICardCarouselErrorProps {
    children: React.ReactNode;
}

export const CardCarouselError = ({children}: ICardCarouselErrorProps) => {
    return (
        <div className="CardCarousel__empty">
            <FontAwesomeIcon icon={faExclamationTriangle} size="2x" className="mr-3 text-danger align-middle"/>
            <span className="text-muted">{children}</span>
        </div>
    );
};
