import React from 'react';

import {Button} from 'react-bootstrap';
import {useSetRecoilState} from 'recoil';

import {IWorkExperience} from 'modules/profile/models/IWorkExperience';
import {IProfile} from 'modules/profile/models/IProfile';
import {updateProfile} from 'modules/profile/api/profile';
import {insertProfileSelector} from 'modules/profile/state/profile-insert';
import {getErrorMessage} from 'shared/utils/error';
import {
    getContactDetailsFormDefaults,
    getServiceDetailsFormDefaults,
} from 'shared/components/ProfilePageContent/shared/utils/form';
import {toast} from 'shared/utils/toast';

import {Modal} from 'shared/components/modals/Modal/Modal';
import {ModalBody} from 'shared/components/modals/Modal/ModalBody';

interface IDeleteExperienceProps {
    open: boolean;
    onHide: () => void;
    EditIndex?: number | null;
    ExperienceData: IWorkExperience;
    profile: IProfile;
}

export const DeleteExperience = ({open, onHide, EditIndex, profile, ExperienceData}: IDeleteExperienceProps) => {
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const insertProfile = useSetRecoilState(insertProfileSelector);

    const handleDeleteExperience = () => {
        setIsSubmitting(true);
        (async () => {
            try {
                const ProfileworkExperienceData = profile.work_experience
                    ? profile.work_experience.filter((data, indexValue) => {
                        return indexValue !== EditIndex;
                    })
                    : [];

                const updatedProfile = await updateProfile(profile.id, {
                    firstname: profile.firstname || '',
                    lastname: profile.lastname || '',
                    contact_details: getContactDetailsFormDefaults(profile.contact_details),
                    contact_details_display: getContactDetailsFormDefaults(profile.contact_details_display),
                    resume_id: profile.resume_id,
                    profession: profile.profession,
                    summary: profile.summary,
                    claim_to_fame: profile.claim_to_fame || '',
                    service_types: profile.service_types,
                    service_details: getServiceDetailsFormDefaults(profile.service_details),
                    skills: profile.skills || [],
                    work_experience: ProfileworkExperienceData || [],
                    social_links: profile.social_links || [],
                });
                insertProfile(updatedProfile);
                onHide();
                toast.success('Profile Settings Saved', 'Your profile was updated successfully');
            } catch (e) {
                toast.error('Profile Settings Error', getErrorMessage(e));
            }
            setIsSubmitting(false);
        })();
    };

    return (
        <Modal
            show={open}
            onHide={() => {
                onHide();
            }}
            size="lg"
            centered
            isSubmitting={isSubmitting}
            title="Delete Experience"
        >
            <ModalBody className="ProfilePageAbout__margin--a25 ">
                <div>
                    <div className="ProfilePageDetails__padding--b12 ProfilePageDetails__text--semibold--small ProfilePageDetails__text--semibold--small--ls0 ProfilePageDetails__text--semibold">
                        <p className="m-0">{ExperienceData.name}</p>
                    </div>
                    <div className="ProfilePageDetails__padding--b22 ProfilePageDetails__text--semibold--small ProfilePageDetails__text--semibold--small--ls0 ProfilePageDetails__text--semibold--small">
                        <p className="m-0">{ExperienceData.description}</p>
                    </div>
                </div>
                <div className="Horizontal-line-hr-dashed"/>
                <div className="text-center ProfilePageDetails__padding--t42 ProfilePageDetails__text--semibold ProfilePageDetails__text--semibold--large">
                    <p className="m-0">Are you sure you want to delete this ?</p>
                </div>
                <div className="text-center ProfilePageDetails__padding--t42">
                    <Button
                        variant="featured"
                        onClick={handleDeleteExperience}
                        disabled={isSubmitting}
                        className="ProfilePageAbout__button--save-button"
                    >
                        Okay
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};
