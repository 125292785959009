import React from 'react';

import {Card} from 'react-bootstrap';
import classNames from 'classnames';

import './style.scss';

interface IMediaCardProps {
    className?: string;
}

export const MediaCard = ({className, children}: React.PropsWithChildren<IMediaCardProps>) => {
    return (
        // <Card className={classNames('MediaCard', className)}>
        <Card className={classNames(className)}>{children}</Card>
    );
};
