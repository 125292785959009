import {atom, selectorFamily} from 'recoil';

import {IPost} from 'modules/post/models/IPost';
import {readPost} from 'modules/post/api';
import {postListAtom} from 'modules/post/state/post-list';
import {guardRecoilDefaultValue} from 'shared/utils/recoil';

export interface IPostLookupAtom {
    [key: string]: IPost;
}

export const postLookupAtom = atom<IPostLookupAtom>({
    key: 'postLookupAtom',
    default: {},
});

export const postLookupSelector = selectorFamily<IPost, string>({
    key: 'postLookupSelector',

    get: (postId: string) => async ({get}): Promise<IPost> => {
        const postLookupState = get(postLookupAtom);
        const postListState = get(postListAtom);
        const postInStore = postLookupState[postId] || postListState?.posts?.find(post => post.id === postId);
        return postInStore ?? await readPost(postId);
    },
    set: (postId: string) => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const postLookupState = get(postLookupAtom);
        const newPostLookup = {...postLookupState};
        newPostLookup[postId] = newValue;
        set(postLookupAtom, newPostLookup);
    },
});
