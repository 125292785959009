import {atom, selectorFamily} from 'recoil';

import {INotification} from 'modules/notification/models';
import {readNotificationList} from 'modules/notification/api';
import {guardRecoilDefaultValue} from 'shared/utils/recoil';

const defaultNotificationLimit = 10;

interface INotificationKey {
    userId: string;
    notificationId: string;

    [key: string]: string;
}

export interface INotificationListState {
    userId: string;
    notifications: INotification[];
    resetVersion: number;
}

export const notificationListAtom = atom<INotificationListState | undefined>({
    key: 'notificationListAtom',
    default: undefined,
});

export const notificationListResetAtom = atom<number>({
    key: 'notificationListResetAtom',
    default: 1,
});

export const notificationListSelector = selectorFamily<INotificationListState | undefined, string>({
    key: 'notificationListSelector',
    get: (userId) => ({get}) => {
        const atomValue = get(notificationListAtom);
        const resetVersion = get(notificationListResetAtom);
        if (atomValue && atomValue.userId === userId && atomValue.resetVersion === resetVersion) {
            return atomValue;
        }
        return undefined;
    },
    set: () => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(notificationListAtom, newValue);
    },
});

export const notificationListReadSelector = selectorFamily<INotificationListState, string>({
    key: 'notificationListReadSelector',
    get: (userId) => async ({get}) => {
        const currentValue = get(notificationListSelector(userId));
        if (currentValue) {
            return currentValue;
        }
        const resetVersion = get(notificationListResetAtom);
        const notificationListResult = await readNotificationList({
            userId,
            limit: defaultNotificationLimit,
        });
        return {
            notifications: notificationListResult.notifications,
            userId,
            resetVersion,
        };
    },
});

export const unreadNotificationsSelector = selectorFamily<INotification[], string>({
    key: 'unreadNotificationsSelector',
    get: (userId) => async ({get}): Promise<INotification[]> => {
        const notificationList = get(notificationListReadSelector(userId)) || [];
        return notificationList.notifications.filter(notification => !notification.read);
    },
});

export const notificationSelector = selectorFamily<INotification, INotificationKey>({
    key: 'notificationSelector',
    get: ({userId, notificationId}) => async ({get}): Promise<INotification> => {
        const notificationList = get(notificationListReadSelector(userId));
        const notification = notificationList.notifications.find(notification => notification.id === notificationId);
        if (!notification) {
            throw new Error('Notification not found');
        }
        return notification;
    },
});
