import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface IProfilePictureErrorProps {
    className?: string;
}

export const ProfilePictureError = ({className}: IProfilePictureErrorProps) => {
    const tooltip = (
        <Tooltip id="ProfilePictureError">Image failed to load</Tooltip>
    );
    const overlayClassName = `${className} d-inline-flex justify-content-center align-items-center`;

    // this unusual syntax avoids calls to `React.findDOMNode` which cause issues when running in "strict" mode
    // see: https://react-bootstrap.github.io/components/overlays/#customizing-trigger-behavior
    return (
        <OverlayTrigger transition={false} placement="top" overlay={tooltip}>
            {({ref, ...triggerHandler}) => (
                <div {...triggerHandler} ref={ref ?? undefined} className={overlayClassName}>
                    <FontAwesomeIcon icon={faExclamation} className="ProfilePictureError__icon" />
                </div>
            )}
        </OverlayTrigger>
    );
};
