import axios from 'axios';

import {ITagListResult} from 'modules/tag/models';
import {Config} from 'config';

const getURLForTagAPI = (path: string) => {
    return `${Config.mainServiceURL}/tags${path}`;
};

export const readTagList = async (): Promise<ITagListResult> => {
    const response = await axios.get(getURLForTagAPI('/'));
    return response.data;
};
