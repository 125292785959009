import React from 'react';

import {useNavbar} from 'shared/hooks/useNavbar';
import {usePostFromPath} from 'modules/post/hooks/usePostFromPath';

import {PodCastViewHeader} from 'public/PodcastView/PodCastViewHeader';
import {PodcastCard} from './PodcastCard';
import {PodcastPlayerModal} from 'shared/components/PodcastPlayerModal/PodcastPlayerModal';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';

import './style.scss';

export const PodcastView = () => {
    useNavbar(true);

    const post = usePostFromPath();

    const [playerURL, setPlayerURL] = React.useState<string | undefined>(undefined);

    return (
        <div className="PodcastView__container">
            <PageHelmet title={post.title} description={post.summary}/>
            <PodCastViewHeader post={post}/>
            <PodcastCard post={post} onOpen={playerURL => setPlayerURL(playerURL)}/>
            <PodcastPlayerModal
                onHide={() => setPlayerURL(undefined)}
                open={!!playerURL}
                playerURL={playerURL}
            />
        </div>
    );
};
