import axios from 'axios';

import {getAccessTokenSilently} from 'shared/utils/token';
import {getAuthHeaders} from 'shared/utils/auth';
import {
    IEventCreate,
    IEvent,
    IEventStats,
    IEventStatsQuery,
    IEventUpdate,
    IEventProject,
    IEventReschedule,
    IEventReject,
    IEventListQuery,
    IEventChat,
} from 'modules/event/models';
import {Config} from 'config';

export const getURLForEventAPI = (path: string): string => {
    return `${Config.mainServiceURL}/events${path}`;
};

export const readAllEvents = async ({
    userId,
    orderBy,
    ...rest
}: IEventListQuery): Promise<IEvent[]> => {
    const accessToken = await getAccessTokenSilently();
    let allEventsList: IEvent[] = [];
    let more = true;
    let next_cursor = '';
    while (more) {
        const response = await axios.get(getURLForEventAPI('/'), {
            params: {
                ...rest,
                user_id: userId,
                order_by: orderBy,
                cursor: next_cursor ?? undefined,
            },
            headers: getAuthHeaders(accessToken),
        });
        allEventsList = [...allEventsList, ...response.data?.events ?? ([] as IEvent[])];
        next_cursor = response.data?.next_cursor;
        more = next_cursor && response.data?.events?.length === rest.limit ? true : false;
    }
    return allEventsList;
};

export const readEventList = async ({
    userId,
    orderBy,
    ...rest
}: IEventListQuery): Promise<IEvent[]> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForEventAPI('/'), {
        params: {
            ...rest,
            user_id: userId,
            order_by: orderBy,
        },
        headers: getAuthHeaders(accessToken),
    });
    return response.data?.events ?? ([] as IEvent[]);
};

export const readEvent = async (eventId: string): Promise<IEvent> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForEventAPI(`/${eventId}`), {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const createEvent = async (event: IEventCreate): Promise<IEvent> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForEventAPI('/'), event, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const updateEvent = async (eventId: string, eventUpdate: IEventUpdate): Promise<IEvent> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(getURLForEventAPI(`/${eventId}`), eventUpdate, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const deleteEvent = async (eventId: string): Promise<string> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.delete(getURLForEventAPI(`/${eventId}`), {
        headers: getAuthHeaders(accessToken),
    });
    return response && 'success';
};

export const readEventStats = async (eventStatsQuery: IEventStatsQuery): Promise<IEventStats> => {
    const readEventStatsPayload = {
        user_id: eventStatsQuery.userId,
        today_start_at: eventStatsQuery.startAt,
        today_end_at: eventStatsQuery.endAt,
    };
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForEventAPI('/stats'), readEventStatsPayload, {
        headers: getAuthHeaders(accessToken),
    });
    return {
        total: response.data.total,
        completed: response.data.completed,
        upcoming: response.data.upcoming,
        todayPending: response.data.today_pending,
    };
};

export const readEventProject = async (eventId: string): Promise<IEventProject> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForEventAPI(`/${eventId}/project`), {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const acceptEvent = async (eventId: string): Promise<IEvent> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForEventAPI(`/${eventId}/accept`), null, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const rejectEvent = async (eventId: string, eventReject: IEventReject): Promise<IEvent> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForEventAPI(`/${eventId}/reject`), eventReject, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const rescheduleEvent = async (eventId: string, eventReschedule: IEventReschedule): Promise<IEvent> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForEventAPI(`/${eventId}/reschedule`), eventReschedule, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const createEventChat = async (eventId: string): Promise<IEventChat> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForEventAPI(`/${eventId}/chat`), null, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};
