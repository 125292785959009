import {atom, selector} from 'recoil';

import {IArticle} from '../models';
import {readArticleList} from '../api';

interface IFeaturedArticleListState {
    articles: IArticle[];
    resetVersion: number;
}

export const featuredArticleListResetAtom = atom<number>({
    key: 'featuredArticleListResetAtom',
    default: 1,
});

export const featuredArticleListAtom = atom<IFeaturedArticleListState | undefined>({
    key: 'featuredArticleListAtom',
    default: undefined,
});

export const featuredArticleListReadSelector = selector<IFeaturedArticleListState>({
    key: 'featuredArticleListReadSelector',
    get: async ({get}) => {
        const atomValue = get(featuredArticleListAtom);
        const resetVersion = get(featuredArticleListResetAtom);
        if (atomValue && atomValue.resetVersion === resetVersion) {
            return atomValue;
        }
        const {articles} = await readArticleList({
            is_featured: true,
            is_published: true,
            limit: 1000,
        });
        return {
            articles,
            resetVersion,
        };
    },
});
