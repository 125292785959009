import React from 'react';

import Skeleton from 'react-loading-skeleton';

import {MediaCard} from 'shared/components/MediaCard/MediaCard';
import {MediaCardFooter} from 'shared/components/MediaCard/MediaCardFooter/MediaCardFooter';

const ProfileCardSkeleton = () => {
    return (
        <div className="h-100">
            {/* PROFILE IMG */}
            <MediaCard className={'disableHover ProfileCard'}>
                <div
                    style={{
                        paddingBottom: '70%',
                    }}
                    className="MediaCardPicture"
                >
                    <div
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        <Skeleton
                            style={{
                                borderRadius: '1rem',
                                lineHeight: '2',
                                height: '100%',
                            }}
                        />
                    </div>
                </div>
                {/* PROFILE DETAILS */}
                <div className="ProfileCard__details d-flex justify-content-between">
                    <div>
                        <div className="ProfileCard__profession">
                            <Skeleton width={90} />
                        </div>
                        <div className="ProfileCard__title">
                            <Skeleton width={140} />
                        </div>
                        <div className="ProfileCard__location">
                            <Skeleton width={100} />
                        </div>
                    </div>
                    <div className="flex_colum_end">
                        <div className="text_align_end ProfileCard__star_icon">
                            <Skeleton width={86} />
                        </div>
                        <div className={'flex_row_center mobileDayHour Hourly_rate_responsive'}>
                            <Skeleton width={86} />
                        </div>
                        <div className={'text_align_end fixed_fee_enum_div'}>
                            <Skeleton width={24} height={29} />
                        </div>
                    </div>
                </div>

                <MediaCardFooter variant="dashed">
                    <div
                        style={{
                            display: 'flex',
                            columnGap: '3px',
                            overflow: 'hidden',
                            paddingRight: '0px',
                            marginRight: '20px',
                        }}
                        className="ProfileCard__TagsBar"
                    >
                        {[...Array(5)].map((_, index) => (
                            <Skeleton key={index} width={100} height={36} style={{borderRadius: '16px'}} />
                        ))}
                    </div>
                    <div className="ProfileCard__Checkbtn">
                        <Skeleton style={{borderRadius: '0.375rem'}} height={54} />
                    </div>
                </MediaCardFooter>
            </MediaCard>
        </div>
    );
};

export default ProfileCardSkeleton;
