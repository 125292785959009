import React from 'react';

import classNames from 'classnames';

import './style.scss';

interface IBadgeFilterProps {
    active?: boolean;
    title: string;
    onClick: () => void;
    className?: string;
}

export const BadgeFilter = ({title, active, onClick, className}: IBadgeFilterProps) => (
    <div
        onClick={onClick}
        className={classNames(className ? className : 'BadgeFilter', {
            'BadgeFilter--active': active,
        })}
    >
        {title}
    </div>
);
