import React from 'react';

import {Container, Row} from 'react-bootstrap';

import {GuideToChoosingSymbolsCard} from '../GuideToChoosingSymbolSayCard/GuideToChoosingSymbolsCard';

import './style.scss';

export const GuideToChoosingSymbols = () => {
    const SymbolSayCardData = [
        {
            title: 'Free First Consultation',
            iconPath: '/assets/GuideToChoosinglegalteam/ionic-md-checkmark-circle.png',
            description:
                'The rightful member is offering their first appointment on the platform for free. This is usually a great opportunity to get to know them better.  ',
        },
        {
            title: 'Does not accept direct briefs',
            iconPath: '/assets/GuideToChoosinglegalteam/ionic-ios-close-circle.png',
            description:
                'The rightful member prefers to have another lawyer involved in the case so that they can focus on the critical legal issues. For example, so that they can focus on the trial and the other lawyer can focus on correspondence and filing.',
        },
        {
            title: 'Accredited Specialist',
            iconPath: '/assets/GuideToChoosinglegalteam/Group_9658.png',
            description:
                'The rightful member has obtained peer certified specialist expertise in their chosen subject matter. Usually they have completed some form of extra curricular course or recognised study.',
        },
        {
            title: 'Under Supervision',
            iconPath: '/assets/GuideToChoosinglegalteam/A.png',
            description:
                'The lawyer is in training and can perform legal work but another lawyer needs to sign off. It can be extremely cost effective to engage trainee lawyers. Make sure you ask about their supervision arrangements.',
        },
        {
            title: 'Fixed Fee',
            iconPath: '/assets/GuideToChoosinglegalteam/Group_9733.png',
            description:
                'The rightful member offers some of their services on a fixed fee basis. Be sure to ask them about their fixed fee options. ',
        },
    ];

    return (
        <div className="GuideToChoosingSymbols">
            <div className="GuideToChoosingSymbols__header">
                <h2 className="GuideToChoosingSymbols__title">What our symbols mean</h2>
            </div>
            <Container>
                <Row>
                    {SymbolSayCardData && SymbolSayCardData.map((data, index) => (
                        <GuideToChoosingSymbolsCard
                            key={index}
                            iconPath={data.iconPath}
                            title={data.title}
                            description={data.description}
                        />
                    ))}
                </Row>
            </Container>
        </div>
    );
};
