import React from 'react';

import {Link, useLocation} from 'react-router-dom';
import classnames from 'classnames';

import './style.scss';

interface IAppNavbarLinkProps {
    to: string;
    isMobile?: boolean;
}

export const AppNavbarLink = ({to, isMobile, children}: React.PropsWithChildren<IAppNavbarLinkProps>) => {
    const location = useLocation();

    const ActiveLinkClassName = classnames(`AppNavbar__nav-item--link AppNavbar__link ${isMobile ? 'Mobile' : ''}`, {
        'AppNavbar__link--active': location.pathname.includes(to),
    });

    return (
        <Link className={ActiveLinkClassName} to={to}>
            {children}
        </Link>
    );
};
