import React from 'react';

import {ICaseChart} from 'modules/case-chart/models';

import {CaseEstimatorPreviewMilestone} from 'user/pages/CreateCaseEstimator/CaseEstimatorPreview/CaseEstimatorPreviewMilestone';

interface ICaseEstimatorPreviewProps {
    chart: ICaseChart;
}

export const CaseEstimatorPreview = ({chart}: ICaseEstimatorPreviewProps) => {
    return (
        <div className="CaseEstimator__preview">
            <div className="CaseEstimator__title">
                <h4>{chart.name}</h4>
            </div>
            <div className="CaseEstimator__subtitles">
                <span>COST PER ACTIVITY</span>
                <span>PARALEGAL / SOLICITOR</span>
                <span>BARRISTER</span>
                <span>COST PER ACTIVITY</span>
            </div>
            <div className="CaseEstimator-preview__container">
                <div className="CaseEstimator-preview__content">
                    {chart &&
                        chart.milestones.map((milestone, milestoneIndex) => (
                            <CaseEstimatorPreviewMilestone
                                key={`${milestoneIndex}.${milestone.name}`}
                                milestone={milestone}
                            />
                        ))}
                </div>
            </div>
        </div>
    );
};
