import React, { useState } from 'react';

import { useSetRecoilState } from 'recoil';

import { IProfile } from 'modules/profile/models/IProfile';
import { insertProfileSelector } from 'modules/profile/state/profile-insert';
import { toast } from 'shared/utils/toast';
import { getErrorMessage } from 'shared/utils/error';
import { updateProfile } from 'modules/profile/api/profile';

import { UpdateProfileForm, UpdateProfileFormData } from 'shared/components/UpdateProfile/UpdateProfileForm';

interface IUpdateProfileProps {
    profile: IProfile;
    onComplete: (updatedProfile: IProfile) => void;
    onCancel: () => void;
}

export const UpdateProfile = ({profile, onComplete, onCancel}: IUpdateProfileProps) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const insertProfile = useSetRecoilState(insertProfileSelector);

    const onSubmit = (formData: UpdateProfileFormData) => {
        if (isSubmitting) {
            return;
        } else {
            setIsSubmitting(true);
        }

        (async () => {
            try {
                const updatedProfile = await updateProfile(profile.id, Object.assign({}, profile, formData));
                insertProfile(updatedProfile);
                toast.success(
                    'Update Profile',
                    'Changes saved. Thanks for updating your profile.',
                );
                onComplete(updatedProfile);
            } catch (e) {
                toast.error('Update Profile', getErrorMessage(e));
            }

            setIsSubmitting(false);
        })();
    };

    return (
        <UpdateProfileForm
            onSubmit={onSubmit}
            profile={profile}
            disabled={isSubmitting}
            onCancel={() => onCancel()}
        />
    );
};
