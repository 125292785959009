import React from 'react';

import {formatDuration} from 'date-fns';

import {IProfile} from 'modules/profile/models/IProfile';
import {getFullName} from 'modules/profile/utils';
import {formatDayOfWeek} from 'shared/utils/date';
import {getLocationDescription} from 'modules/availability-settings/utils/location-description';
import {useAvailabilitySettingsLoadable} from 'modules/availability-settings/hooks/useAvailabilitySettingsLoadable';
import {useIsUserOnline} from 'modules/chat-websocket/hooks/useIsUserOnline';

import {ProfilePicture} from 'shared/components/ProfilePicture/ProfilePicture';

import './style.scss';

interface IBookingModalHeaderProps {
    profile: IProfile;
    durationMinutes: number;
}

export const BookingModalHeader = ({profile, durationMinutes}: IBookingModalHeaderProps) => {
    const fullName = getFullName(profile);
    const readableDuration = formatDuration({minutes: durationMinutes});
    const {availabilitySettings, loading, error} = useAvailabilitySettingsLoadable(profile.id);

    const isLoading = loading || error;
    const isOnline = useIsUserOnline(profile.id);

    const availabilityDescription = React.useMemo(() => {
        if (!availabilitySettings?.locations) {
            return undefined;
        }

        const readableLocation = getLocationDescription(availabilitySettings?.locations, false);
        if (!readableLocation) {
            // `location` is undefined if no availability types are selected so we can simply exit.
            return undefined;
        }

        // if not available any day of the week, then return undefined, which shows the "not available" message
        const isNotAvailable = availabilitySettings?.availability.every(availability => !availability.is_available);
        if (isNotAvailable) {
            return undefined;
        }

        // define which days of the week the user is available according to their availability settings
        const days: Record<number, boolean> = {};
        availabilitySettings?.availability
            .filter((availability) => availability.is_available)
            .forEach((availability) => (days[availability.day_of_week] = true));

        // define whether the user is available weekdays and weekends.
        // Note: Sunday could be 0 or 7
        const isWeekends = (days[0] || days[7]) && days[6];
        const isWeekdays = days[1] && days[2] && days[3] && days[4] && days[5];

        let daysDescription: string;
        if (isWeekdays && isWeekends) {
            daysDescription = 'Mon - Fri, Weekends';
        } else if (isWeekdays) {
            daysDescription = 'Mon - Fri';
        } else if (isWeekends) {
            daysDescription = 'Weekends';
        } else {
            const daysOfWeek = (Object.keys(days) as unknown) as number[];
            const dayNames = daysOfWeek.map((dayOfWeek) => formatDayOfWeek(dayOfWeek, true));
            daysDescription = dayNames.join(', ');
        }

        return `Available ${readableLocation}, Available on ${daysDescription}`;
    }, [availabilitySettings]);

    return (
        <div className="d-lg-flex mb-3 align-items-center border-bottom pb-3">
            <div className="header-profile">
                <ProfilePicture
                    name=""
                    id={profile.image_id}
                    url={profile.image_url}
                    alt={profile.firstname}
                    size="xsm"
                    round
                />
                {isOnline && <div className="header-profile-online"></div>}
            </div>
            <div className="flex-grow-1 pl-lg-3 pt-3 pt-lg-0">
                <div className="d-lg-flex">
                    <div className="mb-3 mb-lg-0">
                        <p className="BookingModalHeader__name mb-0">{fullName}'s Meeting Room</p>
                        <p className="BookingModalHeader__availability mb-0">
                            &nbsp;
                            {!isLoading && availabilityDescription && availabilityDescription}
                            {!isLoading && !availabilityDescription && 'Not available'}
                        </p>
                    </div>
                    <div className="BookingModalHeader__duration ml-auto mt-3 mt-lg-0">
                        {isLoading ? '' : `Duration: ${readableDuration}`}
                    </div>
                </div>
            </div>
        </div>
    );
};
