import React from 'react';

import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface ICardCarouselNavProps {
    direction: '#prev' | '#next';
    onClick: () => void;
    disabled?: boolean;
    isChevrons?: boolean;
    className?: string;
}

export const CardCarouselNav = ({onClick, direction, disabled, isChevrons, className}: ICardCarouselNavProps) => {
    const imageUrl = direction === '#prev' ? '/assets/carousel-previous.svg' : '/assets/carousel-next.svg';
    const altText = direction === '#prev' ? 'Load previous' : 'Load more';

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();

        if (!disabled) {
            onClick();
        }
    };

    const linkClassName = classNames('CardCarouselNav__button', {
        disabled: disabled,
    });
    const linkClassNameIcon = classNames('CardCarouselNav__icon_button', {
        disabled: disabled,
    });

    return (
        <div
            className={direction === '#prev'
                ? (className + ' CardCarouselNav margin_right_10')
                : (className + ' CardCarouselNav  margin_left_10')}
        >
            <a href={direction} className={!isChevrons ? linkClassName : linkClassNameIcon} onClick={handleClick}>
                {isChevrons ? (
                    direction === '#prev' ? (
                        <FontAwesomeIcon className="svg_fontAwesomeIcon padding_right_3" icon={faChevronLeft}/>
                    ) : (
                        <FontAwesomeIcon className="svg_fontAwesomeIcon padding_left_3" icon={faChevronRight}/>
                    )
                ) : (
                    <img src={imageUrl} alt={altText} className="CardCarouselNav__button-image"/>
                )}
            </a>
        </div>
    );
};
