import axios from 'axios';

import {getAccessTokenMaybe, getAccessTokenSilently} from 'shared/utils/token';
import {IProfileUpdate} from 'modules/profile/models/IProfileUpdate';
import {IProfile} from 'modules/profile/models/IProfile';
import {IProfileListQuery} from 'modules/profile/models/IProfileListQuery';
import {IReadProfilesResponse} from 'modules/profile/models/IReadProfilesResponse';
import {IProfileSummary} from 'modules/profile/models/IProfileSummary';
import {Config} from 'config';
import {getAuthHeaders, serializeArrayParams} from 'shared/utils/auth';
import {IProfileCreate} from 'modules/profile/models/IProfileCreate';
import {IProfileBatchResult} from 'modules/profile/models/IProfileBatchResult';
import {ISearchToken} from 'shared/models/search-token';
import {IProfileSummaryBatch} from 'modules/profile/models/IProfileSummaryBatch';

const getURLForProfileAPI = (path: string): string => {
    return `${Config.mainServiceURL}/profiles${path}`;
};

const getURLForProfileSummaryAPI = (path: string) => {
    return `${Config.mainServiceURL}/profile_summary${path}`;
};

export const processProfile = (profile: IProfile): IProfile => {
    if (!profile.skills) {
        profile.skills = [];
    }
    if (!profile.work_experience) {
        profile.work_experience = [];
    }
    if (!profile.extra_experience) {
        profile.extra_experience = [];
    }
    if (!profile.tag_ids) {
        profile.tag_ids = [];
    }
    if (!profile.service_types) {
        profile.service_types = [];
    }
    if (!profile.social_links) {
        profile.social_links = [];
    }
    return profile;
};

export const readProfile = async (profileId: string): Promise<IProfile> => {
    // access token token is optional so don't worry if this fails. you need to be authorized to view profile set to
    // private, but not published profile
    // TODO: add an argument for whether to use an access token or not
    const accessToken = await getAccessTokenMaybe();
    const response = await axios.get(getURLForProfileAPI(`/${profileId}`), {
        headers: getAuthHeaders(accessToken),
    });
    return processProfile(response.data);
};

export const readProfileList = async ({
    isPublished,
    isFeatured,
    profession,
    searchTerm,
    limit,
    cursor,
}: IProfileListQuery): Promise<IReadProfilesResponse> => {
    // being signed in is optional for this endpoint
    const accessToken = await getAccessTokenMaybe();
    const response = await axios.get(getURLForProfileAPI('/'), {
        withCredentials: true,
        headers: getAuthHeaders(accessToken),
        params: {
            search_term: searchTerm,
            is_published: isPublished,
            is_featured: isFeatured,
            profession,
            limit,
            cursor,
        },
    });
    return {
        profiles: response.data?.profiles,
        nextCursor: response.data?.next_cursor,
    };
};

export const readProfileBatch = async (profileIds: string[]): Promise<IProfileBatchResult> => {
    const url = getURLForProfileAPI('/batch');
    const params = serializeArrayParams('profile_ids', profileIds);
    const readProfileBatchURL = `${url}?${params}`;

    // being signed in is optional for this endpoint
    const accessToken = await getAccessTokenMaybe();
    const response = await axios.get(readProfileBatchURL, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const readProfileSummaryBatch = async (profileIds: string[]): Promise<IProfileSummary[]> => {
    const accessToken = await getAccessTokenMaybe();
    const response = await axios.post(getURLForProfileSummaryAPI('/batch'), {
        profile_ids: profileIds,
    }, {
        headers: getAuthHeaders(accessToken),
    });
    return (response.data as IProfileSummaryBatch).profile_summaries;
};

export const updateProfile = async (profileId: string, profileData: IProfileUpdate): Promise<IProfile> => {
    const accessToken = await getAccessTokenSilently();
    const patchData = {
        ...profileData,
        user_id: profileId,
    };
    const response = await axios.patch(getURLForProfileAPI(`/${profileId}`), patchData, {
        headers: getAuthHeaders(accessToken),
    });
    return processProfile(response.data);
};

export const readProfileSummary = async (profileId: string): Promise<IProfileSummary> => {
    const response = await axios.get(getURLForProfileSummaryAPI(`/${profileId}`));
    return response.data;
};

export const createProfile = async (profileCreate: IProfileCreate): Promise<IProfile> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForProfileAPI('/'), profileCreate, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const createProfileSummarySearchToken = async (): Promise<ISearchToken> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(
        getURLForProfileSummaryAPI('/search_token'),
        null,
        {
            headers: getAuthHeaders(accessToken),
        },
    );
    return response.data;
};
