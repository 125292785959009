import React from 'react';

import {Container} from 'react-bootstrap';
import {useNavbar} from 'shared/hooks/useNavbar';

import {AppFooter} from 'shared/components/AppFooter/AppFooter';
import {HelpCenterHeader} from 'public/help-center/components/HelpCenterHeader/HelpCenterHeader';
import {HelpCenterSvg} from 'shared/icons/HelpCentre';
import {PopularQuestions} from 'public/help-center/HelpCenter/PopularQuestions/PopularQuestions';
import {HelpCenterCategoryList} from 'public/help-center/HelpCenter/HelpCenterCategoryList/HelpCenterCategoryList';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';

import './style.scss';

export const HelpCenterPage = () => {
    useNavbar(true);

    return (
        <>
            <PageHelmet
                title="Help Center"
                description="We're here to help. Browse our library of help articles on using the Rightful platform either as a client or legal services provider."
            />
            <div className="HelpCenter">
                <Container>
                    <HelpCenterHeader/>
                    <div className="HelpCenter__image">
                        <HelpCenterSvg/>
                    </div>
                </Container>
                <PopularQuestions/>
                <HelpCenterCategoryList/>
            </div>
            <AppFooter/>
        </>
    );
};
