import React from 'react';

import {Control, Controller, Path, FieldValues} from 'react-hook-form';

import {TagControl} from 'shared/components/form/TagControl/TagControl';

interface ITagControllerProps<FormType extends FieldValues> {
    control: Control<FormType>;
    name: Path<FormType>;
    disabled?: boolean;
    className?: string;
    placeholder?: string;
}

export const TagController = <FormType extends FieldValues>({
    name,
    control,
    ...rest
}: ITagControllerProps<FormType>) => {

    return (
        <Controller
            name={name}
            control={control}
            render={({field}) => (
                <TagControl {...field} {...rest}/>
            )}
        />
    );
};
