import React, {useEffect, useState} from 'react';

import {Link} from 'react-router-dom';
import {Dropdown} from 'react-bootstrap';

import {ITokenData} from 'shared/auth/models';
import {getFullName} from 'modules/profile/utils';
import {IProfile, Role} from 'modules/profile/models/IProfile';

import {UserIdProfilePicture} from 'shared/components/UserIdProfilePicture/UserIdProfilePicture';
import {CustomToggle} from 'shared/components/CustomToggle/CustomToggle';
import {Caret} from 'shared/components/Caret/Caret';
import {EditProfile, LogOut, ProfileUser, Settings, ViewProfile} from 'shared/icons/navbar';

import './style.scss';

interface IAppNavbarDropdownProps {
    tokenData: ITokenData;
    profile: IProfile;
    signOut: () => void;
}

export const AppNavbarDropdown = ({tokenData, profile, signOut}: IAppNavbarDropdownProps) => {
    const isProvider = profile.role === Role.Provider || profile.role === Role.System;
    const isClient = profile.role === Role.Client;

    const onLogOutClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        signOut();
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Dropdown>
            <Dropdown.Toggle
                as={CustomToggle}
                id="AppNavbarDropdown.toggle"
                className="AppNavbar__dropdown-toggle AppNavbar__nav-item AppNavbar__nav-item--right AppNavbar__link pr-0"
            >
                {windowWidth > 576 ? (
                    <UserIdProfilePicture
                        id={tokenData?.id || ''}
                        imgClassName="article"
                        round
                        size="xs"
                        table
                        darkPlaceholder={true}
                    />
                ) : (
                    <img
                        style={{
                            width: '20px',
                            height: '24px',
                        }}
                        src="/assets/ProfileIcon.svg"
                        alt="p-icon"
                    />
                )}
                {profile && (
                    <>
                        <span className="AppNavbar__dropdown-name ml-3 d-none d-sm-inline-block">
                            {getFullName(profile)}
                        </span>
                        <Caret className={`${windowWidth > 576 ? 'ml-3' : 'ml-2'}`} />
                    </>
                )}
            </Dropdown.Toggle>

            <Dropdown.Menu className="AppNavbar__dropdown-menu">
                {isProvider && (
                    <Dropdown.Item as={Link} to="/dashboard/" className="AppNavbar__dropdown-item">
                        <ProfileUser className="mr-2" />
                        Dashboard
                    </Dropdown.Item>
                )}
                {isClient && (
                    <Dropdown.Item as={Link} to="/dashboard/" className="AppNavbar__dropdown-item">
                        <ProfileUser className="mr-2" />
                        Client Dashboard
                    </Dropdown.Item>
                )}
                {isClient && (
                    <Dropdown.Item as={Link} to="/dashboard/settings" className="AppNavbar__dropdown-item">
                        <Settings className="mr-2" />
                        Settings
                    </Dropdown.Item>
                )}
                {isProvider && (
                    <>
                        <Dropdown.Item as={Link} to="/profile" className="AppNavbar__dropdown-item">
                            <ViewProfile className="mr-2" />
                            View Profile
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/profile/edit" className="AppNavbar__dropdown-item">
                            <EditProfile className="mr-2" />
                            Edit Profile
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/dashboard/settings" className="AppNavbar__dropdown-item">
                            <Settings className="mr-2" />
                            Account Settings
                        </Dropdown.Item>
                    </>
                )}
                <Dropdown.Item href="/logout" onClick={onLogOutClick} className="AppNavbar__dropdown-item">
                    <LogOut className="mr-2" /> Log Out
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};
