import React from 'react';

import {Link, useNavigate} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import {useAuth} from 'shared/auth/hooks/useAuth';

export const LegalPracticeSection = () => {
    const {isAuthenticated, login} = useAuth();
    const navigate = useNavigate();

    const onSignupClick = (e: React.MouseEvent) => {
        e.preventDefault();

        if (!isAuthenticated) {
            return login({
                screen_hint: 'signup',
            });
        }

        return navigate({pathname: '/become-a-member'});
    };

    return (
        <div className="AboutUsPage__Legalpractice AboutUsPage__margin--b30">
            <div className="AboutUsPage__heading AboutUsPage__margin--b52">
                <h2 className="AboutUsPage__heading--text">
                    Rightful is the future of <br/> legal practice
                </h2>
            </div>
            <div>
                <Button as={Link} variant="featured" className="btn-xxl-wide" onClick={onSignupClick} to={isAuthenticated ? '/become-a-member' : '/signup'}>
                    Join Us
                </Button>
            </div>
        </div>
    );
};