import {PriceName} from 'modules/billing/models';

export interface IPlanPrice {
    price: number;
    priceName: PriceName | null;
}

export interface IPlanPricing {
    monthly: IPlanPrice;
    yearly: IPlanPrice;
}

export interface IPlan {
    name: string;
    icon: string;
    description: string;
    isPopular: boolean;
    perks: string[];
    pricing: IPlanPricing | null;
}

export const clientPlans: IPlan[] = [
    {
        name: 'Free',
        icon: '/assets/plans/free.svg',
        description: 'Free means free and shall forever be',
        isPopular: false,
        pricing: null,
        perks: [
            'Free profile search',
            'Free jobs post',
            'Free bookings with lawyers',
            'Free messaging',
            'Free legal project invites',
            'Free talent lists',
            'Free slack community membership',
            '1 free legal document',
            '1 free e-signature per month',
        ],
    },
    {
        name: 'Premium',
        icon: '/assets/plans/basic.svg',
        description: 'Swim with sharks',
        isPopular: true,
        pricing: {
            monthly: {
                price: 39,
                priceName: PriceName.ClientPremiumMonthly,
            },
            yearly: {
                price: 400,
                priceName: PriceName.ClientPremiumYearly,
            },
        },
        perks: [
            'Everything in Free',
            'Legal Project Owner Status',
            'Unlimited legal doc generations - access complete library',
            'Unlimited e-signatures',
            'Unlock exclusive legal content channels in slack',
            'Exclusive Partner Offers',
            'Support with lawyer recommendations',
        ],
    },
];

export const providerPlans: IPlan[] = [
    {
        name: 'Free',
        icon: '/assets/plans/free.svg',
        description: 'Free means free and shall forever be',
        isPopular: false,
        pricing: null,
        perks: [
            'Free Marketplace listing',
            'Free Messaging & Bookings',
            'Free Legal Project Invites',
            'Free slack community membership',
            '1 Free Legal Doc',
            '1 Free e-signature a month',
            'Jobs Post/Replies',
        ],
    },
    {
        name: 'Basic',
        icon: '/assets/plans/basic.svg',
        description: "Dip your toes, but don't get wet",
        isPopular: false,
        pricing: {
            monthly: {
                price: 49,
                priceName: PriceName.ProviderBasicMonthly,
            },
            yearly: {
                price: 530,
                priceName: PriceName.ProviderBasicYearly,
            },
        },
        perks: [
            'Everything in Free',
            'Google/outlook calendar integration',
            'Legal Project Owner status',
            'Unlimited legal document Generation - access complete library',
            'Unlimited e-signatures',
            'Case charts',
            'Exclusive CPD Channels in slack and webinar promotion',
            'Practice scale up help - 3 recruitments a year/special offers/firm marketing',
            'Prime marketplace position',
        ],
    },
    {
        name: 'Pro',
        icon: '/assets/plans/pro.svg',
        description: 'Dive 20,000 leagues under the sea',
        isPopular: true,
        pricing: {
            monthly: {
                price: 99,
                priceName: PriceName.ProviderProMonthly,
            },
            yearly: {
                price: 1000,
                priceName: PriceName.ProviderProYearly,
            },
        },
        perks: [
            'Everything in Basic',
            '2 guaranteed leads a month',
        ],
    },
];
