import React from 'react';

import {Col, Container, Row} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';

import {useCategoryList} from 'modules/help/hooks/useCategoryList';

import {HelpCenterPicture} from 'public/help-center/components/HelpCenterPicture/HelpCenterPicture';

import './style.scss';

export const HelpCenterCategoryList = () => {
    const navigate = useNavigate();
    const categories = useCategoryList();

    return (
        <div className="HelpCenterCategoryList">
            <div className="content-page-header ">
                <h2 className="content-page-title">Help by Category</h2>
            </div>
            <Container>
                <Row>
                    {categories.map(category => (
                        <Col key={category.id} sm={12} md={6} lg={4}>
                            <div
                                onClick={() => navigate(`/help-center/${category.id}`)}
                                className="HomeAboutpage--box HelpCenterCategoryList__card"
                            >
                                <div className="d-flex justify-content-center">
                                    <HelpCenterPicture category={category}/>
                                </div>
                                <p className="HomeAboutpage--boxLable">
                                    {category.name}
                                </p>
                                <div className="d-flex justify-content-center w-100">
                                    <p className="HomeAboutpage--boxText text-center">
                                        {category.description}
                                    </p>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};
