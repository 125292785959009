import React from 'react';

import {Right} from 'shared/icons/Homepage';

import './style.scss';

export const HomeWorkPage = () => {
    return (
        <div className="HomeWorkPage container">
            <p className="HomeWorkPage--title">How does Rightful Work ?</p>
            <p className="HomeWorkPage--text">
                Going through a legal entanglement is stressful enough without worrying about outdated office practices
                costing you time and money. Rightful has been custom built by advocates to put you in the driver's seat.
                Need a legal professional with space law experience in Sydney? Piece of cake! It's like having your
                entire legal team in your pocket, leaving you free to focus on things that matter.
            </p>
            <div className="HomeWorkPage--cardcontain">
                <div className="HomeWorkPage--box">
                    <p className="HomeWorkPage--boxtitle">As a Client</p>
                    <div className="HomeWorkPage--boxText">
                        <div>
                            <Right className="HomeWorkPage--icon" />{' '}
                        </div>
                        <div>
                            <p>
                                Find the right legal team, by searching their specialities, legal resources and reviews.
                            </p>
                        </div>
                    </div>
                    <div className="HomeWorkPage--boxText">
                        <div>
                            <Right className="HomeWorkPage--icon" />{' '}
                        </div>
                        <div>
                            <p>
                                Compare rates and fixed fee options, ask for detailed project costings in case charts.
                            </p>
                        </div>
                    </div>
                    <div className="HomeWorkPage--boxText">
                        <div>
                            <Right className="HomeWorkPage--icon" />{' '}
                        </div>
                        <div>
                            <p>
                                Easy to use appointment system and notifications ensure you are never left out of the
                                loop.
                            </p>
                        </div>
                    </div>
                    <div className="HomeWorkPage--boxText">
                        <div>
                            <Right className="HomeWorkPage--icon" />{' '}
                        </div>
                        <div>
                            <p>
                                Obtain a 360 degree view of your legal project. See it come together online in real
                                time.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="HomeWorkPage--box">
                    <p className="HomeWorkPage--boxtitle">As a Legal Professional</p>
                    <div className="HomeWorkPage--boxText">
                        <div>
                            <Right className="HomeWorkPage--icon" />{' '}
                        </div>
                        <div>
                            <p>
                                Be on the forefront of the work from anywhere revolution. Set up a truly virtual online
                                presence to connect with clients.
                            </p>
                        </div>
                    </div>
                    <div className="HomeWorkPage--boxText">
                        <div>
                            <Right className="HomeWorkPage--icon" />{' '}
                        </div>
                        <div>
                            <p>
                                Document automation means onboarding, court documents, client signatures are all at the
                                flick of a button.
                            </p>
                        </div>
                    </div>
                    <div className="HomeWorkPage--boxText">
                        <div>
                            <Right className="HomeWorkPage--icon" />{' '}
                        </div>
                        <div>
                            <p>Leave payments admin behind. Secure payments with our escrow service.</p>
                        </div>
                    </div>
                    <div className="HomeWorkPage--boxText">
                        <div>
                            <Right className="HomeWorkPage--icon" />{' '}
                        </div>
                        <div>
                            <p>Enter a progressive community of legal minds truly seeking to make a difference.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
