import React, {useState} from 'react';

import {Container, Tab, Tabs} from 'react-bootstrap';

import {ICaseChart} from 'modules/case-chart/models';
import {useNavbar} from 'shared/hooks/useNavbar';

import {CaseEstimatorForm} from 'shared/components/CaseEstimatorForm/CaseEstimatorForm';
import {CaseEstimatorPreview} from './CaseEstimatorPreview/CaseEstimatorPreview';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';

import './style.scss';

export const CreateCaseEstimator = () => {
    useNavbar(true);

    const [chart, setChart] = useState<ICaseChart | undefined>(undefined);

    return (
        <Container className="CreateCaseEstimator__section">
            <PageHelmet noindex title="Create Case Chart Estimator"/>
            <div className="CreateCaseEstimatorTabNav">
                <Tabs defaultActiveKey="create-case-estimator-form" className="CreateCaseEstimator__tab">
                    <Tab eventKey="create-case-estimator-form" title="Create">
                        <CaseEstimatorForm onChange={(chart) => setChart(chart)}/>
                    </Tab>
                    <Tab eventKey="preview-case-estimator" title="Preview">
                        {chart && (
                            <CaseEstimatorPreview chart={chart}/>
                        )}
                    </Tab>
                </Tabs>
            </div>
        </Container>
    );
};
