import {IAvailabilityStateKey, availabilityAtom, availabilityReadSelector} from 'modules/availability/state';
import {useRecoilSuspense} from 'shared/hooks/useRecoilSuspense';

export const useAvailability = (props: IAvailabilityStateKey) => {
    const result = useRecoilSuspense({
        state: availabilityAtom(props),
        loadable: availabilityReadSelector(props),
    });
    return result.timeSlots;
};
