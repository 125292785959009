import axios from 'axios';

import {
    IChatListResult,
    IChatListQuery,
    IChatSummary,
    IExistingChatQuery,
    IChatBatch,
    IChatCreateChat,
    IChat, IUnreadChatStatus,
} from 'modules/chat/models';
import {getAccessTokenSilently} from 'shared/utils/token';
import {Config} from 'config';
import {getAuthHeaders} from 'shared/utils/auth';

const getURLForChatAPI = (path: string) => {
    return `${Config.chatServiceURL}${path}`;
};

export const readChatList = async (chatListQuery: IChatListQuery): Promise<IChatListResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForChatAPI('/chats/'), {
        params: chatListQuery,
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const readChatSummary = async (chatId: string): Promise<IChatSummary> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForChatAPI(`/chats/${chatId}`), {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const findExistingChat = async (existingChatQuery: IExistingChatQuery): Promise<IChatBatch> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForChatAPI('/chats/find_existing'), existingChatQuery, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const createChat = async (usersId: IChatCreateChat): Promise<IChat> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForChatAPI('/chats/'), usersId, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const readUnreadChatStatus = async (userID: string): Promise<IUnreadChatStatus> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForChatAPI(`/unread_chats/${userID}/status`), {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};
