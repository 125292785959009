import { ServiceType } from 'modules/profile/models/serviceType';

export const getServiceTypeName = (serviceType: ServiceType): string => {
    switch (serviceType) {
        case (ServiceType.FixedFees):
            return 'Fixed Fees';
        case (ServiceType.FreeConsultation):
            return 'Free Consultation';
        case (ServiceType.NoDirectBriefs):
            return 'No Direct Briefs';
        case (ServiceType.AccreditedSpecialist):
            return 'Accredited Specialist';
        case (ServiceType.UnderSupervision):
            return 'Under Supervision';
    }
};
