import {atom, selector} from 'recoil';

import {IHomepageCache} from 'modules/homepage/models';
import {readHomepageCache} from 'modules/homepage/api';

export const homepageCacheAtom = atom<IHomepageCache | undefined>({
    key: 'homepageCacheAtom',
    default: undefined,
});

export const homepageCacheReadSelector = selector<IHomepageCache>({
    key: 'homepageCacheReadSelector',
    get: async ({get}) => {
        const atomValue = get(homepageCacheAtom);
        if (atomValue) {
            return atomValue;
        }
        return await readHomepageCache();
    },
});
