import {atom, selector, selectorFamily} from 'recoil';

import {IProject} from 'modules/project/models';
import {readProject} from 'modules/project/api';
import {throwWriteOnlySelectorError, guardRecoilDefaultValue} from 'shared/utils/recoil';
import {projectListAtom} from 'modules/project/state/project-list';
import {projectInsertSelector} from 'modules/project/state/project-insert';

export const projectLookupAtom = atom<IProject[]>({
    key: 'projectLookupAtom',
    default: [],
});

export const projectLookupSelector = selectorFamily<IProject | undefined, string>({
    key: 'projectLookupSelector',
    get: (projectId: string) => ({get}) => {
        const atomValue = get(projectLookupAtom);
        const valueInLookup = atomValue.find(project => project.id === projectId);
        if (valueInLookup) {
            return valueInLookup;
        }

        const listValue = get(projectListAtom);
        const valueInList = listValue?.projects?.find(project => project.id === projectId);
        if (valueInList) {
            return valueInList;
        }

        // there is no need to search for the project in the "search" selector, because the state setter automatically
        // adds the projects to the lookup state

        return undefined;
    },
    set: () => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(projectLookupInsertSelector, newValue);
    },
});

export const projectLookupReadSelector = selectorFamily<IProject, string>({
    key: 'projectLookupReadSelector',
    get: (projectId: string) => async ({get}): Promise<IProject> => {
        const currentValue = get(projectLookupSelector(projectId));
        if (currentValue) {
            return currentValue;
        }
        return await readProject(projectId);
    },
});

export const projectLookupMaybeSelector = selectorFamily<IProject | undefined, string | undefined>({
    key: 'projectLookupMaybeSelector',
    get: (projectId) => ({get}) => {
        if (!projectId) {
            return undefined;
        }
        return get(projectLookupSelector(projectId));
    },
    set: () => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(projectInsertSelector, newValue);
    },
});

export const projectLookupMaybeReadSelector = selectorFamily<IProject | undefined, string | undefined>({
    key: 'projectLookupMaybeReadSelector',
    get: (projectId) => async ({get}): Promise<IProject | undefined> => {
        if (!projectId) {
            return undefined;
        }
        return get(projectLookupReadSelector(projectId));
    },
});

export const projectLookupInsertSelector = selector<IProject>({
    key: 'projectLookupInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({set, get}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const atomValue = get(projectLookupAtom);
        set(projectLookupAtom, [
            ...atomValue.filter(project => project.id !== newValue.id),
            newValue,
        ]);
    },
});

export const projectLookupRemoveSelector = selector<string>({
    key: 'projectLookupRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({set, get}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const atomValue = get(projectLookupAtom);
        set(projectLookupAtom, atomValue.filter(project => project.id !== newValue));
    },
});
