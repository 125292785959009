import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSignInAlt} from '@fortawesome/free-solid-svg-icons';
import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import {useAuth} from 'shared/auth/hooks/useAuth';

interface INotAuthenticatedModalBodyProps {
    redirectUrl?: string;
}

export const NotAuthenticatedModalBody = ({redirectUrl}: INotAuthenticatedModalBodyProps) => {
    const {login} = useAuth();

    return (
        <div className="text-muted py-5 text-center">
            <FontAwesomeIcon icon={faSignInAlt} size="4x" className="mb-3"/>
            <p className="mb-4">
                You need a {process.env.REACT_APP_NAME} account to continue.
            </p>
            <Button
                as={Link}
                variant="success"
                to="/signup"
                className="w-40"
            >
                Create Account
            </Button>
            <Button
                as={Link}
                variant="primary"
                to="/login"
                onClick={(e) => {
                    e.preventDefault();
                    login({
                        from: redirectUrl,
                    });
                }}
                className="w-40 ml-1"
            >
                Log In
            </Button>
        </div>
    );
};
