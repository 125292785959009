import {atom, selector} from 'recoil';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/utils/recoil';

import {readUnreadChatStatus} from '../api';
import {guaranteedCurrentUserIdSelector} from 'shared/auth/state/current-user-id';

export interface IUnreadChatState {
    unreadCount: number;
    resetVersion: number;

    [key: string]: number | boolean;
}


export const unreadChatStatusAtom = atom<IUnreadChatState | undefined>({
    key: 'unreadChatStatusAtom',
    default: undefined,
});

export const unreadChatStatusResetAtom = atom<number>({
    key: 'unreadChatStatusResetAtom',
    default: 0,
});

export const unreadChatStatusSelector = selector<IUnreadChatState | undefined>({
    key: 'unreadChatStatusSelector',
    get: ({get}) => {
        const atomValue = get(unreadChatStatusAtom);
        const resetVersion = get(unreadChatStatusResetAtom);
        if (atomValue && resetVersion === atomValue.resetVersion) {
            return atomValue;
        }

        return undefined;
    },
    set: ({set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return null;
        }
        set(unreadChatStatusAtom, newValue);
    },
});

export const unreadChatStatusReadSelector = selector<IUnreadChatState>({
    key: 'unreadChatStatusReadSelector',
    get: async ({get}) => {
        const currentValue = get(unreadChatStatusSelector);
        if (currentValue) {
            return currentValue;
        }

        const userID = get(guaranteedCurrentUserIdSelector);
        const resetVersion = get(unreadChatStatusResetAtom);
        const unreadStatus = await readUnreadChatStatus(userID);

        return {
            unreadCount: unreadStatus.unread_count,
            resetVersion,
        } as IUnreadChatState;
    },
});

export const unreadChatStatusResetSelector = selector<null>({
    key: 'unreadChatStatusResetSelector',
    get: throwWriteOnlySelectorError,
    set: ({set, get}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return null;
        }
        const currentValue = get(unreadChatStatusResetAtom);
        return set(unreadChatStatusResetAtom, currentValue + 1);
    },
});
