import {GetTokenSilentlyOptions} from '@auth0/auth0-react';

const _getAccessTokenSilentlyStub = async () => {
    throw new Error('Not authenticated');
};
let _getAccessTokenSilently: ((options?: GetTokenSilentlyOptions) => Promise<string>) = _getAccessTokenSilentlyStub;

export const getAccessTokenSilently = (options?: GetTokenSilentlyOptions) => _getAccessTokenSilently(options);
export const setAccessTokenSilently = (func: (options?: GetTokenSilentlyOptions) => Promise<string>) => (_getAccessTokenSilently = func);

export const getAccessTokenMaybe = async (options?: GetTokenSilentlyOptions): Promise<string | undefined> => {
    let accessTokenMaybe: string | undefined;
    try {
        accessTokenMaybe = await getAccessTokenSilently(options);
    } catch {
        accessTokenMaybe = undefined;
    }
    return accessTokenMaybe;
};
