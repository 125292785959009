import React from 'react';

import {Params, useParams} from 'react-router-dom';
import {useRecoilValueLoadable} from 'recoil';

import {useNavbar} from 'shared/hooks/useNavbar';
import {postLookupSelector} from 'modules/post/state/post-lookup';

import {NotFound} from 'public/NotFound/NotFound';
import {PostViewContent} from 'public/PostView/PostViewContent';

import './style.scss';

export const PostView = () => {
    useNavbar(true);

    const params = useParams() as Params<'postId'>;
    const postId = params.postId as string;

    const postLoadable = useRecoilValueLoadable(postLookupSelector(postId));

    if (postLoadable.state === 'loading') {
        throw postLoadable.promiseMaybe();
    }

    return (
        <>
            {postLoadable.state === 'hasValue' && (
                <PostViewContent post={postLoadable.contents}/>
            )}
            {postLoadable.state === 'hasError' && (
                <NotFound buttonUrl="/" buttonTitle="RETURN HOME"/>
            )}
        </>
    );
};
