import React from 'react';
import {Button} from 'react-bootstrap';
import {useSetRecoilState} from 'recoil';

import {useNavbar} from 'shared/hooks/useNavbar';
import useLoadingPage from 'shared/hooks/useLoadingPage';
import {useHomepageCache} from 'modules/homepage/hooks';
import {tagListAtom} from 'modules/tag/state/tag-list';
import {useBool} from 'shared/hooks/useBool';
import {useAuth} from 'shared/auth/hooks/useAuth';
import {useWindowSize} from 'shared/hooks/useWindowSize';

import {HomePageHeader} from 'public/HomePage/HomePageHeader/HomePageHeader';
import {HomePageFeatures} from 'public/HomePage/HomePageFeatures/HomePageFeatures';
import {HomePageProfiles} from 'public/HomePage/HomePageProfiles/HomePageProfiles';
import {HomePagePosts} from 'public/HomePage/HomePagePosts/HomePagePosts';
import {AppFooter} from 'shared/components/AppFooter/AppFooter';
import {HomeAboutpage} from 'public/HomePage/HomeAboutpage/HomeAboutpage';
import {HomeWorkPage} from './HomeWorkPage/HomeWorkPage';
import {HomepageSlider} from './HomepageSlider/HomepageSlider';
import {HomePageReview} from './HomePageReview/HomePageReview';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';
import {HomePageJobs} from 'public/HomePage/HomePageJobs/HomePageJobs';
import {ProfileMarketplace} from 'public/ProfileMarketplace/ProfileMarketplace';
import {Spinner} from 'shared/components/Spinner/Spinner';
import {ScrolltobottomArrow} from 'shared/icons/common';
import {LogoutRedirectHelper} from 'shared/auth/components/LogoutRedirectHelper';

import './style.scss';

export const HomePage = () => {
    useNavbar(true);
    const {isAuthenticated, login} = useAuth();
    const {width} = useWindowSize();

    const [isHeaderLoaded, setIsHeaderLoaded] = useBool();

    const {homepageCache, loading} = useHomepageCache();
    useLoadingPage(loading && !isHeaderLoaded);

    const setTagList = useSetRecoilState(tagListAtom);
    React.useEffect(() => {
        if (homepageCache) {
            setTagList(homepageCache.tags);
        }
    }, [homepageCache, setTagList]);

    const cacheMainSectionHeightRef = React.useRef<number>(0);
    const mainSectionRef = React.useRef<HTMLDivElement | null>(null);
    const titleAndBtnRef = React.useRef<HTMLDivElement | null>(null);
    const scrollDownBtnRef = React.useRef<HTMLButtonElement | null>(null);
    const isMainSectionVisible = React.useRef<boolean>(true);
    const [mainDivOpacity, setMainDivOpacity] = React.useState<number>(1);

    React.useLayoutEffect(() => {
        if (!mainSectionRef.current) return;
        cacheMainSectionHeightRef.current = mainSectionRef.current.clientHeight;
    }, [mainSectionRef.current]);

    const onLoginClick = (e: React.MouseEvent) => {
        e.preventDefault();
        login();
    };

    const onSignupClick = (e: React.MouseEvent) => {
        e.preventDefault();
        login({
            screen_hint: 'signup',
        });
    };

    const onContainerScroll = (e: React.UIEvent<HTMLDivElement>) => {
        if (
            !cacheMainSectionHeightRef.current ||
            !mainSectionRef.current ||
            !titleAndBtnRef.current ||
            !scrollDownBtnRef.current
        ) {
            console.log('Error while scrolling');
            return;
        }

        const scrollTop = e.currentTarget.scrollTop;
        const mainSectionHeight = cacheMainSectionHeightRef.current;

        if (scrollTop >= mainSectionHeight / 3) {
            isMainSectionVisible.current = false;
        } else {
            isMainSectionVisible.current = true;
        }

        if (!isMainSectionVisible.current) return;

        const scrollRatio = scrollTop / (mainSectionHeight / 2);

        const opacity = +Math.max(0, 1 - scrollRatio).toFixed(2);
        const heightPercentage = 100 * opacity;

        const shrinkFactor = 0;
        const scaledOpacity = opacity + shrinkFactor;
        const scale = Math.min(1, scaledOpacity).toFixed(2);

        const shouldHideScrollBtn = scrollTop > 0;
        scrollDownBtnRef.current.classList.toggle('d-none', shouldHideScrollBtn);

        const shouldRemoveTitleAndBtn = scrollTop >= mainSectionHeight * 0.1;
        // titleAndBtnRef.current.classList.toggle('HomePageHeader__removeElement', shouldRemoveTitleAndBtn);

        const mainSectionStyle = {
            opacity,
            height: `${heightPercentage}%`,
            ...(shouldRemoveTitleAndBtn
                ? {transform: `scale(${scale}) translateY(${100 - heightPercentage}%)`}
                : {transform: `scale(${scale}) translateY(0)`}),
        };

        Object.assign(mainSectionRef.current.style, mainSectionStyle);
    };

    const isAnimationCompleted = mainDivOpacity <= 0.5;

    return (
        <div className="HomePage" onScroll={onContainerScroll}>
            <LogoutRedirectHelper/>
            <PageHelmet
                title="Rightful"
                noTemplate
                description="Where legal projects come together. Easily source legal talent, build a legal project, manage payments and so much more."
            />

            {width < 769 ? (
                <>
                    <div ref={mainSectionRef} className="HomePage__section HomePageHeader">
                        <img className="HomePage__rightfullogo" src="/assets/RightfulLogo.png" alt="Rightful Logo" />
                        <div className="InteractiveLogo">
                            <img src="/assets/logo-mobile.gif" className="img-fluid" alt="Rightful logo" />
                        </div>
                        <div ref={titleAndBtnRef} className="HomePageHeader__title-button">
                            <h2 style={{marginBottom: '18px'}} className="HomePageHeader__title">
                                Australia's leading
                                <br />
                                legal network
                            </h2>
                            <div className="d-flex justify-content-center">
                                <p className="HomePageHeader__textdes">
                                    Easily source legal talent, build a legal project, manage payments and so much more
                                </p>
                            </div>
                        </div>
                        {!isAuthenticated && (
                            <div className="homepage-auth--button">
                                {/* <Button
                                    style={{
                                        paddingInline: '35px',
                                    }}
                                    as={Link}
                                    // variant="featured"
                                    className="AppNavbar__button focusNone"
                                    to="/login"
                                    onClick={onLoginClick}
                                >
                                    SIGN IN
                                </Button> */}
                                <button className="homepage-auth__signin" onClick={onLoginClick}>
                                    SIGN IN
                                </button>
                                <p onClick={onSignupClick} className="homepage-auth__signuplink">
                                    Join for free
                                </p>
                            </div>
                        )}
                        {/* {isAuthenticated && (
                            <div className="hompage-network-button">
                                <Button
                                    style={{
                                        paddingInline: '35px',
                                    }}
                                    as={Link}
                                    variant="featured-inverted"
                                    className="AppNavbar__button focusNone"
                                    to="/network"
                                >
                                    Network
                                </Button>
                            </div>
                        )} */}
                    </div>
                    {isAuthenticated && (
                        <>
                            <Button ref={scrollDownBtnRef} className="homepage-scrolltobottom-btn">
                                <ScrolltobottomArrow />
                            </Button>
                            <React.Suspense fallback={<Spinner size="sm" variant="primary" />}>
                                <ProfileMarketplace
                                    // isReadyToScroll={isAnimationCompleted}
                                    shouldRenderHelment={false}
                                    stickyFilter={true}
                                />
                            </React.Suspense>
                        </>
                    )}
                </>
            ) : (
                <>
                    <HomePageHeader onLoad={() => setIsHeaderLoaded(true)} />
                    <HomeAboutpage />
                    <HomePageFeatures />
                    <HomeWorkPage />
                    <HomepageSlider />
                    <HomePageProfiles />
                    <HomePageReview />
                    <HomePagePosts />
                    <HomePageJobs />
                    <AppFooter subscribe={true} />
                </>
            )}
        </div>
    );
};

// import React, {useCallback, useEffect, useLayoutEffect, useMemo} from 'react';

// import {Link} from 'react-router-dom';
// import {Button} from 'react-bootstrap';
// import {useSetRecoilState} from 'recoil';

// import {useNavbar} from 'shared/hooks/useNavbar';
// import useLoadingPage from 'shared/hooks/useLoadingPage';
// import {useHomepageCache} from 'modules/homepage/hooks';
// import {tagListAtom} from 'modules/tag/state/tag-list';
// import {useBool} from 'shared/hooks/useBool';
// import {useAuth} from 'shared/auth/hooks/useAuth';
// import {useWindowSize} from 'shared/hooks/useWindowSize';

// import {HomePageHeader} from 'public/HomePage/HomePageHeader/HomePageHeader';
// import {HomePageFeatures} from 'public/HomePage/HomePageFeatures/HomePageFeatures';
// import {HomePageProfiles} from 'public/HomePage/HomePageProfiles/HomePageProfiles';
// import {HomePagePosts} from 'public/HomePage/HomePagePosts/HomePagePosts';
// import {AppFooter} from 'shared/components/AppFooter/AppFooter';
// import {HomeAboutpage} from 'public/HomePage/HomeAboutpage/HomeAboutpage';
// import {HomeWorkPage} from './HomeWorkPage/HomeWorkPage';
// import {HomepageSlider} from './HomepageSlider/HomepageSlider';
// import {HomePageReview} from './HomePageReview/HomePageReview';
// import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';
// import {HomePageJobs} from 'public/HomePage/HomePageJobs/HomePageJobs';
// import {ProfileMarketplace} from 'public/ProfileMarketplace/ProfileMarketplace';
// import {Spinner} from 'shared/components/Spinner/Spinner';
// import {ScrolltobottomArrow} from 'shared/icons/common';
// import 'animate.css';
// import './style.scss';

// export const HomePage = () => {
//     useNavbar(true);

//     const {isAuthenticated, login} = useAuth();
//     const {width} = useWindowSize();

//     const [isHeaderLoaded, setIsHeaderLoaded] = useBool();

//     const {homepageCache, loading} = useHomepageCache();
//     useLoadingPage(loading && !isHeaderLoaded);

//     const setTagList = useSetRecoilState(tagListAtom);
//     React.useEffect(() => {
//         if (homepageCache) {
//             setTagList(homepageCache.tags);
//         }
//     }, [homepageCache, setTagList]);

//     const cacheMainSectionHeightRef = React.useRef<number>(0);
//     const scrollHomepage = React.useRef<HTMLDivElement | null>(null);
//     const firstSectionRef = React.useRef<HTMLDivElement | null>(null);
//     const secondSectionRef = React.useRef<HTMLDivElement | null>(null);
//     const [scrollPosition, setScrollPosition] = React.useState<number>(1);
//     console.log('scrollPosition =====', scrollPosition);
//     // React.useLayoutEffect(() => {
//     //     if (!mainSectionRef.current) return;
//     //     cacheMainSectionHeightRef.current = mainSectionRef.current.clientHeight;
//     // }, [mainSectionRef.current]);

//     const onLoginClick = (e: React.MouseEvent) => {
//         e.preventDefault();
//         login();
//     };

//     const onSignupClick = (e: React.MouseEvent) => {
//         e.preventDefault();
//         login({
//             screen_hint: 'signup',
//         });
//     };

//     const handleScroll = () => {
//         if (scrollHomepage.current) {
//             const position = scrollHomepage.current.scrollTop;
//             setScrollPosition(position);
//         }
//     };

//     useEffect(() => {
//         const scrollDiv = scrollHomepage.current;

//         if (scrollDiv) {
//             scrollDiv.addEventListener('scroll', handleScroll, {passive: true});
//         }

//         return () => {
//             if (scrollDiv) {
//                 scrollDiv.removeEventListener('scroll', handleScroll);
//             }
//         };
//     }, []);

//     // Applying animation based on scroll position
//     useEffect(() => {
//         const firstSection = firstSectionRef.current;
//         const secondSection = secondSectionRef.current;

//         if (firstSection && secondSection) {
//             if (scrollPosition > 100) {
//                 // Shrink first section and fade out
//                 firstSection.classList.add('animate__fadeOutUp');
//                 firstSection.classList.remove('animate__fadeInDown');

//                 // Expand second section and fade in
//                 secondSection.classList.add('animate__fadeInUp');
//                 secondSection.classList.remove('animate__fadeOutDown');
//             } else {
//                 // Expand first section back to full height and fade in
//                 firstSection.classList.remove('animate__fadeOutUp');
//                 firstSection.classList.add('animate__fadeInDown');

//                 // Shrink second section and fade out
//                 secondSection.classList.remove('animate__fadeInUp');
//                 secondSection.classList.add('animate__fadeOutDown');
//             }
//         }
//     }, [scrollPosition]);

//     return (
//         <div ref={scrollHomepage} className="HomePage">
//             <PageHelmet
//                 title="Rightful"
//                 noTemplate
//                 description="Where legal projects come together. Easily source legal talent, build a legal project, manage payments and so much more."
//             />

//             {width < 769 ? (
//                 <>
//                     <div
//                         ref={firstSectionRef}
//                         className="HomePage__section HomePageHeader animate__animated animate__fadeInDown"
//                     >
//                         {/* First Section Content */}
//                         <img className="HomePage__rightfullogo" src="/assets/RightfulLogo.png" alt="Rightful Logo" />
//                         <div className="InteractiveLogo">
//                             <img src="/assets/logo-mobile.gif" className="img-fluid" alt="Rightful logo" />
//                         </div>
//                         <div className="HomePageHeader__title-button">
//                             <h2 style={{marginBottom: '18px'}} className="HomePageHeader__title">
//                                 Australia's leading legal network
//                             </h2>
//                             <div className="d-flex justify-content-center">
//                                 <p className="HomePageHeader__textdes">
//                                     Easily source legal talent, build a legal project, manage payments and so much more.
//                                 </p>
//                             </div>
//                         </div>
//                         {!isAuthenticated && (
//                             <div className="homepage-auth--button">
//                                 <button className="homepage-auth__signin" onClick={onLoginClick}>
//                                     SIGN IN
//                                 </button>
//                                 <p onClick={onSignupClick} className="homepage-auth__signuplink">
//                                     Join for free
//                                 </p>
//                             </div>
//                         )}
//                         <button className="homepage-scrolltobottom-btn">
//                             <ScrolltobottomArrow />
//                         </button>
//                     </div>
//                     <React.Suspense fallback={<Spinner size="sm" variant="primary" />}>
//                         <div ref={secondSectionRef} className="animate__animated animate__fadeOutDown">
//                             {/* Second Section Content */}
//                             <ProfileMarketplace shouldRenderHelment={false} stickyFilter={true} />
//                         </div>
//                     </React.Suspense>
//                 </>
//             ) : (
//                 <>
//                     <HomePageHeader onLoad={() => setIsHeaderLoaded(true)} />
//                     <HomeAboutpage />
//                     <HomePageFeatures />
//                     <HomeWorkPage />
//                     <HomepageSlider />
//                     <HomePageProfiles />
//                     <HomePageReview />
//                     <HomePagePosts />
//                     <HomePageJobs />
//                     <AppFooter subscribe={true} />
//                 </>
//             )}
//         </div>
//     );
// };
