import React from 'react';

import Slider from 'react-slick';
import Slick from 'react-slick';

import {useWindowSize} from 'shared/hooks/useWindowSize';

import {NextSlick, NextSlickMob, PrevSlick, PrevSlickMob} from 'shared/icons/Homepage';

interface Props {
    children: JSX.Element[] | JSX.Element;
    slidesToShow?: number;
    infinite?: boolean;
    dots?: boolean;
}

export const SlickCards = (props: Props) => {
    const sliderRef = React.useRef<Slick | null>(null);

    const {width} = useWindowSize();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const SampleArrow = (props: any) => {
        const {className, style, onClick} = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    fontSize: '45.7px !important',
                    display: 'none',
                }}
                onClick={onClick}
            />
        );
    };

    const cardNum = () => {
        if (width > 630) {
            if (width > 1024) {
                return 3;
            } else {
                return 1;
            }
        } else {
            return 1;
        }
    };

    const settings = {
        dots: (width > 1024 && props.dots) || false,
        infinite: props.infinite || false,
        speed: 500,
        slidesToShow: props.slidesToShow || cardNum(),
        slidesToScroll: 1,
        nextArrow: width > 1024 ? <NextSlick/> : <SampleArrow/>,
        prevArrow: width > 1024 ? <PrevSlick/> : <SampleArrow/>,
        className: 'm-2',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        appendDots: (dots: any) => <ul>{dots}</ul>,
        customPaging: (i: number) => (
            <div className="slick-dots-custom"/>
        ),
    };

    return (
        <React.Fragment>
            <Slider ref={sliderRef}{...settings}>
                {props.children}
            </Slider>
            <div className={`${width > 1024 ? 'd-none' : 'd-flex'} justify-content-center`}>
                <div
                    className="px-2"
                    onClick={sliderRef.current ? sliderRef.current.slickPrev : undefined}
                    style={{cursor: 'pointer'}}
                >
                    <PrevSlickMob/>
                </div>
                <div
                    className="px-2"
                    onClick={sliderRef.current ? sliderRef.current.slickNext : undefined}
                    style={{cursor: 'pointer'}}
                >
                    <NextSlickMob/>
                </div>
            </div>
        </React.Fragment>
    );
};
