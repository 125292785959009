import React from 'react';

import {Controller, FieldValues, UseControllerProps} from 'react-hook-form';
import {Form} from 'react-bootstrap';

import {SkillsSelectorItem} from 'shared/components/SkillsSelector/SkillsSelectorItem';

interface ISkillsSelectorProps<FormType> extends UseControllerProps<FormType> {
    disabled?: boolean;
}

export const SkillsSelector = <FormType extends FieldValues>({
    control,
    name,
    disabled,
    defaultValue,
}: ISkillsSelectorProps<FormType>) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({field, fieldState}) => (
                <>
                    <div className="ProfilePageSkillsItem__stars p-0">
                        {[1, 2, 3, 4, 5].map((skillIndex) => (
                            <SkillsSelectorItem
                                key={`star-${skillIndex}`}
                                active={skillIndex <= field.value}
                                onClick={() => field.onChange(skillIndex)}
                                disabled={disabled}
                            />
                        ))}
                    </div>
                    {fieldState.error && fieldState.isDirty && (
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {fieldState.error.message}
                        </Form.Control.Feedback>
                    )}
                </>
            )}
        />
    );
}
;
