import React, {useState} from 'react';

import {Button} from 'react-bootstrap';
import {useSetRecoilState} from 'recoil';

import {IFile} from 'modules/file/models/IFile';
import {insertProfileSelector} from 'modules/profile/state/profile-insert';
import {toast} from 'shared/utils/toast';
import {updateProfile} from 'modules/profile/api/profile';

import {ProfileImageList} from 'shared/components/ProfileImageList/ProfileImageList';
import {Modal} from 'shared/components/modals/Modal/Modal';
import {ModalBody} from 'shared/components/modals/Modal/ModalBody';
import {ModalFooter} from 'shared/components/modals/Modal/ModalFooter';
import {Spinner} from 'shared/components/Spinner/Spinner';

interface IChooseProfilePictureModalProps {
    userId: string;
    show?: boolean;
    onClose?: () => void;
}

export const ChooseProfilePictureModal = ({show, onClose, userId}: IChooseProfilePictureModalProps) => {
    const [selected, setSelected] = useState<IFile | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const insertProfile = useSetRecoilState(insertProfileSelector);

    const handleClose = () => {
        onClose?.();
    };

    const onSaveClick = () => {
        if (isSubmitting || !selected) {
            return;
        }
        setIsSubmitting(true);
        (async () => {
            try {
                const profile = await updateProfile(userId, {
                    image_id: selected.id,
                });
                insertProfile(profile);
                toast.success('Choose Profile Image', 'Profile image updated successfully');
                onClose?.();
            } catch (e) {
                toast.error('Error updating profile image', e.toString());
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    return (
        <Modal show={!!show} onHide={handleClose} title="Choose Profile Picture" isSubmitting={isSubmitting}>
            <ModalBody>
                <p>Update Profile Picture From Your Existing Images</p>
                <ProfileImageList userId={userId} onChange={(file) => setSelected(file)}/>
                {selected && (
                    <p className="mb-0">Use {selected.filename}</p>
                )}
            </ModalBody>
            <ModalFooter>
                <Button variant="secondary" onClick={handleClose} disabled={isSubmitting}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    type="button"
                    disabled={isSubmitting}
                    onClick={onSaveClick}
                    className="d-flex align-items-center"
                >
                    {isSubmitting && (
                        <Spinner size="sm" className="mr-1"/>
                    )}
                    Save changes
                </Button>
            </ModalFooter>
        </Modal>
    );
};
