import React from 'react';

import {useProfileSummary} from 'modules/profile/hooks/useProfileSummary';

interface IUserIdNameInnerProps {
    id: string;
    className?: string;
}

export const UserIdNameInner = ({id, className}: IUserIdNameInnerProps) => {
    const {profileSummary, error} = useProfileSummary(id);

    const fullName = React.useMemo(() => {
        if (profileSummary) {
            return `${profileSummary.firstname} ${profileSummary.lastname}`;
        } else if (error) {
            return 'unknown user';
        } else {
            return 'Loading...';
        }
    }, [profileSummary, error]);

    return <span className={className}>{fullName}</span>;
};
