import React from 'react';

import {Form} from 'react-bootstrap';
import {startOfMonth, endOfMonth} from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import {useRecoilValue} from 'recoil';

import {formatISOUTC} from 'shared/utils/date';
import {useAvailabilitySettings} from 'modules/availability-settings/hooks/useAvailabilitySettings';
import {useAvailability} from 'modules/availability/hooks/useAvailability';
import {bookingTimezoneAtom} from 'shared/state/booking-timezone';

import {TimeSlotControl} from 'shared/components/form/TimeSlotControl/TimeSlotControl/TimeSlotControl';

export interface ITimeSlotControllerContentProps {
    errorMessage?: string;
    className?: string;
    userId: string;
    value: Date;
    onChange: (value: Date) => void;
    disabled?: boolean;
    currentDate: Date;
    setCurrentDate: (currentDate: Date) => void;
}

export const TimeSlotControllerContent = ({
    className,
    errorMessage,
    disabled,
    value,
    onChange,
    userId,
    currentDate,
    setCurrentDate,
}: ITimeSlotControllerContentProps) => {
    const availabilitySettings = useAvailabilitySettings(userId);
    const bookingTimezone = useRecoilValue(bookingTimezoneAtom);
    const availableTimeSlots = useAvailability({
        userId,
        startAt: formatISOUTC(startOfMonth(currentDate)),
        endAt: formatISOUTC(endOfMonth(currentDate)),
    });

    return (
        <>
            <TimeSlotControl
                className={className}
                value={value}
                onChange={(value) => onChange(value)}
                invalid={!!errorMessage?.length}
                currentDate={currentDate}
                setCurrentDate={setCurrentDate}
                availableTimeSlots={availableTimeSlots}
                disabled={disabled}
                emptyContent={
                    availableTimeSlots.length === 0 && (
                        <div className="d-flex flex-wrap justify-content-center px-3">
                            {[...Array(14).keys()].map((itm: number, index: number) => (
                                <React.Fragment key={index}>
                                    <Skeleton
                                        style={{
                                            margin: '5px',
                                            width: '102px',
                                            height: '40px',
                                            borderRadius: '13px',
                                        }}
                                    />
                                </React.Fragment>
                            ))}
                        </div>
                    )
                }
                availabilityTimezone={availabilitySettings.timezone}
                displayTimezone={bookingTimezone}
            />

            {!!errorMessage?.length && (
                <Form.Control.Feedback type="invalid" className="d-block">
                    {errorMessage}
                </Form.Control.Feedback>
            )}
        </>
    );
};
