import React from 'react';

import {IPostMedia} from 'modules/post/models/IPostMedia';
import {PostMediaKind} from 'modules/post/models/postMediaKind';
import {PostKind} from 'modules/post/models/postKind';
import {getFileDownloadUrl} from 'modules/file/utils';

import {MediaCardPicture} from 'shared/components/MediaCard/MediaCardPicture/MediaCardPicture';

interface IPostCardProps {
    url: string;
    media: IPostMedia[];
    className?: string;
    classNameImage?: string;
    kind: PostKind;
    setPostWebinarUrl: (url: string) => void;
    setIsOpenPreview: (OpenPreview: boolean) => void;
}

export const PostCardImage = ({
    url,
    media,
    kind,
    className,
    classNameImage,
    setPostWebinarUrl,
    setIsOpenPreview,
}: IPostCardProps) => {
    const imageMedia = media.find(
        (mediaItem) => mediaItem.kind === PostMediaKind.Image || mediaItem.kind === PostMediaKind.ImageUrl,
    );

    let imageUrl: string | undefined;
    if (imageMedia?.kind === PostMediaKind.Image && imageMedia?.file_id && imageMedia?.name) {
        if (imageMedia.public_url) {
            imageUrl = imageMedia.public_url;
        } else {
            imageUrl = getFileDownloadUrl({
                id: imageMedia.file_id,
                filename: imageMedia.name,
            });
        }
    } else if (imageMedia?.kind === PostMediaKind.ImageUrl) {
        imageUrl = imageMedia?.content;
    }

    return (
        <>
            <MediaCardPicture
                classNameImage={classNameImage ? classNameImage : ''}
                className={className ? className : ''}
                to={url}
                kind={kind}
                media={media}
                imageUrl={imageUrl}
                setPostWebinarUrl={setPostWebinarUrl}
                setIsOpenPreview={setIsOpenPreview}
            />
        </>
    );
};
