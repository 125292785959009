import React from 'react';

import {useSetRecoilState} from 'recoil';

import {toast} from 'shared/utils/toast';
import {toastAxiosError} from 'shared/utils/error';
import {deleteReview} from 'modules/review/api';
import {reviewListRemoveSelector} from 'modules/review/state/review-list';
import {useBool} from 'shared/hooks/useBool';

import {ConfirmationModal} from 'shared/components/modals/ConfirmationModal/ConfirmationModal';

interface IDeleteReviewModalProps {
    show: boolean;
    onClose: () => void;
    reviewId: string;
}

export const DeleteReviewModal = ({show, onClose, reviewId}: IDeleteReviewModalProps) => {
    const removeReview = useSetRecoilState(reviewListRemoveSelector);
    const [isSubmitting, setIsSubmitting] = useBool();

    const onSubmit = () => {
        setIsSubmitting(true);
        (async () => {
            try {
                await deleteReview(reviewId);
                removeReview(reviewId);
                onClose();
                toast.success('Delete Review', 'The review was deleted successfully');
            } catch (e) {
                toastAxiosError(e, 'Delete Review');
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    return (
        <ConfirmationModal
            show={show}
            onHide={onClose}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
            title="Delete Review"
        >
            <p>Are you sure?</p>
        </ConfirmationModal>
    );
};
