import {Tab, Tabs} from 'react-bootstrap';

import {useSignupStatus} from 'modules/profile/hooks/useSignupStatus';
import {Role} from 'modules/profile/models/IProfile';
import {useAuth} from 'shared/auth/hooks/useAuth';
import {clientPlans, providerPlans} from 'public/PlansPage/models';

import PlansList from '../PlantList/PlansList';

import './PlansListHeader.scss';

const PlansListHeader = () => {
    const {profile} = useSignupStatus();
    const isProvider = profile?.role === Role.Provider || profile?.role === Role.System;
    const isClient = profile?.role === Role.Client;
    const {isAuthenticated} = useAuth();

    return (
        <div className="PlansHeader">
            {isClient && (
                <PlansList plans={clientPlans}/>
            )}
            {isProvider && (
                <PlansList plans={providerPlans}/>
            )}
            {!isAuthenticated && (
                <Tabs defaultActiveKey="client" className="PlansHeader__ClientService-tab">
                    <Tab eventKey="client" title="Client">
                        <PlansList plans={clientPlans}/>
                    </Tab>
                    <Tab eventKey="service-provider" title="Service Provider">
                        <PlansList plans={providerPlans}/>
                    </Tab>
                </Tabs>
            )}
        </div>
    );
};

export default PlansListHeader;
