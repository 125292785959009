import React from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {useNavigate} from 'react-router-dom';

import {logoutRedirectLocalStorageKey} from 'shared/auth/constants';

export const LogoutRedirectHelper = () => {
    const {isAuthenticated} = useAuth0();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (isAuthenticated) {
            localStorage.removeItem(logoutRedirectLocalStorageKey);
        } else {
            // read redirect url from local storage, if there's a value redirect to it
            const url = localStorage.getItem(logoutRedirectLocalStorageKey);
            localStorage.removeItem(logoutRedirectLocalStorageKey);
            if (url) {
                navigate(url);
            }
        }
    }, [isAuthenticated, navigate]);

    return null;
};
