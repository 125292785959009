import {selector} from 'recoil';

import {IFavorite, IFavoriteKey} from 'modules/favorite/models';
import {favoriteListInsertSelector, favoriteListRemoveSelector} from 'modules/favorite/state/favorite-list';
import {favoriteLookupInsertSelector, favoriteLookupRemoveSelector} from 'modules/favorite/state/favorite-lookup';
import {throwWriteOnlySelectorError, guardRecoilDefaultValue} from 'shared/utils/recoil';

export const favoriteInsertSelector = selector<IFavorite>({
    key: 'favoriteInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        set(favoriteListInsertSelector, newValue);
        set(favoriteLookupInsertSelector, newValue);
    },
});

export const favoriteRemoveSelector = selector<IFavoriteKey>({
    key: 'favoriteRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        set(favoriteListRemoveSelector, newValue);
        set(favoriteLookupRemoveSelector, newValue);
    },
});
