export class FolderPath {
    static userDocument(userId: string) {
        return `users/${userId}/document`;
    }

    static userPicture(userId: string) {
        return `users/${userId}/picture`;
    }

    static userPublic(userId: string) {
        return `users/${userId}/public`;
    }

    static userPost(userId: string) {
        return `users/${userId}/post`;
    }

    static userTemp(userId: string) {
        return `users/${userId}/temp`;
    }

    static project(projectId: string) {
        return `projects/${projectId}`;
    }
}
