export const getAuthorizationHeader = (accessToken: string) => {
    return `Bearer ${accessToken}`;
};

export const getAuthHeaders = (accessToken?: string, headers?: Record<string, string>): Record<string, string> => {
    const headerResult = {...headers};
    if (accessToken) {
        headerResult['Authorization'] = getAuthorizationHeader(accessToken);
    }
    return headerResult;
};

export const serializeArrayParams = (paramName: string, valArray: (number | string)[]): string => {
    return valArray.map(val => `${paramName}=${val}`).join('&');
};
