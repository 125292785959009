import {atom, selector, selectorFamily, atomFamily} from 'recoil';

import {IFile} from 'modules/file/models/IFile';
import {readFileList} from 'modules/file/api';
import {throwWriteOnlySelectorError, guardRecoilDefaultValue} from 'shared/utils/recoil';

export interface IFileListStateFilters {
    page: number;
    userId?: string;
    limit: number;
    folder: string;
    fileToken?: string;

    [key: string]: string | number | undefined;
}

interface IFileListState {
    files: IFile[];
    cursors: (string | undefined)[];
    page: number;
    limit: number;
    more: boolean;
    userId?: string;
    folder: string;
    fileToken?: string;
    resetVersion: number;

    [key: string]: string | number | undefined | boolean | IFile[] | (string | undefined)[];
}

export const fileListAtom = atomFamily<IFileListState | undefined, string>({
    key: 'fileListAtom',
    default: undefined,
});

export const fileListResetAtom = atomFamily<number, string>({
    key: 'fileListResetAtom',
    default: 1,
});

export const fileListFoldersAtom = atom<string[]>({
    key: 'fileListFoldersAtom',
    default: [],
});

export const fileListFoldersInsertSelector = selector<string>({
    key: 'fileListFoldersInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const atomValue = get(fileListFoldersAtom);
        if (!atomValue.includes(newValue)) {
            set(fileListFoldersAtom, [...atomValue, newValue]);
        }
    },
});

export const fileListSelector = selectorFamily<IFileListState | undefined, IFileListStateFilters>({
    key: 'fileListSelector',
    get: ({page, limit, userId, folder, fileToken}) => ({get}) => {
        const fileListState = get(fileListAtom(folder));
        const resetVersion = get(fileListResetAtom(folder));
        if (
            fileListState &&
            fileListState.userId === userId &&
            fileListState.folder === folder &&
            fileListState.limit === limit &&
            fileListState.folder === folder &&
            fileListState.resetVersion === resetVersion &&
            fileListState.fileToken === fileToken &&
            fileListState.page === page
        ) {
            return fileListState;
        }
        return undefined;
    },
    set: () => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(fileListAtom(newValue.folder), newValue);
        set(fileListFoldersInsertSelector, newValue.folder);
    },
});

export const fileListReadSelector = selectorFamily<IFileListState, IFileListStateFilters>({
    key: 'fileListReadSelector',
    get: (filters) => async ({get}) => {
        const currentValue = get(fileListSelector(filters));
        if (currentValue) {
            return currentValue;
        }

        const fileListState = get(fileListAtom(filters.folder));
        const resetVersion = get(fileListResetAtom(filters.folder));

        // load a fresh page from the server
        const result = await readFileList({
            userId: filters.userId,
            limit: filters.limit,
            folder: filters.folder,
            cursor: filters.page === 0 ? undefined : fileListState?.cursors[filters.page - 1],
            fileToken: filters.fileToken,
        });

        // add the cursor to the list of cursors so we can paginate backwards
        const newCursors = fileListState ? Array.from(fileListState.cursors) : [];
        newCursors[filters.page] = result.nextCursor;

        return {
            files: result.files,
            cursors: newCursors,
            page: filters.page,
            limit: filters.limit,
            userId: filters.userId,
            fileToken: filters.fileToken,
            folder: filters.folder,
            more: !!result.nextCursor,
            resetVersion,
        } as IFileListState;
    },
});

export const fileListFileSelector = selectorFamily<IFile | undefined, string>({
    key: 'fileListFileSelector',
    get: (fileId) => ({get}) => {
        const folders = get(fileListFoldersAtom);
        for (const folder of folders) {
            const atomValue = get(fileListAtom(folder));
            const valueInAtom = atomValue?.files?.find(file => file.id === fileId);
            if (valueInAtom) {
                return valueInAtom;
            }
        }
        return undefined;
    },
});

export const fileListInsertSelector = selector<IFile>({
    key: 'fileListInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const atomValue = get(fileListAtom(newValue.folder));
        const index = atomValue?.files?.findIndex(file => file.id === newValue.id);
        if (!atomValue || typeof index === 'undefined') {
            return;
        }

        // replace the file in the list, or trigger a reset
        if (index !== -1) {
            const newFiles = Array.from(atomValue.files);
            newFiles.splice(index, 1, newValue);
            set(fileListAtom(newValue.folder), {
                ...atomValue,
                files: newFiles,
            });
        } else {
            const _resetSelector = fileListResetAtom(newValue.folder);
            set(_resetSelector, get(_resetSelector) + 1);
        }
    },
});

export const fileListRemoveSelector = selector<string>({
    key: 'fileListRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const folders = get(fileListFoldersAtom);
        for (const folder of folders) {
            set(fileListRemoveFromFolderSelector(folder), newValue);
        }
        return undefined;
    },
});

export const fileListRemoveFromFolderSelector = selectorFamily<string, string>({
    key: 'fileListRemoveFromFolderSelector',
    get: throwWriteOnlySelectorError,
    set: (folder) => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const atomValue = get(fileListAtom(folder));
        if (!atomValue) {
            return;
        }
        const index = atomValue.files.findIndex(file => file.id === newValue);
        if (index !== -1) {
            set(fileListAtom(folder), {
                ...atomValue,
                files: atomValue.files.filter(file => file.id !== newValue),
            });
        } else {
            const _resetSelector = fileListResetAtom(folder);
            set(_resetSelector, get(_resetSelector) + 1);
        }
        return undefined;
    },
});
