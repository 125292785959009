import React from 'react';
import Gleap from 'gleap';

import {useSignupStatus} from 'modules/profile/hooks/useSignupStatus';
import {getFullName} from 'modules/profile/utils';
import {useAuth} from 'shared/auth/hooks/useAuth';
import {safelyParseDate} from 'shared/utils/date';

export const GleapIdentity = () => {
    const {tokenData} = useAuth();
    const {profile} = useSignupStatus();

    React.useEffect(() => {
        if (profile && tokenData) {
            Gleap.identify(profile.id, {
                name: getFullName(profile),
                email: tokenData.email,
                createdAt: safelyParseDate(profile.created_at),
            });
        } else if (!Gleap.isUserIdentified()) {
            Gleap.clearIdentity();
        }
    }, [profile, tokenData]);

    return null;
};
