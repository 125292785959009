import React from 'react';

interface IToastMessageProps {
    title: string;
}

interface IToastMessage {
    title: string;
    message: string;
}

export const ToastMessage = ({title, children}: React.PropsWithChildren<IToastMessageProps>) => {
    return (
        <>
            <div className="Toastify__toast-title">{title}</div>
            <div className="Toastify__toast-message">{children}</div>
        </>
    );
};

export const getToastMessage = ({title, message}: IToastMessage) => {
    return (
        <ToastMessage title={title}>{message}</ToastMessage>
    );
};