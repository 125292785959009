import {selector} from 'recoil';

import {profileListInsertSelector} from 'modules/profile/state/profile-list';
import {profileLookupInsertSelector} from 'modules/profile/state/profile-lookup';
import {IProfile} from 'modules/profile/models/IProfile';
import {throwWriteOnlySelectorError, guardRecoilDefaultValue} from 'shared/utils/recoil';
import {marketplaceInfiniteScrollInsertSelector} from 'modules/profile/state/marketplace-infinite-scroll';

export const insertProfileSelector = selector<IProfile>({
    key: 'insertProfileSelector',
    get: throwWriteOnlySelectorError,
    set: ({set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        set(profileLookupInsertSelector, newValue);
        set(profileListInsertSelector, newValue);
        set(marketplaceInfiniteScrollInsertSelector, newValue);
        // NOTE: do not set the reset version as it causes infinite-refreshes from the lookup atom
        // set(profileListResetAtom, get(profileListResetAtom) + 1);
    },
});
