import {atom, selector} from 'recoil';

import {IProfile} from 'modules/profile/models/IProfile';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/utils/recoil';
import {IProfilesPageFilters} from 'shared/state/profiles-page';

interface IMarketPlaceProfileListState {
    profiles: IProfile[];
    nextCursor?: string;
    currentCursor?: string;
    filters: IProfilesPageFilters;
}

export const marketplaceInfiniteScrollAtom = atom<IMarketPlaceProfileListState | undefined>({
    key: 'marketplaceInfiniteScrollAtom', 
    default: undefined,
});


export const marketplaceInfiniteScrollInsertSelector = selector<IProfile>({
    key: 'marketplaceInfiniteScrollInsertSelector', 
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const atomValue = get(marketplaceInfiniteScrollAtom);
        if (!atomValue) {
            return;
        }

        // if the profile is present in the store then update it. Otherwise, force a reload.
        const profileIndex = atomValue.profiles.findIndex(profile => profile.id === newValue.id);
        if (profileIndex !== -1) {
            const newProfiles = Array.from(atomValue.profiles);
            newProfiles.splice(profileIndex, 1, newValue);
            set(marketplaceInfiniteScrollAtom, {
                ...atomValue,
                profiles: newProfiles,
            });
        }
    },
});