import { useRef } from 'react';

import useEffectOnce from 'shared/hooks/useEffectOnce';

// taken from https://github.com/streamich/react-use
// licence allows it
// I'm hesitant to use the library due to the large number of contributors
const useUnmount = (fn: () => void): void => {
    const fnRef = useRef(fn);

    // update the ref each render so if it change the newest callback will be invoked
    fnRef.current = fn;

    useEffectOnce(() => () => fnRef.current());
};

export default useUnmount;
