import React from 'react';

import classNames from 'classnames';

import './style.scss';

interface IProfilePageEmptyProps {
    className?: string;
    children?: React.ReactNode;
}

export const ProfilePageEmpty = ({children, className}: IProfilePageEmptyProps) => {

    return (
        <div className={classNames('ProfilePageEmpty', className)}>
            {children}
        </div>
    );
};
