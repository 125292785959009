import React from 'react';

import {useAuth} from 'shared/auth/hooks/useAuth';

import {Loading} from 'shared/components/loading/Loading/Loading';

export const SignupRedirect = () => {
    const {login} = useAuth();

    React.useEffect(() => {
        login({
            screen_hint: 'signup',
        });
    });

    return (
        <Loading/>
    );
};
