export const toTitleCase = (text: string): string => {
    return text.split(' ')
        .map(word => word.trim())
        .map(word => {
            return word.charAt(0).toUpperCase() + word.substring(1, word.length);
        })
        .join(' ');
};

export const truncateContent = (content: string, contentSize: number) => {
    if (content) {
        return content.length >= contentSize ? content.substring(0, (contentSize - 3)) + '...' : content;
    }
};

export const makeCommaSeparatedString = (words: string[]) => {
    const listStart = words.slice(0, -1).join(', ');
    const listEnd = words.slice(-1);
    const conjunction = words.length <= 1 ? '' : ' and ';
    return [listStart, listEnd].join(conjunction);
};

/**
 * A simple function to uppercase the first character in a string. Does not address internationalization!
 * @param str
 */
export const capitalizeFirstLetterSimple = (str: string): string => {
    // return str[0].toUpperCase() + str.slice(1)   // without template string
    if (!str.length) {
        return '';
    }
    return `${str[0].toUpperCase()}${str.slice(1)}`;
};

/**
 * Mask a number of leading characters in a string with asterix ("*") characters.
 * @param str
 * @param numCharsToMask
 */
export const maskString = (str: string, numCharsToMask: number): string => {
    if (numCharsToMask >= str.length) {
        // if numCharsToMask is greater than or equal to string length, mask the entire string
        return '*'.repeat(str.length);
    } else {
        // mask the first numCharsToMask characters and keep the rest of the string as is
        const maskedChars = '*'.repeat(numCharsToMask);
        const unmaskedChars = str.slice(numCharsToMask);
        return maskedChars + unmaskedChars;
    }
};

/**
 * Parse a string to an enum and raise an exception if it's invalid.
 * @param enumObj
 * @param str
 */
export const parseEnumValue = <T extends Record<string, string>>(enumObj: T, str: string): T[keyof T] => {
    const enumValues = Object.values(enumObj);

    if (!enumValues.includes(str)) {
        throw new Error(`Invalid enum value: ${str}`);
    }

    return str as T[keyof T];
};
