import {usePostListLoadable} from 'modules/post/hooks/usePostListLoadable';

export const useProfilePostsLoadable = (userId: string) => {
    return usePostListLoadable({
        filters: {
            userId: userId,
            isPublished: true,
        },
        page: 0,
    });
};
