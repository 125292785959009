import axios from 'axios';

import {ICreatePost, ICreatePostLawyer} from 'modules/post/models/ICreatePost';
import {IPost} from 'modules/post/models/IPost';
import {IReadPostsResponse} from 'modules/post/models/IReadPostsResponse';
import {IPostListQuery} from 'modules/post/models/IPostListQuery';
import {IReadFeaturedPostsRequest} from 'modules/post/models/IReadFeaturedPostsRequest';
import {PostOrder} from 'modules/post/models/postOrder';
import {getAccessTokenMaybe, getAccessTokenSilently} from 'shared/utils/token';
import {Config} from 'config';
import {getAuthHeaders} from 'shared/utils/auth';

const getURLForPostAPI = (path: string) => {
    return `${Config.mainServiceURL}/posts${path}`;
};

export const createPost = async (postData: ICreatePost): Promise<IPost> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForPostAPI('/'), postData, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const updatePost = async (postId: string, postData: ICreatePost | ICreatePostLawyer): Promise<IPost> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(getURLForPostAPI(`/${postId}`), postData, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const readPost = async (postId: string): Promise<IPost> => {
    const accessToken = await getAccessTokenMaybe();
    const response = await axios.get(getURLForPostAPI(`/${postId}`), {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const readPostList = async (params: IPostListQuery): Promise<IReadPostsResponse> => {
    const accessToken = await getAccessTokenMaybe();
    const response = await axios.get(getURLForPostAPI('/'), {
        params: {
            limit: params.limit,
            cursor: params.cursor,
            user_id: params.userId,
            kind: params.kind,
            is_featured: params.isFeatured,
            is_published: params.isPublished,
            order_by: params.orderBy,
            search_term: params.searchTerm,
            tag_id: params.tagId,
        },
        headers: getAuthHeaders(accessToken),
    });
    return {
        posts: response.data?.posts,
        nextCursor: response.data?.next_cursor,
    };
};

export const readFeaturedPostList = async (params: IReadFeaturedPostsRequest): Promise<IReadPostsResponse> => {
    const accessToken = await getAccessTokenMaybe();
    const response = await axios.get(getURLForPostAPI('/'), {
        params: {
            is_published: params?.is_published,
            is_featured: params?.is_featured,
            limit: params.limit,
            cursor: params.cursor,
            user_id: params.userId,
            kind: params.kind,
            order_by: PostOrder.PublishedAt,
        },
        headers: getAuthHeaders(accessToken),
    });
    return {
        posts: response.data?.posts,
        nextCursor: response.data?.next_cursor,
    };
};

export const deletePost = async (postId: string) => {
    const accessToken = await getAccessTokenSilently();
    await axios.delete(getURLForPostAPI(`/${postId}`), {
        headers: getAuthHeaders(accessToken),
    });
};
