import React from 'react';

import {z} from 'zod';
import classNames from 'classnames';
import {useSetRecoilState} from 'recoil';
import {Button, Form} from 'react-bootstrap';
import {useFieldArray, useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';

import {IProfile} from 'modules/profile/models/IProfile';
import {updateProfile} from 'modules/profile/api/profile';
import {insertProfileSelector} from 'modules/profile/state/profile-insert';
import {toastAxiosError} from 'shared/utils/error';
import {getFormDefaultsFromProfile, IProfileSettingsForm} from 'shared/components/ProfilePageContent/shared/utils/form';
import {toast} from 'shared/utils/toast';

import {Modal} from 'shared/components/modals/Modal/Modal';
import {ModalBody} from 'shared/components/modals/Modal/ModalBody';
import {InputController} from 'shared/components/form/InputController/InputController';
import {SkillsSelector} from 'shared/components/SkillsSelector/SkillsSelector';
import {DeleteIcon} from 'shared/icons/EditProfile';
import {ReactComponent as CancelIcon} from 'shared/icons/legalmatters/cancel.svg';
import {ModalFooter} from 'shared/components/modals/Modal/ModalFooter';

interface IEditSkillsModalProps {
    open: boolean;
    onHide: () => void;
    profile: IProfile;
}

const skillSchema = z.object({
    name: z.string().min(1),
    level: z.number().min(1),
});

const profileSettingsFormSchema = z.object({skills: z.array(skillSchema).optional()});

export const EditSkillsModal = ({open, onHide, profile}: IEditSkillsModalProps) => {
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const insertProfile = useSetRecoilState(insertProfileSelector);

    const {handleSubmit, reset, control} = useForm<IProfileSettingsForm>({
        resolver: zodResolver(profileSettingsFormSchema),
        defaultValues: getFormDefaultsFromProfile(profile),
    });

    const {fields: skillFields, append: appendSkill, remove: removeSkill} = useFieldArray({
        control,
        name: 'skills',
    });

    const addSkill = () => {
        appendSkill({
            name: '',
            level: 0,
        });
    };

    const onSubmit = handleSubmit((formData) => {
        setIsSubmitting(true);

        (async () => {
            try {
                const updatedProfile = await updateProfile(profile.id, {
                    skills: formData.skills || [],
                });
                insertProfile(updatedProfile);
                onHide();
                reset(getFormDefaultsFromProfile(updatedProfile));
                toast.success('Profile Settings Saved', 'Your profile was updated successfully');
            } catch (e) {
                toastAxiosError(e, 'Profile Settings Error');
            } finally {
                setIsSubmitting(false);
            }
        })();
    });

    const disableAddBtn = skillFields.length > 5;

    return (
        <Modal
            show={open}
            onHide={() => {
                onHide();
                reset();
            }}
            size="lg"
            centered
            isSubmitting={isSubmitting}
            title="Skills"
        >
            <Form onSubmit={onSubmit}>
                <ModalBody>
                    <p>Add your skills and give each of them a rating.</p>
                    {skillFields.map((data, index) => (
                        <div key={`skills-${index}`} className="pb-3 ProfilePageSkills__container--input">
                            <div className="ProfilePageSkills__container--left">
                                <Form.Group controlId={`name-${index}`} className="mb-0" key={`FormGroup-${index}`}>
                                    <InputController
                                        name={`skills.${index}.name`}
                                        key={`input${index}-${data.name}`}
                                        placeholder=""
                                        control={control}
                                        disabled={isSubmitting}
                                    />
                                </Form.Group>
                            </div>
                            <div className="ProfilePageSkills__container--center">
                                <SkillsSelector
                                    name={`skills.${index}.level`}
                                    control={control}
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className="ProfilePageSkills__container--right">
                                <DeleteIcon onClick={() => removeSkill(index)} />
                            </div>
                        </div>
                    ))}
                    <div
                        className={classNames('ProfilePageDetails__icon--add-icon text-right mb-3', {
                            disableAdd: disableAddBtn,
                        })}
                    >
                        <CancelIcon
                            onClick={() => {
                                if (!disableAddBtn) {
                                    addSkill();
                                }
                            }}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="featured"
                        type="submit"
                        disabled={isSubmitting || skillFields.length === 0}
                        className="ProfilePageAbout__margin--t15 ProfilePageAbout__button--save-button"
                    >
                        Save
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};
