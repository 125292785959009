import React from 'react';

import classNames from 'classnames';
import {useDropzone} from 'react-dropzone';
import {useSetRecoilState} from 'recoil';

import {IPendingFile} from 'shared/models/pending-file/IPendingFile';
import {IFile} from 'modules/file/models/IFile';
import {FileManagerInsertSelector} from 'modules/file/state/file-manager';
import {createFileFromFile} from 'modules/file/utils';
import {toastAxiosError} from 'shared/utils/error';

import 'shared/components/form/FileDropzone/style.scss';

interface IFileDropzoneProps {
    userId: string;
    onFileAdded: (file: IFile) => void;
    onPendingFilesChange: (pendingFiles: IPendingFile[]) => void;
    onSuccessFilesChanges?: (pendingFiles: IPendingFile[]) => void;
    className?: string;
    size?: 'small';
    disabled?: boolean;
    accept?: string | string[];
    multiple?: boolean;
    folder: string;
    setIsSubmitting?: (value: boolean) => void;
}

export const FileDropzone = ({
    userId,
    onFileAdded,
    className,
    size,
    disabled,
    children,
    onPendingFilesChange,
    onSuccessFilesChanges,
    multiple,
    accept,
    folder,
    setIsSubmitting,
}: React.PropsWithChildren<IFileDropzoneProps>) => {
    const fileIdCounterRef = React.useRef<number>(1);
    const [pendingFiles, setPendingFiles] = React.useState<IPendingFile[]>([]);
    const [pendingRefresh, setPendingRefresh] = React.useState<string>();
    const insertFile = useSetRecoilState(FileManagerInsertSelector);

    React.useEffect(() => {
        onPendingFilesChange(pendingFiles);
    }, [pendingFiles, onPendingFilesChange]);

    // remove completed file uploads whenever `pendingRefresh` changes
    React.useEffect(() => {
        const newPendingFiles = pendingFiles.filter((pendingFile) => !pendingFile.isComplete);
        if (newPendingFiles.length !== pendingFiles.length) {
            setPendingFiles(newPendingFiles);
        }
    }, [pendingFiles, setPendingFiles, pendingRefresh]);

    const uploadFile = React.useMemo(
        () => (file: File, pendingFile: IPendingFile) => {
            setIsSubmitting && setIsSubmitting(true);
            (async () => {
                try {
                    const fileResult = await createFileFromFile({
                        file,
                        userId,
                        folder,
                    });
                    // Remove the pendingFile from the list by increment the "refresh" counter. That will trigger the useEffect
                    // (above) which will remove all completed file uploads. We cant call setPendingFiles in an async function
                    // in case its changed in the background
                    pendingFile.isComplete = true;
                    setPendingRefresh(fileResult.id);
                    insertFile(fileResult);
                    onFileAdded(fileResult);
                } catch (e) {
                    toastAxiosError(e, 'Error uploading file');
                } finally {
                    setIsSubmitting && setIsSubmitting(false);
                }
            })();
        },
        [setIsSubmitting, userId, folder, insertFile, onFileAdded],
    );

    // Upload files as soon as they're added and create an IPendingFile to track their upload progress.
    const onDrop = React.useCallback(
        (acceptedFiles: File[]) => {
            (async () => {
                const newPendingFiles: IPendingFile[] = await Promise.all(
                    acceptedFiles.map(
                        async (file): Promise<IPendingFile> => {
                            const pendingFile: IPendingFile = {
                                id: fileIdCounterRef.current,
                                filename: file.name,
                                contentType: file.type,
                                size: file.size,
                            };
                            fileIdCounterRef.current += 1;
                            uploadFile(file, pendingFile);
                            return pendingFile;
                        },
                    ),
                );
                if (onSuccessFilesChanges) {
                    onSuccessFilesChanges([...pendingFiles, ...newPendingFiles]);
                }
                setPendingFiles([...pendingFiles, ...newPendingFiles]);
            })();
        },
        [pendingFiles, setPendingFiles, uploadFile, onSuccessFilesChanges],
    );

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        disabled,
        multiple,
        accept,
    });

    const classNameComputed = classNames(
        className,
        'FileDropzone__dropzone d-flex align-items-center justify-content-center',
        {
            'FileDropzone__dropzone--active': isDragActive,
            [`FileDropzone__dropzone--${size}`]: size,
        },
    );

    return (
        <div className="FileDropzone d-flex align-items-center">
            <div className={classNameComputed} {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="FileDropzone__content">{children}</div>
            </div>
        </div>
    );
};
