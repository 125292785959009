import React from 'react';

import {Link} from 'react-router-dom';

import {useNavbar} from 'shared/hooks/useNavbar';

import {PlainContentPage} from 'shared/components/PlainContentPage/PlainContentPage';
import {PlainContentPageSection} from 'shared/components/PlainContentPage/PlainContentPageSection';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';

import './style.scss';

export const TermsPage = () => {
    useNavbar(true);

    return (
        <PlainContentPage title="Terms & Conditions">
            <PageHelmet
                title="Terms and Conditions"
                description="These Terms of Service (“Terms”) are a binding legal agreement between you and Airbnb that govern your right to use the websites, applications, and other offerings from Airbnb (collectively, the “Airbnb Platform”). When used in these Terms, “Airbnb,” “we,” “us,” or “our” refers to the Airbnb entity set out on Schedule 1 with whom you are contracting."
            />
            <PlainContentPageSection title="Client Terms">
                <ol start={1} className="pl-3 ListDisign">
                    <li>
                        Rightful is a digital legal platform and marketplace operated by Newlaw Chambers Pty Ltd (ACN
                        642 771 239) ('we', 'our', 'us'), a company incorporated in Australia, for users to easily
                        access legal services online.
                    </li>
                    <li>
                        By continuing to use our platform you agree to these terms and conditions. If you do not agree
                        you must not use the platform.
                    </li>
                    <li>
                        At present, Rightful provides its services solely within Australia. If you are from overseas you
                        should not use our platform unless your legal project has a connection with Australian law.
                    </li>
                    <li>
                        We are not a law practice and do not provide legal services directly. We provide tools for users
                        to interface directly with legal practitioners and paralegals online, to manage legal projects,
                        manage payments and access legal information.
                    </li>
                    <li>
                        Each legal practitioner on our platform has their own unique regulatory authorisation to provide
                        legal services. For further details, check the law society or bar association register in that
                        individual's state or territory. We review these authorisations when we welcome new
                        practitioners to our platform, but otherwise do not regularly review them.
                    </li>
                    <li>
                        All legal practitioners on our platform are required to provide you with information about your
                        rights when accessing their services. If you have a dispute about the services you have received
                        from one of the legal practitioners on the Rightful platform, you should first express your
                        concern to the lawyer directly. For more information about how to complain about a lawyer, you
                        should contact the office of the legal commissioner in the state or territory in which that
                        lawyer is situated.
                    </li>
                    <li>
                        You agree by using our platform not to hold us liable for the legal or other services you
                        receive from practitioners or paralegals on our platform. You agree that all such services are
                        provided to you directly by the legal practitioners authorised to provide those services to you
                        rather than us. You further agree to conduct your own due diligence on each practitioner's right
                        to provide you those services.
                    </li>
                    <li>
                        If you have a dispute that relates to a Rightful staff member or a non legal aspect of the
                        platform, you can make a complaint to us at{' '}
                        <a href="mailto:enquiries@rightful.com.au">enquiries@rightful.com.au</a>. We endeavour to
                        resolve all complaints within 5 business days of receipt.
                    </li>
                    <li>
                        All resources on our platform (such as articles, webinars, podcasts etc) are intended to provide
                        information in summary form on legal and other topics only, current at the time of first
                        publication. They do not constitute legal or other advice, and do not consider your personal
                        circumstances.
                    </li>
                    <li>
                        Rightful legal practitioners will only provide legal advice or services after they have been
                        formerly engaged under a costs agreement and will only provide legal services within the scope
                        of that agreement. Any other information, consultation, document or advice Rightful provides to
                        you is not legal advice or services. All rates listed in legal profiles are exclusive of GST.
                    </li>
                    <li>
                        Unless otherwise indicated, all materials used on this platform or as part of our services
                        (including legal templates, case charts, text, graphics, logos, icons, and software) are subject
                        to copyright, trade mark or other intellectual property rights that are owned or licensed by us.
                        You may only access and use these materials for non-commercial or personal use, except to the
                        extent permitted by law or where you have received prior written approval from us. If you would
                        like to get approval to use our platform materials, please contact{' '}
                        <a href="mailto:enquiries@rightful.com.au">enquiries@rightful.com.au</a>.
                    </li>
                    <li>
                        We make no warranties or representations about our platform or any content it contains, and will
                        not be responsible to you or any third party for any direct or consequential loss suffered in
                        connection with the use of our platform or any links we provide to websites operated by third
                        parties. To the maximum extent permitted by law, we exclude any liability that may arise due to
                        your use of our platform and/or the information or materials it contains. The information on our
                        website is not comprehensive and is intended to provide a summary of the subject matter covered.
                        While we use all reasonable attempts to ensure the accuracy and completeness of the information
                        on our platform, to the extent permitted by law, including the Australian Consumer Law, we make
                        no warranty regarding the information on our platform. You agree to indemnify us for any loss or
                        liability arising out of your use of our platform.
                    </li>
                    <li>
                        We may change any aspect of our platform (including these terms and conditions), with or without
                        notice to you, by updating it. Your continued use of the platform following an update will
                        represent an agreement by you to the updated platform and/or these terms and conditions as
                        amended from time to time.
                    </li>
                    <li>
                        These terms and conditions also include our Privacy Policy that can be accessed{' '}
                        <Link to="/privacy">here</Link>.
                    </li>
                    <li>
                        You must be a registered member to book legal talent on our platform. When you register and
                        activate your account, you will provide us with personal information such as your name and email
                        address. You must ensure that this information is accurate and current. We will handle all
                        personal information we collect in accordance with our Privacy Policy.
                    </li>
                    <li>
                        When you register and activate your account, we will provide you with a username and password.
                        You are responsible for keeping this username and password secure and are responsible for all
                        use and activity carried out under this user name.
                    </li>
                    <li>
                        To create an account you must be:
                        <ol>
                            <li>at least 18 years of age;</li>
                            <li>
                                possess the legal right and ability to enter into a legally binding agreement with us;
                                and
                            </li>
                            <li>agree and warrant to use the website according to these terms and conditions.</li>
                        </ol>
                    </li>
                    <li>
                        By using the platform you may be provided with confidential information, you agree to keep all
                        such information confidential. The obligations in this clause survive termination. By using the
                        platform and engaging a lawyer or paralegal on the platform, you have consented to that lawyer
                        or paralegal communicating with you on matters that may include confidential information.
                    </li>
                    <li>
                        Rightful takes platform security very seriously. We take reasonable steps to protect information
                        on the platform from misuse, interference and loss, as well as unauthorised access, modification
                        or disclosure by a number of physical, administrative, personnel and technical protective
                        measures. However, no method of electronic storage is 100% secure. We cannot guarantee the
                        absolute security of information on our platform.
                    </li>
                    <li>
                        We will use our best endeavours to ensure that the platform is available at all times, but do
                        not warrant that the services we provide under the platform will be uninterrupted or error-free,
                        or that the service will meet your requirements. We are not responsible for any delays, delivery
                        failures or interruptions that arise as a result of circumstances outside our control. You
                        acknowledge that the platform services may be subject to limitations, delays and problems
                        inherent in the use of electronic communication facilities.
                    </li>
                    <li>
                        To the extent permitted by law, Rightful will not be liable for: a) any indirect, incidental,
                        exemplary, punitive, or consequential damages of any kind; b) loss of profits, sales, revenue,
                        data, use, goodwill or other intangible losses; c) damages relating to your access to, use of,
                        or inability to access to the platform. To the extent permitted by law, Rightful's total
                        liability for any claim under these terms is limited to the cost paid for the services or the
                        cost of providing the services again.
                    </li>
                    <li>
                        Your use of the website and these terms and conditions are governed by the laws of New South
                        Wales, Australia. You may terminate these terms and conditions with us at any time, in which
                        case you will no longer have access to the platform. We may terminate or suspend your access if
                        we suspect you have breached these terms and conditions.
                    </li>
                    <li>
                        If a provision of these terms and conditions is determined by any court or other competent
                        authority to be unlawful and/or unenforceable, the other provisions will continue in effect.
                    </li>
                </ol>
            </PlainContentPageSection>
            <PlainContentPageSection title="Lawyer & Paralegal Terms" />
            <ol className="pl-3 ListDisign margin-bottom-6">
                <li>
                    Rightful is a digital legal platform and marketplace operated by Newlaw Chambers Pty Ltd (ACN 642
                    771 239) ('we', 'our', 'us'), a company incorporated in Australia, for users to easily access legal
                    services online.
                </li>
                <li>
                    Lawyers and paralegals are required to accept these terms & conditions before offering their
                    services on the platform. By registering as a provider of services on the platform, you are agreeing
                    to these Lawyer & Paralegal terms and conditions and entering into a legally binding contract with
                    us. If you do not agree you must not use the platform as a services provider.
                </li>
                <li>
                    At present, Rightful provides its services solely within Australia. If you are from overseas you
                    should not use our platform unless your legal project has a connection with Australian law.
                </li>
                <li>
                    Rightful is currently in a beta testing phase. During this time your use of the platform during its
                    beta testing phase is subject to any pricing arrangements you enter into with us directly.
                </li>
                <li>
                    The Rightful platform is not a law practice and we do not provide legal services directly. We
                    provide tools for users to interface directly with legal practitioners and paralegals online, to
                    manage legal projects, payments and access legal information. You warrant and agree that any legal
                    services provided are between you and the client user directly. We will not be held liable in any
                    way for your provision of legal services. If you are a paralegal or a lawyer subject to supervision
                    arrangements, you warrant that you will not engage in legal practice or services (within the meaning
                    of the Uniform Law), except where supervised by a legal practitioner suitably qualified to provide
                    those services and take responsibility for them as principal. This means in the vast majority of
                    cases, anything you work on is not submitted directly to the client it goes through the lawyer added
                    to your legal project.
                </li>
                <li>
                    We may ask for evidence of your right to engage in legal practice and may place classifications on
                    your profile that reflect any practice certifications you may have. You agree that it is your
                    responsibility to ensure you have correct practice certifications, insurance and other
                    qualifications for the services you provide. By offering your services on the platform as a legal
                    practitioner, you are warranting to us that you have the requisite authority to do so. We may remove
                    you from the platform as a services provider if we discover you are no longer entitled to engage in
                    legal practice or are in breach of your practice conditions.
                </li>
                <li>
                    You agree that it is your responsibility to ensure that you comply with all professional conduct
                    legislation, including providing appropriate disclosures, when providing legal services through our
                    platform.
                </li>
                <li>
                    Rightful does not guarantee that practitioners or paralegals on the platform will receive clients.
                </li>
                <li>
                    You agree that if we receive a complaint from a client user in relation to the legal services you
                    have provided through our platform and the matter cannot be easily resolved, we may refer the user
                    to the legal commissioner in your state or territory.
                </li>
                <li>
                    If you have a dispute that relates to a Rightful staff member or a non legal aspect of the platform,
                    you can make a complaint to us at{' '}
                    <a href="mailto:enquiries@rightful.com.au">enquiries@rightful.com.au</a>. We endeavour to resolve
                    all complaints within 5 business days of receipt.
                </li>
                <li>
                    Clients may provide ratings of lawyers and paralegals after the completion of legal project work.
                    All ratings are subject to your approval as the manager of your profile before being listed.
                    Rightful does not vet reviews although we reserve our discretion to remove reviews when we consider
                    it appropriate to do so.
                </li>
                <li>
                    All resources on our platform (such as articles, webinars, podcasts etc) are intended to provide
                    information in summary form on legal and other topics only, current at the time of first
                    publication. They do not constitute legal or other advice and should not be relied upon as such. You
                    agree not to hold out any resources you upload to the platform as legal advice.
                </li>
                <li>
                    By uploading or posting resources to the platform, you are granting us an exclusive, worldwide,
                    irrevocable, perpetual, non-transferable licence to display them on our platform. You also warrant
                    to us by uploading resources that you have the requisite intellectual property rights, or authority
                    from the author to do so, and they have consented to the resources being displayed on our platform.
                    We may feature certain posts in prominent positions on our platform, we may also remove posts for
                    any reason if we consider it necessary to do so.
                </li>
                <li>
                    Unless otherwise indicated, all materials used on this platform or as part of our services
                    (including legal templates, case charts, text, graphics, logos, icons, and software) are subject to
                    copyright, trade mark or other intellectual property rights that are owned or licensed by us. Unless
                    the materials belong or are licensed by you, You may only access and use these materials for
                    non-commercial or personal use, except to the extent permitted by law or where you have received
                    prior written approval from us. If you would like to get approval to use our platform materials,
                    please contact <a href="mailto:enquiries@rightful.com.au">enquiries@rightful.com.au</a>.
                </li>
                <li>
                    We make no warranties or representations about our platform or any content it contains, and will not
                    be responsible to you or any third party for any direct or consequential loss suffered in connection
                    with the use of our platform or any links we provide to websites operated by third parties. To the
                    maximum extent permitted by law, we exclude any liability that may arise due to your use of our
                    platform and/or the information or materials it contains. You agree to indemnify us for any loss or
                    liability arising out of your use of our platform.
                </li>
                <li>
                    We may change any aspect of our platform (including these terms and conditions), with or without
                    notice to you, by updating it. Your continued use of the platform following an update will represent
                    an agreement by you to the updated platform and/or these terms and conditions as amended from time
                    to time.
                </li>
                <li>
                    These terms and conditions also include our Privacy Policy that can be accessed{' '}
                    <Link to="/privacy">here</Link>.
                </li>
                <li>
                    You must be registered to provide services on our platform. When you register and activate your
                    account, you will provide us with personal information such as your name and email address. You must
                    ensure that this information is accurate and current. We will handle all personal information we
                    collect in accordance with our Privacy Policy.
                </li>
                <li>
                    When you register and activate your account, we will provide you with a username and password. You
                    are responsible for keeping this username and password secure and are responsible for all use and
                    activity carried out under this user name.
                </li>
                <li>
                    To create an account you must be:
                    <ol>
                        <li>at least 18 years of age;</li>
                        <li>
                            possess the legal right and ability to enter into a legally binding agreement with us; and
                        </li>
                        <li>agree and warrant to use the website according to these terms and conditions.</li>
                    </ol>
                </li>
                <li>
                    By registering you:
                    <ol>
                        <li>
                            warrant that (if you are legal services provider) you are the holder of a current practicing
                            certificate entitling you to practice Australian law, or that you satisfy any other
                            requirements applicable to eligibility to practice in your legal discipline, and that you
                            hold or will maintain professional indemnity insurance;
                        </li>
                        <li>you agree that we have no employment relationship with you;</li>
                        <li>
                            you agree to provide us with true and accurate information and to keep your profile up to
                            date when any of your information materially changes; and
                        </li>
                        <li>
                            you agree to comply with all laws, rules and regulations including any professional
                            standards laws and codes of ethics that relate to your provision of legal services in the
                            jurisdiction where you provide those services through the Rightful platform. You agree to
                            indemnify us, our partners, officers, affiliates and employees for any liability arising
                            from your provision of those legal services.
                        </li>
                    </ol>
                </li>
                <li>
                    By using the platform you may be provided with confidential information, you agree to keep all such
                    information confidential. The obligations in this clause survive termination. By using the platform
                    and accepting client engagements, you have consented to the client and or any project members
                    communicating with you on matters that may include confidential information. It is your
                    responsibility to ensure the confidentiality of information you may become privy to.
                </li>
                <li>
                    Rightful takes platform security very seriously. We take reasonable steps to protect information on
                    the platform from misuse, interference and loss, as well as unauthorised access, modification or
                    disclosure by a number of physical, administrative, personnel and technical protective measures.
                    However, no method of electronic storage is 100% secure. We cannot guarantee the absolute security
                    of information on our platform.
                </li>
                <li>
                    We will use our best endeavours to ensure that the platform is available at all times, but do not
                    warrant that the services we provide under the platform will be uninterrupted or error-free, or that
                    the service will meet your requirements. We are not responsible for any delays, delivery failures or
                    interruptions that arise as a result of circumstances outside our control. You acknowledge that the
                    platform services may be subject to limitations, delays and problems inherent in the use of
                    electronic communication facilities.
                </li>
                <li>
                    To the extent permitted by law, Rightful will not be liable for: a) any indirect, incidental,
                    exemplary, punitive, or consequential damages of any kind; b) loss of profits, sales, revenue, data,
                    use, goodwill or other intangible losses; c) damages relating to your access to, use of, or
                    inability to access to the platform; d) damages arising out of your relationship with any client
                    found through the platform. To the extent permitted by law, Rightful's total liability for any claim
                    under these terms is limited to the cost paid for the services or the cost of providing the services
                    again.
                </li>
                <li>
                    If a provision of these terms and conditions is determined by any court or other competent authority
                    to be unlawful and/or unenforceable, the other provisions will continue in effect.
                </li>
                <li>
                    Nothing in these terms and conditions shall be construed as creating any kind of employment
                    relationship between Rightful and any practitioner or paralegal listed on the platform. Unless
                    expressly stated, all individuals with profiles on the platform are independent of Rightful and are
                    either affiliated with other organisations or have their own independent entitlement to conduct
                    business in Australia.
                </li>
                <li>
                    Rightful and the Rightful lawyer or paralegal jointly and separately acknowledge to each other that
                    all prior agreements or understandings have not been relied on and these terms and conditions
                    constitute the entire agreement between them and Rightful.
                </li>
                <li>
                    Your use of the platform and these terms and conditions are governed by the laws of New South Wales,
                    Australia. You may terminate these terms and conditions with us at any time, in which case you will
                    no longer have access to the platform. Rightful lawyers agree that all client matters that are
                    ongoing at the date of termination will be finalised according to their usual obligations. We may
                    terminate or suspend your access if we suspect you have breached these terms and conditions.
                </li>
            </ol>
        </PlainContentPage>
    );
};
