import React from 'react';

import { FeeKind } from 'shared/models/fee-kind/FeeKind';

import './style.scss';

interface IProfileCardFeeProps {
    amount: number;
    kind: FeeKind;
}

export const ProfileCardFee = ({amount, kind}: IProfileCardFeeProps) => {
    const className = `ProfileCardFee ProfileCardFee--${kind}`;
    return (
        <span className={className}>${amount} / {kind}</span>
    );
};
