import {atom, selector, selectorFamily} from 'recoil';

import {readChatMessageList} from 'modules/chat-message/api';
import {IChatMessageListQuery, IChatMessage} from 'modules/chat-message/models';
import {CursorList} from 'shared/types/cursor-list';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/utils/recoil';
import {chatSummaryResetAtom} from 'modules/chat/state/chat-summary';
import {chatListAtom} from 'modules/chat/state';

export interface IChatMessageSelectorKey {
    chatId: string;
    page: number;
    limit: number;

    [key: string]: number | string | IChatMessageListQuery;
}

interface IChatMessageAtom {
    filters: IChatMessageSelectorKey;
    chat_messages: IChatMessage[];
    cursors: CursorList;
    more: boolean;
    resetVersion: number;

    [key: string]: number | string | boolean | IChatMessageSelectorKey | IChatMessage[] | CursorList;
}

export const chatMessageListAtom = atom<IChatMessageAtom | undefined>({
    key: 'chatMessageListAtom',
    default: undefined,
});

export const chatMessageListResetAtom = atom<number>({
    key: 'chatMessageListResetAtom',
    default: 0,
});

const compareFilters = (filters1: IChatMessageSelectorKey, filters2: IChatMessageSelectorKey) => {
    return (
        filters1.chatId === filters2.chatId &&
        filters1.limit === filters2.limit &&
        filters1.page === filters2.page
    );
};

export const chatMessageListSelector = selectorFamily<IChatMessageAtom | undefined, IChatMessageSelectorKey>({
    key: 'chatMessageListSelector',
    get: (filters) => ({get}) => {
        const atomValue = get(chatMessageListAtom);
        const resetVersion = get(chatMessageListResetAtom);
        if (
            atomValue &&
            compareFilters(filters, atomValue.filters) &&
            atomValue.resetVersion === resetVersion
        ) {
            return {...atomValue};
        }
        return undefined;
    },
    set: () => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return null;
        }
        set(chatMessageListAtom, newValue);
    },
});

export const chatMessageListReadSelector = selectorFamily<IChatMessageAtom, IChatMessageSelectorKey>({
    key: 'chatMessageListReadSelector',
    get: (filters) => async ({get}) => {
        const currentValue = get(chatMessageListSelector(filters));
        if (currentValue) {
            return currentValue;
        }

        const atomValue = get(chatMessageListAtom);
        const resetVersion = get(chatMessageListResetAtom);

        // load a fresh page from the server
        const result = await readChatMessageList({
            chat_id: filters.chatId,
            limit: filters.limit,
            cursor: filters.page === 0 || !atomValue ? undefined : atomValue.cursors[filters.page - 1],
        });

        // add the cursor to the list of cursors so we can paginate backwards
        const newCursors = atomValue ? Array.from(atomValue.cursors) : [];
        newCursors[filters.page] = result.next_cursor;

        return {
            filters,
            chat_messages: result.chat_messages,
            cursors: newCursors,
            more: !!result.next_cursor,
            resetVersion,
        } as IChatMessageAtom;
    },
});

export const chatMessageInsertSelector = selector<IChatMessage>({
    key: 'chatMessageInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        const atomValue = get(chatMessageListAtom);
        const index = atomValue?.chat_messages?.findIndex(message => message.id === newValue.id);
        if (atomValue && typeof index !== 'undefined' && index !== -1) {
            const newChatMessages = Array.from(atomValue.chat_messages);
            newChatMessages.splice(index, 1, newValue);
            set(chatMessageListAtom, {
                ...atomValue,
                chat_messages: newChatMessages,
            });
        } else if (atomValue && atomValue.filters.chatId === newValue.chat_id) {
            set(chatMessageListAtom, {
                ...atomValue,
                chat_messages: [newValue, ...atomValue.chat_messages],
            });

            // force reset chat summary atom
            set(chatSummaryResetAtom(newValue.chat_id), get(chatSummaryResetAtom(newValue.chat_id)) + 1);

            set(chatMessageUpdateSelector, newValue.chat_id);
        }
        // NOTE: We do not need to update the resetVersion atom, as we can predictably insert new messages at the
        // correct location.
    },
});

export const chatMessageUpdateSelector = selector<string>({
    key: 'chatMessageUpdateSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        const atomValue = get(chatListAtom);
        if (!atomValue) {
            return;
        }

        const chatList = [...atomValue.chats];
        const index = chatList.findIndex((chat) => chat.id === newValue);

        if (index > 0) {
            chatList.splice(index, 0, chatList.splice(0, 1)[index]);
            set(chatListAtom, {
                ...atomValue,
                chat: chatList,
            });
        }
    },
});
