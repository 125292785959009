import React from 'react';

import {chatWebsocketContext} from './chatWebsocketContext';
import {useChatWebsocketProvider} from 'modules/chat-websocket/context/useChatWebsocketProvider';

interface IChatWebSocketProviderAuthenticatedProps {
    wsAccessToken: string;
    children?: React.ReactNode;
}

export const ChatWebSocketProviderAuthenticated = ({
    children,
    wsAccessToken,
}: IChatWebSocketProviderAuthenticatedProps) => {
    const chatWebsocketContextValue = useChatWebsocketProvider(wsAccessToken);

    return (
        <chatWebsocketContext.Provider value={chatWebsocketContextValue}>
            {children}
        </chatWebsocketContext.Provider>
    );
};
