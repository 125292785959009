import React from 'react';

import classNames from 'classnames';

import {getFileDownloadUrl} from 'modules/file/utils';

import {ProfilePictureError} from 'shared/components/ProfilePicture/ProfilePictureError';

import './style.scss';

export type ProfilePictureSizeProp =
    | 'xs'
    | 'sm'
    | 'xsm'
    | 'md'
    | 'xmd'
    | 'lg'
    | 'xl'
    | 'xxl'
    | 'ld'
    | 'mp'
    | 'ADI'
    | 'Mobile'
    | 'notification'
    | 'lg-sm';

export type ProfilePictureSharedProps = Omit<IProfilePictureProps, 'id' | 'url' | 'alt' | 'name'>;

export interface IProfilePictureProps {
    id?: string | null;
    url?: string | null;
    name?: string;
    alt?: string;
    round?: boolean;
    size?: ProfilePictureSizeProp;
    border?: boolean;
    table?: boolean;
    className?: string;
    imgClassName?: string;
    darkPlaceholder?: true;
    loading?: boolean; 
}

export const ProfilePicture = ({
    alt,
    border,
    id,
    name,
    round,
    size,
    url,
    table,
    className,
    imgClassName,
    darkPlaceholder,
    loading,
}: IProfilePictureProps) => {
    const [hasError, setHasError] = React.useState<boolean>(false);

    const classNameComputed = classNames(className, 'ProfilePicture', {
        'ProfilePicture--round': round,
        'ProfilePicture--border': border,
        'ProfilePicture--table': table,
        [`ProfilePicture--${size}`]: size,
        'ProfilePicture--dark-placeholder': darkPlaceholder,
    });

    let imageURL: string | undefined;
    const defaultProfilePicUrl = '/assets/profilePictures/defaultAvatar.png';
    if (url) {
        imageURL = url;
    } else if (id) {
        imageURL = getFileDownloadUrl({id, filename: name});
    } else {
        imageURL = undefined;
    }

    return (
        <>
            {hasError && <ProfilePictureError className={classNameComputed} />}
            {!hasError && imageURL && !loading && (
                <img
                    src={imageURL}
                    alt={alt}
                    className={`ml-0 ${classNameComputed} ${imgClassName || ''}`}
                    onError={() => setHasError(true)}
                    style={{objectFit: 'cover'}}
                />
            )}

            {!hasError && !imageURL && !loading && (
                <img
                    src={defaultProfilePicUrl}
                    alt={alt}
                    className={`ml-0 ${classNameComputed} ${imgClassName || ''}`}
                    onError={() => setHasError(true)}
                    style={{objectFit: 'cover'}}
                />
            )}
        </>
    );
};
