import React from 'react';

import {useRecoilStateLoadable, useRecoilValue} from 'recoil';

import {guaranteedCurrentUserScopesSelector, currentUserScopesAtom} from '../state/current-user-scopes';
import {getAuthPermissions, getNotAuthenticatedAuthPermissions} from 'shared/auth/utils/get-auth-permissions';
import {Scope} from 'shared/auth/models';

export const usePermissionsLoadable = (isAuthenticated: boolean) => {
    const currentUserScopes = useRecoilValue(currentUserScopesAtom);
    const [scopesLoadable, setScopes] = useRecoilStateLoadable(guaranteedCurrentUserScopesSelector);

    React.useEffect(() => {
        if (scopesLoadable.state === 'hasValue') {
            setScopes(scopesLoadable.contents);
        }
    }, [scopesLoadable, setScopes]);

    if (!isAuthenticated) {
        return {
            scopes: [] as Scope[],
            permissions: getNotAuthenticatedAuthPermissions(),
            loading: false,
            error: false,
        };
    } else if (currentUserScopes) {
        return {
            scopes: currentUserScopes.scopes,
            permissions: getAuthPermissions(currentUserScopes.scopes),
            loading: false,
            error: undefined,
        };
    } else if (scopesLoadable.state === 'hasValue') {
        return {
            scopes: scopesLoadable.contents.scopes,
            permissions: getAuthPermissions(scopesLoadable.contents.scopes),
            loading: false,
            error: undefined,
        };
    } else if (scopesLoadable.state === 'loading') {
        return {
            permissions: undefined,
            loading: true,
            error: undefined,
        };
    } else {
        return {
            permissions: undefined,
            loading: false,
            error: scopesLoadable.errorOrThrow(),
        };
    }
};
