import {IProfile} from 'modules/profile/models/IProfile';
import {getFullName} from 'modules/profile/utils';

import {AppFooter} from 'shared/components/AppFooter/AppFooter';
import {ProfilePageContent} from 'shared/components/ProfilePageContent/ProfilePageContent';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';

interface IViewProfilePageContentProps {
    profile: IProfile;
}

export const ViewProfilePageContent = ({profile}: IViewProfilePageContentProps) => {

    return (
        <>
            <PageHelmet
                noindex={!profile.is_published}
                title={getFullName(profile)}
                description={profile.claim_to_fame}
            />
            <ProfilePageContent profile={profile} />
            <AppFooter subscribe={false} />
        </>
    );
};
