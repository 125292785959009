import React from 'react';
import {Form} from 'react-bootstrap';

import {australianTimezones} from 'shared/utils/date/australian-timezones';

import './style.scss';

interface ITimezoneControlProps {
    disabled?: boolean;
    value: string;
    onChange: (value: string) => void;
}

export const TimezoneControl = ({disabled, value, onChange}: ITimezoneControlProps) => {
    return (
        <Form.Group>
            <Form.Control
                as="select"
                className="TimezoneControl col-12"
                custom
                disabled={disabled}
                onChange={(e) => onChange(e.target.value)}
                value={value}
            >
                {australianTimezones.map(timezone => (
                    <option value={timezone.code}>
                        {timezone.offset} - {timezone.name}
                    </option>
                ))}
            </Form.Control>
        </Form.Group>
    );
};
