import React from 'react';

import {Badge} from 'react-bootstrap';

import {useProjectMaybeLoadable} from 'modules/project/hooks/useProjectMaybeLoadable';
import {Spinner} from 'shared/components/Spinner/Spinner';

interface ISelectedProjectProps {
    projectId?: string;
    onRemove: () => void;
}

export const SelectedProject = ({projectId, onRemove}: ISelectedProjectProps) => {
    const {project, loading} = useProjectMaybeLoadable(projectId);

    if (!projectId?.length) {
        return null;
    }

    return (
        <div className="ProjectSearchController__selected-project d-flex align-items-center justify-content-between mb-4">
            {loading && <Spinner size="sm" variant="muted" />}
            {project && (
                <>
                    <div>
                        <Badge variant="primary" className="mr-2">
                            PROJECT
                        </Badge>
                        <span>{project.title}</span>
                    </div>
                    <div style={{cursor: 'pointer'}} onClick={onRemove}>
                        Remove
                    </div>
                </>
            )}
        </div>
    );
};
