import React, {useState} from 'react';

import {Alert} from 'react-bootstrap';

import './style.scss';

export const AppFooterSubscribeForm = () => {
    const [email, setEmail] = useState('');
    const [showMsg, setShowMsg] = useState(false);

    const onSubmit = () => {
        setTimeout(() => {
            setEmail('');
        }, 100);
        setShowMsg(true);
    };

    return (
        <div className="AppFooterSubscribeForm">
            <h3 className="AppFooterSubscribeForm__heading text-center">Subscribe to our newsletter</h3>
            <div className="container d-flex justify-content-center">
                <div id="mc_embed_shell">
                    <link
                        href="//cdn-images.mailchimp.com/embedcode/classic-061523.css"
                        rel="stylesheet"
                        type="text/css"
                    />

                    <style type="text/css">
                        {`
                    #mc_embed_signup {
                        background: #fff;
                        clear: left;
                    }
                `}
                    </style>
                    <div id="mc_embed_signup">
                        <form
                            action="https://rightful.us21.list-manage.com/subscribe/post?u=bd11ec807f9900b7004e1bc76&amp;id=b866c91567&amp;f_id=00e75ce1f0"
                            method="post"
                            target="_blank"
                            id="mc-embedded-subscribe-form"
                            name="mc-embedded-subscribe-form"
                            className="validate"
                            onSubmit={onSubmit}
                            noValidate
                        >
                            <Alert show={showMsg} variant="success" onClose={() => setShowMsg(false)} dismissible>
                                <div>Thanks for subscribing</div>
                            </Alert>
                            <div id="mc_embed_signup_scroll">
                                <div className="mc-field-group AppFooterSubscribeForm__main">
                                    <div>
                                        <input
                                            className="required email AppFooterSubscribeForm__input"
                                            type="email"
                                            name="EMAIL"
                                            id="mce-EMAIL"
                                            required={true}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                            value={email}
                                            placeholder="Email Address"
                                        />
                                        <div hidden={true}>
                                            <input type="hidden" name="tags" value="2825544" />
                                        </div>
                                        <div id="mce-responses" className="clear">
                                            <div
                                                className="response"
                                                id="mce-error-response"
                                                style={{display: 'none'}}
                                            ></div>
                                            <div
                                                className="response"
                                                id="mce-success-response"
                                                style={{display: 'none'}}
                                            ></div>
                                        </div>
                                        <div aria-hidden={true} style={{position: 'absolute', left: '-5000px'}}>
                                            <input
                                                type="text"
                                                name="b_bd11ec807f9900b7004e1bc76_b866c91567"
                                                tabIndex={-1}
                                                value=""
                                            />
                                        </div>
                                    </div>

                                    <div className="clear AppFooterSubscribeForm__btn-main">
                                        <input
                                            className="AppFooterSubscribeForm__button"
                                            type="submit"
                                            name="subscribe"
                                            id="mc-embedded-subscribe"
                                            value="Send"
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <script
                        type="text/javascript"
                        src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
                    ></script>
                    <script
                        type="text/javascript"
                        dangerouslySetInnerHTML={{
                            __html: `
                (function ($) {
                    window.fnames = new Array();
                    window.ftypes = new Array();
                    fnames[0] = 'EMAIL';
                    ftypes[0] = 'email';
                    fnames[1] = 'FNAME';
                    ftypes[1] = 'text';
                    fnames[2] = 'LNAME';
                    ftypes[2] = 'text';
                    fnames[3] = 'ADDRESS';
                    ftypes[3] = 'address';
                    fnames[4] = 'PHONE';
                    ftypes[4] = 'phone';
                    fnames[5] = 'BIRTHDAY';
                    ftypes[5] = 'birthday';
                }(jQuery));
                var $mcj = jQuery.noConflict(true);
            `,
                        }}
                    ></script>
                </div>
            </div>
        </div>
    );
};
