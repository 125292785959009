import React from 'react';

import {Container} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import './style.scss';

export const GameChangerSection = () => {
    React.useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <div className="GameChangerSection">
            <Container className="mt-5">
                <div className="row">
                    <div className="col">
                        <h1 className="GameChangerSection__title">
                            A game <strong className="GameChangerSection__strong">changer</strong>
                        </h1>
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-8">
                        <img
                            className="img-fluid"
                            src="/assets/gamechanger/Iphone.advocacyiso.png"
                            alt="A game changer"
                        />
                    </div>
                </div>
                <div className="row GameChangerSection__negative pb-5">
                    <div className="col">
                        <p>
                            Traditional legal advocacy<br/>
                            hasn't changed much in hundreds<br/>
                            of years. Today it is practised the<br/>
                            same way as it was in the 1700s.
                        </p>
                        <p>
                            Technology can now automate and simplify <br/> the advocacy process.There no longer
                            needs to be <br/> the same level of ‘back and forth’ as there once was.
                        </p>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-md-5">
                        <p>
                            <span className="text-uppercase">Rightful</span> is an online platform for advocates
                            (lawyers who specialise in trial advocacy or alternative dispute resolution). We operate
                            remotely right around Australia.We are on a mission to simplify and humanise legal advocacy
                            both from a lawyer and client perspective.It is no secret that dispute resolution through
                            the courts is often inaccessible, time consuming and costly.
                        </p>
                    </div>
                    <div className="col-md-2"/>
                    <div className="col-md-5">
                        <p>
                            Our business is giving you greater certainty over your legal spending by providing a better,
                            faster, cheaper fixed fee service.We can work solo, or whether needed we can easily upscale
                            into a full service remote legal team.
                        </p>
                        <p>Come get to know us.We're real people, passionate about the law and access to justice.</p>
                    </div>
                </div>
                <div className="d-flex flex-responsive justify-content-around align-items-center text-center GameChangerSection__icon-wrapper">
                    <div>
                        <img
                            className="GameChangerSection__icon"
                            src="/assets/gamechanger/Clock.turnaround.png"
                            alt="Quick turn around"
                        />
                        <h4 className="h6">Quick turn around</h4>
                    </div>
                    <div>
                        <img
                            className="GameChangerSection__icon"
                            src="/assets/gamechanger/Nopaper.line.png"
                            alt="100% paperless"
                        />
                        <h4 className="h6">100% paperless</h4>
                    </div>
                    <div>
                        <img
                            className="GameChangerSection__icon"
                            src="/assets/gamechanger/Scissorsdollar.transparentfees.png"
                            alt="Transparent fixed fees"
                        />
                        <h4 className="h6">Transparent fixed fees</h4>
                    </div>
                </div>
                <div className="row pb-5">
                    <div className="col">
                        <h2 className="h4">We've redesigned the process</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <p>
                            We saw an opportunity to completely redesign the way advocacy is practised with you the
                            client in mind.
                        </p>
                        <p>
                            We don't have billable hours, which only rewards inefficiency. We have eliminated paper
                            briefs and court docs (except where Court ordered) which can make the costs of legal cases
                            astronomical. We have turned our processes into an efficient conveyor belt by embracing
                            digital and the 'just in time' revolution in legal services. The results speak for
                            themselves
                        </p>
                        <p>
                            For a complete summary of our process, see our<br/>
                            <Link to="/">technical whitepaper</Link>
                        </p>
                    </div>
                    <div className="col-md"/>
                    <div className="col-md-5 text-center">
                        <h4 className="h2">
                            We only trot off to Court when we have to<br/>
                            (we'd rather preserve your relationships), but when we do we fight hard for your interests.
                        </h4>
                    </div>
                </div>
                <div className="row py-5">
                    <div className="col">
                        <h2 className="h4">Legal cases run entirely online</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <p>
                            In the 80’s, many legal firm photocopying bills were larger than professional fees. The
                            standard charge was 60c a page although some went up to $1 a page (Source: Michael Bradley,
                            AFR). Sadly such practices are still alive and well. Clients are rightly demanding more from
                            their Lawyers today than they have in the past.
                        </p>
                    </div>
                    <div className="col-md"/>
                    <div className="col-md-5">
                        <p>
                            We use custom built technology that eliminates the need for paper. It also makes us more
                            effective lawyers in and out of the courtroom.
                        </p>
                        <p>
                            We can draft documents faster and be more across the brief with the power of a computer
                            behind us. Truly a game changer.
                        </p>
                    </div>
                </div>
            </Container>
        </div>
    );
};
