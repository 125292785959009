import React from 'react';

import {Dropdown, Badge} from 'react-bootstrap';
import classNames from 'classnames';
import {DropdownItemProps} from 'react-bootstrap/DropdownItem';

import {IHelpSearchDocument} from 'modules/help/models';

export interface IHelpSearchResultProps extends DropdownItemProps {
    document: IHelpSearchDocument;
    highlighted: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const HelpSearchResult = React.forwardRef<any, IHelpSearchResultProps>(({
    document,
    highlighted,
    ...rest
}, ref) => {
    const className = classNames('NavbarSearch__item', {
        'NavbarSearch__item--highlighted': highlighted,
    });

    const searchResultDisplay = {
        title: document.name,
        description: document.description,
        badge: {
            variant: 'info',
            title: document.kind,
        },
    };

    return (
        <Dropdown.Item className={className} {...rest} ref={ref}>
            <div>
                <Badge variant={searchResultDisplay.badge.variant} className="mr-1">
                    {searchResultDisplay.badge.title}
                </Badge>
                {searchResultDisplay.title}
            </div>
            <div className="text-muted">
                {searchResultDisplay.description}
            </div>
        </Dropdown.Item>
    );
});
