import React from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';

import {useAuth} from 'shared/auth/hooks/useAuth';
import {isStateVaild} from '../utils/is-state-vaild';

import {Loading} from 'shared/components/loading/Loading/Loading';

export const LoginPage = () => {
    const {login} = useAuth();
    const location = useLocation();

    // Redirect param
    const [searchParams] = useSearchParams();
    const redirectParam = searchParams.get('redirect') ?? undefined;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let from: string | undefined;
    if (isStateVaild(location.state)) {
        const state = location.state;
        from = state.from;
    }

    React.useEffect(() => {
        login({from: redirectParam ?? from});
    });

    return (
        <Loading/>
    );
};
