import React from 'react';

import {useSetRecoilState} from 'recoil';

import {createFavorite, deleteFavorite} from 'modules/favorite/api';
import {toastAxiosError} from 'shared/utils/error';
import {useGuaranteedAuth} from 'shared/auth/hooks/useAuth';
import {useBool} from 'shared/hooks/useBool';
import {useFavoriteLoadable} from 'modules/favorite/hooks/useFavoriteLoadable';
import {toast} from 'shared/utils/toast';
import {favoriteInsertSelector, favoriteRemoveSelector} from 'modules/favorite/state/favorite-insert';

import {StarRatingStar} from 'shared/components/StarRating/StarRatingStar';

interface IProfileCardProps {
    profileId: string;
}

export const FavoriteStar = ({profileId}: IProfileCardProps) => {
    const {tokenData} = useGuaranteedAuth();

    const {favorite, loading, error} = useFavoriteLoadable(profileId);

    const insertFavorite = useSetRecoilState(favoriteInsertSelector);
    const removeFavorite = useSetRecoilState(favoriteRemoveSelector);
    const [pendingStarred, setPendingStarred] = useBool();
    const [isSubmitting, setIsSubmitting] = useBool();

    const onClickFavourite = (e: React.MouseEvent<SVGElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (!tokenData) {
            toast.error('Not Signed In', 'Please sign in to save profiles to your Talent List');
            return;
        }

        if (isSubmitting) {
            return;
        }

        setPendingStarred(!favorite);
        setIsSubmitting(true);

        if (favorite) {
            toast.warning('Talent List', 'Successfully removed the profile from your Talent List');
        } else {
            toast.success('Talent List', 'Successfully saved the profile to your Talent List');
        }

        (async () => {
            try {
                if (favorite) {
                    // Note: IFavorite extends IFavoriteKey, so we can use it in place of the key.
                    await deleteFavorite(favorite);
                    removeFavorite(favorite);
                } else {
                    const newFavorite = await createFavorite({
                        user_id: tokenData.id,
                        profile_id: profileId,
                    });
                    insertFavorite(newFavorite);
                }
            } catch (e) {
                toastAxiosError(e, 'Talent List');
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    return (
        <StarRatingStar
            isClickable={true}
            onClick={onClickFavourite}
            isActive={isSubmitting ? pendingStarred : !!favorite}
            disabled={isSubmitting || loading || !!error}
        />
    );
};
