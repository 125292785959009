import React from 'react';

import {Form, Row, Col, Modal, Button} from 'react-bootstrap';
import {Control} from 'react-hook-form';

import {IReviewForm} from 'modules/review/forms';

import {StarRatingController} from 'shared/components/StarRating/StarRatingController';
import {FormController} from 'shared/components/form/FormController/FormController';

import './style.scss';

interface IReviewModalFormProps {
    control: Control<IReviewForm>;
    isSubmitting: boolean;
    onSubmit: React.FormEventHandler;
}

export const ReviewModalForm = ({onSubmit, control, isSubmitting}: IReviewModalFormProps) => {

    return (
        <Form onSubmit={onSubmit} noValidate>
            <Modal.Body>
                <Form.Group controlId="ReviewModalForm.rating">
                    <Form.Label>Add a rating</Form.Label>
                    <StarRatingController
                        control={control}
                        name="rating"
                        disabled={isSubmitting}
                    />
                </Form.Group>
                <FormController
                    control={control}
                    name="content"
                    as="textarea"
                    rows={4}
                    label="Description"
                    disabled={isSubmitting}
                    placeholder="Write a detailed review"
                />
                <Row>
                    <Col lg={6}>
                        <FormController
                            control={control}
                            name="author_name"
                            label="Author"
                            disabled={isSubmitting}
                            placeholder="Author"
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="ReviewModalForm__button"
                    type="submit"
                    variant="featured"
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Saving...' : 'SAVE'}
                </Button>
            </Modal.Footer>
        </Form>
    );
};
