import axios from 'axios';

import { IFavorite, IFavoriteKey, IFavoriteListQuery, IFavoriteListResult } from 'modules/favorite/models';
import { getAccessTokenSilently } from 'shared/utils/token';
import { getAuthHeaders } from 'shared/utils/auth';
import { Config } from 'config';

const getURLForFavoriteAPI = (favoriteKey?: IFavoriteKey) => {
    const path = favoriteKey ? `/${favoriteKey.user_id}/${favoriteKey.profile_id}` : '/';
    return `${Config.mainServiceURL}/favorites${path}`;
};

export const createFavorite = async (favoriteCreate: IFavoriteKey): Promise<IFavorite> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForFavoriteAPI(), favoriteCreate, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const readFavorite = async (favoriteKey: IFavoriteKey): Promise<IFavorite> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForFavoriteAPI(favoriteKey), {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const readFavoriteMaybe = async (favoriteKey: IFavoriteKey): Promise<IFavorite | undefined> => {
    try {
        return await readFavorite(favoriteKey);
    } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === 404) {
            return undefined;
        }
        throw e;
    }
};

export const readFavoriteList = async (params?: IFavoriteListQuery): Promise<IFavoriteListResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForFavoriteAPI(), {
        params,
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const deleteFavorite = async (favoriteKey: IFavoriteKey): Promise<void> => {
    const accessToken = await getAccessTokenSilently();
    await axios.delete(getURLForFavoriteAPI(favoriteKey), {
        headers: getAuthHeaders(accessToken),
    });
};

