import jwtDecode from 'jwt-decode';

import {getAccessTokenSilently} from '../../utils/token';
import {Scope} from 'shared/auth/models';

export const getCurrentUserScopes = async (): Promise<Scope[]> => {
    const accessToken = await getAccessTokenSilently();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const claims: any = jwtDecode(accessToken);
    return claims.permissions ?? [];
};
