import React from 'react';
import classNames from 'classnames';

import {IFixedFeeService} from 'modules/profile/models/IServiceDetails';

import {FixedFeesIcon} from 'shared/components/FixedFeesLabel/FixedFeesIcon';
import {FixedFeesTooltip} from 'shared/components/FixedFeesLabel/FixedFeesTooltip';

import './style.scss';

interface IFixedFeesLabelProps {
    id: string;
    fixedFeeServices: IFixedFeeService[];
    size?: 'lg';
    className?: string;
    noText?: boolean;
    labelText?: string;
}

export const FixedFeesLabel = ({id, fixedFeeServices, size, className, noText, labelText}: IFixedFeesLabelProps) => {
    const textClassName = classNames('FixedFeesLabel__text', {
        'FixedFeesLabel__text--lg': size === 'lg',
    });

    return (
        <FixedFeesTooltip id={id} fixedFeeServices={fixedFeeServices} className={className}>
            <FixedFeesIcon size={size} noText={noText} />
            {!noText && (
                <span className={textClassName}>{labelText ?? 'Fixed Fee Options'}</span>
            )}
        </FixedFeesTooltip>
    );
};
