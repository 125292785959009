import { useLayoutEffect, useState } from 'react';

export enum Breakpoint {
    ExtraSmall = 'xs',
    Small = 'sm',
    Medium = 'md',
    Large = 'lg',
    ExtraLarge = 'xl',
    ExtraExtraLarge = 'xxl',
    ExtraExtraExtraLarge = 'xxxl',
}

export interface IWindowSize {
    width: number;
    height: number;
    breakpoint: Breakpoint;

    [key: string]: number | Breakpoint;
}

const getWindowSize = (): IWindowSize => {
    return {
        width: window.innerWidth,
        height: window.innerHeight,
        breakpoint: getBreakpoint(window.innerWidth),
    };
};

const getBreakpoint = (width: number): Breakpoint => {
    if (width < 576) {
        return Breakpoint.ExtraSmall;
    } else if (width < 768) {
        return Breakpoint.Small;
    } else if (width < 992) {
        return Breakpoint.Medium;
    } else if (width < 1200) {
        return Breakpoint.Large;
    } else if (width < 1440) {
        return Breakpoint.ExtraLarge;
    } else if (width < 1600) {
        return Breakpoint.ExtraExtraLarge;
    } else {
        return Breakpoint.ExtraExtraExtraLarge;
    }
};

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState<IWindowSize>(getWindowSize());

    useLayoutEffect(() => {
        const handleResize = () => setWindowSize(getWindowSize());
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
};
