import React from 'react';

import {atom, useSetRecoilState} from 'recoil';

export enum BackgroundColor {
    Default = 'default',
    LightGray = 'light',
}

export const backgroundColorAtom = atom<BackgroundColor>({
    key: 'useBackgroundColorAtom',
    default: BackgroundColor.Default,
});

/**
 * IMPORTANT: This should be used carefully, because it resets the background color to default when un-mounted.
 * Sets the background color for the app, and resets it to default when un-mounted. The change is only applied once,
 * dynamically changing the value of the `bg` prop won't work.
 * @param bg
 */
export const useBackgroundColor = (bg: BackgroundColor) => {
    const setBackgroundColor = useSetRecoilState(backgroundColorAtom);

    React.useEffect(() => {
        setBackgroundColor(bg);
        return () => {
            setBackgroundColor(BackgroundColor.Default);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
