import React from 'react';

import {Col, Form, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IResourceSearchBarProps {
    disabled?: boolean;
    onChange: (value: string) => void;
    value: string;
    removeMargin?: boolean;
}

export const ResourceSearchBar = ({disabled, onChange, value, removeMargin}: IResourceSearchBarProps) => {
    return (
        <Row style={{margin: removeMargin ? '0px' : ''}}>
            <Col md={{span: 8, offset: 2}}>
                <div className="d-flex justify-content-center align-items-center">
                    <div className="ProfilesPageSearch" style={{width: '50vw'}}>
                        <FontAwesomeIcon className="ProfilesPageSearch__icon" icon={faSearch} />
                        <Form.Control
                            name="search"
                            type="text"
                            className="ProfilesPageSearch__input"
                            placeholder="Search Posts"
                            autoFocus
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            disabled={disabled}
                        />
                    </div>
                </div>
            </Col>
        </Row>
    );
};
