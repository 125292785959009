import React from 'react';

import './style.scss';

interface IHeaderSubCategoryProps {
    name: string;
    imageURL: string;
    description: string;
}

export const HeaderSubCategory = ({name, description, imageURL}: IHeaderSubCategoryProps) => {
    return (
        <div className="HeaderSubCategory">
            <div className="HeaderSubCategory--img">
                {imageURL.length > 0 ? (
                    <img src="/assets/SVG/calendar.svg" alt="calendar"/>
                ) : (
                    <div className="HeaderSubCategory--img--div"></div>
                )}
            </div>
            <div className="HeaderSubCategory--Header">
                <p className="m-0">{name}</p>
            </div>
            <div className="HeaderSubCategory--content">
                <p className="m-0">{description}</p>
            </div>
        </div>
    );
};
