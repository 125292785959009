import React, {useRef, useState} from 'react';
import Slick, {Settings} from 'react-slick';
import {Link} from 'react-router-dom';

import {Profession} from 'modules/profile/models/profession';
import {professionFilters} from 'modules/profile/constants';

import {Spinner} from 'shared/components/Spinner/Spinner';
import {BadgeFilter} from 'shared/components/BadgeFilter/BadgeFilter';

import './style.scss';

interface IProfessionSliderProps {
    value: Profession | undefined;
    onChange: (profession: Profession | undefined) => void;
}

export const ProfessionSlider = ({value, onChange}: IProfessionSliderProps) => {
    const sliderRef = useRef<Slick | null>(null);
    const [slidePosition, setSlidePosition] = useState<number>(0);

    const slickSettings: Settings = {
        draggable: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 3,
        infinite: false,
        variableWidth: true,
        className: 'slider variable-width ProfessionSlider__carousel',
        beforeChange: (prev, next) => {
            setSlidePosition(next);
        },
    };

    const onPrevClick = (e: React.MouseEvent) => {
        e.preventDefault();
        sliderRef.current?.slickPrev();
    };

    const onNextClick = (e: React.MouseEvent) => {
        e.preventDefault();
        sliderRef.current?.slickNext();
    };

    return (
        <div className="ProfessionSlider">
            <React.Suspense fallback={<Spinner size="sm" variant="primary" />}>
                <div className="d-flex align-items-center flex-row justify-content-between">
                    {slidePosition !== 0 && (
                        <Link
                            to="#"
                            className="text-dark ProfessionSlider__nav ProfessionSlider__nav--prev"
                            onClick={onPrevClick}
                        >
                            <img
                                style={{
                                    rotate: '180deg',
                                }}
                                src={'/assets/next-dark-icon.svg'}
                                alt="nextIcon"
                            />
                        </Link>
                    )}
                    <Slick ref={sliderRef} {...slickSettings}>
                        {professionFilters.map(profession => (
                            <BadgeFilter
                                key={profession.value || ''}
                                onClick={() => onChange(profession.value)}
                                title={profession.title}
                                active={value === profession.value}
                            />
                        ))}
                    </Slick>
                    {slidePosition <= professionFilters.length - 3 && (
                        <Link
                            to="#"
                            className="text-dark ProfessionSlider__nav ProfessionSlider__nav--next"
                            onClick={onNextClick}
                        >
                            <img src={'/assets/next-dark-icon.svg'} alt="nextIcon" />
                        </Link>
                    )}
                </div>
            </React.Suspense>
        </div>
    );
};
