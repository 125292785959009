import axios from 'axios';

import {ITimeSlotAvailability} from 'modules/availability/models';
import {getAccessTokenMaybe} from 'shared/utils/token';
import {formatISOUTC} from 'shared/utils/date';
import {getAuthHeaders} from 'shared/utils/auth';
import {Config} from 'config';

interface IReadAvailabilityParams {
    userId: string;
    startAt: Date;
    endAt: Date;
    timezone: string;
}

export const availabilityBaseUrl = `${Config.mainServiceURL}/availability`;

export const readAvailability = async ({
    userId,
    startAt,
    endAt,
    timezone,
}: IReadAvailabilityParams): Promise<ITimeSlotAvailability[]> => {
    const accessToken = await getAccessTokenMaybe();
    const response = await axios({
        method: 'get',
        url: `${availabilityBaseUrl}/`,
        params: {
            user_id: userId,
            start_at: formatISOUTC(startAt),
            end_at: formatISOUTC(endAt),
            timezone,
        },
        withCredentials: true,
        headers: getAuthHeaders(accessToken),
    });
    return response.data?.timeslots || [];
};
