import React from 'react';

import {useRecoilState, useRecoilValueLoadable} from 'recoil';

import {featuredArticleListAtom, featuredArticleListReadSelector} from '../state/featured-article';

export const useFeaturedArticles = () => {
    const [featuredArticleList, setFeaturedArticleList] = useRecoilState(featuredArticleListAtom);
    const featuredArticleListLoadable = useRecoilValueLoadable(featuredArticleListReadSelector);

    React.useEffect(() => {
        if (featuredArticleListLoadable.state === 'hasValue') {
            setFeaturedArticleList(featuredArticleListLoadable.contents);
        }
    }, [featuredArticleListLoadable, setFeaturedArticleList]);

    if (featuredArticleList) {
        return featuredArticleList.articles;
    } else if (featuredArticleListLoadable.state === 'hasValue') {
        return featuredArticleListLoadable.contents.articles;
    } else if (featuredArticleListLoadable.state === 'loading') {
        throw featuredArticleListLoadable.promiseOrThrow();
    } else {
        throw featuredArticleListLoadable.errorOrThrow();
    }
};
