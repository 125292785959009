import {z} from 'zod';

import {EventLocationType} from 'modules/event/models';
import {IAvailabilitySettings} from 'modules/availability-settings/models';

export interface IBookingModalBookingForm {
    project_id?: string;
    description: string;
    start_at: Date;
    isProject: 'true' | 'false';
}

export interface IBookingModalConfirmationForm {
    notes: string;
    location: EventLocationType;
    address?: string;
    phone?: string;
    virtual?: string;
}

export const bookingModalBookingSchema = z.object({
    project_id: z.string().optional(),
    description: z.string().min(1),
    start_at: z.date(),
    isProject: z.enum(['true', 'false']),
});

const bookingModalConfirmationSchema = z.object({
    notes: z.string().optional(),
    location: z.nativeEnum(EventLocationType),
    address: z.string().optional(),
    phone: z.string().optional(),
    virtual: z.string().optional(),
});

/**
 * returns custom form validation based on an availability settings.
 * @param availabilitySettings
 */
export const getBookingModalConfirmationSchema = (availabilitySettings: IAvailabilitySettings) => {
    return bookingModalConfirmationSchema
        .refine(
            data => {
                if (availabilitySettings.locations.callback?.is_client_can_edit) {
                    return data.location !== EventLocationType.Callback || (!!data.phone?.length && data.phone.length > 6);
                }
                return true;
            },
            {
                message: 'Valid phone number required for "callback" type events',
                path: ['phone'], // path of error
            },
        )
        .refine(
            data => {
                if (
                    availabilitySettings.locations.virtual?.is_client_can_edit ||
                    availabilitySettings.locations.virtual?.url
                ) {
                    return data.location !== EventLocationType.Virtual || !!data.virtual?.length;
                }
                return true;
            },
            {
                message: 'Valid link required for "virtual meeting" type events',
                path: ['virtual'], // path of error
            },
        )
        .refine(
            data => {
                if (
                    availabilitySettings.locations.physical?.is_client_can_edit ||
                    availabilitySettings.locations.physical?.address
                ) {
                    return data.location !== EventLocationType.Physical || !!data.address?.length;
                }
                return true;
            },
            {
                message: 'Valid address required for "meeting" type events',
                path: ['address'], // path of error
            },
        );
};
