import {atom, selectorFamily, selector} from 'recoil';

import {IChat, IChatListQuery, IChatQuery} from 'modules/chat/models';
import {readChatList} from 'modules/chat/api';
import {CursorList} from 'shared/types/cursor-list';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/utils/recoil';

export interface IChatListSelectorKey {
    filters: IChatQuery;
    page: number;
    limit: number;

    [key: string]: number | IChatQuery;
}

export interface IChatListAtom {
    filters: IChatListQuery;
    chats: IChat[];
    cursors: CursorList;
    page: number;
    limit: number;
    more: boolean;
    resetVersion: number;

    [key: string]: number | boolean | IChatListQuery | IChat[] | CursorList;
}

export const chatListAtom = atom<IChatListAtom | undefined>({
    key: 'chatListAtom',
    default: undefined,
});

export const chatListResetAtom = atom<number>({
    key: 'chatListResetAtom',
    default: 0,
});

const compareChatFilters = (filters1: IChatListQuery, filters2: IChatListQuery) => {
    return filters1.user_id === filters2.user_id && filters1.is_active === filters2.is_active;
};

export const chatListSelector = selectorFamily<IChatListAtom | undefined, IChatListSelectorKey>({
    key: 'chatListSelector',
    get: ({page, limit, filters}) => ({get}) => {
        const atomValue = get(chatListAtom);
        const resetVersion = get(chatListResetAtom);
        if (
            atomValue &&
            page === atomValue.page &&
            limit === atomValue.limit &&
            compareChatFilters(filters, atomValue.filters) &&
            atomValue.resetVersion === resetVersion
        ) {
            return {...atomValue};
        }
        return undefined;
    },
    set: () => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(chatListAtom, newValue);
    },
});

export const chatInsertSelector = selector<IChat>({
    key: 'chatInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const atomValue = get(chatListAtom);
        if (!atomValue) {
            return;
        }
        const newChats = [...atomValue.chats];
        const index = newChats.findIndex((chat) => chat.id === newValue.id);
        if (index !== -1) {
            newChats.splice(index, 1, newValue);
        } else {
            newChats.unshift(newValue);
        }
        set(chatListAtom, {
            ...atomValue,
            chats: newChats,
        });
    },
});

export const chatListBumpToTopSelector = selector<string>({
    key: 'chatListBumpToTopSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, chatId) => {
        if (guardRecoilDefaultValue(chatId)) {
            return;
        }
        const atomValue = get(chatListAtom);
        if (!atomValue) {
            return;
        }
        const newChats = [...atomValue.chats];
        const targetIndex = newChats.findIndex((obj) => obj.id === chatId);
        if (targetIndex !== -1) {
            const targetObject = newChats.splice(targetIndex, 1)[0];
            newChats.splice(0, 0, targetObject);
        }
        set(chatListAtom, {
            ...atomValue,
            chats: newChats,
        });
    },
});

export const chatListReadSelector = selectorFamily<IChatListAtom, IChatListSelectorKey>({
    key: 'chatListReadSelector',
    get: (filters) => async ({get}) => {
        const currentValue = get(chatListSelector(filters));
        if (currentValue) {
            return currentValue;
        }

        const resetVersion = get(chatListResetAtom);
        const atomValue = get(chatListAtom);

        // load a fresh page from the server
        const chatListResult = await readChatList({
            ...filters.filters,
            cursor: filters.page === 0 || !atomValue ? undefined : atomValue.cursors[filters.page - 1],
        });

        // add the cursor to the list of cursors so we can paginate backwards
        const newCursors = atomValue ? Array.from(atomValue.cursors) : [];
        newCursors[filters.page] = chatListResult.next_cursor;

        return {
            filters: filters.filters,
            chats: chatListResult.chats,
            cursors: newCursors,
            page: filters.page,
            limit: filters.limit,
            more: !!chatListResult.next_cursor,
            resetVersion,
        } as IChatListAtom;
    },
});
