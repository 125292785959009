import classNames from 'classnames';

import {ProjectMembersItem} from './ProjectMembersItem';

import './style.scss';

interface IProjectMemberShowMore {
    remainingMembers: string[];
    isOpen: boolean;
    size: string | undefined;
}

export const ProjectMemberShowMore = ({remainingMembers, isOpen, size}: IProjectMemberShowMore) => {
    return (
        <>
            <div
                className={classNames('ProjectMembers__picture ProjectMembers--count', {
                    'ProfilePicture--xs': true,
                    'ProjectMembers__picture--xs': !!size,
                })}
                style={{
                    fontSize: !!size ? '80%' : '1rem',
                }}
            >
                +{remainingMembers.length}
            </div>

            <div
                className={classNames('d-none ProjectMembers__DropDown', {
                    'd-block': isOpen,
                })}
            >
                {remainingMembers.map((memberId) => (
                    <div className="p-2">
                        <ProjectMembersItem
                            key={memberId}
                            userId={memberId}
                            disableTooltip={true}
                            isDispalyFullname={true}
                        />
                    </div>
                ))}
            </div>
        </>
    );
};
