import React from 'react';

import {ModalBody as BootstrapModalBody} from 'react-bootstrap';

interface IModalBodyProps {
    children?: React.ReactNode;
    className?: string;
}

export const ModalBody = ({children, className}: IModalBodyProps) => {
    return <BootstrapModalBody className={className ? className : ''}>{children}</BootstrapModalBody>;
};
