import React, {useRef, useState} from 'react';

import Slick, {Settings} from 'react-slick';
import classNames from 'classnames';

import {IReview} from 'modules/review/models';
import {useWindowSize} from 'shared/hooks/useWindowSize';

import {ReviewCard} from 'shared/components/review/ReviewCard/ReviewCard';
import {EmptyTable} from 'shared/components/EmptyTable/EmptyTable';
import {CardCarousel} from 'shared/components/CardCarousel/CardCarousel';
import {MediaCardDeck} from 'shared/components/MediaCard/MediaCardDeck/MediaCardDeck';
import {ProfilePageEmpty} from '../shared/ProfilePageEmpty/ProfilePageEmpty';

interface IReviewsCarouselProps {
    reviews?: IReview[];
    loading?: boolean;
    error?: string;
    firstname?: string;
    editable: boolean;
}

export const ReviewsCarousel = ({reviews, firstname, loading, error, editable}: IReviewsCarouselProps) => {
    const windowSize = useWindowSize();

    // define the number of slides to show based on the screen size
    const slidesToShow = React.useMemo(() => {
        if (windowSize.width < 1400) {
            return 1;
        } else if (windowSize.width < 1800) {
            return 2;
        } else {
            return 3;
        }
    }, [windowSize.width]);

    const totalReviews = reviews?.length ?? 0;

    const [slideIndex, setSlideIndex] = useState<number>(0);
    const sliderRef = useRef<Slick | null>(null);
    const slickSettings: Settings = {
        draggable: true,
        arrows: false,
        slidesToShow: totalReviews > 3 ? 3 : totalReviews,
        slidesToScroll: 1,
        infinite: false,
        speed: 1000,
        beforeChange: (prev, next) => {
            setSlideIndex(next);
        },
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: totalReviews > 1 ? 2 : 1,
                    slidesToScroll: 1,
                },
            },
        ],
        lazyLoad: 'ondemand',
    };

    const cardDeckClassName = classNames({
        'review-desk-class-1': totalReviews === 1,
        'review-desk-class-2': totalReviews === 2,
        'review-desk-class': totalReviews !== 1 && totalReviews !== 2,
    });

    const cardClassName = classNames('ReviewCard', {
        'm-auto': totalReviews !== 2 || windowSize.width < 1400,
    });

    return (
        <div className="ProfilePageReviews__content pt-lg-4 pb-xs-1 pb-lg-4 pt-lg-5">
            <CardCarousel
                onNext={() => sliderRef && sliderRef.current && sliderRef.current.slickNext()}
                onPrev={() => sliderRef && sliderRef.current && sliderRef.current.slickPrev()}
                hasPrev={slideIndex > 0}
                hasMore={slideIndex < totalReviews - slidesToShow}
                loading={loading && !error}
                isChevrons={true}
                error={error ? 'There was an error loading reviews' : undefined}
                skeleton={
                    // DISPLAYING EMPTY PLACEHOLDER
                    <ProfilePageEmpty>
                        <div className="ProfilePageAbout--empty justify-content-center align-content-center">
                            <p className="NoData mb-0">{firstname} has no reviews yet</p>
                            <img src="/assets/profileIcons/review.svg" className="AboutmeIMG" alt="" />
                        </div>
                    </ProfilePageEmpty>
                }
            >
                {!!reviews?.length ? (
                    <MediaCardDeck className={cardDeckClassName}>
                        <Slick
                            ref={sliderRef}
                            {...slickSettings}
                            className={totalReviews === 3 ? 'postViewforthree' : ''}
                        >
                            {reviews.map((review) => (
                                <ReviewCard
                                    key={review.id}
                                    author_name={review.author_name}
                                    rating={review.rating}
                                    content={review.content}
                                    reviewId={review.id}
                                    authorId={review.author_id}
                                    userId={review.user_id}
                                    reviewDetails={review}
                                    className={cardClassName}
                                    editable={editable}
                                />
                            ))}
                        </Slick>
                    </MediaCardDeck>
                ) : (
                    <EmptyTable>{firstname} has no reviews yet</EmptyTable>
                )}
            </CardCarousel>
        </div>
    );
};
