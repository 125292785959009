export enum ItemKind {
    POST = 'POST',
    USER = 'USER',
    EVENT = 'EVENT',
    CHAT = 'CHAT',
    PROJECT = 'MATTER',
    JOB = 'JOB',
}

export interface IItemKey {
    kind: ItemKind;
    id: string;
}
