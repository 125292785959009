import React from 'react';

import {Container} from 'react-bootstrap';
import {Params, useParams} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import {postLookupSelector} from 'modules/post/state/post-lookup';

import {FormSize} from 'shared/components/form/Forms/FormSize';
import {MainForm} from 'shared/components/form/Forms/MainForm';
import {UpdateResourceForm} from 'user/pages/CreateResource/UpdateResourceForm';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';

export const UpdateUserPostPage = () => {
    const params = useParams() as Params<'postId'>;
    const postId = params.postId as string;
    const post = useRecoilValue(postLookupSelector(postId));

    const title: string = `Update post ${post?.title}`;

    return (
        <Container className="AdminCreateUserPost mt-5 mb-xl">
            <PageHelmet noindex title={title}/>
            <MainForm title={title} width={FormSize.W_60}>
                <UpdateResourceForm
                    post={post}
                />
            </MainForm>
        </Container>
    );
};
