import React, {DragEvent} from 'react';

import {Button, Form, Modal} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';
import {useSetRecoilState} from 'recoil';
import classnames from 'classnames';

import {IProject, IProjectCreate, ProjectStatus} from 'modules/project/models';
import {IPendingFile} from 'shared/models/pending-file/IPendingFile';
import {createProject, updateProject, createProjectInvite} from 'modules/project/api';
import {projectInsertSelector} from 'modules/project/state/project-insert';
import {toast} from 'shared/utils/toast';
import {getErrorMessage} from 'shared/utils/error';
import {useAuth} from 'shared/auth/hooks/useAuth';
import {createFileFromFile, getFolderForProject} from 'modules/file/utils';

import {InputController} from 'shared/components/form/InputController/InputController';
import {ModalFooter} from 'shared/components/modals/Modal/ModalFooter';
import {ImageUploadedContainer} from 'shared/components/project/CreateProjectModal/ImageUploadedContainer/ImageUploadedContainer';
import {NotAuthenticatedModalBody} from 'shared/components/modals/NotAuthenticatedModalBody/NotAuthenticatedModalBody';

import './style.scss';

interface ICreateProjectModalProps {
    show: boolean;
    onHide: () => void;
    project?: IProject;
    isEdit?: boolean;
    isBrief?: boolean;
    profileId?: string;
    onComplete?: (projectId: string) => void;
    // invite members to the project after it has been created.
    // ignored if updating an existing project
    inviteToProject?: string[];
}

const createProjectForm = z.object({
    title: z.string().nonempty(),
    description: z.string().nonempty(),
    shareLink: z.string().optional(),
});

export const CreateProjectModal = ({
    onHide,
    show,
    isEdit,
    isBrief,
    project,
    profileId,
    onComplete,
    inviteToProject,
}: ICreateProjectModalProps) => {
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const {tokenData, isAuthenticated} = useAuth();
    const [pendingFiles, setPendingFiles] = React.useState<IPendingFile[]>([]);
    const [files, setFiles] = React.useState<File[]>([]);

    const {handleSubmit, reset, control} = useForm<IProjectCreate>({
        resolver: zodResolver(createProjectForm),
        defaultValues: {
            status: ProjectStatus.OPEN,
            member_ids: project && project?.member_ids ? project?.member_ids : [],
            title: project && project?.title ? project?.title : '',
            description: project && project?.description ? project?.description : '',
        },
    });

    const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        const newFiles = fileInputRef.current?.files ? Array.from(fileInputRef.current.files) : [];
        setFiles([...files, ...newFiles]);

        // clear the file input so that onFileAdd is triggered even if same file is chosen again
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const uploadFile = (file: File, projectId: string) => {
        (async () => {
            await createFileFromFile({
                userId: tokenData?.id ?? profileId ?? '',
                file,
                folder: getFolderForProject(projectId),
            });
        })();
    };

    const onDeleteFile = (file: File) => {
        setFiles(files.filter((fileInList) => fileInList !== file));
    };

    const insertProject = useSetRecoilState(projectInsertSelector);

    React.useEffect(() => {
        if (!show) {
            reset();
            setIsSubmitting(false);
        }
    }, [show, reset]);

    const getImages = () => {
        document.getElementById('inputFile')?.click();
    };

    const onSubmit = (formData: IProjectCreate) => {
        // TODO: fix not inserting project into the dropdown search after creation
        setIsSubmitting(true);
        (async () => {
            try {
                // create/update project
                let newOrUpdatedProject: IProject;
                if (isEdit && project && project.id) {
                    newOrUpdatedProject = await updateProject(project.id, formData);
                    insertProject(newOrUpdatedProject);
                } else {
                    newOrUpdatedProject = await createProject(formData);

                    // invite members to project if any were specified
                    if (inviteToProject) {
                        try {
                            newOrUpdatedProject = await createProjectInvite(newOrUpdatedProject.id, {
                                rightful_member_ids: inviteToProject,
                                public_emails: [],
                                invited_by_id: tokenData?.id ?? profileId ?? '',
                            });
                        } catch (e) {}
                    }

                    insertProject(newOrUpdatedProject);
                }

                // upload files to the project
                files.forEach((file) => {
                    uploadFile(file, newOrUpdatedProject.id);
                });
                onComplete?.(newOrUpdatedProject.id);
                onHide();
                setFiles([]);
                setPendingFiles([]);
            } catch (e) {
                toast.error(`${isEdit ? 'Edit' : 'Create'} Project Error`, getErrorMessage(e));
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    const UploadImageClassName = classnames('UploadImg', {
        'UploadImg--sort': pendingFiles && pendingFiles.length > 0,
    });

    const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        // Add any styling or visual indicators to show the drop zone
    };

    const handleDragLeave = () => {
        // Remove any styling or visual indicators when the drag leaves the area
    };

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        // Handle the dropped file(s) here
        const getFiles = event.dataTransfer.files;
        const newFiles = getFiles.length > 0 ? getFiles : [];
        setFiles([...files, ...newFiles]);
        // Add further handling logic for the dropped files
    };

    return (
        <Modal
            show={show}
            onHide={() => {
                onHide();
                setFiles([]);
                setPendingFiles([]);
            }}
            title="Legal Project"
            className="CreateMatterModal"
            centered
        >
            <Modal.Header closeButton={!isSubmitting} className="p-3 ">
                <Modal.Title className="headerProfile ">
                    {isEdit ? 'Edit' : 'New'} {isBrief ? 'Brief' : 'Legal Project'}
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(onSubmit)}>
                {isAuthenticated ? (
                    <>
                        <Modal.Body>
                            <Form.Group controlId="createMatter.title">
                                <Form.Label className="text-muted">Title</Form.Label>
                                <InputController
                                    control={control}
                                    name="title"
                                    placeholder={`Give your ${isBrief ? 'brief' : 'legal project'} a title`}
                                    disabled={isSubmitting}
                                    className="focusNone pl-2"
                                />
                            </Form.Group>

                            <Form.Group controlId="createMatter.description">
                                <Form.Label className="text-muted">Summary</Form.Label>
                                <InputController
                                    asType="textarea"
                                    placeholder={`Briefly summarise ${isBrief ? 'the' : 'your legal'} project`}
                                    control={control}
                                    name="description"
                                    disabled={isSubmitting}
                                    className="focusNone pl-2"
                                    autoFocus
                                />
                            </Form.Group>
                            <div
                                onDragEnter={handleDragEnter}
                                onDragLeave={handleDragLeave}
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                            >
                                <Form.Label className="text-muted">Upload files</Form.Label>
                                <div className={UploadImageClassName} onClick={getImages}>
                                    <img src="/assets/Uplodad.svg" alt="UPLOAD FILE" />
                                    <p className="mb-0">Upload or drop files here</p>
                                </div>
                                <div style={{display: 'none'}} className="drag-drop-area">
                                    <input
                                        type="file"
                                        id="inputFile"
                                        accept="image/*, application/pdf, message/rfc822, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vind.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                        onChange={onFileInputChange}
                                        ref={fileInputRef}
                                        multiple
                                    />
                                </div>
                            </div>
                            {files.map((file, index) => (
                                <ImageUploadedContainer
                                    key={`${file.name}.${file.size}.${index}`}
                                    onDelete={() => onDeleteFile(file)}
                                    name={file.name}
                                    percentage={true}
                                />
                            ))}

                            <Form.Group controlId="createMatter.shareDocument">
                                <Form.Label className="text-muted">Share link</Form.Label>
                                <InputController
                                    control={control}
                                    name="shareLink"
                                    disabled={isSubmitting}
                                    className="focusNone pl-2"
                                    placeholder="Any links can be shared here"
                                />
                            </Form.Group>
                        </Modal.Body>
                        <ModalFooter>
                            <Button type="submit" disabled={isSubmitting}>
                                {isEdit ? (
                                    <>{isSubmitting ? 'Saving...' : 'Save'}</>
                                ) : (
                                    <>
                                        {isSubmitting
                                            ? isBrief ? 'Creating Brief' : 'Creating Project...'
                                            : isBrief ? 'Create Brief' : 'Create Project'}
                                    </>
                                )}
                            </Button>
                        </ModalFooter>
                    </>
                ) : (
                    <Modal.Body>
                        <NotAuthenticatedModalBody />
                    </Modal.Body>
                )}
            </Form>
        </Modal>
    );
};
