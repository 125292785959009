import React from 'react';

import {SignupStatus} from 'modules/profile/models/signup-status';
import {useSignupStatus} from 'modules/profile/hooks/useSignupStatus';

import {SignupPage} from 'auth/SignupPage/SignupPage';

interface IRequireSignupProps {
    children: React.ReactNode;
}

export const RequireSignup = ({children}: IRequireSignupProps) => {
    const {signupStatus} = useSignupStatus();

    return (
        <>
            {signupStatus === SignupStatus.NotSignedUp ? (
                <SignupPage/>
            ) : (
                <>{children}</>
            )}
        </>
    );
};
