import React from 'react';

import {IChatWsClientMessage} from 'modules/chat-websocket/models/client-message';

export interface IUseChatWebSocketValue {
    isOnline: boolean;
    sendMessage: (message: IChatWsClientMessage) => void;
    lastMessage: MessageEvent | null,
}

const stub = (): never => {
    throw new Error('You forgot to wrap your component in <ChatWebSocketProvider>.');
};

export const chatWebsocketContext = React.createContext<IUseChatWebSocketValue>({
    isOnline: false,
    sendMessage: stub,
    lastMessage: null,
});

/**
 * This is the main hook you use in components. It exposes methods from the websocket context.
 */
export const useChatWebsocket = () => {
    return React.useContext(chatWebsocketContext);
};
