import React from 'react';

import { z } from 'zod';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Form } from 'react-bootstrap';

import { CaseChartUser, ICaseChart } from 'modules/case-chart/models';
import { FormSize } from 'shared/components/form/Forms/FormSize';

import { MainForm } from 'shared/components/form/Forms/MainForm';
import { InputController } from 'shared/components/form/InputController/InputController';
import { CaseEstimatorFormMilestone } from 'shared/components/CaseEstimatorForm/CaseEstimatorFormMilestone';

import './style.scss';

interface ICaseEstimatorFormProps {
    onChange?: (caseChart: ICaseChart) => void;
}

const caseChartBranchSchema = z.object({
    name: z.string().min(1),
    owner: z.nativeEnum(CaseChartUser),
    cost: z.number(),
});

const caseChartMilestoneSchema = z.object({
    name: z.string().min(1),
    branches: z.array(caseChartBranchSchema),
});

const caseChartSchema = z.object({
    name: z.string().min(1),
    milestones: z.array(caseChartMilestoneSchema),
});

export const CaseEstimatorForm = ({onChange}: ICaseEstimatorFormProps) => {
    const {
        control,
        handleSubmit,
    } = useForm<ICaseChart>({
        resolver: zodResolver(caseChartSchema),
        defaultValues: {
            name: '',
            milestones: [],
        },
    });
    const {fields: milestoneFields, append: appendMilestone, remove: removeMilestone} = useFieldArray({
        control,
        name: 'milestones',
    });

    const addMilestone = () => {
        appendMilestone({
            name: '',
            branches: [],
        });
    };

    const onSubmit = (formData: ICaseChart) => {
        onChange?.(formData);
    };

    return (
        <div className="CreateCaseEstimator__form">
            <MainForm title="Create a fixed fee case chart" width={FormSize.DEFAULT} classNameWrapper="mt-5">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group>
                        <Form.Label>Case Chart Title</Form.Label>
                        <InputController
                            name="name"
                            control={control}
                            placeholder="Title of the case chart"
                        />
                    </Form.Group>
                    {milestoneFields.map((milestoneField, milestoneIndex) => (
                        <CaseEstimatorFormMilestone
                            key={milestoneField.id}
                            name={`milestones.${milestoneIndex}`}
                            index={milestoneIndex}
                            control={control}
                            onDelete={removeMilestone}
                        />
                    ))}
                    <Button type="button" variant="featured" onClick={() => addMilestone()}>
                        Add Milestone
                    </Button>
                    <div className="mt-3">
                        <Button type="submit" variant="featured">Save Changes</Button>
                    </div>
                </Form>
            </MainForm>
        </div>
    );
};
