import React from 'react';

import axios from 'axios';
import {useRecoilState, useRecoilValueLoadable} from 'recoil';

import {SignupStatus} from 'modules/profile/models/signup-status';
import {IProfile} from 'modules/profile/models/IProfile';
import {currentProfileSelector, currentProfileReadSelector} from 'modules/profile/state/current-profile';
import {useAuth} from 'shared/auth/hooks/useAuth';

export const useSignupStatus = () => {
    const {tokenData, isLoading, isAuthenticated} = useAuth();
    const [profile, setProfile] = useRecoilState(currentProfileSelector(tokenData?.id));
    const profileLoadable = useRecoilValueLoadable(currentProfileReadSelector(tokenData?.id));

    React.useEffect(() => {
        if (profile && profileLoadable.state === 'hasValue' && profileLoadable.contents) {
            setProfile(profileLoadable.contents);
        }
    }, [profileLoadable, setProfile, profile]);

    if (!isLoading && !isAuthenticated) {
        return {
            profile: undefined,
            signupStatus: SignupStatus.NotSignedIn,
            loading: false,
        };
    } else if (isLoading || profileLoadable.state === 'loading') {
        return {
            profile: undefined,
            signupStatus: SignupStatus.Loading,
            loading: true,
        };
    } else if (profileLoadable.state === 'hasError' && axios.isAxiosError(profileLoadable.contents) && profileLoadable.contents.response?.status === 404) {
        return {
            profile: undefined,
            signupStatus: SignupStatus.NotSignedUp,
            loading: false,
        };
    } else if (profile || profileLoadable.state === 'hasValue') {
        return {
            profile: (profile ?? profileLoadable.contents) as IProfile,
            signupStatus: SignupStatus.SignedUp,
            loading: false,
        };
    } else {
        return {
            profile: undefined,
            signupStatus: SignupStatus.Error,
            loading: false,
        };
    }
};
