import React from 'react';

import {IProfile} from 'modules/profile/models/IProfile';
import {getFileDownloadUrl} from 'modules/file/utils';

import {MediaCardPicture} from 'shared/components/MediaCard/MediaCardPicture/MediaCardPicture';

export interface IProfileCardPictureProps {
    profile: IProfile;
    to: string;
}

export const ProfileCardPicture = ({profile, to}: IProfileCardPictureProps) => {
    let imageUrl: string | undefined;
    if (profile.image_url) {
        imageUrl = profile.image_url;
    } else if (profile.image_id) {
        imageUrl = getFileDownloadUrl({
            id: profile.image_id,
        });
    }

    return (
        <MediaCardPicture imageUrl={imageUrl} to={to}/>
    );
};
