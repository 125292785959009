import {selectorFamily} from 'recoil';

import {ICategory} from '../models';
import {readCategory} from '../api';
import {categoryListAtom} from './category-list';

export const categoryLookupReadSelector = selectorFamily<ICategory, string>({
    key: 'categoryLookupReadSelector',
    get: (categoryId) => async ({get}) => {
        const categoryList = get(categoryListAtom);
        const category = categoryList?.categories?.find(category => category.id === categoryId);
        if (category) {
            return category;
        }

        return await readCategory(categoryId);
    },
});
