import React, {Fragment, useRef, useState} from 'react';

import classNames from 'classnames';
import Slick, {Settings} from 'react-slick';

import {Profession} from 'modules/profile/models/profession';
import {IProfile} from 'modules/profile/models/IProfile';
import {useWindowSize} from 'shared/hooks/useWindowSize';
import {useHomepageCache} from 'modules/homepage/hooks';

import {ProfileCard} from 'shared/components/ProfileCard/ProfileCard';
import {CardCarousel} from 'shared/components/CardCarousel/CardCarousel';
import {MediaCardDeck} from 'shared/components/MediaCard/MediaCardDeck/MediaCardDeck';
import {CardCarouselEmpty} from 'shared/components/CardCarousel/CardCarouselEmpty/CardCarouselEmpty';
import {BookingModal} from 'shared/components/BookingModal/BookingModal';
import ProfileCardSkeleton from 'public/ProfileMarketplace/ProfileCardSkeleton';

import 'animate.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.scss';

interface IProfilesCarouselProps {
    profession?: Profession;
}

export const ProfilesCarousel = ({profession}: IProfilesCarouselProps) => {
    const windowSize = useWindowSize();
    const sliderRef = useRef<Slick | null>(null);
    const [slideIndex, setSlideIndex] = useState<number>(0);

    const {homepageCache, loading, error} = useHomepageCache();
    const [bookingModalProfile, setBookingModalProfile] = React.useState<IProfile | undefined>(undefined);

    const allProfiles: IProfile[] | undefined = homepageCache?.featured_profiles;

    // define the number of slides to show based on the screen size
    const slidesToShow = React.useMemo(() => {
        if (windowSize.width < 1400) {
            return 1;
        } else if (windowSize.width < 1800) {
            return 2;
        } else {
            return 3;
        }
    }, [windowSize.width]);

    const filteredProfiles = React.useMemo<IProfile[]>(() => {
        return allProfiles ? allProfiles.filter((profile) => !profession || profile.profession === profession) : [];
    }, [profession, allProfiles]);

    const slickSettings: Settings = {
        draggable: true,
        arrows: false,
        slidesToShow: filteredProfiles?.length > 2 ? 3 : filteredProfiles?.length,
        slidesToScroll: filteredProfiles?.length > 1 ? 1 : filteredProfiles?.length,
        infinite: false,
        speed: 1000,
        beforeChange: (prev, next) => {
            setSlideIndex(next);
        },
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: filteredProfiles.length > 1 ? 2 : 1,
                    slidesToScroll: 1,
                },
            },
        ],
        lazyLoad: 'ondemand',
    };

    const cardDeckClassName = classNames({
        'default-desk-class-1': filteredProfiles?.length === 1,
        'default-desk-class-2': filteredProfiles?.length === 2,
        'default-desk-class-3': filteredProfiles?.length === 2,
        'default-desk-class':
            filteredProfiles?.length !== 1 && filteredProfiles?.length !== 2 && filteredProfiles?.length !== 3,
    });

    const cardClassName = classNames('ProfileCard', {
        'm-auto': allProfiles?.length !== 2 || windowSize.width < 1400,
    });

    return (
        <>
            <CardCarousel
                onNext={() => sliderRef && sliderRef.current && sliderRef.current.slickNext()}
                onPrev={() => sliderRef && sliderRef.current && sliderRef.current.slickPrev()}
                hasPrev={slideIndex > 0}
                hasMore={slideIndex < filteredProfiles.length - slidesToShow}
                isChevrons={true}
                loading={loading}
                error={error ? 'There was an error loading profiles' : undefined}
                skeleton={
                    <div
                        style={{width: '100%'}}
                        className={classNames(cardDeckClassName, 'flex-grow-1 justify-content-center')}
                    >
                        <div
                            style={{display: 'flex', columnGap: '20px'}}
                            className="m-auto PostCard__wrap--div mobilePostView mb-3 animate__animated animate__fadeIn "
                        >
                            {[...Array(slidesToShow)].map((_, index) => (
                                <Fragment key={index}>
                                    <div style={{margin: 'auto'}}>
                                        <ProfileCardSkeleton />
                                    </div>
                                </Fragment>
                            ))}
                        </div>
                    </div>
                }
            >
                {!!filteredProfiles.length ? (
                    <MediaCardDeck className={cardDeckClassName}>
                        <Slick
                            ref={sliderRef}
                            {...slickSettings}
                            className={`Home__ProfileCard ${filteredProfiles?.length === 3 ? 'postViewforthree' : ''}`}
                        >
                            {filteredProfiles.map((profile) => (
                                <div className="animate__animated animate__fadeIn p-2" key={profile.id}>
                                    <ProfileCard
                                        className={cardClassName}
                                        profile={profile}
                                        onAvailabilityClick={() => setBookingModalProfile(profile)}
                                    />
                                </div>
                            ))}
                        </Slick>
                    </MediaCardDeck>
                ) : (
                    <CardCarouselEmpty>There are no profiles.</CardCarouselEmpty>
                )}
            </CardCarousel>
            <BookingModal
                profile={bookingModalProfile}
                show={!!bookingModalProfile}
                onHide={() => setBookingModalProfile(undefined)}
            />
        </>
    );
};
