import React from 'react';
import {Modal} from 'react-bootstrap';
import {addMinutes} from 'date-fns';
import {useSetRecoilState} from 'recoil';

import {IProfile} from 'modules/profile/models/IProfile';
import {EventKind, IEventCreate, EventLocationType} from 'modules/event/models';
import {IBookingModalConfirmationForm, IBookingModalBookingForm} from 'shared/components/BookingModal/schema';
import {useAuth} from 'shared/auth/hooks/useAuth';
import {createEvent} from 'modules/event/api';
import {toastAxiosError} from 'shared/utils/error';
import {toast} from 'shared/utils/toast';
import {formatISOUTC} from 'shared/utils/date';
import {getFullName} from 'modules/profile/utils';
import {availabilityInsertSelector} from 'modules/availability/state';
import {projectListResetSelector} from 'modules/project/state/project-list';

import {BookingModalForm} from 'shared/components/BookingModal/BookingModalForm';
import {BookingModalConfirmation} from 'shared/components/BookingModal/BookingModalConfirmation';
import {Loading} from 'shared/components/loading/Loading/Loading';
import {NotAuthenticatedModalBody} from 'shared/components/modals/NotAuthenticatedModalBody/NotAuthenticatedModalBody';

import './style.scss';

interface IBookingModalProps {
    profile: IProfile | undefined;
    show: boolean;
    onHide: () => void;
    onComplete?: () => void;
}

export const BookingModal = ({profile, show, onHide, onComplete}: IBookingModalProps) => {
    const {isAuthenticated, tokenData} = useAuth();
    const [formReset, setFormReset] = React.useState<number>(1);
    const [formData, setFormData] = React.useState<IBookingModalBookingForm | undefined>();
    const [wasShown, setWasShown] = React.useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const resetProjectList = useSetRecoilState(projectListResetSelector);
    const updateAvailability = useSetRecoilState(availabilityInsertSelector);

    // reset the form whenever it's shown for the first time
    React.useEffect(() => {
        if (show !== wasShown) {
            setWasShown(show);
            if (show) {
                setFormData(undefined);
                setFormReset(formReset + 1);
            }
        }
    }, [show, setWasShown, wasShown, setFormReset, formReset]);

    // TODO: read these values from the service offering
    const meetingDurationMinutes: number = 30;
    const timezone: string = 'Australia/NSW';

    const onSubmit = (confirmationData: IBookingModalConfirmationForm) => {
        if (!formData || !tokenData || !profile) {
            return;
        }
        (async () => {
            setIsSubmitting(true);
            const createEventData: IEventCreate = {
                user_id: profile.id,
                title: formData.description,
                description: confirmationData.notes,
                start_at: formatISOUTC(formData.start_at),
                end_at: formatISOUTC(addMinutes(formData.start_at, meetingDurationMinutes)),
                participant_ids: [tokenData.id],
                timezone,
                kind: EventKind.Booking,
                location: {
                    location_type: confirmationData.location,
                    location:
                        confirmationData.location === EventLocationType.Physical
                            ? confirmationData.address
                            : confirmationData.virtual,
                    phone_number: confirmationData.phone,
                },
                project_id: formData.project_id,
            };
            try {
                await createEvent(createEventData);
                // TODO: Insert event into state? May not be necessary though
                toast.success('Book Appointment', 'Request Sent!');
                onComplete?.();
                onHide();
                updateAvailability(undefined);
                // We need to reset the project list because the provider will be added to it in a background task
                if (!!formData.project_id?.length) {
                    resetProjectList(undefined);
                }
            } catch (e) {
                toastAxiosError(e, 'Book Appointment');
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    // NOTE: animation is set to false because the modal doesn't show when the page is first loaded otherwise,
    // something to do with transitions/refs.
    return (
        <Modal show={show} centered size="lg" onHide={onHide} animation={false}>
            <Modal.Header closeButton className="p-3">
                {profile && (
                    <Modal.Title className="BookingModal__title">{getFullName(profile)}'s Availability</Modal.Title>
                )}
            </Modal.Header>
            <React.Suspense
                fallback={
                    <Modal.Body className="p-5">
                        <Loading />
                    </Modal.Body>
                }
            >
                {profile && (
                    <Modal.Body className="p-4">
                        {!formData && isAuthenticated && (
                            <BookingModalForm
                                profile={profile}
                                onSubmit={(formData) => setFormData(formData)}
                                durationMinutes={meetingDurationMinutes}
                                formReset={formReset}
                                onHide={onHide}
                            />
                        )}
                        {formData && isAuthenticated && (
                            <BookingModalConfirmation
                                profile={profile}
                                onSubmit={(confirmationData) => onSubmit(confirmationData)}
                                formData={formData}
                                durationMinutes={meetingDurationMinutes}
                                formReset={formReset}
                                disabled={isSubmitting}
                            />
                        )}
                        {!isAuthenticated && <NotAuthenticatedModalBody />}
                    </Modal.Body>
                )}
            </React.Suspense>
        </Modal>
    );
};
