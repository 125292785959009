import React from 'react';

import {useCombobox} from 'downshift';
import classNames from 'classnames';
import {Dropdown, Form} from 'react-bootstrap';

import {IProject} from 'modules/project/models';
import {useProjectSearchLoadable} from 'modules/project/hooks/useProjectSearchLoadable';

import {ProjectSearchControlSuggestion} from 'shared/components/form/ProjectSearchControl/ProjectSearchControlSuggestion';
import {Spinner} from 'shared/components/Spinner/Spinner';

import './style.scss';

interface IProjectSearchControlProps {
    userId: string;
    onSelect: (project: IProject) => void;
    disabled?: boolean;
    controlClassName?: string;
    placeholder?: string;
}

export const ProjectSearchControl = ({
    userId,
    onSelect,
    disabled,
    controlClassName,
    placeholder,
}: IProjectSearchControlProps) => {
    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const [isOpen, setIsOpen] = React.useState<boolean | undefined>(false);

    const {projects, loading, total, error} = useProjectSearchLoadable({
        userId,
        searchTerm,
        limit: 10,
    });
    const userHasProjects = total > 0;

    const onSelectedItem = (project: IProject) => {
        onSelect(project);
        setSearchTerm('');
        setIsOpen(false);
    };

    const {getMenuProps, getInputProps, highlightedIndex, getItemProps, getComboboxProps} = useCombobox({
        isOpen: isOpen,
        inputValue: searchTerm,
        items: projects,
        onSelectedItemChange: ({selectedItem}) => (selectedItem ? onSelectedItem(selectedItem) : undefined),
        itemToString: (project) => project?.title ?? 'undefined',
        onIsOpenChange: ({isOpen}) => setIsOpen(isOpen),
    });

    const dropdownMenuClassName = classNames('dropdown-menu dropdown-menu-right w-100', {
        show: isOpen,
    });

    return (
        <Dropdown show={isOpen}>
            <div
                {...getComboboxProps({
                    className: 'MatterSearchControl__wrapper',
                })}
            >
                <Form.Control
                    {...getInputProps({
                        onChange: (e) => setSearchTerm((e.target as HTMLInputElement).value),
                        onFocus: () => setIsOpen(true),
                        // IMPORTANT: Do not set isOpen when onBlur is called, otherwise clicking items doesn't work.
                        // See https://github.com/downshift-js/downshift/issues/1409
                        // onBlur: () => setIsOpen(false),
                    })}
                    className={controlClassName}
                    type="text"
                    placeholder={placeholder}
                    disabled={disabled}
                />
            </div>
            <ul
                style={{
                    maxHeight: '242px',
                    overflowY: 'auto',
                }}
                {...getMenuProps({className: dropdownMenuClassName})}
            >
                {!!projects.length &&
                    projects.map((project, index) => (
                        <ProjectSearchControlSuggestion
                            {...getItemProps({
                                key: project.id,
                                index,
                                item: project,
                                className: classNames({
                                    active: highlightedIndex === index,
                                }),
                            })}
                            userId={userId}
                            project={project}
                            highlighted={highlightedIndex === index}
                        />
                    ))}
                {isOpen && !projects.length && !loading && !error && (
                    <Dropdown.Item className="MatterSearchControl__empty-dropdown-item">
                        {userHasProjects ? 'No Results' : 'You do not have any projects'}
                    </Dropdown.Item>
                )}
                {isOpen && !projects.length && (loading || error) && (
                    <Dropdown.Item className="MatterSearchControl__empty-dropdown-item">
                        <Spinner variant="muted" size="sm" />
                    </Dropdown.Item>
                )}
            </ul>
        </Dropdown>
    );
};
