import {atom, selector} from 'recoil';

import {guardRecoilDefaultValue} from 'shared/utils/recoil';
import {IFileToken} from '../models/IFileToken';
import {createFileToken} from '../api';

interface IFileDownloadTokenAtom {
    token: IFileToken | undefined;
    resetVersion: number;
}

export const fileTokenAtom = atom<IFileDownloadTokenAtom>({
    key: 'fileTokenAtom',
    default: {
        token: undefined,
        resetVersion: 0,
    },
});

export const fileTokenResetAtom = atom<number>({
    key: 'fileTokenResetAtom',
    default: 1,
});

export const fileTokenReadSelector = selector<IFileToken>({
    key: 'fileTokenReadSelector',
    get: async ({get}) => {
        const resetVersion = get(fileTokenResetAtom);
        const atomValue = get(fileTokenAtom);
        if (atomValue && atomValue.token && atomValue.resetVersion === resetVersion) {
            return atomValue.token;
        }
        return await createFileToken();
    },
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return null;
        }
        const resetVersion = get(fileTokenResetAtom);
        const atomValue = get(fileTokenAtom);
        if (!atomValue.token || atomValue.token !== newValue) {
            set(fileTokenAtom, {
                token: newValue,
                resetVersion: resetVersion,
            });
        }
    },
});

export const fileTokenSelector = selector<string | undefined>({
    key: 'fileTokenSelector',
    get: ({get}) => {
        const atomValue = get(fileTokenAtom);
        if (atomValue && atomValue.token) {
            return atomValue.token.token;
        } else {
            return undefined;
        }
    },
});
