import React from 'react';

import {useAuth0} from '@auth0/auth0-react';
import {useSetRecoilState} from 'recoil';

import {setAccessTokenSilently} from '../../utils/token';
import {currentUserIdAtom} from '../state/current-user-id';
import {getUUIDFromUser} from 'shared/auth/utils/get-uuid-from-token';

export const AccessTokenHelper = () => {
    const {getAccessTokenSilently, user} = useAuth0();
    const setCurrentUserId = useSetRecoilState(currentUserIdAtom);

    React.useEffect(() => {
        setCurrentUserId(user ? getUUIDFromUser(user) : undefined);
    }, [user, setCurrentUserId]);

    // this makes the `getAccessTokenSilently` function available outside of React components
    setAccessTokenSilently(getAccessTokenSilently);

    return null;
};
