import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCog} from '@fortawesome/free-solid-svg-icons';

interface ISystemProfilePictureProps {
    name: string;
    className?: string;
    size: 'xs' | 'lg' | 'sm' | '1x' | '2x' | '3x';
}

export const SystemProfilePicture = ({name, className, size}: ISystemProfilePictureProps) => {

    return (
        <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faCog} className={className} size={size} title={name}/>
        </div>
    );
};