import React from 'react';

import {Container} from 'react-bootstrap';
import {useNavbar} from 'shared/hooks/useNavbar';
import {useArticleList} from 'modules/help/hooks/useArticleList';
import {useCategoryFromPath} from 'modules/help/hooks/useCategoryFromPath';

import {HelpCenterHeader} from 'public/help-center/components/HelpCenterHeader/HelpCenterHeader';
import {AppFooter} from 'shared/components/AppFooter/AppFooter';
import {BreadCrumbs} from 'public/help-center/HelpCenterCategory/BreadCrumbs/BreadCrumbs';
import {HeaderSubCategory} from 'public/help-center/HelpCenterCategory/HeaderSubCategory/HeaderSubCategory';
import {ArticleCard} from 'public/help-center/HelpCenterCategory/ArticleCard/ArticleCard';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';

import './style.scss';

export const HelpCenterCategory = () => {
    const category = useCategoryFromPath();

    useNavbar(true);

    const articles = useArticleList({
        isPublished: true,
        categoryId: category.id,
    });

    const breadcrumbs = [
        {
            title: 'Help Center',
            url: '/help-center',
        },
        {
            title: category.name,
        },
    ];

    return (
        <>
            <PageHelmet
                title={`${category.name} - Help Center`}
                description={category.description}
            />
            <div className="HelpSubCategory">
                <Container>
                    <HelpCenterHeader/>
                    <BreadCrumbs breadcrumbs={breadcrumbs}/>
                    <HeaderSubCategory
                        name={category.name}
                        description={category.description}
                        imageURL="/assets/SVG/calendar.svg"
                    />
                    {articles.map(article => (
                        <ArticleCard key={article.id} article={article}/>
                    ))}
                </Container>
            </div>
            <AppFooter/>
        </>
    );
};
