import React from 'react';

import {Right} from 'shared/icons/Homepage';

import './style.scss';

interface IBecomeMemberFeatureProps {
    children: React.ReactNode;
}

export const BecomeMemberFeature = ({children}: IBecomeMemberFeatureProps) => {
    return (
        <div className="d-flex align-items-center BecomeMemberFeature">
            <div className="BecomeMemberFeature__check-icon-wrapper">
                <Right className="BecomeMemberFeature__check-icon"/>
            </div>
            <div className="BecomeMember__description--text p-0">
                {children}
            </div>
        </div>
    );
};
