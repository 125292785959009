import React from 'react';

import {useSearchParams} from 'react-router-dom';

import {IProfile} from 'modules/profile/models/IProfile';
import {BookingModal} from 'shared/components/BookingModal/BookingModal';

interface IProfilePageBookingModalProps {
    profile: IProfile;
}

/**
 * This component shows/hides the booking modal based on the presence of a "booking" search parameter
 * @param profile
 * @constructor
 */
export const ProfilePageBookingModal = ({profile}: IProfilePageBookingModalProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const isShowModal = !!searchParams.get('booking')?.length;

    const hideModal = () => {
        searchParams.delete('booking');
        setSearchParams(searchParams);
    };

    return (
        <BookingModal profile={profile} show={isShowModal} onHide={hideModal}/>
    );
};
