import React from 'react';

import {Link} from 'react-router-dom';

import {useFeaturedArticles} from 'modules/help/hooks/useFeaturedArticles';

import './style.scss';

export const PopularQuestions = () => {
    const featuredArticles = useFeaturedArticles();

    return (
        <div className="PopularQuestions">
            <div className="content-page-header mt-0">
                <h2 className="content-page-title">Popular Questions</h2>
            </div>
            {!featuredArticles.length && (
                <div className="text-center lead text-muted">Empty</div>
            )}
            {!!featuredArticles.length && (
                <div className="PopularQuestions__article-group">
                    {featuredArticles.map(article => (
                        <Link
                            key={article.id}
                            to={`/help-center/${article.category_id}/${article.id}`}
                            className="PopularQuestions__article"
                        >
                            {article.name}
                        </Link>
                    ))}
                </div>
                )}
        </div>
    );
};
