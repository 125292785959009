import React from 'react';

import {useSetRecoilState} from 'recoil';
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';
import {Button, Form} from 'react-bootstrap';

import {IWorkExperience} from 'modules/profile/models/IWorkExperience';
import {IProfile} from 'modules/profile/models/IProfile';
import {updateProfile} from 'modules/profile/api/profile';
import {insertProfileSelector} from 'modules/profile/state/profile-insert';
import {getErrorMessage} from 'shared/utils/error';
import {
    getContactDetailsFormDefaults,
    getServiceDetailsFormDefaults,
} from 'shared/components/ProfilePageContent/shared/utils/form';
import {toast} from 'shared/utils/toast';

import {Modal} from 'shared/components/modals/Modal/Modal';
import {ModalBody} from 'shared/components/modals/Modal/ModalBody';
import {InputController} from 'shared/components/form/InputController/InputController';
import {ModalFooter} from 'shared/components/modals/Modal/ModalFooter';

interface IEditAboutUsProps {
    openModal: boolean;
    onHideModal: () => void;
    profile: IProfile;
    isEdit?: boolean;
    EditIndex?: number | null;
    ExperienceData: IWorkExperience;
}

const workExperienceSchema = z.object({
    name: z.string().min(1),
    description: z.string().min(1),
});

export const AddEditExperience = ({
    openModal,
    onHideModal,
    profile,
    isEdit,
    EditIndex,
    ExperienceData,
}: IEditAboutUsProps) => {
    const descriptionLimit = 500;
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const insertProfile = useSetRecoilState(insertProfileSelector);

    const {handleSubmit, reset, control, setValue, watch} = useForm<IWorkExperience>({
        resolver: zodResolver(workExperienceSchema),
        defaultValues: {
            name: ExperienceData.name,
            description: ExperienceData.description,
        },
    });

    const descriptionLength = watch('description').length;

    React.useEffect(() => {
        if (isEdit && openModal) {
            setValue('name', ExperienceData.name);
            setValue('description', ExperienceData.description);
        }
    }, [isEdit, ExperienceData, openModal, setValue]);

    const onSubmit = handleSubmit((formData) => {
        setIsSubmitting(true);

        (async () => {
            try {
                const profileWorkExperience = profile.work_experience.slice();
                if (isEdit && typeof EditIndex === 'number' && EditIndex >= 0) {
                    profileWorkExperience[EditIndex] = formData;
                }

                const updatedProfile = await updateProfile(profile.id, {
                    firstname: profile.firstname || '',
                    lastname: profile.lastname || '',
                    contact_details: getContactDetailsFormDefaults(profile.contact_details),
                    contact_details_display: getContactDetailsFormDefaults(profile.contact_details_display),
                    resume_id: profile.resume_id,
                    profession: profile.profession,
                    summary: profile.summary,
                    claim_to_fame: profile.claim_to_fame || '',
                    service_types: profile.service_types,
                    service_details: getServiceDetailsFormDefaults(profile.service_details),
                    skills: profile.skills || [],
                    work_experience: isEdit
                        ? profileWorkExperience
                        : profile.work_experience
                            ? [...profile.work_experience, formData]
                            : [formData] || [],
                    social_links: profile.social_links || [],
                });

                insertProfile(updatedProfile);
                onHideModal();
                reset({
                    name: '',
                    description: '',
                });
                toast.success('Profile Settings Saved', 'Your profile was updated successfully');
            } catch (e) {
                toast.error('Profile Settings Error', getErrorMessage(e));
            }
            setIsSubmitting(false);
        })();
    });

    return (
        <Modal
            show={openModal}
            onHide={() => {
                onHideModal();
                reset({
                    name: '',
                    description: '',
                });
            }}
            size="lg"
            centered
            isSubmitting={isSubmitting}
            title={`${isEdit ? 'Edit' : 'Add'} Experience`}
        >
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit();
                }}
            >
                <ModalBody>
                    <div>
                        <Form.Group controlId="name">
                            <Form.Label className="form-label--sm">Heading</Form.Label>
                            <InputController
                                name="name"
                                placeholder=""
                                control={control}
                                disabled={isSubmitting}
                                isErrorNotShow={true}
                            />
                        </Form.Group>
                    </div>
                    <div>
                        <Form.Group controlId="description">
                            <Form.Label className="form-label--sm">Description</Form.Label>
                            <InputController
                                name="description"
                                placeholder=""
                                asType="textarea"
                                rows={5}
                                control={control}
                                disabled={isSubmitting}
                                isErrorNotShow={true}
                                maxlength={descriptionLimit}
                            />
                            <div
                                style={{marginTop: '5px', fontSize: '12px'}}
                                className="w-100 d-flex justify-content-end"
                            >
                                <span>
                                    {descriptionLength}/{descriptionLimit}
                                </span>
                            </div>
                        </Form.Group>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="featured"
                        type="submit"
                        disabled={isSubmitting}
                        className="ProfilePageAbout__margin--t15 ProfilePageAbout__button--save-button"
                    >
                        Save
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};
