import React from 'react';

import classNames from 'classnames';

import {useNavbar} from 'shared/hooks/useNavbar';

import './style.scss';

interface ITechProductsPageSectionProps {
    title: string;
    children: React.ReactNode;
    iconSrc: string;
    imageSrc: string;
    alignRight?: boolean;
}

export const TechProductsPageSection = ({
    title,
    children,
    iconSrc,
    imageSrc,
    alignRight,
}: ITechProductsPageSectionProps) => {
    useNavbar(true);

    const className = classNames('TechProductsPageSection', {
        'TechProductsPageSection--right': alignRight,
    });
    const imageCardClassName = classNames('TechProductsPageSection__image-card', {
        'TechProductsPageSection__image-card--right': alignRight,
        'TechProductsPageSection__image-card--left': !alignRight,
    });
    const imageShadowClassName = classNames('TechProductsPageSection__image-shadow', {
        'TechProductsPageSection__image-shadow--right': alignRight,
        'TechProductsPageSection__image-shadow--left': !alignRight,
    });
    const contentColClassName = classNames('TechProductsPageSection__content-col', {
        'TechProductsPageSection__content-col--right': alignRight,
        'TechProductsPageSection__content-col--left': !alignRight,
    });

    return (
        <div className={className}>
            <div className={contentColClassName}>
                <div className="TechProductsPage__feature-head">
                    <div className="TechProductsPageSection__icon-wrapper">
                        <img src={iconSrc} alt={`${title} - Icon`}/>
                    </div>
                    <h5 className="TechProductsPageSection__title">{title}</h5>
                </div>
                <div className="TechProductsPageSection__content">
                    {children}
                </div>
            </div>
            <div className="TechProductsPageSection__image-col">
                <div className={imageCardClassName}>
                    <img
                        src={imageSrc}
                        className="TechProductsPageSection__image"
                        alt={title}
                    />
                </div>
                <div className={imageShadowClassName}/>
            </div>
        </div>
    );
};
