import React from 'react';

import './style.scss';

import { HowItWorks } from 'shared/components/PageSections/HowItWorks/HowItWorks';
//import { HomePageFeaturesAnimation } from 'public/HomePage/HomePageFeatures/HomePageFeaturesAnimation';

export const HomePageFeatures = () => {
    return (
        <div className="HomePageFeatures">
            <HowItWorks />
        </div>
    );
};
