import React from 'react';

import classnames from 'classnames';

import { ReactComponent as CancelIcon } from 'shared/icons/legalmatters/Crossdelete.svg';

interface IFileControlAttachmentProps {
    name?: string;
    size?: number;
    onDelete?: () => void;
    disabled?: boolean;
    spinner?: boolean;
}

export const FileControlAttachment = ({name, size, onDelete, disabled, spinner}: IFileControlAttachmentProps) => {
    const ImageIconPath = name?.includes('pdf') ? '/assets/legalmatters/pdf.png' : '/assets/legalmatters/jpeg.png';
    const CompleteUploadClass = classnames('LegalMatter__UploadImg--ProgressBar', {
        'LegalMatter__UploadImg--ProgressBar--active': disabled === false,
    });
    const ProgresscompleteClass = classnames('progress-bar', {
        'progress-bar-complete': spinner === true,
    });

    return (
        <>
            <div className="spinnerBox">
                <div className="LegalMatter__UploadImg--left colsize--left">
                    <img src={ImageIconPath} alt="Girl in a jacket" width="35" height="35" />
                </div>

                <div className="LegalMatter__UploadImg--center colsize--center">
                    <div className="LegalMatter__UploadImg--text">{name}</div>
                    <div className={CompleteUploadClass}>
                        <div className="progress">
                            <div className={ProgresscompleteClass} />
                        </div>
                    </div>
                </div>

                <div className="LegalMatter__UploadImg--right colsize--right">
                    <CancelIcon
                        className=""
                        onClick={() => {
                            onDelete?.();
                        }}
                    />
                </div>
            </div>
        </>
    );
};
