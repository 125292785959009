import React from 'react';

import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';

import { ResumeUploader } from 'shared/components/form/ResumeController/ResumeUploader';

import 'shared/components/form/ResumeController/style.scss';

interface IResumeControllerProps<FormType> extends UseControllerProps<FormType> {
    disabled?: boolean;
    userId: string;
}

export const ResumeController = <FormType extends FieldValues>({
    control,
    disabled,
    userId,
    name,
}: React.PropsWithChildren<IResumeControllerProps<FormType>>) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value}}) => (
                <div className="ResumeController">
                    <ResumeUploader userId={userId} onChange={onChange} fileId={value} disabled={disabled} />
                </div>
            )}
        />
    );
};
