import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';

export const ArticleEditorError = () => (
    <div className="text-center text-danger">
        <FontAwesomeIcon icon={faExclamationCircle} size="3x"/>
        <div>
            The article editor experienced an error. Please reload the page, or
            &nbsp;<a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>contact support</a>.
        </div>
    </div>
);
