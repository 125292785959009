import React from 'react';

import {Accordion, Container} from 'react-bootstrap';
import {useNavbar} from 'shared/hooks/useNavbar';

import {AppFooter} from 'shared/components/AppFooter/AppFooter';
import {FaqItem} from 'public/FrequentlyAskedQuestions/FaqItem';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';

import './style.scss';

export const FrequentlyAskedQuestions = () => {
    useNavbar(true);
    const title = 'Frequently Asked Questions';

    return (
        <div>
            <PageHelmet
                title={title}
                description="Find answers to any question you may have about the Rightful platform and using it as either a client or a provider of legal services."
            />
            <Container className="mb-5">
                <h1 className="FAQ__title">{title}</h1>
                <Accordion>
                    <FaqItem
                        title="How much does it cost for legal practitioners to be on the platform?"
                        eventKey="faqCost"
                    >
                        Rightful is currently in its beta testing phase. During this time, any pricing arrangements are
                        between Rightful and its users individually. If you would like to discuss our pricing options,
                        why not book a platform demonstration by heading to the ‘book a demo’ page.
                    </FaqItem>
                    <FaqItem title="How does Rightful work?" eventKey="faqHowDoesitWork?">
                        Rightful uses custom made technology to connect you directly with remote lawyers and paralegals
                        that can run legal projects entirely online. This enables the lawyers on the platform to offer
                        better value for high quality legal services.
                    </FaqItem>
                    <FaqItem title="Do you provide legal advice?" eventKey="faqAdvice">
                        We are not a law firm and do not provide legal services directly. We provide a search function
                        and tools for users to interface with independent legal practitioners and paralegals, to project
                        manage legal cases, provide advice and access legal information.
                    </FaqItem>
                    <FaqItem
                        title="What is the difference between a solicitor, a barrister and paralegal and when do I need them?"
                        eventKey="faqDifference"
                    >
                        Traditionally barristers are courtroom specialists and solicitors prepare evidence, file
                        documents and sometimes appear in Court. These roles have shifted somewhat with the introduction
                        of virtual working and online courts. These days many solicitors have become specialist
                        advocates and many barristers do a lot of document drafting that would traditionally be done by
                        solicitors. There are also things solicitors do that barristers are not allowed to do like
                        writing correspondence on your behalf, investigating the evidence or filing documents. When
                        choosing your legal team a good way to think of it is like this, who is my legal project manager
                        going to be? Is that person someone that has the right expertise in the area of law and
                        jurisdiction to be able to project manage this matter? Once you have that sorted, you can
                        upscale your team as needed for specific functions. If you would like to learn more you can read
                        our 'Choosing your legal team' page.
                    </FaqItem>
                    <FaqItem title="Do you operate trust accounts?" eventKey="faqTrust">
                        Rightful does not operate trust accounts. We are currently seeking regulatory approval for a new
                        payment product to enable clients and legal service providers to exchange money on the platform
                        and escrow payments, without the need for a trust account.
                    </FaqItem>
                    <FaqItem title="How do I know that lawyers on your platform are qualified?" eventKey="faqQualified">
                        Each legal practitioner member on our platform has their own unique regulatory authorisation
                        number to provide legal services. For further details, check the law society or bar association
                        register in that individual's state or territory. We review these authorisations when we welcome
                        new practitioners to our platform, but otherwise do not regularly review them.
                    </FaqItem>
                    <FaqItem title="What is different about your process?" eventKey="faqProcess">
                        Whether you are a client or legal services provider, we focus on giving you the right tools to
                        manage your legal projects efficiently and securely, by reducing legal talent sourcing
                        headaches, eliminating double up and reducing overheads. We are digital first which means that
                        where possible, we seek to enable legal services to be offered electronically to save you time
                        and money.
                    </FaqItem>
                    <FaqItem
                        title="Does Rightful guarantee a particular outcome in my legal project?"
                        eventKey="faqGuarantee"
                    >
                        No we do not guarantee outcomes. Firstly all legal work on the platform is conducted by our
                        legal practitioner members directly rather than by us. We don't have any oversight over their
                        work, they are responsible for it themselves. Secondly, legal projects are inherently uncertain
                        and some carry an element of risk. That said, we are obsessed with continuously improving the
                        process which we think will ultimately lead to improved outcomes for clients. If for any reason
                        you have concerns about the legal services you have been provided, please see FAQ Question: What
                        if I have concerns about my lawyer?
                    </FaqItem>
                    <FaqItem title="I am a legal practitioner, why would I join Rightful?" eventKey="faqJoin">
                        Imagine an online world where you are judged on your legal ideas rather than your seniority.
                        Where creativity in legal problem solving is highly valued and where the process is customer
                        driven. Where high overheads and facetime are all a thing of the past. Where you have access to
                        a wealth of resources and other knowledgeable practitioners to draw from. If you are sick of
                        'traditional' law then we definitely are interested in hearing from you.{' '}
                    </FaqItem>
                    <FaqItem title="Why do lawyers share their legal resources on the platform?" eventKey="faqShare">
                        Our philosophy is about empowering everyone, lawyer or non lawyer, to handle their legal
                        affairs. We want to put the power back in your hands and so we try to provide as much helpful
                        information as possible. From the lawyers perspective, sharing resources is a great way to
                        showcase what you know about and to access other lawyer's knowledge. When lawyers share their
                        resources they provide a limited licence to us to showcase those resources on the platform, but
                        otherwise they continue to own their own Intellectual Property.
                    </FaqItem>
                    <FaqItem title="What if I have concerns about my Lawyer?" eventKey="faqConcerns">
                        All legal practitioner members on our platform are required to provide you with information
                        about your rights. If you have a dispute about the services you have received from one of our
                        members you should express your concern to the lawyer directly. For more information about how
                        to complain about a lawyer, you should contact the office of the legal commissioner in which
                        that lawyer is situated.
                    </FaqItem>
                    <FaqItem
                        title="I am a lawyer, there is no way I am going to let clients review me"
                        eventKey="faqReviews"
                    >
                        Reviews are a great way to get feedback from those you are serving. But they should not be used
                        as a weapon in business. Negative reviews (which we haven't received yet) would be discussed
                        with the service provider and then removed.
                    </FaqItem>
                    <FaqItem title="Do I have to fix fee everything as a lawyer on your platform?" eventKey="faqFixed">
                        No. Legal projects are no different to other complex projects in that they may often carry a
                        degree of uncertainty as to how the project will unfold (for example, if a particular legal
                        application is challenged by an opposing party the costs may be greater than anticipated). A
                        fixed fee may not be suitable for all aspects of legal work, particularly where there is a
                        degree of uncertainty as to how long a milestone will take to complete. That said, using
                        Rightful, we hope that you will have more confidence in offering a greater proportion of your
                        legal work on a fixed fee basis. What Rightful provides is a mechanism for greater fee
                        transparency to clients over both the individual milestones in a legal project and any
                        uncertainties that may arise. As a legal practitioner, you can provide a client with a project
                        map in the form of a case chart and either fix fee certain aspects or milestones of the work
                        (for example, sending a letter or appearing at a hearing) while reserving the right to apply
                        further costs if necessary. This scoping is made possible via the notes fields in the case chart
                        editor. If you need assistance we would be happy to give you a demonstration.
                    </FaqItem>
                </Accordion>
            </Container>
            <AppFooter />
        </div>
    );
};
