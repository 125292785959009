import React, {useState} from 'react';

import {Button, Col, Form, Row} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';

import {toast} from 'shared/utils/toast';
import {toastAxiosError} from 'shared/utils/error';
import {IContactForm} from 'modules/contact/models';
import {createContactAsync} from 'modules/contact/api';
import {useCurrentProfile} from 'modules/profile/hooks/useCurrentProfile';
import {getFullName} from 'modules/profile/utils';

import {Spinner} from 'shared/components/Spinner/Spinner';
import {InputController} from 'shared/components/form/InputController/InputController';

const contactPageFormSchema = z.object({
    name: z.string().nonempty(),
    contact: z.string().nonempty(),
    message: z.string().nonempty(),
});

interface IContactPageFormProps {
    onComplete: () => void;
}

export const ContactPageFormSignedIn = ({onComplete}: IContactPageFormProps) => {
    const profile = useCurrentProfile();

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const {control, handleSubmit, reset} = useForm<IContactForm>({
        resolver: zodResolver(contactPageFormSchema),
        defaultValues: {
            name: getFullName(profile),
            contact: '',
            message: '',
        },
    });

    const onSubmit = handleSubmit((formData) => {
        if (isSubmitting) {
            return;
        } else {
            setIsSubmitting(true);
        }
        (async () => {
            try {
                await createContactAsync(formData);
                toast.success(
                    'Message Sent',
                    'Your message was sent successfully. You\'ll hear back from us within 24 hours.',
                );
                reset();
                onComplete();
            } catch (e) {
                toastAxiosError(e, 'Error Sending Message');
            } finally {
                setIsSubmitting(false);
            }
        })();
    });

    return (
        <Form onSubmit={onSubmit}>
            <Row>
                <Col xs={12} lg={6}>
                    <Form.Group className="Auth_page__form-group" controlId="ContactForm__name">
                        <InputController
                            control={control}
                            name="name"
                            className="AuthPage__input"
                            placeholder="Full name"
                            disabled={isSubmitting}
                            autoFocus
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} lg={6}>
                    <Form.Group className="AuthPage__form-group" controlId="ContactForm__contact">
                        <InputController
                            control={control}
                            name="contact"
                            className="AuthPage__input"
                            placeholder="Phone number"
                            disabled={isSubmitting}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Form.Group className="AuthPage__form-group" controlId="ContactForm__message">
                <InputController
                    control={control}
                    asType="textarea"
                    name="message"
                    placeholder="Type a message"
                    disabled={isSubmitting}
                    className="AuthPage__input ContactPage__textarea"
                    rows={6}
                />
            </Form.Group>
            <Button
                className="AuthPage__button m-auto"
                variant="featured"
                block
                type="submit"
                disabled={isSubmitting}
            >
                {isSubmitting && (
                    <>
                        Sending <Spinner size="sm"/>
                    </>
                )}
                {!isSubmitting && (
                    <span>Send</span>
                )}
            </Button>
        </Form>
    );
};
