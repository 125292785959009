import {SocialProvider} from 'modules/profile/models/socialProvider';
import {Profession} from 'modules/profile/models/profession';

export interface IProfessionFilter {
    value: Profession | undefined;
    title: string;
}

export const socialProviderOptions = [
    {
        title: 'Linkedin',
        value: SocialProvider.Linkedin,
    },
    {
        title: 'Facebook',
        value: SocialProvider.Facebook,
    },
    {
        title: 'Twitter',
        value: SocialProvider.Twitter,
    },
    {
        title: 'Other',
        value: SocialProvider.Other,
    },
];

export const professionValues = [
    {
        value: Profession.BARRISTER,
        title: 'Barrister',
    },
    {
        value: Profession.SOLICITOR,
        title: 'Solicitor',
    },
    {
        value: Profession.LAWYER,
        title: 'Lawyer',
    },
    {
        value: Profession.PARALEGAL,
        title: 'Paralegal',
    },
    {
        value: Profession.SENIOR_IN_HOUSE_COUNSEL,
        title: 'Senior In-House Counsel',
    },
    {
        value: Profession.LEGAL_COUNSEL,
        title: 'Legal Counsel',
    },
    {
        value: Profession.ATTORNEY,
        title: 'Attorney',
    },
    {
        value: Profession.PATENT_ATTORNEY,
        title: 'Patent Attorney',
    },
    {
        value: Profession.COMPANY_SECRETARY,
        title: 'Company Secretary',
    },
    {
        value: Profession.COMPLIANCE_MANAGER,
        title: 'Compliance Manager',
    },
    {
        value: Profession.RISK_PROFESSIONAL,
        title: 'Risk Professional',
    },
    {
        value: Profession.POLICY_WRITER,
        title: 'Policy Writer',
    },
    {
        value: Profession.EXPERT_WITNESS,
        title: 'Expert Witness',
    },
    {
        value: Profession.LEGAL_TECH_PROFESSIONAL,
        title: 'Legal Tech Professional',
    },
    {
        value: Profession.LEGAL_OPERATIONS,
        title: 'Legal Operations',
    },
    {
        value: Profession.REGULATORY_AFFAIRS,
        title: 'Regulatory Affairs',
    },
    {
        value: Profession.SPECIALIST,
        title: 'Specialist',
    },
    {
        value: Profession.AI_EXPERT,
        title: 'AI Expert',
    },
    {
        value: Profession.BARRISTERS_CLERK,
        title: 'Barrister\'s Clerk',
    },
    {
        value: Profession.PRACTICE_MANAGER,
        title: 'Practice Manager',
    },
    {
        value: Profession.GOVERNANCE_EXPERT,
        title: 'Governance Expert',
    },
    {
        value: Profession.JUNIOR_LAWYER_4_YEARS_PQE,
        title: 'Junior Lawyer',
    },
    {
        value: Profession.EXTERNAL_SUPPORT_SERVICE,
        title: 'External Support Service',
    },
    {
        value: Profession.CONVEYANCER,
        title: 'Conveyancer',
    },
    {
        value: Profession.OTHER,
        title: 'Other',
    },
];

export const professionFilters: IProfessionFilter[] = [
    {
        title: 'All',
        value: undefined,
    },
    ...professionValues,
];
