import {ProjectStatus} from 'modules/project/models';

export enum EventStatus {
    Pending = 'PENDING',
    Confirmed = 'CONFIRMED',
    Cancelled = 'CANCELLED',
    Rejected = 'REJECTED',
}

export enum EventKind {
    Booking = 'BOOKING',
    Personal = 'PERSONAL',
}

export enum EventOrder {
    StartAtAsc = 'START_AT_ASC',
}

export enum EventLocationType {
    Physical = 'PHYSICAL',
    Virtual = 'VIRTUAL',
    Callback = 'CALLBACK'
}

// phone_number is only used when callback is selected, otherwise
// location is used for physical address and virtual
export interface IEventLocation {
    location_type: EventLocationType,
    location?: string;
    phone_number?: string;
}

export interface IEventRejection {
    rejected_by: string;
    reason?: string;
    rejected_at: string;
}

export interface IEvent {
    id: string;
    user_id?: string;
    participant_ids: string[];
    accepted_by_ids: string[];
    title: string;
    description?: string;
    url?: string;
    file_ids?: string[];
    status: EventStatus;
    kind: EventKind;
    location?: IEventLocation;
    timezone: string;
    start_at: string;
    end_at: string;
    created_at: string;
    updated_at: string;
    project_id?: string;
    rejection?: IEventRejection;
}

export interface IEventStatsQuery {
    userId: string;
    startAt: string;
    endAt: string;

    [key: string]: string;
}

export interface IEventStats {
    total: number;
    completed: number;
    upcoming: number;
    todayPending: number;
}

export interface IEventCreate {
    user_id: string;
    participant_ids: string[];
    title: string;
    description?: string;
    url?: string;
    file_ids?: string[];
    kind: EventKind;
    location?: IEventLocation;
    timezone: string;
    start_at: string;
    end_at: string;
    project_id?: string;
}

export interface IEventUpdate {
    participant_ids?: string[];
    title?: string;
    description?: string;
    url?: string;
    file_ids?: string[];
    kind?: EventKind;
    location?: IEventLocation;
    timezone?: string;
    start_at?: string;
    end_at?: string;
    project_id?: string;
}

export interface IEventProject {
    id: string;
    user_id: string;
    member_count: number;
    title: string;
    description: string;
    status: ProjectStatus;
}

export interface IEventReschedule {
    location?: IEventLocation;
    start_at: string;
    end_at: string;
}

export interface IEventReject {
    reason?: string;
}

export interface IEventListQuery {
    userId: string;
    user_id_type: EventUserIdType;
    status?: EventStatus;
    limit?: number;
    cursor?: string;
    start_at_min?: string;
    start_at_max?: string;
    orderBy?: EventOrder;
}

export interface IEventChat {
    chat_id: string;
}

export enum EventUserIdType {
    ACCEPTED = 'ACCEPTED',
    PENDING = 'PENDING',
    RESCHEDULE_REQUESTED = 'RESCHEDULE_REQUESTED',
    PARTICIPATING_IN = 'PARTICIPATING_IN',
}
