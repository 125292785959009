import {Row} from 'react-bootstrap';
import {AdvisoryCard} from './AdvisoryCard';

import './styles.scss';

interface IBoardMember {
    picture: string;
    name: string;
}

const boardMembers: IBoardMember[] = [
    {
        picture: '/assets/profilePictures/advisory-1a.png',
        name: 'Astrid Raetze',
    },
    {
        picture: '/assets/profilePictures/advisory-2.jpg',
        name: 'Adam Caines',
    },
    {
        picture: '/assets/profilePictures/advisory-3.jpg',
        name: 'Daniel Kahn',
    },
    {
        picture: '/assets/profilePictures/advisory-4.png',
        name: 'Michael Green',
    },
];

export const AdvisoryAllCard = () => {
    return (
        <>
            <div className="AboutUsPage__heading AboutUsPage__margin--t130">
                <h2 className="AboutUsPage__heading--text">Advisory Board Members</h2>
            </div>
            <div className="Advisory__container">
                <Row className="Advisory__container__Row">
                    {boardMembers.map((boardMember, index) => (
                        <AdvisoryCard
                            key={`boardMember${index + 1}`}
                            personImage={boardMember.picture}
                            name={boardMember.name}
                        />
                    ))}
                </Row>
            </div>
        </>
    );
};
