import {selectorFamily} from 'recoil';

import {IProfile} from 'modules/profile/models/IProfile';
import {Profession} from 'modules/profile/models/profession';
import {RateType} from 'shared/models/rate-filters/rate-filters';
import {profileListAtom} from 'modules/profile/state/profile-list';
import {ServiceType} from 'modules/profile/models/serviceType';
import {filterProfiles} from 'modules/profile/utils';
import {marketplaceInfiniteScrollAtom} from 'modules/profile/state/marketplace-infinite-scroll';

export interface IPriceRange {
    min: number;
    max: number;

    [key: string]: number;
}

export interface IProfilesPageFilters {
    limit: number;
    page: number;
    priceRange?: IPriceRange;
    location?: string;
    tagId?: string;
    rateType?: RateType;
    profession?: Profession;
    searchTerm?: string;
    serviceType?: ServiceType;
    name?: string;

    [key: string]: number | string | undefined | Profession | RateType | IPriceRange | ServiceType;
}

export const profilesPageProfiles = selectorFamily<IProfile[], IProfilesPageFilters>({
    key: 'profilesPageProfiles',
    get: (filters) => ({get}) => {
        const atomValue = get(profileListAtom);
        return filterProfiles(atomValue?.profiles ?? [], filters);
    },
});

export const marketplacePageProfiles = selectorFamily<IProfile[], IProfilesPageFilters>({
    key: 'profilesPageProfiles',
    get: (filters) => ({get}) => {
        const atomValue = get(marketplaceInfiniteScrollAtom);
        return filterProfiles(atomValue?.profiles ?? [], filters);
    },
});

export const marketplacePageProfilesForSearching = selectorFamily<IProfile[], IProfilesPageFilters>({
    key: 'profilesPageProfiles',
    get: (filters) => ({get}) => {
        const atomValue = get(profileListAtom);
        
        return filterProfiles(atomValue?.profiles ?? [], filters);
    },
});