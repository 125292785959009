import React from 'react';

import {Button, Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';
import {useSetRecoilState} from 'recoil';

import {IProfile} from 'modules/profile/models/IProfile';
import {insertProfileSelector} from 'modules/profile/state/profile-insert';
import {getErrorMessage} from 'shared/utils/error';
import {updateProfile} from 'modules/profile/api/profile';
import {toast} from 'shared/utils/toast';
import {
    getContactDetailsFormDefaults,
    getFormDefaultsFromProfile,
    getServiceDetailsFormDefaults,
    IProfileSettingsForm,
} from 'shared/components/ProfilePageContent/shared/utils/form';

import {Modal} from 'shared/components/modals/Modal/Modal';
import {ModalBody} from 'shared/components/modals/Modal/ModalBody';
import {InputController} from 'shared/components/form/InputController/InputController';
import {ModalFooter} from 'shared/components/modals/Modal/ModalFooter';

interface IEditClaimToFameModalProps {
    open: boolean;
    onHide: () => void;
    profile: IProfile;
}

const EditClaimToFameFormSchema = z.object({
    claim_to_fame: z.string().max(1000).nonempty({message: 'This field can\'t be empty'}),
});

export const EditClaimToFameModal = ({open, onHide, profile}: IEditClaimToFameModalProps) => {
    const insertProfile = useSetRecoilState(insertProfileSelector);
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const {handleSubmit, reset, control} = useForm<IProfileSettingsForm>({
        resolver: zodResolver(EditClaimToFameFormSchema),
        defaultValues: getFormDefaultsFromProfile(profile),
    });

    const onSubmit = handleSubmit((formData) => {
        setIsSubmitting(true);

        (async () => {
            try {
                const updatedProfile = await updateProfile(profile.id, {
                    firstname: profile.firstname || '',
                    lastname: profile.lastname || '',
                    contact_details: getContactDetailsFormDefaults(profile.contact_details),
                    contact_details_display: getContactDetailsFormDefaults(profile.contact_details_display),
                    resume_id: profile.resume_id,
                    profession: profile.profession,
                    summary: profile.summary || '',
                    claim_to_fame: formData.claim_to_fame,
                    service_types: profile.service_types,
                    service_details: getServiceDetailsFormDefaults(profile.service_details),
                    skills: profile.skills || [],
                    work_experience: profile.work_experience || [],
                    social_links: profile.social_links || [],
                });

                insertProfile(updatedProfile);
                onHide();
                reset(getFormDefaultsFromProfile(updatedProfile));
                toast.success('Profile Settings Saved', 'Your profile was updated successfully');
            } catch (e) {
                toast.error('Profile Settings Error', getErrorMessage(e));
            }
            setIsSubmitting(false);
        })();
    });

    return (
        <Modal
            show={open}
            onHide={() => {
                onHide();
            }}
            size="lg"
            centered
            isSubmitting={isSubmitting}
            title="Claim To Fame"
        >

            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit();
                }}
            >
                <ModalBody>
                    <Form.Group controlId="About_me">
                        <InputController
                            name="claim_to_fame"
                            placeholder=""
                            asType="textarea"
                            rows={14}
                            className="ProfilePageAbout__input"
                            control={control}
                            disabled={isSubmitting}
                        />
                    </Form.Group>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button
                            variant="featured"
                            type="submit"
                            disabled={isSubmitting}
                            className="btn-xl-wide"
                        >
                            Save
                        </Button>
                    </div>
                </ModalFooter>
            </Form>
        </Modal>
    );
};
