import React from 'react';

import CoreArticleEditor from 'vendor/article-editor/article-editor.js';
import 'vendor/article-editor/plugins/reorder/reorder';
import 'vendor/article-editor/plugins/inlineformat/inlineformat';
import 'vendor/article-editor/css/article-editor.css';

import useUnmount from 'shared/hooks/useUnmount';

interface IArticleEditorControlProps {
    name: string;
    grammarly: boolean;
    disabled: boolean;
    onChange?: (content: string) => void;
    autofocus: boolean;
    isInvalid?: boolean;
    defaultValue?: string;
    placeholder?: string;
}

export const ArticleEditorControl = ({
    name,
    grammarly,
    onChange,
    autofocus,
    isInvalid,
    defaultValue,
    placeholder,
}: IArticleEditorControlProps) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [editor, setEditor] = React.useState<any>();
    const textareaRef = React.useRef<HTMLTextAreaElement>(null);

    useUnmount(() => {
        editor?.stop();
        setEditor(undefined);
    });

    React.useEffect(() => {
        if (textareaRef.current && !editor) {
            const newEditor = CoreArticleEditor(textareaRef.current, {
                path: false,
                css: '/vendor/article-editor/css/',
                classes: {
                    img: 'img-fluid',
                    table: 'table table-bordered',
                },
                embed: {
                    responsive: 'embed-responsive embed-responsive-16by9',
                },
                plugins: ['reorder', 'inlineformat'],
                quote: {
                    template:
                        '<figure><blockquote class="blockquote"><p>Quote...</p><figcaption class="blockquote-footer">Author Attribution</figcaption></blockquote></figure>',
                },
                editor: {
                    focus: autofocus,
                    grammarly,
                    minHeight: '354px',

                    // minHeight: '100%',
                },
                subscribe: {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    'editor.change': ({params}: any) => {
                        onChange?.(params.html);
                    },
                },
                content: defaultValue,
            });
            setEditor(newEditor);
        }
    }, [textareaRef, setEditor, editor, autofocus, grammarly, onChange, defaultValue]);

    return (
        <textarea
            ref={textareaRef}
            name={name}
            className={isInvalid ? 'is-invalid custome_text_area_content' : 'custome_text_area_content'}
            placeholder={placeholder}
        />
    );
};

ArticleEditorControl.defaultProps = {
    grammarly: false,
    disabled: false,
    autofocus: false,
};
