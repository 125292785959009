import {atom, selectorFamily} from 'recoil';

import {IProject} from 'modules/project/models';
import {readProjectList} from 'modules/project/api';
import {projectLookupInsertSelector} from 'modules/project/state/project-lookup';
import {guardRecoilDefaultValue} from 'shared/utils/recoil';

const projectSearchReadLimit = 1000;

export interface IProjectSearchFilters {
    userId: string;
    searchTerm?: string;
    limit: number;

    [key: string]: number | string | undefined;
}

interface IProjectSearchState {
    userId: string;
    projects: IProject[];
    _resetVersion: number;

    [key: string]: string | number | undefined | IProject[];
}

export const projectSearchAtom = atom<IProjectSearchState | undefined>({
    key: 'projectSearchAtom',
    default: undefined,
});

export const projectSearchResetAtom = atom<number>({
    key: 'projectSearchResetAtom',
    default: 0,
});

export const projectSearchStateSelector = selectorFamily<IProjectSearchState | undefined, string>({
    key: 'projectSearchStateSelector',
    get: (userId) => ({get}) => {
        const atomValue = get(projectSearchAtom);
        const resetVersion = get(projectSearchResetAtom);
        if (
            atomValue &&
            resetVersion === atomValue._resetVersion &&
            userId === atomValue.userId
        ) {
            return atomValue;
        }
        return undefined;
    },
    set: () => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return null;
        }
        set(projectSearchAtom, {...newValue});
        newValue.projects.forEach(project => {
            set(projectLookupInsertSelector, project);
        });
    },
});

export const projectSearchStateReadSelector = selectorFamily<IProjectSearchState, string>({
    key: 'projectSearchStateReadSelector',
    get: (userId) => async ({get}) => {
        const currentValue = get(projectSearchStateSelector(userId));
        if (currentValue) {
            return currentValue;
        }

        const resetVersion = get(projectSearchResetAtom);
        const projectListResult = await readProjectList({
            member_id: userId,
            limit: projectSearchReadLimit,
            cursor: undefined,
        });

        // Log a warning if the user has more than 1000 projects
        if (projectListResult.projects.length === projectSearchReadLimit) {
            console.warn(`User has more than ${projectSearchReadLimit} projects, search may not be accurate`);
        }

        return {
            userId,
            projects: projectListResult.projects,
            _resetVersion: resetVersion,
        } as IProjectSearchState;
    },
});

export const projectSearchSelector = selectorFamily<IProject[], IProjectSearchFilters>({
    key: 'projectSearchSelector',
    get: ({limit, searchTerm, userId}) => ({get}) => {
        const atomValue = get(projectSearchAtom);
        const searchTermLower = searchTerm?.toLowerCase()?.trim();

        let results = (atomValue && userId === atomValue.userId) ? atomValue.projects : [];
        if (!!searchTermLower?.length) {
            results = results.filter(project => (
                project.title.toLowerCase().trim().includes(searchTermLower) ||
                project.description?.toLowerCase().trim().includes(searchTermLower)
            ));
        }
        return results.slice(0, limit);
    },
});
