import React from 'react';

import {parseAndFormatDistance} from 'shared/utils/date';

interface IFormatDistanceProps {
    children: Date | string;
    addSuffix?: boolean;
}

export const FormatDistance = ({children, addSuffix}: IFormatDistanceProps) => {
    const dateString = React.useMemo(() => {
        return parseAndFormatDistance({
            date: children,
            addSuffix,
        });
    }, [children, addSuffix]);

    return (
        <>{dateString}</>
    );
};
