import {IProfile} from 'modules/profile/models/IProfile';

import {ProfilePageHeader} from 'shared/components/ProfilePageContent/ProfilePageHeader/ProfilePageHeader';
import {ProfilePageAbout} from 'shared/components/ProfilePageContent/ProfilePageAbout/ProfilePageAbout';
import {ProfilePageDetails} from 'shared/components/ProfilePageContent/ProfilePageDetails/ProfilePageDetails';
import {ProfilePageSkills} from 'shared/components/ProfilePageContent/ProfilePageSkills/ProfilePageSkills';
import {ProfilePageResources} from 'shared/components/ProfilePageContent/ProfilePageResources/ProfilePageResources';
import {ProfilePageReviews} from 'shared/components/ProfilePageContent/ProfilePageReviews/ProfilePageReviews';

import './style.scss';

interface IProfilePageContentProps {
    profile: IProfile;
    isEditable?: boolean;
    onEdit?: () => void;
}

export const ProfilePageContent = ({profile, isEditable, onEdit}: IProfilePageContentProps) => {
    return (
        <>
            <ProfilePageHeader profile={profile} isEditable={isEditable} onEdit={onEdit} />
            <ProfilePageAbout profile={profile} summary={profile.summary || ''} isEditable={isEditable} />
            <ProfilePageDetails profile={profile} isEditable={isEditable} workExperience={profile.work_experience} />
            <ProfilePageSkills
                profile={profile}
                isEditable={isEditable}
                firstname={profile.firstname}
                skills={profile.skills || []}
            />
            <ProfilePageResources userId={profile.id} firstname={profile.firstname} isEditable={isEditable} />
            <ProfilePageReviews id={profile.id} firstname={profile.firstname} />
        </>
    );
};
