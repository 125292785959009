import {Params, useParams} from 'react-router-dom';
import {useRecoilValueLoadable} from 'recoil';

import {useNavbar} from 'shared/hooks/useNavbar';
import {profileLookupReadSelector} from 'modules/profile/state/profile-lookup';

import {ViewProfilePageContent} from 'public/ViewProfilePage/ViewProfilePageContent';
import {NotFound} from 'public/NotFound/NotFound';

export const ViewProfilePage = () => {
    useNavbar(true);

    const params = useParams() as Params<'profileId'>;
    const profileId = params.profileId as string;

    const profileLoadable = useRecoilValueLoadable(profileLookupReadSelector(profileId));

    if (profileLoadable.state === 'loading') {
        throw profileLoadable.promiseMaybe();
    }

    return (
        <>
            {profileLoadable.state === 'hasValue' && (
                <ViewProfilePageContent profile={profileLoadable.contents} />
            )}
            {profileLoadable.state === 'hasError' && (
                <NotFound buttonUrl="/" buttonTitle="RETURN HOME" />
            )}
        </>
    );
};
