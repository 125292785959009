import React from 'react';

import {Nav} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

import './style.scss';

interface ICarouselNavProps {
    children: React.ReactNode;
    viewAll?: string;
    className?: string;
}

export const CarouselNav = ({children, viewAll, className}: ICarouselNavProps) => {
    return (
        <div
            className={classNames(className, 'd-flex align-items-center mb-5 row', {
                'default-nav-class mx-2': !className,
            })}
        >
            <div className="CarouselNav__side-item row" />
            <Nav variant="pills" defaultActiveKey="/home">
                {children}
            </Nav>
            <div className="CarouselNav__side-item text-right">
                {viewAll ? (
                    <Link to={viewAll} className="mobileViewAll">
                        View All
                    </Link>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};
