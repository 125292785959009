import React from 'react';

import {Modal} from 'shared/components/modals/Modal/Modal';
import {ModalBody} from 'shared/components/modals/Modal/ModalBody';
import {VideoPlayer} from 'shared/components/WebinarPreviewModal/VideoPlayer';

import './style.scss';

interface IWebinarPreviewModalProps {
    open: boolean;
    onHideModal: () => void;
    videoURL?: string;
}

export const WebinarPreviewModal = ({open, onHideModal, videoURL}: IWebinarPreviewModalProps) => {
    return (
        <Modal
            show={open}
            onHide={() => {
                onHideModal();
            }}
            centered
            className="WebinarPreviewModal"
        >
            <ModalBody className="WebinarPreviewModal__body">
                <VideoPlayer videoURL={videoURL} className="WebinarPreviewModal__video-player"/>
            </ModalBody>
        </Modal>
    );
};
