import React from 'react';
import './PlansAndFeaturesTable.scss';

const PlansAndFeaturesTable = () => {
    const pricingTableData = [
        {
            title: 'Marketplace listing',
            description: 'Set up a profile in our legal network, start receiving client enquiries and bookings.',
            free: true,
            basic: true,
            pro: true,
            // enterprise: true,
        },
        {
            title: 'Unlimited Document generation and e-signatures',
            description: 'Access our library of legal documents. E-sign for extra convenience.',
            free: false,
            basic: true,
            pro: true,
            // enterprise: true,
        },
        {
            title: 'Case charts',
            description: 'Prepare case charts for clients showing the likely steps and costs in a legal project.',
            free: false,
            basic: true,
            pro: true,
            // enterprise: true,
        },
        {
            title: 'Continuing Professional Development',
            description: 'Access our library of CPD resources from our members.',
            free: false,
            basic: true,
            pro: true,
            // enterprise: true,
        },
        {
            title: 'Create unlimited Legal Projects',
            description: 'Our collaborative file sharing system means you can manage legal projects on platform.',
            free: false,
            basic: true,
            pro: true,
            // enterprise: true,
        },
        {
            title: 'Client leads',
            description: 'Verified client leads to your Rightful inbox or appointment dashboard.',
            free: false,
            basic: false,
            pro: true,
        },
    ];

    return (
        <div className="pricing-table-wrapper">
            <div className="pricing-table">
                <div className="features-header">Features</div>
                <div className="plan-header">Free</div>
                <div className="plan-header">Basic</div>
                <div className="plan-header">Pro</div>
                {/* <div className="plan-header">Enterprise</div> */}

                {pricingTableData.map((item, index) => (
                    <React.Fragment key={index}>
                        <div className="feature-title">{item.title}</div>
                        <div className="feature-description">{item.description}</div>
                        <div className="plan-value">
                            {item.free ? (
                                <span className="check">
                                    {' '}
                                    <img src={'/assets/plans/right.svg'} alt="img" />
                                </span>
                            ) : (
                                '-'
                            )}
                        </div>
                        <div className="plan-value">
                            {item.basic ? (
                                <span className="check">
                                    {' '}
                                    <img src={'/assets/plans/right.svg'} alt="img" />
                                </span>
                            ) : (
                                '-'
                            )}
                        </div>
                        <div className="plan-value">
                            {item.pro ? (
                                <span className="check">
                                    <img src={'/assets/plans/right.svg'} alt="img" />
                                </span>
                            ) : (
                                '-'
                            )}
                        </div>
                        {/* <div className="plan-value">
                            {item.enterprise ? (
                                <span className="check">
                                    {' '}
                                    <img src={'/assets/plans/right.svg'} alt="img" />
                                </span>
                            ) : (
                                '-'
                            )}
                        </div> */}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default PlansAndFeaturesTable;
