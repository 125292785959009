import React from 'react';

import {Dropdown} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import {CustomToggle} from 'shared/components/CustomToggle/CustomToggle';
import {DropdownSelectItem} from 'shared/components/form/DropdownSelect/DropdownSelectItem';

import 'shared/components/form/DropdownSelect/style.scss';

export interface IDropdownSelectOption {
    title: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
}

interface ICommonPillSelectProps {
    id: string;
    onChange: (value: string) => void;
    options: IDropdownSelectOption[];
    value?: string;
    block?: boolean;
    variant?: 'pill';
    disabled?: boolean;
    isInvalid?: boolean;
    className?: string;
    size?: 'sm' | 'lg';
}

export const DropdownSelect = ({
    id,
    onChange,
    options,
    value,
    block,
    variant,
    disabled,
    isInvalid,
    className,
    size,
}: ICommonPillSelectProps) => {
    // className for the main toggle button
    const toggleClassName = classNames('DropdownSelect__toggle', className, {
        [`DropdownSelect__toggle--${variant}`]: variant,
        'form-control': !variant,
        [`form-control-${size}`]: size,
        'DropdownSelect__toggle--has-error': isInvalid,
        'disabled': disabled,
    });

    // className for the outer dropdown container
    const dropdownClassName = classNames('DropdownSelect', {
        'DropdownSelect--block': block,
    });
    const dropdownMenuClassName = classNames('DropdownSelect__menu', {
        [`DropdownSelect__menu--${variant}`]: variant,
    });

    // offset the dropdown menu by 9px. This accounts for the size of the "triangle" on the dropdown menu
    const popperConfig = React.useMemo(() => {
        return {
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 9],
                    },
                },
            ],
        };
    }, []);

    const title = React.useMemo(() => {
        const selectedOption = options.find(option => option.value === value);
        if (selectedOption) {
            return selectedOption.title;
        } else {
            return 'Select';
        }
    }, [value, options]);

    return (
        <Dropdown className={dropdownClassName}>
            <Dropdown.Toggle
                as={CustomToggle}
                id={id}
                block
                className={toggleClassName}
                disabled={disabled}
            >
                {title}
                <FontAwesomeIcon className="DropdownSelect__caret ml-2" icon={faCaretDown}/>
            </Dropdown.Toggle>

            <Dropdown.Menu className={dropdownMenuClassName} popperConfig={popperConfig}>
                {options.map((option) => (
                    <DropdownSelectItem
                        key={`${id}.${option.value}`}
                        option={option}
                        onClick={() => onChange(option.value)}
                        active={option.value === value}
                    />
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};
