import React, {ElementType} from 'react';

import {Form} from 'react-bootstrap';
import {Controller, FieldValues, UseControllerProps} from 'react-hook-form';

import {InputControl} from 'shared/components/form/InputControl/InputControl';

interface IInputControllerProps<FormType extends FieldValues> extends UseControllerProps<FormType> {
    disabled?: boolean;
    placeholder?: string;
    type?: string;
    max?: number;
    min?: number;
    asType?: ElementType;
    className?: string;
    autoFocus?: boolean;
    rows?: number;
    id?: string;
    custom?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formatValue?: (value: string) => any;
    isErrorNotShow?: boolean;
    autoComplete?: string;
    size?: 'sm' | 'lg';
    readOnly?: boolean;
    maxlength?: number;
}

export const InputController = <FormType extends FieldValues>({
    disabled,
    className,
    autoFocus,
    name,
    control,
    placeholder,
    type,
    max,
    min,
    defaultValue,
    asType,
    rows,
    children,
    id,
    custom,
    formatValue,
    isErrorNotShow,
    autoComplete,
    size,
    readOnly,
    maxlength,
}: React.PropsWithChildren<IInputControllerProps<FormType>>) => {
    const getFormattedValue = (value: string) => {
        return formatValue ? formatValue(value) : value;
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({field: {onChange, onBlur, value, name: controlName, ref}, fieldState: {invalid, error}}) => (
                <>
                    <InputControl
                        as={asType}
                        ref={ref}
                        name={controlName}
                        value={value}
                        placeholder={placeholder}
                        disabled={disabled}
                        onChange={(e) => onChange(getFormattedValue(e.target.value))}
                        onBlur={onBlur}
                        isInvalid={invalid}
                        type={type}
                        max={max}
                        min={min}
                        id={id}
                        className={className}
                        autoFocus={autoFocus}
                        rows={rows}
                        custom={custom}
                        autoComplete={autoComplete}
                        size={size}
                        readOnly={readOnly}
                        maxlength={maxlength}
                    >
                        {children}
                    </InputControl>
                    {!isErrorNotShow && error && (
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {error.message}
                        </Form.Control.Feedback>
                    )}
                </>
            )}
        />
    );
};

InputController.defaultValue = {
    type: 'text',
};
