import React from 'react';

import {Form} from 'react-bootstrap';
import {Control, Controller, FieldValues, Path} from 'react-hook-form';

import {DropdownSelect, IDropdownSelectOption} from 'shared/components/form/DropdownSelect/DropdownSelect';

interface IDropdownSelectControllerProps<FormType> {
    disabled?: boolean;
    className?: string;
    control: Control<FormType> | undefined;
    name: Path<FormType>;
    options: IDropdownSelectOption[];
    block?: boolean;
    variant?: 'pill';
    size?: 'sm' | 'lg';
}

export const DropdownSelectController = <FormType extends FieldValues>({
    disabled,
    className,
    name,
    control,
    options,
    block,
    variant,
    size,
}: IDropdownSelectControllerProps<FormType>) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value, name: controlName}, fieldState: {invalid, error}}) => (
                <>
                    <DropdownSelect
                        id={controlName}
                        options={options}
                        disabled={disabled}
                        onChange={onChange}
                        isInvalid={invalid}
                        className={className}
                        value={value}
                        block={block}
                        variant={variant}
                        size={size}
                    />
                    {error && (
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {error.message && error.message?.includes('Invalid enum value') ? (
                                'Please select proper type.'
                            ) : (
                                error.message
                            )}
                        </Form.Control.Feedback>
                    )}
                </>
            )}
        />
    );
};
