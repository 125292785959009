import React from 'react';
import {useNavbar} from 'shared/hooks/useNavbar';
import {useAuth} from 'shared/auth/hooks/useAuth';

import {ContactPageFormSignedIn} from 'public/ContactPage/ContactPageFormSignedIn';
import {ContactPageSuccess} from './ContactPageSuccess';
import {AuthPage} from 'auth/AuthPage/AuthPage';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';
import {ContactPageFormAnonymous} from 'public/ContactPage/ContactPageFormAnonymous';

import './style.scss';

export const ContactPage = () => {
    useNavbar(true);

    const {isAuthenticated} = useAuth();
    const [isComplete, setIsComplete] = React.useState<boolean>(false);

    return (
        <AuthPage className="ContactPage" title="General Enquiries" size="md">
            <PageHelmet
                title="Contact Us"
                description="Get in touch with the Rightful team with any inquiries you may have about the platform."
            />
            {isComplete ? (
                <ContactPageSuccess />
            ) : (
                <>
                    {isAuthenticated ? (
                        <ContactPageFormSignedIn onComplete={() => setIsComplete(true)} />
                    ) : (
                        <ContactPageFormAnonymous onComplete={() => setIsComplete(true)} />
                    )}
                </>
            )}
        </AuthPage>
    );
};
