import React from 'react';

interface IOurGuidingPrinciplesCardProps {
    title: string;
    iconSVGFun: React.ReactNode;
    paragraphText: string[];
}

export const OurGuidingPrinciplesCard = ({title, iconSVGFun, paragraphText}: IOurGuidingPrinciplesCardProps) => {

    return (
        <div className="AboutUsPage__card--container AboutUsPage__padding--a45">
            <div className="AboutUsPage__card__image--container AboutUsPage__margin--b30">{iconSVGFun}</div>
            <div className="AboutUsPage__card__paragraph AboutUsPage__margin--b22">
                <p className="mb-0 AboutUsPage__card__paragraph--text  AboutUsPage__color--text-151C2D">{title}</p>
            </div>
            <div className="AboutUsPage__card__paragraph">
                {paragraphText && paragraphText.map((data, index) => (
                    <p key={index} className="mb-0 AboutUsPage__card__paragraph--text">{data}</p>
                ))}
            </div>
        </div>
    );
};
