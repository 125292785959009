import React from 'react';

import { NavLink as BootstrapNavLink } from 'react-bootstrap';

import { Link, useMatch, useResolvedPath } from 'react-router-dom';

interface INavLinkProps {
    to: string;
    strict?: boolean;
    className?: string | ((active: boolean) => string);
}

export const NavLink = ({children, to, strict, className}: React.PropsWithChildren<INavLinkProps>) => {
    const resolved = useResolvedPath(to);
    const match = useMatch({path: resolved.pathname, end: !!strict});

    const isActive = !!match;

    const classNameComputed = React.useMemo<string | undefined>(() => {
        if (!className) {
            return undefined;
        } else if (typeof className === 'string') {
            return className;
        } else {
            return className(isActive);
        }
    }, [isActive, className]);

    return (
        <BootstrapNavLink
            as={Link}
            active={!!match}
            to={to}
            className={classNameComputed}
        >
            {children}
        </BootstrapNavLink>
    );
};
