import {selectorFamily, atomFamily} from 'recoil';

import {profileLookupSelector} from 'modules/profile/state/profile-lookup';
import {readProfileSummary} from 'modules/profile/api/profile';
import {IProfileSummary} from 'modules/profile/models/IProfileSummary';
import {profileToProfileSummary} from 'modules/profile/utils';
import {guardRecoilDefaultValue} from 'shared/utils/recoil';
import {profileSummaryMaybeReadSelector} from 'modules/profile/state/profile-summary-maybe';

export const profileSummaryLookupAtom = atomFamily<IProfileSummary | undefined, string>({
    key: 'userSummaryLookupAtom',
    default: undefined,
});

export const profileSummarySelector = selectorFamily<IProfileSummary | undefined, string>({
    key: 'profileSummarySelector',
    get: (profileId) => ({get}) => {
        const currentValue = get(profileSummaryLookupAtom(profileId));
        if (currentValue) {
            return currentValue;
        }

        const profileInLookup = get(profileLookupSelector(profileId));
        if (profileInLookup) {
            return profileToProfileSummary(profileInLookup);
        }

        return undefined;
    },
    set: () => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(profileSummaryLookupAtom(newValue.id), newValue);
    },
});

export const profileSummaryReadSelector = selectorFamily<IProfileSummary, string>({
    key: 'profileSummaryReadSelector',
    get: (profileId) => async ({get}): Promise<IProfileSummary> => {
        const currentValue = get(profileSummarySelector(profileId));
        if (currentValue) {
            return currentValue;
        }
        return await readProfileSummary(profileId);
    },
});

export const profileSummaryBatchSelector = selectorFamily<IProfileSummary[], string[]>({
    key: 'profileSummaryBatchSelector',
    get: (profileIds) => ({get}) => {
        const profileSummaries = profileIds
            .map(profileId => get(profileSummaryMaybeReadSelector(profileId)))
            .filter(profile => !!profile);
        // typescript doesn't understand that we filtered the list above so we must declare its type in the return
        // statement.
        return profileSummaries as IProfileSummary[];
    },
});
