import React from 'react';

import classnames from 'classnames';

import {ReactComponent as CancelIcon} from 'shared/icons/legalmatters/Crossdelete.svg';

interface IImageUploadedContainerProps {
    name: string;
    percentage: boolean | undefined;
    onDelete?: () => void;
}

export const ImageUploadedContainer = ({name, percentage, onDelete}: IImageUploadedContainerProps) => {
    let imageURL;

    if (name.includes('pdf')) {
        imageURL = '/assets/legalmatters/pdf.png';
    } else if (name.includes('.doc') || name.includes('.docx')) {
        imageURL = '/assets/legalmatters/word.png';
    } else if (name.includes('.jpeg')) {
        imageURL = '/assets/legalmatters/jpeg.png';
    } else if (name.includes('.xlsx')) {
        imageURL = '/assets/legalmatters/excel.png';
    } else if (name.includes('.pptx')) {
        imageURL = '/assets/legalmatters/powerpoint.jpg';
    } else if (name.includes('csv')) {
        imageURL = '/assets/legalmatters/csv.jpg';
    } else if (name.includes('png')) {
        imageURL = '/assets/legalmatters/png.jpeg';
    } else if (name.includes('jpg') || name.includes('jpeg')) {
        imageURL = '/assets/legalmatters/jpeg.png';
    } else if (name.includes('eml')) {
        imageURL = '/assets/legalmatters/eml.jpeg';
    }else if (name.includes('svg')) {
        imageURL = '/assets/legalmatters/svg.png';
    }
    const uploadCompleteClassName = classnames('LegalMatter__UploadImg--ProgressBar', {
        'LegalMatter__UploadImg--ProgressBar--active': percentage === true,
    });
    const progressCompleteClassName = classnames('progress-bar', {
        'progress-bar-complete': percentage === false,
    });

    return (
        <>
            <div className="LegalMatter__UploadImg--container">
                <div className="LegalMatter__UploadImg--left">
                    <img src={imageURL} alt="Girl in a jacket" width="35" height="35" />
                </div>
                <div className="LegalMatter__UploadImg--center">
                    <div className="LegalMatter__UploadImg--text">{name}</div>
                    <div className={uploadCompleteClassName}>
                        <div className="progress">
                            <div className={progressCompleteClassName} />
                        </div>
                    </div>
                </div>
                <div className="LegalMatter__UploadImg--right">
                    <CancelIcon className="LegalMatter__UploadImg--Cancel-icon" onClick={() => onDelete?.()} />
                </div>
            </div>
        </>
    );
};
