
import {IAuthPermissions, Scope} from 'shared/auth/models';

export const getAuthPermissions = (scopes: Scope[]): IAuthPermissions => {
    return {
        system: scopes.includes(Scope.System),
        client: scopes.includes(Scope.RoleClient),
        provider: scopes.includes(Scope.RoleProvider),
    };
};

export const getNotAuthenticatedAuthPermissions = (): IAuthPermissions => {
    return {
        system: false,
        client: false,
        provider: false,
    };
};
