import React from 'react';

import {Dropdown} from 'react-bootstrap';
import classNames from 'classnames';
import {useRecoilValueLoadable} from 'recoil';

import {getFullName} from 'modules/profile/utils';
import {IProject} from 'modules/project/models';
import {profileSummaryBatchSelector} from 'modules/profile/state/profile-summary';

import {DropdownItemProps} from 'react-bootstrap/DropdownItem';
import {ProjectMembers} from 'shared/components/project/ProjectMembers/ProjectMembers';

interface IProjectSearchControlSuggestionProps extends DropdownItemProps {
    userId: string;
    project: IProject;
    highlighted: boolean;
}

type DropdownItemType = typeof Dropdown['Item']['prototype'];

export const ProjectSearchControlSuggestion = React.forwardRef<DropdownItemType, IProjectSearchControlSuggestionProps>(({
    userId,
    project,
    highlighted,
    ...rest
}, ref) => {
    const className = classNames('MatterSearchControl__suggestion', {
        'MatterSearchControl__suggestion--active': highlighted,
    });

    const participantSummariesLoadable = useRecoilValueLoadable(profileSummaryBatchSelector(project.member_ids));
    const memberNames = React.useMemo(() => {
        if (project.member_ids.length <= 1) {
            return 'You are the only member';
        }
        if (participantSummariesLoadable.state === 'hasValue') {
            const participantNames = participantSummariesLoadable.contents
                .filter(profileSummary => profileSummary.id !== userId)
                .map(profileSummary => getFullName(profileSummary));
            return participantNames.join(', ') + ' and yourself';
        } else {
            return `Yourself and ${project.member_ids.length} others`;
        }
    }, [participantSummariesLoadable, project.member_ids.length, userId]);

    const description = React.useMemo(() => {
        let computedDescription = memberNames;
        if (!!project.description?.length) {
            computedDescription = `${computedDescription} - ${project.description}`;
        }
        return computedDescription;
    }, [memberNames, project.description]);

    return (
        <Dropdown.Item className={className} ref={ref} {...rest}>
            <div className="MatterSearchControl__suggestion-title">{project.title}</div>
            <div className="d-flex align-items-center">
                <ProjectMembers
                    memberIds={project.member_ids}
                    size="xs"
                    inline
                    disableTooltip
                    className="mr-1"
                    ownerId={project.owner_id}
                />
                <div className="MatterSearchControl__suggestion-description text-muted small d-inline">{description}</div>
            </div>
        </Dropdown.Item>
    );
});
