import React from 'react';

interface IPlainContentPageSectionProps {
    title?: string;
    children?: React.ReactNode;
}

export const PlainContentPageSection = ({children, title}: IPlainContentPageSectionProps) => {
    return (
        <>
            {(title) && (
                <h2 className="PlainContentPageSection__heading">{title}</h2>
            )}
            <div className="PlainContentPageSection__content">
                {children}
            </div>
        </>
    );
};