import React, {useState} from 'react';

import {Modal} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {useSetRecoilState} from 'recoil';

import {IReview} from 'modules/review/models';
import {IReviewForm, reviewForm} from 'modules/review/forms';
import {updateReview} from 'modules/review/api';
import {toastAxiosError} from 'shared/utils/error';
import {toast} from 'shared/utils/toast';
import {reviewListInsertSelector} from 'modules/review/state/review-list';

import {ReviewModalForm} from 'shared/components/review/ReviewModalForm/ReviewModalForm';

interface IUpdateReviewModalProps {
    show: boolean;
    onHide: () => void;
    review: IReview;
}

export const UpdateReviewModal = ({review, show, onHide}: IUpdateReviewModalProps) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const insertReview = useSetRecoilState(reviewListInsertSelector);

    const {handleSubmit, reset, control} = useForm<IReviewForm>({
        resolver: zodResolver(reviewForm),
        defaultValues: {
            rating: review.rating,
            content: review.content,
            author_name: review.author_name,
        },
    });

    const handleHide = () => {
        if (!isSubmitting) {
            onHide();
        }
    };

    React.useEffect(() => {
        if (!show) {
            reset();
        }
    }, [show, reset]);

    const onSubmit = handleSubmit((formData) => {
        (async () => {
            setIsSubmitting(true);
            try {
                const updatedReview = await updateReview(review.id, {
                    author_name: formData.author_name,
                    rating: formData.rating,
                    content: formData.content,
                });
                insertReview(updatedReview);
                reset();
                handleHide();
                toast.success('Review Updated', 'The review was updated successfully');
            } catch (e) {
                toastAxiosError(e, 'Update Review Error');
            } finally {
                setIsSubmitting(false);
            }
        })();
    });

    return (
        <Modal show={show} onHide={handleHide} centered size="lg">
            <Modal.Header closeButton={!isSubmitting}>
                <Modal.Title>
                    Update Review
                </Modal.Title>
            </Modal.Header>
            <ReviewModalForm onSubmit={onSubmit} control={control} isSubmitting={isSubmitting}/>
        </Modal>
    );
};
