import {Params, useParams} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import {postLookupSelector} from '../state/post-lookup';

export const usePostFromPath = () => {
    const params = useParams() as Params<'postId'>;
    const postId = params.postId as string;

    return useRecoilValue(postLookupSelector(postId));
};
