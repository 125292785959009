import React from 'react';

import classNames from 'classnames';

interface IMediaCardFooterProps {
    variant?: 'dashed'
}

export const MediaCardFooter = ({variant, children}: React.PropsWithChildren<IMediaCardFooterProps>) => {
    const className = classNames('MediaCard__footer', {
        [`MediaCard__footer--${variant}`]: variant,
    });

    return (
        <div className={className}>
            {children}
        </div>
    );
};
