import React from 'react';

import {Card} from 'react-bootstrap';

import {IReview} from 'modules/review/models';
import {Scope} from 'shared/auth/models';
import {useAuth} from 'shared/auth/hooks/useAuth';

import {StarRating} from 'shared/components/StarRating/StarRating';
import {ReviewCardControls} from 'shared/components/review/ReviewCard/ReviewCardControls/ReviewCardControls';

import './style.scss';
import {useWindowSize} from 'shared/hooks/useWindowSize';

interface IReviewCardProps {
    author_name: string;
    rating: number;
    content: string;
    className?: string;
    reviewId: string;
    authorId?: string;
    userId: string;
    reviewDetails: IReview;
    editable: boolean;
}

export const ReviewCard = ({
    author_name,
    rating,
    content,
    className,
    reviewId,
    authorId,
    userId,
    reviewDetails,
    editable,
}: IReviewCardProps) => {
    const {tokenData} = useAuth();
    const windowWidth = useWindowSize();

    const isEditable = React.useMemo(() => {
        const isSystemUser = tokenData && tokenData.scopes.includes(Scope.System);
        return editable && (isSystemUser || (tokenData && (tokenData.id === authorId || tokenData.id === userId)));
    }, [tokenData, editable, authorId, userId]);

    return (
        <div className={className}>
            <Card className="ReviewCard">
                <Card.Body>
                    {isEditable && (
                        <ReviewCardControls
                            reviewId={reviewId}
                            authorId={authorId}
                            userId={userId}
                            reviewDetails={reviewDetails}
                        />
                    )}
                    <img
                        className="ReviewCard__quote-icon"
                        src="/assets/profileIcons/Icon awesome-quote-left.png"
                        alt="Review Icon"
                    />
                    <div className={`${windowWidth.width < 1050 ? 'pt-2' : 'pt-4'} ReviewCard__rating`}>
                        <StarRating value={rating}/>
                    </div>
                    <div className="ReviewCard__summary">{content}</div>
                    <hr/>
                    <div className="ReviewCard__author">-{author_name}</div>
                </Card.Body>
            </Card>
        </div>
    );
};
