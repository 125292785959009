import React from 'react';

import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IDropdownCaretProps extends Pick<FontAwesomeIconProps, 'size' | 'className'> {
    toggled?: boolean;
}

export const DropdownCaret = ({toggled, className, ...props}: IDropdownCaretProps) => {
    return <FontAwesomeIcon className={`DropdownCaret ${className || ''}`} icon={!toggled ? faCaretDown : faCaretUp} {...props} />;
};

DropdownCaret.defaultProps = {
    size: 'sm',
};
