import React from 'react';

import {Control, Controller, FieldValues, Path} from 'react-hook-form';

import {TimeSlotControl} from 'shared/components/form/TimeSlotControl/TimeSlotControl/TimeSlotControl';
import {TimeSlotControllerContent} from 'shared/components/form/TimeSlotControl/TimeSlotControllerContent';
import Skeleton from 'react-loading-skeleton';
import {defaultAustraliaTimezone} from 'shared/utils/date/australian-timezones';

export interface ITimeSlotControllerProps<FormType extends FieldValues> {
    name: Path<FormType>;
    control: Control<FormType>;
    className?: string;
    userId: string;
    disabled?: boolean;
}

export const TimeSlotController = <FormType extends FieldValues>({
    name,
    control,
    userId,
    className,
    disabled,
}: ITimeSlotControllerProps<FormType>) => {
    const [currentDate, setCurrentDate] = React.useState<Date>(new Date());

    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value}, fieldState}) => (
                <React.Suspense
                    fallback={
                        <TimeSlotControl
                            className={className}
                            value={value}
                            onChange={() => null}
                            currentDate={currentDate}
                            setCurrentDate={() => null}
                            availableTimeSlots={[]}
                            disabled
                            emptyContent={
                                <div className="d-flex flex-wrap justify-content-center px-3">
                                    {[...Array(14).keys()].map((itm: number, index: number) => (
                                        <React.Fragment key={index}>
                                            <Skeleton
                                                style={{
                                                    margin: '5px',
                                                    width: '102px',
                                                    height: '40px',
                                                    borderRadius: '13px',
                                                }}
                                            />
                                        </React.Fragment>
                                    ))}
                                </div>
                            }
                            invalid={!!fieldState.error}
                            availabilityTimezone={defaultAustraliaTimezone}
                            displayTimezone={defaultAustraliaTimezone}
                        />
                    }
                >
                    <TimeSlotControllerContent
                        userId={userId}
                        className={className}
                        value={value}
                        onChange={(value) => onChange(value)}
                        errorMessage={fieldState.error?.message}
                        disabled={disabled}
                        currentDate={currentDate}
                        setCurrentDate={setCurrentDate}
                    />
                </React.Suspense>
            )}
        />
    );
};
