import React from 'react';

import {Navigate, useLocation} from 'react-router-dom';

import {Scope} from 'shared/auth/models';
import {useAuth} from 'shared/auth/hooks/useAuth';
import {usePermissionsLoadable} from 'shared/auth/hooks/usePermissionsLoadable';

import {RouteSpinner} from 'auth/RouteSpinner/RouteSpinner';

interface IRequireAuthContentProps {
    scopes?: Scope[];
}

export const RequireAuthContent = ({scopes, children}: React.PropsWithChildren<IRequireAuthContentProps>) => {
    const {isAuthenticated, isLoading} = useAuth();
    const location = useLocation();
    const {scopes: authScopes, loading, error} = usePermissionsLoadable(isAuthenticated);

    const isCorrectScopes = React.useMemo<boolean>(() => {
        if (isAuthenticated && authScopes && !isLoading && !loading && !error) {
            return (
                !scopes ||
                (scopes.every(scope => authScopes.includes(scope)))
            );
        } else {
            return false;
        }
    }, [authScopes, loading, isLoading, error, scopes, isAuthenticated]);

    // NOTE: This is important and must come first to avoid mounting anything because we have auth state
    if (isLoading || loading) {
        return (
            <RouteSpinner/>
        );
    }

    if (isAuthenticated && isCorrectScopes && !error) {
        return (
            <RouteSpinner>
                {children}
            </RouteSpinner>
        );
    } else {
        const from = location.pathname.startsWith('/login') ? '/dashboard' : location.pathname + location.search;
        return (
            <Navigate
                to="/login"
                state={{
                    from,
                }}
            />
        );
    }
};
