import {IHelpSearchDocument, HelpSearchKind} from 'modules/help/models';

export const getURLForHelpSearchDocument = (document: IHelpSearchDocument): string => {
    switch (document.kind) {
        case HelpSearchKind.ARTICLE:
            return `/help-center/${document.category_id}/${document.id}`;
        case HelpSearchKind.CATEGORY:
            return `/help-center/${document.id}`;
        default:
            throw new Error(`Unrecognized help search document kind: ${document.kind}`);
    }
};
