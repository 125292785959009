import {projectLookupMaybeSelector, projectLookupMaybeReadSelector} from 'modules/project/state/project-lookup';
import {useRecoilLoadable} from 'shared/hooks/useRecoilLoadable';

export const useProjectMaybeLoadable = (projectId: string | undefined) => {
    const {data, loading, error} = useRecoilLoadable({
        state: projectLookupMaybeSelector(projectId),
        loadable: projectLookupMaybeReadSelector(projectId),
    });
    return {
        project: data,
        loading,
        error,
    };
};
