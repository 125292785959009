import axios from 'axios';

import {IIssueListQuery, IIssueListResult} from 'modules/issue/models';
import {Config} from 'config';

const getURLForIssueAPI = (path: string) => {
    return `${Config.mainServiceURL}/issues${path}`;
};

export const readIssueList = async (params?: IIssueListQuery): Promise<IIssueListResult> => {
    const response = await axios.get(getURLForIssueAPI('/'), {
        params,
    });
    return response.data;
};
