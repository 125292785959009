import React from 'react';

import {Button, Container} from 'react-bootstrap';

import {useAuth} from 'shared/auth/hooks/useAuth';
import {useReviewListLoadable} from 'modules/review/hooks/useReviewListLoadable';
import {useBool} from 'shared/hooks/useBool';

import {CreateReviewModal} from 'shared/components/review/CreateReviewModal/CreateReviewModal';
import {ReviewsCarousel} from 'shared/components/ProfilePageContent/ProfilePageReviews/ReviewsCarousel';
import {ProfilePageEmpty} from 'shared/components/ProfilePageContent/shared/ProfilePageEmpty/ProfilePageEmpty';
import {ProfilePageSectionHeader} from 'shared/components/ProfilePageContent/shared/ProfilePageSectionHeader/ProfilePageSectionHeader';
import {useWindowSize} from 'shared/hooks/useWindowSize';
import {Plus} from 'shared/icons/Homepage';

interface IProfilePageReviewsProps {
    id: string;
    firstname: string;
}

export const ProfilePageReviews = ({id, firstname}: IProfilePageReviewsProps) => {
    const {isAuthenticated} = useAuth();
    const [showCreateModal, setShowCreateModal] = useBool();
    const {width} = useWindowSize();
    const {reviewList, loading, error} = useReviewListLoadable({
        filters: {
            userId: id,
            limit: 20,
        },
        page: 0,
    });

    return (
        <Container style={{position: 'relative'}} className="mb-4">
            <ProfilePageSectionHeader
                title={`${firstname}'s Reviews`}
                description={`What people are saying about ${firstname}`}
            >
                {isAuthenticated && (
                    <>
                        {width > 450 ? (
                            <Button type="button" variant="outline-primary" onClick={() => setShowCreateModal(true)}>
                                Add Review
                            </Button>
                        ) : (
                            <Plus className="add_resources" onClick={() => setShowCreateModal(true)} />
                        )}
                    </>
                )}
            </ProfilePageSectionHeader>
            {(loading || error || !!reviewList?.reviews?.length) && (
                <ReviewsCarousel
                    firstname={firstname}
                    reviews={reviewList?.reviews}
                    loading={loading}
                    error={error ? 'Error loading reviews' : undefined}
                    editable={true}
                />
            )}
            {!loading && !error && reviewList && !reviewList.reviews?.length && (
                <ProfilePageEmpty>
                    <div className="ProfilePageAbout--empty justify-content-center align-content-center">
                        <p className="NoData mb-0">{firstname} has no reviews yet</p>
                        <img src="/assets/profileIcons/review.svg" className="AboutmeIMG" alt="" />
                    </div>
                    {/* {firstname} has no reviews yet */}
                </ProfilePageEmpty>
            )}
            {isAuthenticated && (
                <CreateReviewModal userId={id} onHide={() => setShowCreateModal(false)} show={showCreateModal} />
            )}
        </Container>
    );
};
