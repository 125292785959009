import React from 'react';

import {IProfile} from 'modules/profile/models/IProfile';

import {ReactComponent as CancelIcon} from 'shared/icons/legalmatters/cancel.svg';
import {Edit} from 'shared/icons/EditProfile';
import {EditClaimToFameModal} from 'shared/components/ProfilePageContent/ProfilePageDetails/EditClaimToFameModal';
import {useWindowSize} from 'shared/hooks/useWindowSize';

interface IClaimToFameProps {
    profile: IProfile;
    claim_to_fame: string | undefined;
    isEditable?: boolean;
}

export const ClaimToFame = ({profile, isEditable, claim_to_fame}: IClaimToFameProps) => {
    const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
    const windowWidth = useWindowSize();

    return (
        <>
            {claim_to_fame && claim_to_fame.length && (
                <div
                    className={`${
                        windowWidth.width < 769 ? 'pl-0' : 'pl-3'
                    } ml-3 ProfilePageDetails__flex--space-between`}
                >
                    <div>
                        <div className="text-preserve-whitespace">{profile.claim_to_fame}</div>
                    </div>
                    {isEditable && (
                        <div
                            className="ProfilePageDetails__icon--medium-edit ml-2"
                            onClick={() => setShowEditModal(true)}
                        >
                            <Edit />
                        </div>
                    )}
                </div>
            )}
            {isEditable && (
                <EditClaimToFameModal open={showEditModal} onHide={() => setShowEditModal(false)} profile={profile} />
            )}
            {!claim_to_fame && (
                <>
                    {isEditable && (
                        <div
                            className="ProfilePageDetails__icon--add-icon text-right"
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowEditModal(true);
                            }}
                        >
                            <CancelIcon />
                        </div>
                    )}
                    <div className="text-center">
                        <img
                            src="/assets/profileIcons/experiences.svg"
                            alt="experiences"
                            className="exceptions-image"
                        />
                    </div>
                </>
            )}
        </>
    );
};
