import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';

import {ServiceType} from 'modules/profile/models/serviceType';
import {FeeKind} from 'shared/models/fee-kind/FeeKind';
import {IProfile, Role} from 'modules/profile/models/IProfile';
import {getFullName, getProfessionTitle} from 'modules/profile/utils';

import {ProfileCardFee} from 'shared/components/ProfileCard/ProfileCardFee/ProfileCardFee';
import {FavoriteStar} from 'shared/components/FavoriteStar/FavoriteStar';
import {FixedFeesLabel} from 'shared/components/FixedFeesLabel/FixedFeesLabel';

import './style.scss';

interface IProfileCardProps {
    profile: IProfile;
    onClickDetail?: () => void;
    currentRole?: Role;
}

export const ProfileCardDetails = ({currentRole, profile, onClickDetail}: IProfileCardProps) => {
    const fullName = getFullName(profile);
    const professionTitle = profile.profession ? getProfessionTitle(profile.profession) : null;
    const isClient = currentRole === Role.Client;
    const hasFixedFees = profile?.service_types?.includes?.(ServiceType.FixedFees);

    return (
        <div onClick={onClickDetail} className="ProfileCard__details d-flex justify-content-between">
            <div>
                <div className="ProfileCard__profession">{professionTitle || 'undefined'}</div>
                <div className="ProfileCard__title">{fullName}</div>
                <div className="ProfileCard__location">
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="ProfileCard__location-icon" />
                    {profile?.contact_details_display?.city}
                </div>
            </div>
            <div className="flex_colum_end">
                {isClient && (
                    <div className="text_align_end ProfileCard__star_icon">
                        <FavoriteStar profileId={profile.id} />
                    </div>
                )}
                <div className={hasFixedFees ? 'flex_row_center mobileDayHour Hourly_rate_responsive' : 'flex_center'}>
                    {!!profile.service_details?.hour_rate && profile.service_details.hour_rate > 0 && (
                        <div className="text_align_end">
                            <ProfileCardFee amount={profile.service_details?.hour_rate as number} kind={FeeKind.Hour} />
                        </div>
                    )}
                    <div className="text_align_end">
                        {!!profile.service_details?.day_rate && profile.service_details.day_rate > 0 && (
                            <ProfileCardFee amount={profile.service_details?.day_rate as number} kind={FeeKind.Day} />
                        )}
                        <div className="fixed_fee_enum_div">
                            {hasFixedFees && (
                                <FixedFeesLabel
                                    id={profile.id}
                                    fixedFeeServices={profile.service_details?.fixed_fee_services ?? []}
                                    noText
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
