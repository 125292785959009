import { useEffect } from 'react';

import { useSetRecoilState } from 'recoil';

import { navbarState } from 'shared/state/navbar';

export const useNavbar = (showNavbar: boolean) => {
    const setShowNavbar = useSetRecoilState(navbarState);

    useEffect(() => {
        setShowNavbar(showNavbar);
    }, [setShowNavbar, showNavbar]);
};
