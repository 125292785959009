import React from 'react';

import {useRecoilState, useRecoilValueLoadable} from 'recoil';

import {articleListAtom, articleListReadSelector, IArticleListStateFilters} from '../state/article-list';

export const useArticleList = (filters: IArticleListStateFilters) => {
    const [articleList, setCategoryList] = useRecoilState(articleListAtom);
    const articleListLoadable = useRecoilValueLoadable(articleListReadSelector(filters));

    React.useEffect(() => {
        if (articleListLoadable.state === 'hasValue') {
            setCategoryList(articleListLoadable.contents);
        }
    }, [articleListLoadable, setCategoryList]);

    if (articleList) {
        return articleList.articles;
    } else if (articleListLoadable.state === 'hasValue') {
        return articleListLoadable.contents.articles;
    } else if (articleListLoadable.state === 'loading') {
        throw articleListLoadable.promiseOrThrow();
    } else {
        throw articleListLoadable.errorOrThrow();
    }
};
