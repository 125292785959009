import React from 'react';

import {Tab, Tabs} from 'react-bootstrap';

import {IWorkExperience} from 'modules/profile/models/IWorkExperience';
import {IProfile} from 'modules/profile/models/IProfile';

import {Experiences} from 'shared/components/ProfilePageContent/ProfilePageDetails/Experiences';
import {ClaimToFame} from 'shared/components/ProfilePageContent/ProfilePageDetails/ClaimToFame';

import './style.scss';

interface IProfilePageDetailsProps {
    workExperience: IWorkExperience[];
    profile: IProfile;
    isEditable?: boolean;
}

export const ProfilePageDetails = ({workExperience, profile, isEditable}: IProfilePageDetailsProps) => {
    return (
        <div className="container-fluid ">
            <div className="ProfilePageDetails ">
                <div className="navbarSi">
                    <Tabs defaultActiveKey="topCases" id="uncontrolled-tab-example">
                        <Tab eventKey="topCases" title="Experience">
                            <Experiences isEditable={isEditable} profile={profile} workExperience={workExperience} />
                        </Tab>
                        <Tab eventKey="lookAtMe" title="Claim to Fame">
                            <ClaimToFame
                                isEditable={isEditable}
                                profile={profile}
                                claim_to_fame={profile?.claim_to_fame}
                            />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};
