export interface ITimezone {
    name: string;
    code: string;
    offset: string;
    exampleCity: string;
}

export const defaultAustraliaTimezone = 'Australia/Sydney';

export const australianTimezones: ITimezone[] = [
    {
        name: 'Australian Western Standard Time',
        offset: 'UTC+08:00',
        code: 'Australia/Perth',
        exampleCity: 'Perth',
    },
    {
        name: 'Australian Central Western Standard Time',
        offset: 'UTC+08:45',
        code: 'Australia/Eucla',
        exampleCity: 'Eucla',
    },
    {
        name: 'Australian Central Standard Time',
        offset: 'UTC+09:30',
        code: 'Australia/Adelaide',
        exampleCity: 'Adelaide',
    },
    {
        name: 'Australian Eastern Standard Time',
        offset: 'UTC+10:00',
        code: 'Australia/Sydney',
        exampleCity: 'Sydney',
    },
    {
        name: 'Lord Howe Standard Time',
        offset: 'UTC+10:30',
        code: 'Australia/Lord_Howe',
        exampleCity: 'Lord Howe Island',
    },
];
