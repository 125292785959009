import React, { useRef, useState } from 'react';

import Slick, { Settings } from 'react-slick';

import { CardCarousel } from 'shared/components/CardCarousel/CardCarousel';
import { useWindowSize } from 'shared/hooks/useWindowSize';
import { MediaCardDeck } from 'shared/components/MediaCard/MediaCardDeck/MediaCardDeck';

import './style.scss';

export const HomepageSlider = () => {
    const windowSize = useWindowSize();
    const [IndexForSlide, setIndexForSlide] = useState<number>(0);
    const sliderRef = useRef<Slick | null>(null);
    const slickSettings: Settings = {
        draggable: true,
        arrows: false,
        dots: windowSize.width >= 600,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        speed: 1000,
        beforeChange: (prev, next) => {
            setIndexForSlide(next);
        },
    };

    return (
        <div className="HomepageSlider">
            <div className="container">
                <CardCarousel
                    onNext={() => sliderRef && sliderRef.current && sliderRef.current.slickNext()}
                    onPrev={() => sliderRef && sliderRef.current && sliderRef.current.slickPrev()}
                    hasPrev={IndexForSlide > 0}
                    hasMore={IndexForSlide < 5}
                    isChevrons={true}
                >
                    <MediaCardDeck className={'homepageSlider'}>
                        <Slick ref={sliderRef} {...slickSettings}>
                            <img src="/assets/homepage/HomeSliderI1.png" alt="HomeSliderI1" />
                            <img src="/assets/homepage/slide2.png" alt="HomeSliderI1" />
                            <img src="/assets/homepage/slide4.png" alt="HomeSliderI1" />
                            <img src="/assets/homepage/slide3.png" alt="HomeSliderI1" />
                            <img src="/assets/homepage/slide1.png" alt="HomeSliderI1" />
                        </Slick>
                    </MediaCardDeck>
                </CardCarousel>
            </div>
            <div className="HomepageSlider--background"/>
        </div>
    );
};
