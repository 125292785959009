import {atom, selector, selectorFamily} from 'recoil';

import {IProfile} from 'modules/profile/models/IProfile';
import {IProfileListQuery} from 'modules/profile/models/IProfileListQuery';
import {readProfileList} from 'modules/profile/api/profile';
import {CursorList} from 'shared/types/cursor-list';
import {compareProfileFilters} from '../utils';
import {throwWriteOnlySelectorError, guardRecoilDefaultValue} from 'shared/utils/recoil';

export interface IProfileListStateFilters {
    filters: IProfileListQuery;

    [key: string]: IProfileListQuery;
}

interface IProfileListState {
    filters: IProfileListQuery;
    profiles: IProfile[];
    nextCursor?: string;
    currentCursor?: string;
    resetVersion: number;

    [key: string]: number | IProfile[] | CursorList | IProfileListQuery | string | undefined;
}

export const profileListAtom = atom<IProfileListState | undefined>({
    key: 'profileListAtom',
    default: undefined,
});

export const profileListResetAtom = atom<number>({
    key: 'profileListResetAtom',
    default: 0,
});

export const profileListSelector = selectorFamily<IProfileListState | undefined, IProfileListStateFilters>({
    key: 'profileListSelector',
    get: ({filters}) => ({get}) => {
        const atomValue = get(profileListAtom);
        const resetVersion = get(profileListResetAtom);
        if (
            atomValue &&
            compareProfileFilters(filters, atomValue.filters) &&
            atomValue.resetVersion === resetVersion
        ) {
            return {...atomValue};
        }

        return undefined;
    },
    set: () => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        set(profileListAtom, newValue);
    },
});

export const profileListReadSelector = selectorFamily<IProfileListState, IProfileListStateFilters>({
    key: 'profileListReadSelector',
    get: ({filters}) => async ({get}) => {
        const currentValue = get(profileListSelector({filters}));
        if (currentValue) {
            return currentValue;
        }

        const atomValue = get(profileListAtom);
        const resetVersion = get(profileListResetAtom);

        // load a fresh page from the server
        const result = await readProfileList(filters);

        return {
            filters,
            profiles: result.profiles,
            nextCursor: result.nextCursor,
            currentCursor: atomValue?.nextCursor,
            resetVersion,
        } as IProfileListState;
    },
});

export const profileListLookupSelector = selectorFamily<IProfile | undefined, string>({
    key: 'profileListLookupSelector',
    get: (profileId) => ({get}) => {
        const atomValue = get(profileListAtom);
        return atomValue?.profiles?.find(profile => profile.id === profileId);
    },
});

export const profileListInsertSelector = selector<IProfile>({
    key: 'profileListInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        const atomValue = get(profileListAtom);
        if (!atomValue) {
            set(profileListResetAtom, get(profileListResetAtom) + 1);
            return;
        }

        // if the profile is present in the store then update it. Otherwise, force a reload.
        const profileIndex = atomValue.profiles.findIndex(profile => profile.id === newValue.id);
        if (profileIndex !== -1) {
            const newProfiles = Array.from(atomValue.profiles);
            newProfiles.splice(profileIndex, 1, newValue);
            set(profileListAtom, {
                ...atomValue,
                profiles: newProfiles,
            });
        }
    },
});
