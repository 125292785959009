import React from 'react';

import {useNotificationListLoadable} from 'modules/notification/hooks/useNotificationListLoadable';

import './style.scss';

export const NavbarNotificationBadge = () => {
    const {notificationList} = useNotificationListLoadable();
    const unseenNotification = notificationList?.notifications?.filter(notification => !notification.seen);

    return (
        <>
            {!!unseenNotification?.length && (
                <span className="NavbarNotifications__badge badge badge-danger">&nbsp;</span>
            )}
        </>
    );
};
