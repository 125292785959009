import React from 'react';

import {useDownloadURL} from 'modules/file/hooks/useDownloadURL';

interface IFileIDImageProps {
    fileId: string;
    className?: string;
    alt: string;
    publicUrl?: string;
}

export const FileIDImage = ({fileId, className, alt, publicUrl}: IFileIDImageProps) => {
    const downloadURL = useDownloadURL({
        id: fileId,
    });

    return (
        <img src={publicUrl ?? downloadURL} alt={alt} className={className}/>
    );
};
