import {useNavbar} from 'shared/hooks/useNavbar';

import {AppFooter} from 'shared/components/AppFooter/AppFooter';
import {JusticeEcosystemSection} from './JusticeecosystemSection/JusticeEcosystemSection';
import {OurGuidingPrinciples} from './OurGuidingPrinciples/OurGuidingPrinciples';
import {LegalPracticeSection} from './LegalpracticeSection/LegalPracticeSection';
import {OurPeopleAllCard} from './OurPeopleAllCard/OurPeopleAllCard';
import {GifInEcoSystem} from './GifInEcoSystem/GifInEcoSystem';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';
import {AdvisoryAllCard} from './AdvisoryMembers/AdvisoryAllCard';

import './style.scss';


export const AboutUsPage = () => {
    useNavbar(true);

    return (
        <div className="AboutUsPage">
            <PageHelmet
                title="About Us"
                description="Rightful is a Legal Practice, re-imagined for the 21st century"
            />
            <JusticeEcosystemSection/>
            <OurGuidingPrinciples/>
            <LegalPracticeSection/>
            <GifInEcoSystem/>
            <OurPeopleAllCard/>
            <AdvisoryAllCard />
            <AppFooter subscribe={true}/>
        </div>
    );
};
