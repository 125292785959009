import React from 'react';

import {Container} from 'react-bootstrap';

import {useNavbar} from 'shared/hooks/useNavbar';

import {AppFooter} from 'shared/components/AppFooter/AppFooter';
import {GuideToChoosingQNA} from './GuideToChoosingQNA/GuideToChoosingQNA';
import {GuideToChoosingSymbols} from './GuideToChoosingSymbols/GuideToChoosingSymbols';

import './style.scss';

const GuideToChoosingPage = () => {
    useNavbar(true);

    return (
        <>
            <div className="GuideToChoosingPage__header">
                <h2 className="GuideToChoosingPage__title">
                    Guide to choosing your
                    <br /> legal team
                </h2>
            </div>
            <Container>
                <div className="GuideToChoosingQNA">
                    <div className="GuideToChoosingPage__paragraph">
                        It can be quite daunting selecting all the professionals typically involved in a legal project.
                        To add to the confusion, sometimes their roles overlap. We have created this handy guide to make
                        it easier for you to put your legal dream team together.
                    </div>
                </div>
                <GuideToChoosingQNA title="When do I need a Barrister?">
                    Barristers specialise in appearing in Court. They wear the wigs and black gowns. But these days they
                    also do other things like drafting legal documents and providing advice. It is often great to get
                    them in early at the start of a legal project to scope it effectively. Many organisations also find
                    that Barristers are a cost effective alternative to engaging a panel law firm. They often have
                    highly specialised knowledge in their chosen subject matter. Be aware that if a Barrister is engaged
                    directly without a Solicitor, they have to comply with certain constraints under the Bar Rules. For
                    example, they cannot file documents or send correspondence on your behalf to anyone other than a
                    case opponent (they can draft it for you though). The rationale is that they cannot risk being a
                    witness in the case they are appearing in. Some jurisdictions like South Australia have rules that
                    prohibit Barristers being engaged directly without a solicitor. If you have a question about how the
                    Bar Rules apply, ask the Barrister directly.
                </GuideToChoosingQNA>
                <GuideToChoosingQNA title="When do I need a Solicitor?">
                    If lawyers were doctors, Solicitors would be your GP. But that these days many of them specialise
                    and can be recognised for their unique expertise. Look out for the accredited specialist symbol on
                    their profile. They can also be extremely capable trial advocates. Unlike Barristers, they are
                    allowed to hire other lawyers and do things like filing and correspondence on your behalf
                    (Barristers can only correspond with the opponent on the other side on your behalf). If your case is
                    complex it is useful to retain a mixture of Solicitors and Barristers.
                </GuideToChoosingQNA>
                <GuideToChoosingQNA title="When do I need a Paralegal?">
                    Paralegals support legal professionals. They are great for research, admin and organisation. They
                    are also especially useful in complex legal projects that require many hands. They often have law
                    degrees, but be aware that they cannot give you legal advice or do all of the things that a lawyer
                    can do. All paralegals on our platform are engaged on a per matter basis, and are independent
                    contractors and/or employed by organisations outside of Rightful.
                </GuideToChoosingQNA>
            </Container>
            <GuideToChoosingSymbols />
            <AppFooter />
        </>
    );
};

export default GuideToChoosingPage;
