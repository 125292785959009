import React from 'react';

import { Control, useFieldArray } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { CaseChartUser, ICaseChart } from 'modules/case-chart/models';

import { InputController } from 'shared/components/form/InputController/InputController';
import { CaseEstimatorFormBranch } from 'shared/components/CaseEstimatorForm/CaseEstimatorFormBranch';

import './style.scss';

interface ICaseEstimatorFormMilestoneProps {
    name: `milestones.${number}`;
    index: number;
    disabled?: boolean;
    onDelete: (index: number) => void;
    control: Control<ICaseChart>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CaseEstimatorFormMilestone = ({
    name,
    index,
    disabled,
    onDelete,
    control,
}: ICaseEstimatorFormMilestoneProps) => {
    const {fields: branchFields, append: appendBranch, remove: removeBranch} = useFieldArray({
        control,
        name: `${name}.branches`,
    });

    const addBranch = () => {
        appendBranch({
            name: '',
            owner: CaseChartUser.Barrister,
            price: undefined,
        });
    };

    return (
        <>
            <Form.Group controlId="BookingForm__title">
                <Form.Label>Milestone</Form.Label>
                <Form.Row>
                    <InputController
                        control={control}
                        name={`${name}.name`}
                        placeholder="Name"
                        disabled={disabled}
                    />
                    <Button variant="danger" onClick={() => onDelete(index)}>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </Form.Row>
            </Form.Group>
            {branchFields.map((branchField, branchIndex: number) => (
                <CaseEstimatorFormBranch
                    key={branchField.id}
                    name={`${name}.branches.${branchIndex}`}
                    index={branchIndex}
                    control={control}
                    onDelete={removeBranch}
                />
            ))}
            <Button type="button" variant="featured" onClick={addBranch}>
                Add Branch
            </Button>
        </>
    );
};
