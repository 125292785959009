import {atom, selector} from 'recoil';

export const currentUserIdAtom = atom<string | undefined>({
    key: 'currentUserIdAtom',
    default: undefined,
});

export const guaranteedCurrentUserIdSelector = selector<string>({
    key: 'guaranteedCurrentUserIdSelector',
    get: ({get}) => {
        const currentUserId = get(currentUserIdAtom);
        if (!currentUserId) {
            throw new Error('You are not signed in');
        }
        return currentUserId;
    },
});
