import React from 'react';

import {Modal as BootstrapModal} from 'react-bootstrap';

import {ModalHeader} from 'shared/components/modals/Modal/ModalHeader';

import './style.scss';

interface IModalProps {
    show: boolean;
    centered?: boolean;
    size?: 'sm' | 'lg' | 'xl';
    title?: string;
    isSubmitting?: boolean;
    onHide: () => void;
    onShow?: () => void;
    className?: string;
}

export const Modal = ({
    centered,
    children,
    isSubmitting,
    onHide,
    show,
    size,
    title,
    className,
    onShow,
}: React.PropsWithChildren<IModalProps>) => {
    const handleHide = () => {
        if (isSubmitting) {
            return;
        }
        onHide();
    };

    return (
        <BootstrapModal
            size={size}
            centered={centered}
            show={show}
            onHide={handleHide}
            animation={true}
            backdrop={isSubmitting ? 'static' : true}
            className={className ? className : ''}
            onShow={onShow}
        >
            {title && (
                <ModalHeader onHide={handleHide} isSubmitting={isSubmitting}>
                    {title}
                </ModalHeader>
            )}
            {children}
        </BootstrapModal>
    );
};
