import React from 'react';

import {add, format, isSameDay, isSameMonth, set, isBefore, sub, endOfMonth} from 'date-fns';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

import {PrevSlickMob, NextSlickMob} from 'shared/icons/Homepage';
import {DaySelectDay} from 'shared/components/form/DaySelect/DaySelectDay';

import './style.scss';

interface IDaySelectDate {
    id: string;
    date: Date;
    day: number;
    title: string;
    disabled: boolean;
}

interface ITimeSlotControlDayColumnProps {
    date: Date;
    onChange: (value: Date) => void;
    disabled?: boolean;
    invalid?: boolean;
}

export const DaySelect = ({onChange, date, disabled, invalid}: ITimeSlotControlDayColumnProps) => {
    const sliderRef = React.useRef<HTMLDivElement | null>(null);
    const [currentMonth, setCurrentMonth] = React.useState<Date | undefined>(undefined);

    React.useEffect(() => {
        // If the month changes or the component is mounted for the first time, then set the scroll position so that
        // the selected day is visible.

        if (!sliderRef.current) {
            // the scrolling DOM element was not found
            // TODO: handle not finding the element. If that happens we may need to use a setTimeout to wait for it to
            //  mount
            return;
        }
        if (currentMonth && isSameMonth(currentMonth, date)) {
            // the selected month has not changed, no need to do anything, exit
            return;
        }

        // Set the initial scroll position to the active day
        // find the position of the active day element in the dom and scroll sliderRef to that position
        const activeDay = sliderRef.current.querySelector('.DaySelect__day--active');
        if (!activeDay) {
            // the selected day element is not found, exit
            return;
        }

        // only set the current month after the `activeDay` element is found. This will give this hook a second chance
        // to run in case `activeDay` is not found the first time
        setCurrentMonth(date);

        // temporarily disable smooth scroll behavior when setting the scroll position
        const initialScrollBehavior = sliderRef.current.style.scrollBehavior;
        sliderRef.current.style.scrollBehavior = 'auto';
        sliderRef.current.scrollLeft = (
            activeDay.getBoundingClientRect().left - sliderRef.current.getBoundingClientRect().left
        );
        sliderRef.current.style.scrollBehavior = initialScrollBehavior;
    }, [sliderRef, currentMonth, date]);

    const daysInMonth = React.useMemo<IDaySelectDate[]>(() => {
        const today = new Date();
        const days = [];
        let day = set(date, {date: 1});
        while (isSameMonth(day, date)) {
            days.push({
                id: format(day, 'dd/MM/yyyy'),
                date: day,
                day: day.getDate(),
                title: format(day, 'EEE'),
                disabled: isBefore(day, today) && !isSameDay(day, today),
            });
            day = add(day, {days: 1});
        }
        return days;
    }, [date]);

    const getScrollAmount = () => {
        // Returns the size of a "DaySelect__day" element, so when we click the arrows to scroll it moves the exactly
        // `nDaysToScroll` days

        const fallbackElementWidth = 56; // in case the selected day element is not found
        const nDaysToScroll = 4;

        const dayElement = sliderRef.current?.querySelector('.DaySelect__day') as HTMLElement | undefined;
        if (!dayElement) {
            return fallbackElementWidth * nDaysToScroll;
        }

        let elementWidth = dayElement.clientWidth;
        const style = window.getComputedStyle(dayElement);
        if (style.marginLeft) {
            const marginLeft = parseFloat(style.marginLeft);
            if (!isNaN(marginLeft)) {
                elementWidth += marginLeft;
            }
        }
        return elementWidth * nDaysToScroll;
    };

    const onSlideLeft = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        if (!sliderRef.current || disabled) {
            return;
        }

        if (sliderRef.current.scrollLeft === 0) {
            onChange(endOfMonth(sub(date, {months: 1})));
        } else {
            sliderRef.current.scrollLeft -= getScrollAmount();
        }
    };

    const onSlideRight = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        const scrollContent = sliderRef.current?.querySelector('.DaySelect__days-content') as HTMLElement | undefined;
        if (!sliderRef.current || !scrollContent || disabled) {
            return;
        }

        const scrollLeft = sliderRef.current.scrollLeft;
        const maxScrollLeft = scrollContent.clientWidth - sliderRef.current.clientWidth;
        // The reason we use `scrollLeft + 1` is because scrollLeft can reach 1049.599 when the width is 1050 and
        // not go any further, so it's technically at the end
        const isAtEndOfScroll = (scrollLeft + 1 > maxScrollLeft);

        if (isAtEndOfScroll) {
            onChange(set(add(date, {months: 1}), {date: 1}));
        } else {
            sliderRef.current.scrollLeft += getScrollAmount();
        }
    };

    const navButtonClassName = classNames('DaySelect__nav-btn', {
        'DaySelect__nav-btn--disabled': disabled,
    });

    return (
        <div className="DaySelect">
            <Link to="#" className={navButtonClassName} onClick={onSlideLeft}>
                <PrevSlickMob className="DaySelect__nav-btn-icon"/>
            </Link>
            <div className="DaySelect__days-wrapper" ref={sliderRef}>
                <div className="DaySelect__days-content">
                    {daysInMonth.map(day => (
                        <DaySelectDay
                            key={day.id}
                            active={isSameDay(day.date, date)}
                            onClick={() => onChange(day.date)}
                            day={day.day}
                            title={day.title}
                            inactive={day.disabled}
                            disabled={disabled}
                            invalid={invalid}
                        />
                    ))}
                </div>
            </div>
            <Link to="#" className={navButtonClassName} onClick={onSlideRight}>
                <NextSlickMob className="DaySelect__nav-btn-icon"/>
            </Link>
        </div>
    );
};
