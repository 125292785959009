import React from 'react';

import {ICategory, CategoryIcon} from 'modules/help/models';

import {
    FileSvg,
    BookingSvg,
    CalendarSvg,
    LawyerSvg,
    InvoiceSvg,
    SetupcomputerSvg,
    SecureSvg,
    VirusSvg,
    SetupSvg,
} from 'shared/icons/HelpCentre';

interface IHelpCenterPictureProps {
    category: ICategory;
}

export const HelpCenterPicture = ({category}: IHelpCenterPictureProps) => {

    const IconComponent = React.useMemo(() => {
        if (!category.picture?.icon) {
            return FileSvg;
        }
        switch (category.picture.icon) {
            case CategoryIcon.BOOKING:
                return BookingSvg;
            case CategoryIcon.FILE:
                return FileSvg;
            case CategoryIcon.CALENDAR:
                return CalendarSvg;
            case CategoryIcon.LAWYER:
                return LawyerSvg;
            case CategoryIcon.INVOICE:
                return InvoiceSvg;
            case CategoryIcon.COMPUTER_SETTINGS:
                return SetupcomputerSvg;
            case CategoryIcon.SECURE:
                return SecureSvg;
            case CategoryIcon.VIRUS:
                return VirusSvg;
            case CategoryIcon.USER_COG:
                return SetupSvg;
        }
    }, [category.picture]);

    return (
        <IconComponent className="HomeAboutpage--icon"/>
    );
};
