import React from 'react';

import {Helmet} from 'react-helmet';

interface IPageHelmetProps {
    title: string;
    description?: string;
    children?: React.ReactNode;
    noindex?: boolean;
    noTemplate?: boolean;
}

export const PageHelmet = ({title, description, children, noindex, noTemplate}: IPageHelmetProps) => {

    return (
        <Helmet titleTemplate={noTemplate ? undefined : '%s - Rightful'}>
            <title>{title}</title>
            {!!description?.length && (
                <meta name="description" content={description}/>
            )}
            {noindex && (
                <meta name="robots" content="noindex"/>
            )}
            {children}
        </Helmet>
    );
};
