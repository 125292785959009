import React from 'react';

import {Container} from 'react-bootstrap';
import classNames from 'classnames';

import {AppFooter} from 'shared/components/AppFooter/AppFooter';

import './style.scss';

interface IPlainContentPageProps {
    title: string;
    children?: React.ReactNode;
    className?: string;
}

export const PlainContentPage = ({children, title, className}: IPlainContentPageProps) => {
    return (
        <>
            <Container className={classNames('PlainContentPage p-0', className)}>
                <h1 className="PlainContentPage__heading">{title}</h1>
                <div className="PlainContentPage__content">
                    {children}
                </div>
            </Container>
            <AppFooter/>
        </>
    );
};
