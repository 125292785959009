import {selector} from 'recoil';

import {IProject} from 'modules/project/models';
import {projectLookupInsertSelector, projectLookupRemoveSelector} from 'modules/project/state/project-lookup';
import {projectListInsertSelector, projectListRemoveSelector} from 'modules/project/state/project-list';
import {throwWriteOnlySelectorError, guardRecoilDefaultValue} from 'shared/utils/recoil';
import {projectSearchResetAtom} from 'modules/project/state/project-search';

export const projectInsertSelector = selector<IProject>({
    key: 'projectInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        set(projectLookupInsertSelector, newValue);
        set(projectListInsertSelector, newValue);
        set(projectSearchResetAtom, get(projectSearchResetAtom) + 1);
    },
});

export const projectRemoveSelector = selector<string>({
    key: 'projectRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        set(projectLookupRemoveSelector, newValue);
        set(projectListRemoveSelector, newValue);
        set(projectSearchResetAtom, get(projectSearchResetAtom) + 1);
    },
});
