import React from 'react';

import { Spinner } from 'shared/components/Spinner/Spinner';

export const CardCarouselLoading = () => {
    return (
        <div className="CardCarousel__empty">
            <Spinner variant="muted"/>
        </div>
    );
};
