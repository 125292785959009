import React from 'react';

import {useRecoilValue} from 'recoil';
import {Container, Row} from 'react-bootstrap';

import {IFilterOption} from 'shared/models/filter-option/IFilterOption';
import {PostKind} from 'modules/post/models/postKind';
import {tagListReadSelector} from 'modules/tag/state/tag-list';
import {postKindToReadableString} from 'modules/post/utils';
import {useBool} from 'shared/hooks/useBool';

import {CommonPillSelect} from 'public/ProfileMarketplace/MarketplaceFilter/CommonPillSelect/CommonPillSelect';
import {NavLink} from 'shared/components/NavLink/NavLink';

import './style.scss';

interface IResourcesPageNav {
    tagId: string | undefined;
    onChange: (value: string | undefined) => void;
}

export const ResourcesPageNav = ({onChange, tagId}: IResourcesPageNav) => {
    const [isOpen, setIsOpen] = useBool();

    const tags = useRecoilValue(tagListReadSelector);
    const tagOptions = React.useMemo<IFilterOption<string>[]>(() => {
        const options = tags.map(tag => ({
            title: tag.title,
            value: tag.id,
        }));
        options.unshift({
            title: 'Select Type',
            value: '',
        });
        return options;
    }, [tags]);

    const handleChange = (value: string) => {
        onChange((value && value.length) ? value : undefined);
    };

    return (
        <Container className="ResourcesPageNav">
            <Row style={{marginTop: '100px'}}>
                <NavLink to="" className="ResourcesPageNav__link" strict>
                    All
                </NavLink>
                <NavLink to="webinars" className="ResourcesPageNav__link" strict>
                    {postKindToReadableString(PostKind.Webinar, true)}
                </NavLink>
                <NavLink to="articles" className="ResourcesPageNav__link" strict>
                    {postKindToReadableString(PostKind.Article, true)}
                </NavLink>
                <NavLink to="podcasts" className="ResourcesPageNav__link" strict>
                    {postKindToReadableString(PostKind.Podcast, true)}
                </NavLink>
                <NavLink to="tools-and-self-help" className="ResourcesPageNav__link" strict>
                    {postKindToReadableString(PostKind.Faq, true)}
                </NavLink>
                <div className="col w-100 ResourcesPageNav__dropdown-button d-flex justify-content-end">
                    <div className="mr-2">Speciality</div>
                    <CommonPillSelect
                        id="ResourcesPageNav.tagId"
                        label=""
                        open={isOpen}
                        onOpen={setIsOpen}
                        options={tagOptions}
                        value={tagId}
                        onChange={value => handleChange(value)}
                    />
                </div>
            </Row>
        </Container>
    );
};
