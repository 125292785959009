import {DefaultValue} from 'recoil';

/**
 * Guard against recoil default values in selector set functions.
 * Example:
 *  if (guardRecoilDefaultValue(newValue)) { return; }
 * @param candidate
 */
export const guardRecoilDefaultValue = (candidate: unknown): candidate is DefaultValue => {
    return candidate instanceof DefaultValue;
};

export const throwWriteOnlySelectorError = () => {
    throw new Error('This is a write-only selector');
};
