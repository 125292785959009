export enum CaseChartUser {
    Barrister = 'BARRISTER',
    Paralegal = 'PARALEGAL',
    Solicitor = 'SOLICITOR',
}

export interface ICaseChart {
    id: string;
    name: string;
    user_id: string;
    milestones: ICaseChartMilestone[];
    show_prices: boolean;
    created_at: string;
    updated_at?: string;
}

export interface ICaseChartForm {
    name: string;
    milestones: ICaseChartMilestone[];
    show_prices: boolean;
}

export interface ICaseChartMilestone {
    name: string;
    branches: ICaseChartBranch[];
}

export interface ICaseChartBranch {
    name: string;
    notes?: string;
    owner?: CaseChartUser;
    price: number;
}

export interface ICaseChartListQuery {
    userId: string;
    cursor?: string;
    limit?: number;

    [key: string]: string | number | undefined;
}

export interface ICaseChartListResult {
    case_charts?: ICaseChart[];
    next_cursor?: string;
}
