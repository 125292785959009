import {atom, selector, selectorFamily} from 'recoil';

import {IFavorite, IFavoriteKey, IFavoriteListQuery} from 'modules/favorite/models';
import {readFavoriteList} from 'modules/favorite/api';
import {CursorList} from 'shared/types/cursor-list';
import {compareFavoriteListQueries} from '../utils';
import {throwWriteOnlySelectorError, guardRecoilDefaultValue} from 'shared/utils/recoil';

export interface IFavoriteListStateFilters {
    query: IFavoriteListQuery;
    page: number;

    [key: string]: number | IFavoriteListQuery;
}

interface IFavoriteListState {
    query: IFavoriteListQuery;
    favorites: IFavorite[];
    cursors: CursorList;
    page: number;
    more: boolean;
    resetVersion: number;

    [key: string]: number | boolean | IFavorite[] | CursorList | IFavoriteListQuery;
}

export const favoriteListAtom = atom<IFavoriteListState | undefined>({
    key: 'favoriteListAtom',
    default: undefined,
});

export const favoriteListResetAtom = atom<number>({
    key: 'favoriteListResetAtom',
    default: 0,
});

export const favoriteListSelector = selectorFamily<IFavoriteListState | undefined, IFavoriteListStateFilters>({
    key: 'favoriteListSelector',
    get: ({page, query}) => ({get}) => {
        const atomValue = get(favoriteListAtom);
        const resetVersion = get(favoriteListResetAtom);
        if (
            atomValue &&
            page === atomValue.page &&
            compareFavoriteListQueries(query, atomValue.query) &&
            atomValue.resetVersion === resetVersion
        ) {
            return atomValue;
        }
        return undefined;
    },
    set: () => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(favoriteListAtom, newValue);
    },
});

export const favoriteListReadSelector = selectorFamily<IFavoriteListState, IFavoriteListStateFilters>({
    key: 'favoriteListReadSelector',
    get: (filters) => async ({get}) => {
        const currentValue = get(favoriteListSelector(filters));
        if (currentValue) {
            return currentValue;
        }

        const atomValue = get(favoriteListAtom);
        const resetVersion = get(favoriteListResetAtom);

        // load a fresh page from the server
        const result = await readFavoriteList({
            ...filters.query,
            cursor: atomValue && filters.page > 0 ? atomValue.cursors[filters.page - 1] : undefined,
        });

        // add the cursor to the list of cursors so that we can paginate backwards
        const newCursors = atomValue && filters.page > 0 ? Array.from(atomValue.cursors) : [];
        newCursors[filters.page] = result.next_cursor;

        return {
            query: filters.query,
            favorites: result.favorites,
            cursors: newCursors,
            page: filters.page,
            more: !!result.next_cursor,
            resetVersion,
        } as IFavoriteListState;
    },
});

export const favoriteListInsertSelector = selector<IFavorite>({
    key: 'favoriteListInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        const atomValue = get(favoriteListAtom);
        if (!atomValue) {
            return;
        }

        // if the favorite is present in the store then update it. Append it to the end of the list iof not at the
        // limit. Otherwise, force a reload.
        const index = atomValue.favorites.findIndex(favorite => (
            favorite.user_id === newValue.user_id &&
            favorite.profile_id === newValue.profile_id
        ));
        if (index !== -1) {
            const newFavorites = [...atomValue.favorites];
            newFavorites.splice(index, 1, newValue);
            set(favoriteListAtom, {
                ...atomValue,
                favorites: newFavorites,
            });
        } else if (atomValue.page !== 0 || atomValue.favorites.length === atomValue.query.limit) {
            set(favoriteListResetAtom, get(favoriteListResetAtom) + 1);
        } else {
            const newFavorites = Array.from(atomValue.favorites);
            newFavorites.push(newValue);
            set(favoriteListAtom, {
                ...atomValue,
                favorites: newFavorites,
            });
        }
    },
});

export const favoriteListRemoveSelector = selector<IFavoriteKey>({
    key: 'favoriteListRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        const atomValue = get(favoriteListAtom);
        if (!atomValue) {
            return;
        }

        const index = atomValue.favorites.findIndex(favorite => (
            favorite.user_id === newValue.user_id &&
            favorite.profile_id === newValue.profile_id
        ));
        if (index !== -1 && atomValue.favorites.length === 1) {
            set(favoriteListResetAtom, get(favoriteListResetAtom) + 1);
        } else if (index !== -1) {
            set(favoriteListAtom, {
                ...atomValue,
                favorites: atomValue.favorites.filter(favorite => (
                    favorite.user_id !== newValue.user_id ||
                    favorite.profile_id !== newValue.profile_id
                )),
            });
        }
    },
});
