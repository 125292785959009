import {useEffect, useCallback} from 'react';

export const useOutsideClick = (ref: React.MutableRefObject<HTMLElement | null>, callback: () => void) => {
    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            callback();
        }
    }, [callback, ref]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback, handleClickOutside]);
};
