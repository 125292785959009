import React from 'react';

import {useRecoilState, useRecoilValueLoadable} from 'recoil';

import {categoryListAtom, categoryListReadSelector} from '../state/category-list';

export const useCategoryList = () => {
    const [categoryList, setCategoryList] = useRecoilState(categoryListAtom);
    const categoryListLoadable = useRecoilValueLoadable(categoryListReadSelector);

    React.useEffect(() => {
        if (categoryListLoadable.state === 'hasValue') {
            setCategoryList(categoryListLoadable.contents);
        }
    }, [categoryListLoadable, setCategoryList]);

    if (categoryList) {
        return categoryList.categories;
    } else if (categoryListLoadable.state === 'hasValue') {
        return categoryListLoadable.contents.categories;
    } else if (categoryListLoadable.state === 'loading') {
        throw categoryListLoadable.promiseOrThrow();
    } else {
        throw categoryListLoadable.errorOrThrow();
    }
};
