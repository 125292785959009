import React from 'react';
import {useRecoilState} from 'recoil';

import {ITimeSlotAvailability} from 'modules/availability/models';
import {bookingTimezoneAtom} from 'shared/state/booking-timezone';

import {MonthSelectControl} from 'shared/components/form/MonthSelectControl/MonthSelectControl';
import {TimezoneControl} from 'shared/components/TimezoneControl/TimezoneControl';
import {DaySelect} from 'shared/components/form/DaySelect/DaySelect';
import {AvailableDate} from 'shared/components/AvailableDate/AvailableDate';

interface ITimeSlotControlProps {
    value: Date;
    onChange: (value: Date) => void;
    className?: string;
    invalid?: boolean;
    currentDate: Date;
    setCurrentDate: (currentDate: Date) => void;
    availableTimeSlots: ITimeSlotAvailability[];
    disabled?: boolean;
    emptyContent?: React.ReactNode;
    availabilityTimezone: string;
    displayTimezone: string;
}

export const TimeSlotControl = ({
    value,
    onChange,
    className,
    invalid,
    currentDate,
    setCurrentDate,
    availableTimeSlots,
    disabled,
    emptyContent,
    availabilityTimezone,
    displayTimezone,
}: ITimeSlotControlProps) => {
    const [bookingTimezone, setBookingTimezone] = useRecoilState(bookingTimezoneAtom);

    return (
        <div className={className}>
            <div className="row pl-3 pr-3 position-relative justify-content-between">
                <TimezoneControl disabled={disabled} value={bookingTimezone} onChange={setBookingTimezone} />
                <MonthSelectControl value={currentDate} onChange={setCurrentDate} disabled={disabled} />
            </div>
            <div>
                <DaySelect date={currentDate} onChange={setCurrentDate} disabled={disabled} invalid={invalid} />
            </div>
            <div className="TimeSlotControl__date-picker">
                <AvailableDate
                    date={currentDate}
                    timeSlots={availableTimeSlots}
                    onChange={(value) => onChange(value)}
                    value={value}
                    disabled={disabled}
                    emptyContent={emptyContent}
                    setCurrentDate={setCurrentDate}
                    availabilityTimezone={availabilityTimezone}
                    displayTimezone={displayTimezone}
                />
            </div>
        </div>
    );
};
