import React from 'react';

import {SuperGif} from '@wizpanda/super-gif';
import {useBool} from 'shared/hooks/useBool';

import './style.scss';

interface IInteractiveLogoProps {
    onLoad: () => void;
}

interface IInteractiveLogoMouseState {
    pointerX: number;
    isPointerDown: boolean;
}

export const InteractiveLogo = ({onLoad}: IInteractiveLogoProps) => {
    const gifRef = React.useRef<HTMLImageElement>(null);
    const mouseStateRef = React.useRef<IInteractiveLogoMouseState>({
        pointerX: 0,
        isPointerDown: false,
    });
    const [superGif, setSuperGif] = React.useState<SuperGif | null>(null);
    const [isReady, setIsReady] = useBool();

    React.useEffect(() => {
        if (isReady && superGif) {
            onLoad();
        }
    }, [isReady, superGif, onLoad]);

    // handle mouseup events outside the element
    React.useEffect(() => {
        if (superGif && isReady) {
            const handleDocumentMouseUp = (e: MouseEvent) => {
                if (e.button !== 2) {
                    superGif.play();
                    if (mouseStateRef.current) {
                        mouseStateRef.current.isPointerDown = false;
                    }
                }
            };
            document.addEventListener('mouseup', handleDocumentMouseUp);
            return () => {
                document.removeEventListener('mouseup', handleDocumentMouseUp);
            };
        }
    }, [mouseStateRef, superGif, isReady]);

    // add event handlers once the library has initialized
    React.useEffect(() => {
        if (superGif && isReady) {

            const onPointerDown = (pointerX: number) => {
                superGif?.pause();
                mouseStateRef.current.isPointerDown = true;
                mouseStateRef.current.pointerX = pointerX;
            };

            const onPointerUp = () => {
                if (mouseStateRef.current.isPointerDown) {
                    superGif?.play();
                    mouseStateRef.current.isPointerDown = false;
                }
            };

            const onPointerMove = (pointerX: number) => {
                if (mouseStateRef.current.isPointerDown && superGif) {
                    const x = pointerX;
                    const dx = x - mouseStateRef.current.pointerX;
                    let delta = parseInt((dx * 0.1).toString());
                    if (delta === 0) {
                        delta = Math.sign(dx);
                    }
                    const currFrame = superGif.getCurrentFrame();
                    const length = superGif.getLength();
                    let targetFrame = (currFrame + delta) % length;
                    if (targetFrame < 0) {
                        targetFrame = length - Math.abs(targetFrame);
                    }
                    superGif.moveTo(targetFrame);
                    mouseStateRef.current.pointerX = x;
                }
            };

            const onMouseDown = (e: MouseEvent) => {
                onPointerDown(e.clientX);
            };
            const onMouseUp = (e: MouseEvent) => {
                onPointerUp();
            };
            const onMouseMove = (e: MouseEvent) => {
                onPointerMove(e.clientX);
            };

            const onTouchStart = (e: TouchEvent) => {
                onPointerDown(e.touches[0].clientX);
            };
            const onTouchEnd = (e: TouchEvent) => {
                onPointerUp();
            };
            const onTouchMove = (e: TouchEvent) => {
                onPointerMove(e.touches[0].clientX);
            };

            const canvas = superGif.getCanvas();
            canvas.addEventListener('mousedown', onMouseDown);
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
            canvas.addEventListener('touchstart', onTouchStart);
            document.addEventListener('touchmove', onTouchMove);
            document.addEventListener('touchend', onTouchEnd);

            // this tells React to run this function if the useEffect hook changes
            return () => {
                canvas.removeEventListener('mousedown', onMouseDown);
                document.removeEventListener('mousemove', onMouseMove);
                document.removeEventListener('mouseup', onMouseUp);
                canvas.removeEventListener('touchstart', onTouchStart);
                document.removeEventListener('touchmove', onTouchMove);
                document.removeEventListener('touchend', onTouchEnd);
            };
        }
    }, [superGif, isReady, mouseStateRef]);

    // initialize the SuperGif library when the ref is ready
    React.useEffect(() => {
        if (gifRef?.current && !superGif) {
            const newSuperGif = new SuperGif(gifRef.current, {
                maxWidth: 600,
                autoPlay: false,
            });
            setSuperGif(newSuperGif);
            newSuperGif.load(() => {
                setIsReady(true);
                newSuperGif.play();
            });
        }
    }, [gifRef, superGif, setIsReady]);

    return (
        <div className="InteractiveLogo">
            <img
                ref={gifRef}
                src="/assets/logo-animated.gif"
                className="img-fluid"
                alt="Rightful logo"
            />
        </div>
    );
};
