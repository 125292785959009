import React from 'react';

import {Spinner} from 'shared/components/Spinner/Spinner';

import './style.scss';

interface IRouteSpinnerProps {
    children?: React.ReactNode;
    hideSpinner?: boolean; // set to true if the route uses the `useLoadingPage` hook
}

export const RouteSpinner = ({children, hideSpinner}: IRouteSpinnerProps) => {
    return (
        <React.Suspense
            fallback={(
                <>
                    {!hideSpinner && (
                        <div className="RouteSpinner__spinner-wrapper">
                            <Spinner variant="primary"/>
                        </div>
                    )}
                </>
            )}
        >
            {children}
        </React.Suspense>
    );
};
