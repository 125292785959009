import axios from 'axios';

import {getAccessTokenSilently} from 'shared/utils/token';
import {IFileUploadCreate, IFileUpload} from 'modules/file-upload/models';
import {Config} from 'config';
import {getAuthHeaders} from 'shared/utils/auth';

export const fileUploadBaseUrl = `${Config.fileServiceURL}/file_uploads`;

const getUrlForFileUploadAPI = (path: string): string => {
    return `${fileUploadBaseUrl}${path}`;
};

export const createFileUpload = async (createFileUploadData: IFileUploadCreate): Promise<IFileUpload> => {
    const accessToken = await getAccessTokenSilently();
    const payload = {
        origin: window.location.origin,
        user_id: createFileUploadData.userId,
        filename: createFileUploadData.filename,
        content_type: createFileUploadData.contentType,
        size: createFileUploadData.size,
        folder: createFileUploadData.folder,
        file_token: createFileUploadData.fileToken,
    };
    const response = await axios.post(getUrlForFileUploadAPI('/'), payload, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};
