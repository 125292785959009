import React from 'react';

import {FieldError} from 'react-hook-form';
import {Form} from 'react-bootstrap';

import {ServiceType} from 'modules/profile/models/serviceType';
import {getServiceTypeName} from 'shared/utils/service-types';

interface IServiceGridCheckBoxProps {
    disabled?: boolean;
    name: string;
    value: ServiceType[];
    onChange: (value: ServiceType[]) => void;
    invalid?: boolean;
    error?: FieldError;
}

export const ServiceGridCheckBox = ({
    value,
    invalid,
    onChange,
    error,
    name,
    disabled,
}: IServiceGridCheckBoxProps) => {
    const handleChange = (serviceType: ServiceType) => {
        const index = value && value.indexOf(serviceType);

        if (index === -1 || index === null) {
            onChange(value ? value.concat([serviceType]) : [serviceType]);
        } else {
            onChange(value ? value.filter((valueInList) => valueInList !== serviceType) : []);
        }
    };
    return (
        <>
            <Form.Group className="row pl-3" controlId="servicesController.serviceTypes">
                {Object.values(ServiceType).map(serviceType => (
                    <div key={serviceType} className="col col-lg-6 col-12  mb-4 ProfilePageHeader__checkbox">
                        <Form.Check
                            custom
                            inline
                            name={name}
                            type="checkbox"
                            onChange={() => handleChange(serviceType)}
                            value={serviceType}
                            label={getServiceTypeName(serviceType)}
                            id={`servicesController.${serviceType}`}
                            disabled={disabled}
                            isInvalid={invalid}
                            checked={value && value.indexOf(serviceType) !== -1}
                        />
                    </div>
                ))}
                {error && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {error.message}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
        </>
    );
};
