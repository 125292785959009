import axios from 'axios';

import {IAvailabilitySettings, IAvailabilitySettingsUpdate} from 'modules/availability-settings/models';
import {getAuthHeaders} from 'shared/utils/auth';
import {getAccessTokenMaybe, getAccessTokenSilently} from 'shared/utils/token';
import {Config} from 'config';

export const getURLForAvailabilitySettingsAPI = (profileId: string): string => {
    return `${Config.mainServiceURL}/profiles/${profileId}/availability_settings/`;
};

export const readAvailabilitySettings = async (profileId: string): Promise<IAvailabilitySettings> => {
    const accessToken = await getAccessTokenMaybe();
    const response = await axios.get(getURLForAvailabilitySettingsAPI(profileId), {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const updateAvailabilitySettings = async (
    profileId: string,
    availabilitySettingsUpdate: IAvailabilitySettingsUpdate,
): Promise<IAvailabilitySettings> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(getURLForAvailabilitySettingsAPI(profileId), availabilitySettingsUpdate, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};
