import React from 'react';

import {Link} from 'react-router-dom';

import {RightArrow} from 'shared/icons/HelpCentre';

import './style.scss';

interface IBreadcrumb {
    title: string;
    url?: string;
}

interface IBreadCrumbProps {
    breadcrumbs: IBreadcrumb[];
}

export const BreadCrumbs = ({breadcrumbs}: IBreadCrumbProps) => {
    return (
        <div className="BreadCrumb">
            <div className="BreadCrumb--olList">
                {breadcrumbs.map((breadcrumb, index) => (
                    <React.Fragment key={index}>
                        {index === breadcrumbs.length - 1 ? (
                            <div className="BreadCrumb__text BreadCrumb__text--active">
                                <p className="m-0">{breadcrumb.title}</p>
                            </div>
                        ) : (
                            <div className="BreadCrumb__text">
                                <Link to={breadcrumb.url ? breadcrumb.url : '/help-center'}>
                                    <div>
                                        <p className="m-0">{breadcrumb.title}</p>
                                    </div>
                                </Link>
                                <div className="BreadCrumb__icon">
                                    <RightArrow/>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};
