import {IPostListQuery} from 'modules/post/models/IPostListQuery';
import {PostKind} from 'modules/post/models/postKind';

export const comparePostFilters = (filters1: IPostListQuery, filters2: IPostListQuery) => {
    return (
        filters1.userId === filters2.userId &&
        filters1.kind === filters2.kind &&
        filters1.limit === filters2.limit &&
        filters1.cursor === filters2.cursor &&
        filters1.isPublished === filters2.isPublished &&
        filters1.isFeatured === filters2.isFeatured &&
        filters1.orderBy === filters2.orderBy &&
        filters1.searchTerm === filters2.searchTerm &&
        JSON.stringify(filters1.tagId) === JSON.stringify(filters2.tagId)
    );
};

export const postKindToReadableString = (postType: PostKind, plural: boolean = false): string => {
    if (plural) {
        switch (postType) {
            case PostKind.Faq:
                return 'Tools & Self-Help';
            case PostKind.Article:
                return 'Articles';
            case PostKind.Podcast:
                return 'Podcasts';
            case PostKind.Webinar:
                return 'Webinars';
        }
    } else {
        switch (postType) {
            case PostKind.Faq:
                return 'Tools & Self-Help';
            case PostKind.Article:
                return 'Article';
            case PostKind.Podcast:
                return 'Podcast';
            case PostKind.Webinar:
                return 'Webinar';
        }
    }
};
