import React from 'react';

import {Link} from 'react-router-dom';

import {AppFooter} from 'shared/components/AppFooter/AppFooter';

import './style.scss';

interface INotFoundContentProps {
    footer?: boolean;
    buttonUrl: string;
    buttonTitle: string;
}

export const NotFoundContent = ({footer, buttonTitle, buttonUrl}: INotFoundContentProps) => {

    return (
        <>
            <div className="NotFound">
                <h1 className="NotFound__title">Page not found</h1>
                <p className="NotFound__description">We could not find the page you're looking for</p>
                <div className="NotFound__button-wrapper">
                    {buttonTitle && buttonUrl && (
                        <Link to={buttonUrl} className="btn btn-featured NotFound__button btn-lg">
                            {buttonTitle}
                        </Link>
                    )}
                </div>
                <div className="NotFound__button-wrapper">
                    <img src="/assets/notfound.png" alt="not found" className="NotFound__image"/>
                </div>
            </div>
            {footer && (
                <AppFooter/>
            )}
        </>
    );
};

NotFoundContent.defaultProps = {
    footer: true,
};
