import {selector} from 'recoil';

import {INotification} from 'modules/notification/models';
import {throwWriteOnlySelectorError, guardRecoilDefaultValue} from 'shared/utils/recoil';
import {notificationListAtom} from 'modules/notification/state/notification-list';

export const notificationInsertSelector = selector<INotification>({
    key: 'notificationInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const atomValue = get(notificationListAtom);
        if (!atomValue) {
            return;
        }
        const index = atomValue.notifications.findIndex(notification => notification.id === newValue.id);
        if (index !== -1) {
            const newNotifications = [...atomValue.notifications];
            newNotifications.splice(index, 1, newValue);
            set(notificationListAtom, {
                ...atomValue,
                notifications: newNotifications,
            });
        }
    },
});
