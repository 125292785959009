import React from 'react';

import {useSetRecoilState} from 'recoil';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

import {INotification} from 'modules/notification/models';
import {ItemKind} from 'modules/item-key';
import {updateNotification} from 'modules/notification/api';
import {getErrorMessage} from 'shared/utils/error';
import {notificationInsertSelector} from 'modules/notification/state/notification-insert';

import {FormatDistance} from 'shared/components/date/FormatDistance/FormatDate';
import {UserIdProfilePicture} from 'shared/components/UserIdProfilePicture/UserIdProfilePicture';
import {SystemProfilePicture} from 'shared/components/SystemProfilePicture/SystemProfilePicture';

import './style.scss';

interface INavbarNotificationsItemProps {
    notification: INotification;
}

export const NavbarNotificationsItem = ({notification}: INavbarNotificationsItemProps) => {
    const insertNotification = useSetRecoilState(notificationInsertSelector);

    const className = classNames('NavbarNotificationsItem dropdown-item', {
        'NavbarNotificationsItem--unread': !notification.read,
    });

    const url = React.useMemo(() => {
        if (notification.url) {
            return notification.url;
        } else if (notification.item.kind === ItemKind.PROJECT) {
            return `/dashboard/projects/${notification.item.id}`;
        } else {
            return undefined;
        }
    }, [notification]);

    const onClick = (e: React.MouseEvent) => {
        if (!url) {
            e.preventDefault();
        }

        (async () => {
            try {
                const updatedNotification = await updateNotification(notification.id, {
                    read: true,
                });
                insertNotification(updatedNotification);
            } catch (e) {
                console.error(getErrorMessage(e));
            }
        })();
    };

    return (
        <Link to={url || '#'} className={className} onClick={onClick} role="button" style={{textDecoration: 'none'}}>
            {notification?.action_user_id ? (
                <UserIdProfilePicture
                    id={notification.action_user_id}
                    round
                    size="notification"
                    border={false}
                    className="NavbarNotificationsItem__icon"
                />
            ) : (
                <SystemProfilePicture
                    name="Rightful"
                    className="NavbarNotificationsItem__icon"
                    size="2x"
                />
            )}
            <div>
                <div className="NavbarNotificationsItem__message">{notification.message}</div>
                <small>
                    <p className="NavbarNotificationsItem__time">
                        <FormatDistance>{notification.created_at}</FormatDistance>
                    </p>
                </small>
            </div>
        </Link>
    );
};
