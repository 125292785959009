import React from 'react';

import {useRecoilValue} from 'recoil';

import {IPost} from '../models/IPost';
import {PostMediaKind} from '../models/postMediaKind';
import {getFileDownloadUrl} from '../../file/utils';
import {fileTokenSelector} from '../../file/state/file-token';

export const usePostHeaderImageURL = (post: IPost) => {
    const token = useRecoilValue(fileTokenSelector);

    return React.useMemo<string | undefined>(() => {
        const imageMedia = post.media.find(mediaItem => (
            mediaItem.kind === PostMediaKind.Image ||
            mediaItem.kind === PostMediaKind.ImageUrl
        ));
        if (imageMedia?.kind === PostMediaKind.Image && imageMedia?.file_id && imageMedia?.name) {
            return getFileDownloadUrl({
                id: imageMedia.file_id,
                filename: imageMedia.name,
                token,
            });
        } else if (imageMedia?.kind === PostMediaKind.ImageUrl) {
            return imageMedia?.content;
        } else {
            return undefined;
        }
    }, [post.media, token]);
};
