import {selectorFamily} from 'recoil';

import {IArticle} from '../models';
import {readArticle} from '../api';
import {articleListAtom} from './article-list';

export const articleLookupReadSelector = selectorFamily<IArticle, string>({
    key: 'articleLookupReadSelector',
    get: (articleId) => async ({get}) => {
        const articleList = get(articleListAtom);
        const article = articleList?.articles?.find(article => article.id === articleId);
        if (article) {
            return article;
        }

        return await readArticle(articleId);
    },
});
