import React from 'react';

import './style.scss';
import classNames from 'classnames';

interface IPageErrorProps {
    children?: React.ReactNode;
    description?: string;
    className?: string;
    imageClassName?: string;
}

export const PageError = ({children, description, className, imageClassName}: IPageErrorProps) => (
    <div className={classNames('PageError', className)}>
        <img
            src="/assets/notfound.png"
            alt={description}
            className={classNames('PageError__image', imageClassName)}
        />
        <div>
            {children ?? description}
        </div>
    </div>
);
