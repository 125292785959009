import {useRecoilLoadable} from 'shared/hooks/useRecoilLoadable';
import {homepageCacheAtom, homepageCacheReadSelector} from 'modules/homepage/state';

export const useHomepageCache = () => {
    const {data, loading, error} = useRecoilLoadable({
        state: homepageCacheAtom,
        loadable: homepageCacheReadSelector,
    });

    return {
        homepageCache: data,
        loading,
        error,
    };
};
