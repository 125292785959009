import React from 'react';

import {Container} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import {IPost} from 'modules/post/models/IPost';
import {useAuth} from 'shared/auth/hooks/useAuth';
import {formatDateOnlyReadableLong} from 'shared/utils/date';
import {usePostHeaderImageURL} from 'modules/post/hooks/usePostHeaderImageURL';

import {UserIdProfilePicture} from 'shared/components/UserIdProfilePicture/UserIdProfilePicture';
import {TagId} from 'shared/components/TagId/TagId';
import {UserIdName} from 'shared/components/UserIdName/UserIdName';
import {HtmlComponent} from 'shared/components/HtmlContent/HtmlContent';
import {Edit} from 'shared/icons/EditProfile';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';

import './style.scss';
import {useWindowSize} from 'shared/hooks/useWindowSize';

interface IPostViewContentProps {
    post: IPost;
}

export const PostViewContent = ({post}: IPostViewContentProps) => {
    const {isAuthenticated, tokenData} = useAuth();
    const updatePostForUserUrl: string = `/posts/${post.id}/update`;

    const imageUrl = usePostHeaderImageURL(post);
    const windowWidth = useWindowSize();

    const postedAtText = React.useMemo<string | undefined>(() => {
        if (post.published_at) {
            return `Posted ${formatDateOnlyReadableLong(post.published_at)}`;
        } else {
            return undefined;
        }
    }, [post.published_at]);

    return (
        <>
            <PageHelmet title={post.title} description={post.summary} />
            <div className="PostView__bg--image " style={{backgroundImage: `url("${imageUrl}")`}}>
                <div className="PostView__edit-post-btn">
                    {isAuthenticated && post.user_id === tokenData?.id && (
                        <Link to={updatePostForUserUrl}>
                            <Edit />
                        </Link>
                    )}
                </div>
                <div className="PostView__content">
                    <div className="PostView__content--container">
                        <h1 className="PostView__content--title">{post.title}</h1>
                    </div>
                    <div className="PostView__content--user-detail">
                        <Link
                            to={`/profiles/${post.user_id}`}
                            className="PostView__content--user-detail--link"
                            style={{textDecoration: 'none'}}
                        >
                            <UserIdProfilePicture
                                id={post.user_id}
                                size={windowWidth.width < 993 ? 'sm' : 'xsm'}
                                className="PostView__content--user-detail--pImage"
                                round
                            />
                            <div className="ml-3">
                                <h4 className="PostView__content--user-detail--author m-0">
                                    <UserIdName id={post.user_id} />
                                </h4>
                                <p className="PostView__content--user-detail--date m-0">{postedAtText}</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="PostView__tags-content">
                <Container>
                    <div className="row align-items-center py-3 mx-3">
                        <div className="mr-4">
                            <p className="m-0 pr-2 PostView__tags-content--text">Category:</p>
                        </div>
                        <div className="row">
                            {post.tag_ids.map((tagId) => (
                                <TagId
                                    tagId={tagId}
                                    key={tagId}
                                    size={windowWidth.width < 993 ? 'lg' : 'xl'}
                                    // size="xl"
                                    className="MediaCard__tag"
                                />
                            ))}
                        </div>
                    </div>
                </Container>
            </div>
            <Container>
                <HtmlComponent className="PostView__content--html py-5">{post.content}</HtmlComponent>
            </Container>
        </>
    );
};
