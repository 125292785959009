import React from 'react';

import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import classNames from 'classnames';

import {getFullName} from 'modules/profile/utils';
import {useProfileSummaryLoadable} from 'modules/profile/hooks/useProfileSummaryLoadable';

import {ProfilePicture} from 'shared/components/ProfilePicture/ProfilePicture';

interface IProjectMembersItemProps {
    userId: string;
    size?: 'xs';
    disableTooltip?: boolean;
    isDispalyFullname?: boolean;
    isOwner?: boolean;
}

export const ProjectMembersItem = ({userId, size, disableTooltip, isDispalyFullname, isOwner}: IProjectMembersItemProps) => {
    const {profileSummary} = useProfileSummaryLoadable(userId);

    const fullName = profileSummary ? getFullName(profileSummary) : undefined;
    const domID = `ProjectMembersItem.${userId}`;
    const showTooltip = !disableTooltip && !!fullName;

    const pictureClassName = classNames('ProjectMembers__picture d-block', {
        'ProjectMembers__picture--xs': size === 'xs',
        ProjectMembers__tooltip: showTooltip,
    });

    const picture = (
        <ProfilePicture
            name={fullName}
            alt={fullName}
            url={profileSummary?.image_url}
            imgClassName={pictureClassName}
            size={size}
            round
        />
    );

    return (
        <div className="ProjectMembers__item">
            {showTooltip ? (
                <OverlayTrigger
                    placement="bottom"
                    overlay={(props) => (
                        <Tooltip id={domID} {...props}>
                            {fullName}
                            {!!isOwner && (
                                <div>Project Owner</div>
                            )}
                        </Tooltip>
                    )}
                >
                    {({ref, ...triggerHandler}) => (
                        <div id={domID} ref={ref} {...triggerHandler}>
                            {picture}
                        </div>
                    )}
                </OverlayTrigger>
            ) : (
                <div className="d-flex align-items-center">
                    {picture} {isDispalyFullname && <span className='ml-2'>{fullName}</span>}
                </div>
            )}
        </div>
    );
};
