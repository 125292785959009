import React from 'react';

import {Button, Form} from 'react-bootstrap';

import {IProfile} from 'modules/profile/models/IProfile';

import {BookingModalHeader} from 'shared/components/BookingModal/BookingModalHeader/BookingModalHeader';
import {EmptyTable} from 'shared/components/EmptyTable/EmptyTable';

import './style.scss';

interface IBookingModalNotAvailableProps {
    profile: IProfile;
    durationMinutes: number;
    onHide: () => void;
}

export const BookingModalNotAvailable = ({profile, durationMinutes, onHide}: IBookingModalNotAvailableProps) => {
    return (
        <>
            <BookingModalHeader profile={profile} durationMinutes={durationMinutes} />
            <EmptyTable>
                {profile.firstname} currently has no availability
            </EmptyTable>
            <Form.Group className="text-right">
                <Button type="submit" variant="primary" className="btn-xl-wide" onClick={() => onHide()}>
                    BACK
                </Button>
            </Form.Group>
        </>
    );
};
