import React from 'react';

import {useNavbar} from 'shared/hooks/useNavbar';

import {AppFooter} from 'shared/components/AppFooter/AppFooter';
import {GameChangerSection} from 'public/GameChangerPage/GameChangerSection';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';

import './style.scss';

export const GameChanger = () => {
    useNavbar(true);

    return (
        <div className="GameChanger">
            <PageHelmet
                title="Game Changer"
                description="Rightful is a game changer in the legal services industry. Traditional legal advocacy hasn't changed much in hundreds of years. Today it is practised the same way as it was in the 1700s. Technology can now automate and simplify the advocacy process.There no longer needs to be the same level of ‘back and forth’ as there once was."
            />
            <GameChangerSection/>
            <AppFooter/>
        </div>
    );
};
