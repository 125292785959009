import React from 'react';

import {Link} from 'react-router-dom';
import {PostKind} from 'modules/post/models/postKind';
import {IPostMedia} from 'modules/post/models/IPostMedia';
import './style.scss';

export interface IMediaCardPictureProps {
    imageUrl?: string;
    to: string;
    className?: string;
    classNameImage?: string;
    media?: IPostMedia[];
    kind?: PostKind;
    setPostWebinarUrl?: (url: string) => void;
    setIsOpenPreview?: (OpenPreview: boolean) => void;
}

export const MediaCardPicture = ({
    imageUrl,
    to,
    className,
    classNameImage,
    kind,
    media,
    setIsOpenPreview,
    setPostWebinarUrl,
}: IMediaCardPictureProps) => {
    return (
        <>
            {kind === 'WEBINAR' ? (
                <div
                    id="link_for_card"
                    onClick={() => {
                        if (media) {
                            const PostMediaLink = media.findIndex((value) => value.kind === 'LINK');
                            const urlLink = PostMediaLink !== -1 && media[PostMediaLink].name;
                            if (urlLink && setPostWebinarUrl && setIsOpenPreview) {
                                setPostWebinarUrl(urlLink);
                                setIsOpenPreview(true);
                            }
                        }
                    }}
                >
                    <div className={className ? `${className} card-img-top` : 'MediaCardPicture card-img-top'}>
                        {imageUrl && (
                            <div
                                className={classNameImage ? classNameImage : 'MediaCardPicture__image'}
                                style={{backgroundImage: `url("${imageUrl}")`}}
                            />
                        )}
                        {!imageUrl && <div className="MediaCardPicture__image MediaCardPicture--placeholder" />}
                    </div>
                </div>
            ) : (
                <Link id="link_for_card" to={to}>
                    <div className={className ? `${className} card-img-top` : 'MediaCardPicture card-img-top'}>
                        {imageUrl && (
                            <div
                                className={classNameImage ? classNameImage : 'MediaCardPicture__image'}
                                style={{backgroundImage: `url("${imageUrl}")`}}
                            />
                        )}
                        {!imageUrl && <div className="MediaCardPicture__image MediaCardPicture--placeholder" />}
                    </div>
                </Link>
            )}
        </>
    );
};
