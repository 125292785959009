import {z} from 'zod';

import {
    IAvailabilitySettings,
    IAvailabilitySettingsAvailability,
    IAvailabilitySettingsLocations,
    IAvailabilitySettingsUpdate,
} from 'modules/availability-settings/models';
import {EventLocationType} from 'modules/event/models';
import {zodStringNumber} from 'shared/utils/form';

export enum MeetingLocation {
    Physical = 'PHYSICAL',
    Virtual = 'VIRTUAL',
    Callback = 'CALLBACK',
}

const locationsEditable = z.object({
    physical: z.boolean(),
    virtual: z.boolean(),
    callback: z.boolean(),
});

export const availabilitySettingsAvailabilityForm = z.object({
    is_available: z.boolean(),
    day_of_week: z.number(),
    start_time: z.string(),
    end_time: z.string(),
});

export const availabilitySettingsForm = z.object({
    is_enabled: z.boolean(),
    auto_confirm: z.boolean(),
    is_holiday: z.boolean(),
    description: z.string().optional().nullable(),
    pre_booking_notes: z.string().optional().nullable(),
    post_booking_notes: z.string().optional().nullable(),
    availability: z.array(availabilitySettingsAvailabilityForm),
    duration_minutes: zodStringNumber,
    timezone: z.string().optional(),
    locationType: z.array(z.nativeEnum(MeetingLocation)).nonempty(),
    virtualUrl: z.string().optional().nullable(),
    physicalAddress: z.string().optional().nullable(),
    phoneNumber: z.string().min(7).optional().nullable(),
    isLocationEditable: locationsEditable,
}).refine(({physicalAddress, isLocationEditable, locationType}) =>
    (!!physicalAddress?.length || isLocationEditable.physical || !locationType.find(e => e === MeetingLocation.Physical)), {
    message: 'You must provide an address in the field above or require the client to provide one.',
    path: ['physicalAddress'],
}).refine(({virtualUrl, isLocationEditable, locationType}) =>
    (virtualUrl?.length || isLocationEditable.virtual || !locationType.find(e => e === MeetingLocation.Virtual)), {
    message: 'You must provide a virtual meeting link in the field above or require the client to provide one.',
    path: ['virtualUrl'],
}).refine(({phoneNumber, isLocationEditable, locationType}) =>
    (phoneNumber?.length || isLocationEditable.callback || !locationType.find(e => e === MeetingLocation.Callback)), {
    message: 'You must provide your phone number in the field above or require the client to provide one.',
    path: ['phoneNumber'],
});

interface ILocationsEditableForm {
    virtual: boolean;
    physical: boolean;
    callback: boolean;
}

export interface IAvailabilitySettingsForm {
    is_enabled: boolean;
    auto_confirm: boolean;
    is_holiday: boolean;
    description?: string;
    pre_booking_notes?: string;
    post_booking_notes?: string;
    availability: IAvailabilitySettingsAvailability[];
    duration_minutes: number | string;
    timezone?: string;
    locationType: MeetingLocation[],
    virtualUrl?: string;
    physicalAddress?: string;
    phoneNumber?: string;
    isLocationEditable: ILocationsEditableForm;
}

export const availabilitySettingsToForm = (availabilitySettings: IAvailabilitySettings): IAvailabilitySettingsForm => {
    const {locations, ...rest} = availabilitySettings;

    let locationType: MeetingLocation[] = [];
    if (availabilitySettings.locations.callback) {
        locationType = [...locationType, MeetingLocation.Callback];
    }
    if (availabilitySettings.locations.physical) {
        locationType = [...locationType, MeetingLocation.Physical];
    }
    if (availabilitySettings.locations.virtual) {
        locationType = [...locationType, MeetingLocation.Virtual];
    }

    return {
        ...rest,
        locationType,
        duration_minutes: availabilitySettings.duration_minutes.toString(),
        virtualUrl: availabilitySettings.locations.virtual?.url,
        physicalAddress: availabilitySettings.locations.physical?.address,
        phoneNumber: availabilitySettings.locations.callback?.phone_number,
        isLocationEditable: {
            physical: !!availabilitySettings.locations.physical?.is_client_can_edit,
            virtual: !!availabilitySettings.locations.virtual?.is_client_can_edit,
            callback: !!availabilitySettings.locations.callback?.is_client_can_edit,
        },
    };
};

export const availabilitySettingsFormToUpdate = (availabilitySettingsForm: IAvailabilitySettingsForm): IAvailabilitySettingsUpdate => {
    const {locationType, virtualUrl, physicalAddress, isLocationEditable, phoneNumber, ...rest} = availabilitySettingsForm;
    const locations: IAvailabilitySettingsLocations = {};
    if (locationType.includes(MeetingLocation.Virtual)) {
        locations.virtual = {
            url: virtualUrl,
            is_client_can_edit: isLocationEditable.virtual,
        };
    }
    if (locationType.includes(MeetingLocation.Physical)) {
        locations.physical = {
            address: physicalAddress,
            is_client_can_edit: isLocationEditable.physical,
        };
    }
    if (locationType.includes(MeetingLocation.Callback)) {
        locations.callback = {
            phone_number: phoneNumber,
            is_client_can_edit: isLocationEditable.callback,
        };
    }
    return {
        ...rest,
        locations,
    };
};

export const availabilityLocationsDefaultUtil = (locations: IAvailabilitySettingsLocations): EventLocationType => {
    if (locations.callback) {
        return EventLocationType.Callback;
    } else if (locations.physical && !locations.virtual) {
        return EventLocationType.Physical;
    } else {
        return EventLocationType.Virtual;
    }
};