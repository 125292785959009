import {CancelToken} from 'axios';

export enum CategoryIcon {
    VIRUS = 'VIRUS',
    USER_COG = 'USER_COG',
    COMPUTER_SETTINGS = 'COMPUTER_SETTINGS',
    INVOICE = 'INVOICE',
    CALENDAR = 'CALENDAR',
    BOOKING = 'BOOKING',
    FILE = 'FILE',
    SECURE = 'SECURE',
    LAWYER = 'LAWYER',
}

export interface IArticleSection {
    name: string;
    description?: string;
    file_id?: string;
    file_public_url?: string;
}

export interface IArticle {
    id: string;
    category_id: string;
    name: string;
    description?: string;
    sections: IArticleSection[];
    user_id: string;
    is_published: boolean;
    is_featured: boolean;
    published_at?: string;
    featured_at?: string;
    created_at: string;
    updated_at: string;
}

export interface IArticleListQuery {
    category_id?: string;
    is_published?: boolean;
    is_featured?: boolean;
    cursor?: string;
    limit: number;
}

export interface IArticleListResult {
    articles: IArticle[];
    next_cursor?: string;
}

export interface ICategoryPictureIcon {
    icon: CategoryIcon;
    file_id: never;
    filename: never;
    content_type: never;
}

export interface ICategoryPictureImage {
    icon: never;
    file_id: string;
    filename: string;
    content_type: string;
    public_url?: string;
}

export interface ICategory {
    id: string;
    name: string;
    description: string;
    created_at: string;
    updated_at: string;
    picture: ICategoryPictureImage | ICategoryPictureIcon;
}

export interface ICategoryListQuery {
    cursor?: string;
    limit: number;
}

export interface ICategoryListResult {
    categories: ICategory[];
    next_cursor?: string;
}

export enum HelpSearchKind {
    CATEGORY = 'CATEGORY',
    ARTICLE = 'ARTICLE',
}

export interface IHelpSearchDocument {
    id: string;
    category_id: string;
    name: string;
    kind: HelpSearchKind;
    description: string;
    is_published: boolean;
    created_at: string;
}

export interface IHelpSearchQuery {
    kind?: HelpSearchKind;
    search_term?: string;
    is_published?: boolean;
    limit: number;
    cursor?: string;
}

export interface IReadHelpSearchProps extends IHelpSearchQuery {
    cancelToken?: CancelToken;
}

export interface IHelpSearchResult {
    documents: IHelpSearchDocument[];
    next_cursor?: string;
}
