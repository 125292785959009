import {atom, atomFamily, selector, selectorFamily} from 'recoil';
import {parseISO} from 'date-fns';

import {ITimeSlotAvailability} from 'modules/availability/models';
import {readAvailability} from 'modules/availability/utils';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/utils/recoil';

export interface IAvailabilityStateKey {
    userId: string;
    startAt: string;
    endAt: string;

    [key: string]: string;
}

interface IAvailabilityState {
    timeSlots: ITimeSlotAvailability[];
    _resetVersion: number;

    [key: string]: ITimeSlotAvailability[] | number;
}

export const availabilityAtom = atomFamily<IAvailabilityState | undefined, IAvailabilityStateKey>({
    key: 'availabilityAtom',
    default: undefined,
});

export const availabilityResetAtom = atom<number>({
    key: 'availabilityResetAtom',
    default: 0,
});

export const availabilityReadSelector = selectorFamily<IAvailabilityState, IAvailabilityStateKey>({
    key: 'availabilityReadSelector',
    get: (key) => async ({get}) => {
        const atomValue = get(availabilityAtom(key));
        const resetVersion = get(availabilityResetAtom);
        if (
            atomValue &&
            atomValue._resetVersion === resetVersion
        ) {
            return atomValue;
        }
        const timeSlots = await readAvailability({
            userId: key.userId,
            startAt: parseISO(key.startAt),
            endAt: parseISO(key.endAt),
            timezone: key.timezone,
        });
        return {
            _resetVersion: resetVersion,
            timeSlots,
        } as IAvailabilityState;
    },
});

export const availabilityInsertSelector = selector<undefined>({
    key: 'availabilityInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const resetAtom = availabilityResetAtom;
        set(resetAtom, get(resetAtom) + 1);
    },
});
