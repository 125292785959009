import {atom, selector, selectorFamily} from 'recoil';

import {readProfile} from 'modules/profile/api/profile';
import {IProfile} from 'modules/profile/models/IProfile';
import {throwWriteOnlySelectorError, guardRecoilDefaultValue} from 'shared/utils/recoil';
import {featuredProfileLookupSelector} from 'modules/profile/state/featured-profile';
import {profileListLookupSelector} from 'modules/profile/state/profile-list';
import {insertProfileSelector} from 'modules/profile/state/profile-insert';

export const profileLookupAtom = atom<IProfile[]>({
    key: 'profileLookup:selector',
    default: [],
});

export const profileLookupSelector = selectorFamily<IProfile | undefined, string>({
    key: 'profileLookupSelector',
    get: (profileId: string) => ({get}) => {
        const atomValue = get(profileLookupAtom);
        const profileInAtom = atomValue.find(profile => profile.id === profileId);
        if (profileInAtom) {
            return profileInAtom;
        }

        const profileInList = get(profileListLookupSelector(profileId));
        if (profileInList) {
            return profileInList;
        }

        const featuredProfile = get(featuredProfileLookupSelector(profileId));
        if (featuredProfile) {
            return featuredProfile;
        }

        return undefined;
    },
    set: () => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(insertProfileSelector, newValue);
    },
});

export const profileLookupReadSelector = selectorFamily<IProfile, string>({
    key: 'profileLookupReadSelector',
    get: (profileId: string) => async ({get}): Promise<IProfile> => {
        const existingProfile = get(profileLookupSelector(profileId));
        if (existingProfile) {
            return existingProfile;
        }
        return await readProfile(profileId);
    },
});

export const profileLookupInsertSelector = selector<IProfile>({
    key: 'profileLookupInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({set, get}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const profiles = get(profileLookupAtom);
        set(profileLookupAtom, [
            ...profiles.filter(profile => profile.id !== newValue.id),
            newValue,
        ]);
    },
});

export const profileLookupRemoveSelector = selector<string>({
    key: 'profileLookupRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({set, get}, profileId) => {
        if (guardRecoilDefaultValue(profileId)) {
            return;
        }
        const profiles = get(profileLookupAtom);
        set(profileLookupAtom, profiles.filter(profile => profile.id !== profileId));
    },
});

export const profileLookupBatchSelector = selectorFamily<IProfile[], string[]>({
    key: 'profileLookupBatchSelector',
    get: (profileIds) => ({get}) => {
        const atomValue = get(profileLookupAtom);
        return atomValue.filter(profile => profileIds.includes(profile.id));
    },
});
