import React from 'react';

interface ICaretProps {
    className?: string;
}

export const Caret = ({className}: ICaretProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9.191"
            height="5.036"
            viewBox="0 0 9.191 5.036"
            className={className}
        >
            <g id="down-filled-triangular-arrow" transform="translate(0 -28.017)">
                <path
                    id="Path_14555"
                    data-name="Path 14555"
                    d="M8.747,28.017H.443a.442.442,0,0,0-.311.756l4.152,4.152a.443.443,0,0,0,.623,0l4.152-4.152A.442.442,0,0,0,8.747,28.017Z"
                    transform="translate(0)"
                    fill="#788b9a"
                />
            </g>
        </svg>
    );
};
