import {useRecoilLoadable} from 'shared/hooks/useRecoilLoadable';
import {profileSummarySelector, profileSummaryReadSelector} from 'modules/profile/state/profile-summary';

export const useProfileSummary = (profileId: string) => {
    const {data, loading, error} = useRecoilLoadable({
        state: profileSummarySelector(profileId),
        loadable: profileSummaryReadSelector(profileId),
    });
    return {
        profileSummary: data,
        loading,
        error,
    };
};
