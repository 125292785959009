import React from 'react';

import {HelpSearch} from 'public/help-center/components/HelpSearch/HelpSearch';

import './style.scss';

export const HelpCenterHeader = () => {

    return (
        <div className="HelpCenterHeader">
            <div className="content-page-header ">
                <h2 className="content-page-title">How can we help you?</h2>
            </div>
            <HelpSearch/>
        </div>
    );
};
