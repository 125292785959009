import React from 'react';

import {Col, Container, Nav, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import {AppFooterSubscribeForm} from 'shared/components/AppFooter/AppFooterSubscribeForm/AppFooterSubscribeForm';
import {ContactItem} from 'shared/components/AppFooter/ContactItem/ContactItem';
import {AppFooterBottom} from 'shared/components/AppFooter/AppFooterBottom/AppFooterBottom';

import './style.scss';
import {useWindowSize} from 'shared/hooks/useWindowSize';

interface IAppFooterProps {
    bg: 'dark' | 'light';
    subscribe?: boolean;
}

export const AppFooter = ({bg, subscribe}: IAppFooterProps) => {
    const footerClassName = `AppFooter ${bg !== 'light' ? 'bg-light' : ''}`;

    const windowWidth = useWindowSize();

    return (
        <footer className={footerClassName}>
            {subscribe && <AppFooterSubscribeForm />}
            {windowWidth.width > 576 ? (
                <Container className="AppFooter__section-middle ">
                    <Row>
                        <Col xs={12} xl={5} className="text-left">
                            <div className="AppFooter__Logo-info-box ">
                                <img
                                    className="AppFooter__brand"
                                    src="/assets/Group2091SidebarLogo.png"
                                    alt="Rightful Logo"
                                />
                                <p className="AppFooter__info-text AppFooter__grey-text mb-5">
                                    Rightful is a first of its kind legal collaboration platform. Rightful is owned and
                                    operated by NewLaw Chambers Pty Ltd ACN 642 771 239. Neither the platform nor its
                                    operating entity are a legal practice. Any legal services received are between you
                                    and the individual legal practitioners directly. Rightful paralegals do not provide
                                    legal services. For more information see our terms and conditions.
                                </p>
                            </div>
                        </Col>
                        <Col xs={6} xl={2} className="text-left AppFooter__nav-link-column">
                            <h5 className="AppFooter__heading">Corporate</h5>
                            <Nav defaultActiveKey="/home" className="flex-column">
                                <Nav.Link className="AppFooter__nav-link" as={Link} to="/about">
                                    About Us
                                </Nav.Link>
                                <Nav.Link className="AppFooter__nav-link" as={Link} to="/faq">
                                    FAQs
                                </Nav.Link>
                                <Nav.Link className="AppFooter__nav-link" as={Link} to="/plans">
                                    Pricing
                                </Nav.Link>
                                {/* <Nav.Link className="AppFooter__nav-link" as={Link} to="/techproducts">
                                    Tech Products
                                </Nav.Link> */}
                                <Nav.Link className="AppFooter__nav-link" as={Link} to="/development-pipeline">
                                    Development Pipeline
                                </Nav.Link>
                            </Nav>
                        </Col>
                        <Col xs={6} xl={2} className="text-left AppFooter__nav-link-column">
                            <h5 className="AppFooter__heading">Help</h5>
                            <Nav defaultActiveKey="/home" className="flex-column">
                                <Nav.Link className="AppFooter__nav-link" as={Link} to="/help-center">
                                    Help Centre
                                </Nav.Link>
                                <Nav.Link className="AppFooter__nav-link" as={Link} to="/cool-legal-links-page">
                                    Cool legal links
                                </Nav.Link>
                                <Nav.Link className="AppFooter__nav-link" as={Link} to="/guide-choose-legal-team">
                                    Guide to choosing your
                                    <br />
                                    legal team
                                </Nav.Link>
                            </Nav>
                        </Col>
                        <Col xs={12} xl={2} className="text-left  newBoxLink__right newBoxLink__left">
                            <h5 className="AppFooter__heading">Contact Us</h5>
                            <ContactItem
                                image="/assets/homepage/Footer/envelope.png"
                                title="Email"
                                contact="enquiries@rightful.com.au"
                                alt="The Rightful email address"
                            />
                            <ContactItem
                                image="/assets/homepage/Footer/Group 9568.png"
                                title="Phone Number"
                                contact="1300 755 491"
                                alt="The Rightful phone number"
                            />
                            <ContactItem
                                image="/assets/homepage/Footer/Group -1.png"
                                title="Address"
                                contact="Level 21, 133 Castlereagh Street Sydney NSW 2000"
                                alt="The Rightful physical address"
                            />
                        </Col>
                    </Row>
                </Container>
            ) : (
                <Container className="AppFooter__section-middle">
                    <div className="AppFooter__Logo-info-box ">
                        <div>
                            <img
                                className="AppFooter__brand"
                                src="/assets/Group2091SidebarLogo.png"
                                alt="Rightful Logo"
                            />
                        </div>

                        {/* <p className="AppFooter__info-text AppFooter__grey-text mb-5">
                            Rightful is a first of its kind legal collaboration platform. Rightful is owned and operated
                            by NewLaw Chambers Pty Ltd ACN 642 771 239. Neither the platform nor its operating entity
                            are a legal practice. Any legal services received are between you and the individual legal
                            practitioners directly. Rightful paralegals do not provide legal services. For more
                            information see our terms and conditions.
                        </p> */}
                    </div>
                    {/* <div className="AppFooter__middleSection">
                        <h5 className="AppFooter__heading">Corporate</h5>
                        <Nav defaultActiveKey="/home" className="flex-column">
                            <Nav.Link className="AppFooter__nav-link" as={Link} to="/terms">
                                Terms & Conditions
                            </Nav.Link>
                            <Nav.Link className="AppFooter__nav-link" as={Link} to="/privacy">
                                Privacy Policy
                            </Nav.Link>
                        </Nav>
                    </div> */}
                    <div className="mx-2">
                        <ContactItem
                            image="/assets/homepage/Footer/envelope.png"
                            title=""
                            contact="enquiries@rightful.com.au"
                            alt="The Rightful email address"
                        />
                        <ContactItem
                            image="/assets/homepage/Footer/Group 9568.png"
                            title=""
                            contact="1300 755 491"
                            alt="The Rightful phone number"
                        />
                        <ContactItem
                            image="/assets/homepage/Footer/Group -1.png"
                            title=""
                            contact="Level 21, 133 Castlereagh Street Sydney NSW 2000"
                            alt="The Rightful physical address"
                        />
                    </div>
                </Container>
            )}
            <AppFooterBottom />
        </footer>
    );
};

AppFooter.defaultProps = {
    bg: 'light',
};
