import React from 'react';

import ReactPlayer from 'react-player';
import { setTimeout } from 'timers';

import { toast } from 'shared/utils/toast';

interface IPodCastMusicPlayerProps {
    URL?: string;
    errorFunction: () => void;
}

export const PodCastMusicPlayer = ({URL, errorFunction}: IPodCastMusicPlayerProps) => {
    return (
        <div>
            <ReactPlayer
                width="100%"
                height="60px"
                style={{borderRadius: '0.98rem', overflow: 'none'}}
                controls={true}
                playing={true}
                onError={(error, data) => {
                    setTimeout(() => {
                        errorFunction();
                        toast.error('Podcast ', 'Something went wrong!!.');
                    }, 1000);
                }}
                url={URL ? URL : ''}
            />
        </div>
    );
};
