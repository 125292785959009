import {useRecoilLoadable} from 'shared/hooks/useRecoilLoadable';
import {subscriptionAtom, subscriptionReadSelector} from '../state/subscription';

export const useSubscriptionLoadable = () => {
    const {data, loading, error} = useRecoilLoadable({
        state: subscriptionAtom,
        loadable: subscriptionReadSelector,
    });
    return {
        subscription: data,
        loading,
        error,
    };
};
