import PlansAndFeaturesTable from '../PlansAndFeaturesTable/PlansAndFeaturesTable';
import './style.scss';

const PlansAndFeatures = () => {
    return (
        <div className="PricingPlans">
            <div className="PricingPlans__title">Compare plans & features</div>
            <PlansAndFeaturesTable/>
        </div>
    );
};

export default PlansAndFeatures;
