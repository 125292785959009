import React from 'react';

import {Modal} from 'shared/components/modals/Modal/Modal';
import {ModalBody} from 'shared/components/modals/Modal/ModalBody';
import {PodCastMusicPlayer} from 'shared/components/PodCastMusicPlayer/PodCastMusicPlayer';

import './style.scss';

interface IPodcastPlayerModalProps {
    open: boolean;
    onHide: () => void;
    playerURL?: string;
}

export const PodcastPlayerModal = ({open, onHide, playerURL}: IPodcastPlayerModalProps) => {
    return (
        <Modal
            show={open}
            onHide={() => {
                onHide();
            }}
            centered
            className="PodcastPlayerModal"
        >
            <ModalBody className="PodcastPlayerModal__body">
                <PodCastMusicPlayer URL={playerURL} errorFunction={onHide}/>
            </ModalBody>
        </Modal>
    );
};
