import React from 'react';

import { Container } from 'react-bootstrap';

import { FormSize } from 'shared/components/form/Forms/FormSize';

import 'shared/components/form/Forms/style.scss';

interface IMainFormProps {
    classNameWrapper?: string;
    classNameForm?: string;
    title?: string;
    width: FormSize;
    children?: React.ReactNode;
}

export const MainForm = ({title, width, children, classNameWrapper, classNameForm}: IMainFormProps) => {
    const cssMainFormWrapper = `${classNameWrapper || ''} MainForm__wrapper`;
    const cssMainForm = `${classNameForm || ''} MainForm__form`;

    return (
        <Container className="d-flex flex-column">
            <div className={cssMainFormWrapper}>
                {title && <h2 className="MainForm__title">{title}</h2>}
                <div className={width}>
                    <div className={cssMainForm}>{children}</div>
                </div>
            </div>
        </Container>
    );
};
