import React, {useState} from 'react';

import {Button, Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';

import {Role} from 'modules/profile/models/IProfile';
import {IProfileCreate} from 'modules/profile/models/IProfileCreate';
import {toast} from 'shared/utils/toast';
import {getErrorMessage} from 'shared/utils/error';
import {useNavbar} from 'shared/hooks/useNavbar';
import {useGuaranteedAuth} from 'shared/auth/hooks/useAuth';
import {createProfile} from 'modules/profile/api/profile';

import {Spinner} from 'shared/components/Spinner/Spinner';
import {SignupPageControls} from 'auth/SignupPage/SignupPageControls';
import {AuthPage} from 'auth/AuthPage/AuthPage';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';

import './style.scss';

const SignupFormSchema = z.object({
    firstname: z.string().min(1, 'You must select your firstname'),
    lastname: z.string().min(1, 'You must enter your surname'),
    phone_number: z.string(),
    is_accepted_terms: z.literal('true'),
    role: z.enum([Role.Client, Role.Provider]),
});

export interface IProfileCreateForm extends IProfileCreate {
    user_id: never;
    email: string;
    is_accepted_terms: boolean;
}

export const SignupPage = () => {
    useNavbar(true);
    const {tokenData, signOut} = useGuaranteedAuth();
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<IProfileCreateForm>({
        resolver: zodResolver(SignupFormSchema),
        defaultValues: {
            email: tokenData?.email ?? '',
        },
    });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const pageTitle: string = 'Finish Setup';

    const onSubmit = handleSubmit((formData) => {
        if (isSubmitting) {
            return;
        } else {
            setIsSubmitting(true);
        }
        (async () => {
            try {
                await createProfile({
                    user_id: tokenData.id,
                    role: formData.role,
                    firstname: formData.firstname,
                    lastname: formData.lastname,
                    phone_number: formData.phone_number,
                });
                window.location.href = '/';
            } catch (error) {
                toast.error('Sign-Up Error', getErrorMessage(error));
            } finally {
                setIsSubmitting(false);
            }
        })();
    });

    return (
        <AuthPage className="SignupPage" title={pageTitle} terms={false} size="lg">
            <PageHelmet noindex title={pageTitle}/>
            <Form onSubmit={onSubmit}>
                <SignupPageControls register={register} errors={errors} disabled={isSubmitting}/>
                <div className="SignupPage__form-footer text-center">
                    <Button
                        variant="featured"
                        type="submit"
                        disabled={isSubmitting}
                        className="AuthPage__button"
                    >
                        {isSubmitting && (
                            <>
                                Creating Account <Spinner size="sm"/>
                            </>
                        )}
                        {!isSubmitting && <span>CREATE ACCOUNT</span>}
                    </Button>
                    <div className="pt-3">
                        <Button variant="link text-muted" onClick={() => signOut()}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Form>
        </AuthPage>
    );
};
