import React from 'react';
import './FAQ.scss';

const FAQ = () => {
    const [activeIndex, setActiveIndex] = React.useState<number | null>(0);
    const faqData = [
        {
            title: 'Is my payment information secure?',
            description:
                "Yes like the titanic it's buried at the bottom of the ocean. We use submarine level encryption.",
        },
        {
            title: 'What if I cancel early?',
            description: "We aren't pirates. We'll work something out with you.",
        },
        {
            title: 'Why upgrade?',
            description: 'It is your ticket to an all you can eat seafood buffet.',
        },
        {
            title: 'Your founder is a bit weird, why are all these sea metaphors?',
            description:
                "Our founder is weirder then black beard himself. He wrote this page before people were actually asking payments related questions. So he had to make it up and chatGPT wouldn't give him the answer.",
        },
        {
            title: 'What if I am stuck at sea?',
            description: 'Hopefully you have a life boat, flare, maybe some rations, water and a life jacket.',
        },
        {
            title: 'Where can I catch a good wave?',
            description: 'The South Coast of Australia.',
        },
        {
            title: 'Are your packages even worth it?',
            description:
                'We think so. Legal products and services at your fingertips. It is like scouting for treasure your whole life and someone hands you a metal detector.',
        },
    ];

    const toggleFAQ = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <>
            <div className="faq__title">FAQ</div>
            <div className="faq-container">
                {faqData.map((faq, index) => {
                    console.log('index =====', activeIndex === index);
                    return (
                        <div className="faq-item" key={index}>
                            <div
                                className={`faq-question ${activeIndex === index ? 'active' : ''}`}
                                onClick={() => toggleFAQ(index)}
                            >
                                {faq.title}
                                <span className="faq-icon">
                                    {' '}
                                    <img
                                        src={
                                            activeIndex === index
                                                ? '/assets/plans/arrow-up.svg'
                                                : '/assets/plans/arrow-down.svg'
                                        }
                                        alt="img"
                                    />
                                </span>
                            </div>
                            <div className={`faq-answer ${activeIndex === index ? 'show' : ''}`}>
                                <p className="mb-0">{faq.description}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default FAQ;
