import React from 'react';
import {Button, Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';

import {Profession} from 'modules/profile/models/profession';
import {IProfile} from 'modules/profile/models/IProfile';
import {professionValues} from 'modules/profile/constants';

import {Spinner} from 'shared/components/Spinner/Spinner';

interface IUpdateProfileFormProps {
    profile: IProfile;
    disabled: boolean;
    onSubmit: (formData: UpdateProfileFormData) => void;
    onCancel: () => void;
}

export type UpdateProfileFormData = {
    firstname: string;
    lastname: string;
    profession: Profession;
};

export const UpdateProfileForm = ({profile, onSubmit, disabled, onCancel}: IUpdateProfileFormProps) => {
    const {register, handleSubmit} = useForm<UpdateProfileFormData>();

    const onFormSubmit = handleSubmit((formData: UpdateProfileFormData) => {
        onSubmit(formData);
    });

    return (
        <Form onSubmit={onFormSubmit}>
            <Form.Group controlId="UpdateProfileForm__firstname">
                <Form.Control
                    {...register('firstname')}
                    placeholder="First name"
                    disabled={disabled}
                    defaultValue={profile.firstname}
                    autoFocus
                />
            </Form.Group>
            <Form.Group controlId="UpdateProfileForm__lastname">
                <Form.Control
                    {...register('lastname')}
                    placeholder="Last name"
                    disabled={disabled}
                    defaultValue={profile.lastname}
                />
            </Form.Group>
            <Form.Group controlId="UpdateProfileForm__profession">
                <Form.Control
                    as="select"
                    {...register('profession')}
                    disabled={disabled}
                    defaultValue={profile.profession || undefined}
                >
                    {professionValues.map((professionVal) => (
                        <option key={professionVal.value} value={professionVal.value}>
                            {professionVal.title}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>
            {/*<Form.Group controlId="UpdateProfileForm__country">*/}
            {/*    <Form.Control*/}
            {/*        {...register('country')}*/}
            {/*        placeholder="Country"*/}
            {/*        disabled={disabled}*/}
            {/*        defaultValue={profile.contact_details_display && profile.contact_details_display.country}*/}
            {/*    />*/}
            {/*</Form.Group>*/}
            {/*<Form.Group controlId="UpdateProfileForm__state">*/}
            {/*    <Form.Control*/}
            {/*        {...register('state')}*/}
            {/*        placeholder="State"*/}
            {/*        disabled={disabled}*/}
            {/*        defaultValue={profile.contact_details_display && profile.contact_details_display.state}*/}
            {/*    />*/}
            {/*</Form.Group>*/}
            <Button variant="featured" type="submit" disabled={disabled}>
                {disabled ? 'Saving changes' : 'Save Changes'}
            </Button>
            {disabled && (
                <Spinner size="sm" className="ml-1" />
            )}
            <Button type="button" variant="default" onClick={() => onCancel()}>
                Cancel
            </Button>
        </Form>
    );
};
