import React from 'react';

import ReactPlayer from 'react-player';

interface IVideoPlayerProps {
    className?: string;
    videoURL?: string;
}

export const VideoPlayer = ({className, videoURL}: IVideoPlayerProps) => {
    const videoPlayerRef = React.useRef<ReactPlayer | null>(null);
    const playerRef = React.useRef<HTMLDivElement | null>(null);

    const [state, setState] = React.useState({
        url: null,
        pip: false,
        playing: false,
        controls: false,
        light: false,
        volume: 0.8,
        muted: false,
        played: 0,
        loaded: 0,
        duration: 10,
        playbackRate: 1.0,
        loop: false,
        volumeOpen: false,
        dropdownOpen: false,
        fullscreen: false,
        seeking: false,
        playedSeconds: 0,
    });

    return (
        <React.Fragment>
            <div className="WebinarPreviewModal__player-wrapper" ref={playerRef}>
                <ReactPlayer
                    ref={videoPlayerRef}
                    width="100%"
                    style={{borderRadius: '0.98rem', overflow: 'none'}}
                    className={className}
                    height="100%"
                    controls={true}
                    playing={state.playing}
                    onStart={() => setState({...state})}
                    url={videoURL}
                />
            </div>
        </React.Fragment>
    );
};
