import {useRecoilValue} from 'recoil';

import {useRecoilLoadable} from 'shared/hooks/useRecoilLoadable';
import {
    projectSearchStateSelector,
    projectSearchStateReadSelector,
    projectSearchSelector,
} from 'modules/project/state/project-search';
import {useDebounce} from 'shared/hooks/useDebounce';

interface IUseProjectSearchLoadableProps {
    userId: string;
    searchTerm: string;
    limit: number;
}

export const useProjectSearchLoadable = ({userId, searchTerm, limit}: IUseProjectSearchLoadableProps) => {
    const debouncedSearchTerm = useDebounce(searchTerm, 200);

    const {data, loading, error} = useRecoilLoadable({
        state: projectSearchStateSelector(userId),
        loadable: projectSearchStateReadSelector(userId),
    });

    const projects = useRecoilValue(projectSearchSelector({
        userId,
        limit,
        searchTerm: debouncedSearchTerm,
    }));

    const isLoading = (
        loading ||
        error ||
        searchTerm !== debouncedSearchTerm
    );

    return {
        total: data?.projects?.length ?? 0,
        projects,
        loading: isLoading,
        error,
    };
};
