import {atomFamily, selectorFamily} from 'recoil';
import {guardRecoilDefaultValue} from 'shared/utils/recoil';

import {readChatSummary} from '../api';
import {IChatSummary} from '../models';

export interface IChatSummaryAtom {
    chatSummary: IChatSummary;
    resetVersion: number;

    [key: string]: number | IChatSummary;
}

export const chatSummaryAtom = atomFamily<IChatSummaryAtom | undefined, string>({
    key: 'chatSummaryAtom',
    default: undefined,
});

export const chatSummaryResetAtom = atomFamily<number, string>({
    key: 'chatSummaryResetAtom',
    default: 0,
});

export const chatSummarySelector = selectorFamily<IChatSummaryAtom | undefined, string>({
    key: 'chatSummaryReadSelector',
    get: (chat_id) => ({get}) => {
        const atomValue = get(chatSummaryAtom(chat_id));
        const resetVersion = get(chatSummaryResetAtom(chat_id));
        if (
            atomValue &&
            resetVersion === atomValue.resetVersion &&
            atomValue.chatSummary.id === chat_id
        ) {
            return atomValue;
        }

        return undefined;
    },
    set: (chat_id) => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return null;
        }
        set(chatSummaryAtom(chat_id), newValue);
    },
});

export const chatSummaryReadSelector = selectorFamily<IChatSummaryAtom, string>({
    key: 'chatSummaryReadSelector',
    get: (chat_id) => async ({get}) => {
        const currentValue = get(chatSummarySelector(chat_id));
        if (currentValue) {
            return currentValue;
        }

        const resetVersion = get(chatSummaryResetAtom(chat_id));

        const response = await readChatSummary(chat_id);

        return {
            chatSummary: response,
            resetVersion,
        } as IChatSummaryAtom;
    },
});
