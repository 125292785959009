import React, {useEffect, useState} from 'react';

import {Col, Row} from 'react-bootstrap';

import './style.scss';

interface IProfilePageSkillsItemProps {
    name: string;
    level: number;
}

export const ProfilePageSkillsItem = ({name, level}: IProfilePageSkillsItemProps) => {
    const stars = [];
    for (let i = 0; i < level; i += 1) {
        stars.push(<div key={`star-${i}`} className="ProfilePageSkillsItem__star" />);
    }
    for (let i = 0; i < 5 - level; i += 1) {
        stars.push(
            <div key={`gray-star-${i}`} className="ProfilePageSkillsItem__star ProfilePageSkillsItem__star--gray" />,
        );
    }
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Row className="ProfilePageSkillsItem d-flex align-items-center justify-content-between">
            <Col
                xs={5}
                sm={12}
                xl={5}
                style={{paddingLeft: windowWidth < 577 ? '20%' : '15px', textAlign: 'left'}}
                className="ProfilePageSkillsItem__name"
            >
                {name}
            </Col>
            <Col
                xs={7}
                sm={12}
                xl={7}
                style={{textAlign: windowWidth < 577 ? 'center' : 'left'}}
                className="ProfilePageSkillsItem__stars"
            >
                {stars}
            </Col>
        </Row>
    );
};
