import {atom, selector, selectorFamily} from 'recoil';

import {IProfile} from 'modules/profile/models/IProfile';
import {readProfileList} from 'modules/profile/api/profile';
import {homepageCacheAtom} from 'modules/homepage/state';
import {guardRecoilDefaultValue} from 'shared/utils/recoil';

export const featuredProfilesAtom = atom<IProfile[] | undefined>({
    key: 'rootFeaturedProfileState',
    default: undefined,
});

export const featuredProfileListSelector = selector<IProfile[] | undefined>({
    key: 'featuredProfileListSelector',
    get: ({get}) => {
        const atomValue = get(featuredProfilesAtom);
        if (atomValue) {
            return atomValue;
        }

        const homepageCache = get(homepageCacheAtom);
        if (homepageCache) {
            return homepageCache.featured_profiles;
        }

        return undefined;
    },
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return null;
        }
        set(featuredProfilesAtom, newValue);
    },
});

export const featuredProfileListReadSelector = selector<IProfile[]>({
    key: 'featuredProfileListReadSelector',
    get: async ({get}) => {
        const atomValue = get(featuredProfileListSelector);
        if (atomValue) {
            return atomValue;
        }
        const profileList = await readProfileList({
            isPublished: true,
            isFeatured: true,
            limit: 100,
        });
        return profileList.profiles;
    },
});

export const featuredProfileLookupSelector = selectorFamily<IProfile | undefined, string>({
    key: 'featuredProfileLookupSelector',
    get: (profileId) => ({get}) => {
        const atomValue = get(featuredProfilesAtom);
        return atomValue?.find(profile => profile.id === profileId);
    },
});
