import React from 'react';

import {Container} from 'react-bootstrap';

import {PostKind} from 'modules/post/models/postKind';
import {postKindFilters} from 'modules/post/constants/post-kind';
import {useHomepageCache} from 'modules/homepage/hooks';

import {PostCarousel} from 'shared/components/PostCarousel/PostCarousel';
import {CarouselNav} from 'public/HomePage/shared/CarouselNav/CarouselNav';
import {BadgeFilter} from 'shared/components/BadgeFilter/BadgeFilter';

import './style.scss';

export const HomePagePosts = () => {
    const [postKind, setPostKind] = React.useState<PostKind>();

    const {homepageCache, loading, error} = useHomepageCache();

    return (
        <Container className="HomePage__section MaxWidthAdjustment">
            <div className="HomePageReview__mainheading">
                <h2 className="HomePageReview__heading">Featured Posts</h2>
            </div>
            <CarouselNav viewAll="/resources">
                <div className="d-flex align-items-center flex-wrap">
                    {postKindFilters.map(postKindFilter => (
                        <BadgeFilter
                            key={postKindFilter.value || ''}
                            onClick={() => setPostKind(postKindFilter.value)}
                            title={postKindFilter.title}
                            active={postKind === postKindFilter.value}
                        />
                    ))}
                </div>
            </CarouselNav>
            <PostCarousel
                kind={postKind}
                posts={homepageCache?.featured_posts}
                loading={loading}
                error={error ? 'Error loading posts' : undefined}
            />
        </Container>
    );
};
