import React from 'react';

import {Button, Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';

import {IProfile} from 'modules/profile/models/IProfile';
import {bookingModalBookingSchema, IBookingModalBookingForm} from 'shared/components/BookingModal/schema';
import {useGuaranteedAuth} from 'shared/auth/hooks/useAuth';
import {useAvailabilitySettingsLoadable} from 'modules/availability-settings/hooks/useAvailabilitySettingsLoadable';

import {InputController} from 'shared/components/form/InputController/InputController';
import {TimeSlotController} from 'shared/components/form/TimeSlotControl/TimeSlotController';
import {BookingModalHeader} from 'shared/components/BookingModal/BookingModalHeader/BookingModalHeader';
import {ProjectSearchController} from 'shared/components/form/ProjectSearchController/ProjectSearchController';
import {CreateProjectModal} from 'shared/components/project/CreateProjectModal/CreateProjectModal';
import {BookingModalFormSkeleton} from 'shared/components/BookingModal/BookingModalFormSkeleton';
import {BookingModalNotAvailable} from 'shared/components/BookingModal/BookingModalNotAvailable';

import './style.scss';

interface IBookingModalFormProps {
    profile: IProfile;
    onSubmit: (formData: IBookingModalBookingForm) => void;
    durationMinutes: number;
    formReset?: number;
    onHide: () => void;
}

export const BookingModalForm = ({profile, onSubmit, durationMinutes, formReset, onHide}: IBookingModalFormProps) => {
    const {tokenData} = useGuaranteedAuth();
    const {loading, availabilitySettings} = useAvailabilitySettingsLoadable(profile.id);

    const isAvailable = React.useMemo(() => {
        return availabilitySettings?.availability.some(availability => availability.is_available);
    }, [availabilitySettings]);

    const [currentReset, setCurrentReset] = React.useState<number | undefined>(formReset);
    const [showCreateModal, setShowCreateModal] = React.useState<boolean>(false);

    const {handleSubmit, register, control, reset, watch, formState, setValue} = useForm<IBookingModalBookingForm>({
        resolver: zodResolver(bookingModalBookingSchema),
        defaultValues: {
            description: '',
        },
    });

    // reset the form whenever the formReset value changes
    React.useEffect(() => {
        if (formReset !== currentReset) {
            reset();
            setCurrentReset(formReset);
        }
    }, [formReset, currentReset, reset, setCurrentReset]);

    const onFormSubmit = handleSubmit((formData) => {
        onSubmit(formData);
        // navigate('/profile/confirm-appointment');
    });

    const isProject = watch('isProject');
    const projectId = watch('project_id');
    // this lastProjectId state lets us update the form values whenever it changes, so we can change it to "Yes" when a
    // new project is created, or clear the selected project when the user clicks No.
    const [lastProjectId, setLastProjectId] = React.useState<string | undefined>(projectId);

    React.useEffect(() => {
        if (isProject === 'false' && !!projectId?.length) {
            if (!!lastProjectId?.length) {
                setValue('project_id', undefined);
            } else {
                setValue('isProject', 'true');
            }
        }
        setLastProjectId(projectId);
    }, [projectId, isProject, setValue, lastProjectId, setLastProjectId]);

    // return the skeleton state if loading
    if (loading) {
        return (
            <BookingModalFormSkeleton />
        );
    }

    // if not available, return the not available state
    if (!isAvailable) {
        return (
            <BookingModalNotAvailable profile={profile} durationMinutes={durationMinutes} onHide={onHide}/>
        );
    }

    return (
        <>
            <Form onSubmit={onFormSubmit} noValidate>
                <BookingModalHeader profile={profile} durationMinutes={durationMinutes} />
                <Form.Group>
                    <Form.Label className="BookingModal__label">Select Date & Time</Form.Label>
                    <TimeSlotController control={control} name="start_at" userId={profile.id} />
                </Form.Group>
                <Form.Group controlId="BookingForm.description" className="mb-4">
                    <Form.Label className="BookingModal__label text-dark">
                        Briefly, what is this booking about?
                    </Form.Label>
                    <InputController
                        asType="textarea"
                        name="description"
                        control={control}
                        className="BookingModal__textarea"
                        placeholder="Write here"
                    />
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label className="BookingModal__label text-dark">
                        Is this about an existing legal project you have with us?
                    </Form.Label>
                    <div>
                        <Form.Check
                            id="BookingModalForm.isProject.yes"
                            type="radio"
                            value="true"
                            label="Yes"
                            inline
                            custom
                            isInvalid={!!formState.errors.isProject}
                            {...register('isProject')}
                        />
                        <Form.Check
                            id="BookingModalForm.isProject.no"
                            type="radio"
                            value="false"
                            label="No"
                            inline
                            custom
                            isInvalid={!!formState.errors.isProject}
                            {...register('isProject')}
                        />
                    </div>
                    {formState.errors && formState.errors?.isProject && (
                        <Form.Control.Feedback type="invalid" className="d-block">
                            Required
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
                {isProject && (
                    <Form.Group>
                        <ProjectSearchController
                            hideSearch={isProject === 'false'}
                            hideCreate={isProject === 'true'}
                            control={control}
                            name="project_id"
                            userId={tokenData.id}
                            controlClassName="BookingModal__project-search-control"
                            placeholder="Search for your legal projects"
                            createButtonTitle={isProject === 'true' ? undefined : '+ Upload brief'}
                            onCreateClick={() => setShowCreateModal(true)}
                        />
                    </Form.Group>
                )}
                <Form.Group className="text-right">
                    <Button type="submit" variant="featured" className="btn-xl-wide">
                        CONFIRM APPOINTMENT
                    </Button>
                </Form.Group>
            </Form>
            <CreateProjectModal
                show={showCreateModal}
                isBrief={showCreateModal}
                onHide={() => setShowCreateModal(false)}
                profileId={profile.id}
                onComplete={(projectId) => setValue('project_id', projectId)}
            />
        </>
    );
};
