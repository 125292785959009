export const defaultPopperConfig = {
    modifiers: [
        {
            name: 'computeStyles',
            options: {
                gpuAcceleration: false, // true by default
            },
        },
    ],
};
