import {atom, selector, selectorFamily} from 'recoil';

import {IPost} from 'modules/post/models/IPost';
import {IPostListQuery} from 'modules/post/models/IPostListQuery';
import {CursorList} from 'shared/types/cursor-list';
import {readPostList} from 'modules/post/api';
import {comparePostFilters} from '../utils';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/utils/recoil';

export interface IPostListStateFilters {
    filters: IPostListQuery;
    page: number;

    [key: string]: number | IPostListQuery;
}

interface IPostListState {
    filters: IPostListQuery;
    posts: IPost[];
    cursors: CursorList;
    page: number;
    more: boolean;
    _resetVersion: number;

    [key: string]: number | boolean | IPost[] | CursorList | IPostListQuery;
}

export const postListAtom = atom<IPostListState | undefined>({
    key: 'postList:atom',
    default: undefined,
});

export const postListResetAtom = atom<number>({
    key: 'postListResetAtom',
    default: 0,
});

export const postListSelector = selectorFamily<IPostListState | undefined, IPostListStateFilters>({
    key: 'postListSelector',
    get: ({page, filters}) => ({get}) => {
        const postListState = get(postListAtom);
        const resetVersion = get(postListResetAtom);
        if (
            postListState &&
            page === postListState.page &&
            comparePostFilters(filters, postListState.filters) &&
            postListState._resetVersion === resetVersion
        ) {
            return {...postListState};
        }
        return undefined;
    },
    set: () => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return null;
        }
        set(postListAtom, newValue);
    },
});

export const postListReadSelector = selectorFamily<IPostListState, IPostListStateFilters>({
    key: 'postListReadSelector',
    get: (filters) => async ({get}) => {
        const currentValue = get(postListSelector(filters));
        if (currentValue) {
            return currentValue;
        }

        const postListState = get(postListAtom);
        const resetVersion = get(postListResetAtom);

        const result = await readPostList({
            ...filters.filters,
            cursor: filters.page === 0 ? undefined : postListState?.cursors[filters.page - 1],
        });

        // add the cursor to the list of cursors so that we can paginate backwards
        const cursors: CursorList = postListState ? Array.from(postListState.cursors) : [];
        cursors[filters.page] = result.nextCursor;

        return {
            filters: filters.filters,
            posts: result.posts,
            cursors,
            page: filters.page,
            more: !!result.nextCursor,
            _resetVersion: resetVersion,
        } as IPostListState;
    },
});

export const deletePostListSelector = selector<IPost>({
    key: 'postList:delete',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        const atomValue = get(postListAtom);
        if (atomValue) {
            set(postListAtom, {
                ...atomValue,
                posts: atomValue.posts.filter(post => post.id !== newValue.id),
            });
        } else {
            set(postListResetAtom, get(postListResetAtom) + 1);
        }
    },
});

export const insertPostListSelector = selector<IPost>({
    key: 'postList:insert',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        // if the post is present in the store then update it. Otherwise, force a reload.
        const atomValue = get(postListAtom);
        const postIndex = atomValue?.posts?.findIndex(post => post.id === newValue.id);
        if (atomValue && typeof postIndex !== 'undefined' && postIndex !== -1) {
            const newPosts = Array.from(atomValue.posts);
            newPosts.splice(postIndex, 1, newValue);
            set(postListAtom, {
                ...atomValue,
                posts: newPosts,
            });
        } else {
            set(postListResetAtom, get(postListResetAtom) + 1);
        }
    },
});
