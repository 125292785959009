import {selector, selectorFamily, atomFamily} from 'recoil';

import {IFavorite, IFavoriteKey} from 'modules/favorite/models';
import {readFavoriteMaybe} from 'modules/favorite/api';
import {throwWriteOnlySelectorError, guardRecoilDefaultValue} from 'shared/utils/recoil';
import {favoriteListAtom} from 'modules/favorite/state/favorite-list';

interface IFavoriteLookupState {
    favorite: IFavorite | undefined;
}

const favoriteKeyToString = (favoriteKey: IFavoriteKey) => {
    return `${favoriteKey.user_id}-${favoriteKey.profile_id}`;
};

export const favoriteLookupAtom = atomFamily<IFavoriteLookupState | undefined, string>({
    key: 'favoriteLookupAtom',
    default: undefined,
});

export const favoriteLookupSelector = selectorFamily<IFavoriteLookupState | undefined, IFavoriteKey>({
    key: 'favoriteLookupSelector',
    get: (favoriteKey) => ({get}) => {
        const favoriteKeyStr = favoriteKeyToString(favoriteKey);
        const currentValue = get(favoriteLookupAtom(favoriteKeyStr));
        if (currentValue) {
            return currentValue;
        }

        const favoriteList = get(favoriteListAtom);
        const favoriteInList = favoriteList?.favorites.find(favorite => (
            favorite.user_id === favoriteKey.user_id &&
            favorite.profile_id === favoriteKey.profile_id
        ));
        if (favoriteInList) {
            return {
                favorite: favoriteInList,
            };
        }

        return undefined;
    },
    set: (favoriteKey) => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }

        const favoriteKeyStr = favoriteKeyToString(favoriteKey);
        set(favoriteLookupAtom(favoriteKeyStr), newValue);
    },
});

export const favoriteLookupReadSelector = selectorFamily<IFavoriteLookupState, IFavoriteKey>({
    key: 'favoriteLookupReadSelector',
    get: (favoriteKey) => async ({get}) => {
        const currentValue = get(favoriteLookupSelector(favoriteKey));
        if (currentValue) {
            return currentValue;
        }
        const favorite = await readFavoriteMaybe(favoriteKey);
        return {
            favorite,
        };
    },
});

export const favoriteLookupInsertSelector = selector<IFavorite>({
    key: 'favoriteLookupInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        const favoriteKeyStr = favoriteKeyToString(newValue);
        set(favoriteLookupAtom(favoriteKeyStr), {
            favorite: newValue,
        });
    },
});

export const favoriteLookupRemoveSelector = selector<IFavoriteKey>({
    key: 'favoriteLookupRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        const favoriteKeyStr = favoriteKeyToString(newValue);
        set(favoriteLookupAtom(favoriteKeyStr), {
            favorite: undefined,
        });
    },
});
