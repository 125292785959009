import React from 'react';

import {Controller, FieldValues, UseControllerProps} from 'react-hook-form';

import {ServiceGridCheckBox} from 'shared/components/ProfilePageContent/ProfilePageHeader/ServiceGridCheckBox';

interface IUpdateServicesControllerProps<FormType> extends UseControllerProps<FormType> {
    disabled?: boolean;
}

export const UpdateServicesController = <FormType extends FieldValues>({
    control,
    disabled,
    name,
}: React.PropsWithChildren<IUpdateServicesControllerProps<FormType>>) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value, name: controlName}, fieldState: {invalid, error}}) => (
                <ServiceGridCheckBox
                    name={controlName}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    invalid={invalid}
                    error={error}
                />
            )}
        />
    );
};
