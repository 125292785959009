import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Form } from 'react-bootstrap';
import { Control } from 'react-hook-form';

import { CaseChartUser, ICaseChart } from 'modules/case-chart/models';

import { InputController } from 'shared/components/form/InputController/InputController';

interface ICaseEstimatorFormBranchProps {
    name: `milestones.${number}.branches.${number}`;
    index: number;
    disabled?: boolean;
    onDelete: (index: number) => void;
    control: Control<ICaseChart>;
}

export const CaseEstimatorFormBranch = ({
    name,
    index,
    disabled,
    onDelete,
    control,
}: ICaseEstimatorFormBranchProps) => {

    return (
        <Form.Row>
            <Form.Row className="col-10">
                <Form.Group as={Col} controlId={`owner-${index}`}>
                    <Form.Label>Owner</Form.Label>
                    <InputController
                        asType="select"
                        control={control}
                        name={`${name}.owner`}
                        placeholder="Owner"
                        disabled={disabled}
                    >
                        {Object.keys(CaseChartUser).map(key => (
                            <option key={key}>{key}</option>
                        ))}
                    </InputController>
                </Form.Group>
                <Form.Group as={Col} controlId={`name-${index}`}>
                    <Form.Label>Name</Form.Label>
                    <InputController
                        control={control}
                        name={`${name}.name`}
                        placeholder="Name"
                        disabled={disabled}
                    />
                </Form.Group>
                <Form.Group as={Col} controlId={`cost-${index}`}>
                    <Form.Label>Amount</Form.Label>
                    <InputController
                        control={control}
                        name={`${name}.price`}
                        placeholder="Cost"
                        disabled={disabled}
                    />
                </Form.Group>
            </Form.Row>
            <Form.Group className="col-2" style={{marginLeft: '10px'}}>
                <Form.Label className="d-block">&nbsp;</Form.Label>
                <Button variant="danger" onClick={() => onDelete?.(index)}>
                    <FontAwesomeIcon icon={faTrash} />
                </Button>
            </Form.Group>
        </Form.Row>
    );
};
