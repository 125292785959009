import React from 'react';

import './style.scss';
import {useWindowSize} from 'shared/hooks/useWindowSize';

interface IContactItemProps {
    image: string;
    title: string;
    contact: string;
    alt: string;
}

export const ContactItem = ({title, image, contact, alt}: IContactItemProps) => {
    const windowWidth = useWindowSize();

    return (
        <div className="ContactItem mx-3">
            <div className="ContactItem__image">
                <img src={image} alt={alt} style={{width: windowWidth.width < 576 ? '22px' : 'auto'}} />
            </div>
            <div className="ContactItem__details-col">
                <h5 className="ContactItem__title">{title}</h5>
                <p className="ContactItem__contact text-muted mb-0">{contact}</p>
            </div>
        </div>
    );
};
