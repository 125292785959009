import {CaseChartUser, ICaseChartMilestone} from 'modules/case-chart/models';

import {CaseEstimatorPreviewBranch} from 'user/pages/CreateCaseEstimator/CaseEstimatorPreview/CaseEstimatorPreviewBranch';

interface ICaseEstimatorPreviewMilestoneProps {
    milestone: ICaseChartMilestone;
}

export const CaseEstimatorPreviewMilestone = ({milestone}: ICaseEstimatorPreviewMilestoneProps) => {
    const paralegalBranches = milestone.branches.filter(
        (branch) => branch.owner === CaseChartUser.Paralegal || branch.owner === CaseChartUser.Solicitor,
    );
    const barristerBranches = milestone.branches.filter((branch) => branch.owner === CaseChartUser.Barrister);

    return (
        <>
            <div className="CaseEstimator-preview__title">
                <div className="CaseEstimator-preview__leftTitle" />
                <div className="CaseEstimator-preview__rightTitle" />
            </div>
            <div className="CaseEstimator-preview__titleText">
                <p className="text-center">{milestone.name}</p>
            </div>
            <div className="CaseEstimator-preview__table">
                <div className="CaseEstimator-preview__row">
                    <div className="CaseEstimator-preview__cell">
                        {paralegalBranches &&
                            paralegalBranches.map((branch, branchIndex) => (
                                <CaseEstimatorPreviewBranch key={`${branchIndex}.${branch.name}`} branch={branch} />
                            ))}
                        {!paralegalBranches.length && <div className="empty-activity" />}
                    </div>
                    <div className="CaseEstimator-preview__cell bar" />
                    <div className="CaseEstimator-preview__cell">
                        {barristerBranches &&
                            barristerBranches.map((branch, branchIndex) => (
                                <CaseEstimatorPreviewBranch key={`${branchIndex}.${branch.name}`} branch={branch} />
                            ))}
                        {!barristerBranches.length && <div className="empty-activity" />}
                    </div>
                </div>
            </div>
        </>
    );
};
