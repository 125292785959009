import React from 'react';

import {Container} from 'react-bootstrap';

import {Profession} from 'modules/profile/models/profession';
import {professionFilters} from 'modules/profile/constants';

import {ProfilesCarousel} from 'public/HomePage/HomePageProfiles/ProfilesCarousel';
import {CarouselNav} from 'public/HomePage/shared/CarouselNav/CarouselNav';
import {BadgeFilter} from 'shared/components/BadgeFilter/BadgeFilter';

import './style.scss';

export const HomePageProfiles = () => {
    const [profession, setProfession] = React.useState<Profession>();

    return (
        <Container className="HomePage__section MaxWidthAdjustment">
            <div className="HomePageReview__mainheading">
                <h2 className="HomePageReview__heading">Featured Profiles</h2>
            </div>
            <CarouselNav viewAll="/network">
                <div className="d-flex align-items-center flex-wrap mx-auto w-75">
                    {professionFilters.map((professionFilter) => (
                        <BadgeFilter
                            key={professionFilter.value || ''}
                            onClick={() => setProfession(professionFilter.value)}
                            title={professionFilter.title}
                            active={profession === professionFilter.value}
                        />
                    ))}
                </div>
            </CarouselNav>
            <ProfilesCarousel profession={profession} />
        </Container>
    );
};
