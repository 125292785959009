import React from 'react';

import {CardCarouselNav} from 'shared/components/CardCarousel/CardCarouselNav/CardCarouselNav';
import {CardCarouselLoading} from 'shared/components/CardCarousel/CardCarouselLoading/CardCarouselLoading';
import {CardCarouselError} from 'shared/components/CardCarousel/CardCarouselError/CardCarouselError';

import './style.scss';

interface ICardCarouselProps {
    onPrev: () => void;
    onNext: () => void;
    disabled?: boolean;
    hasMore?: boolean;
    hasPrev?: boolean;
    error?: string;
    loading?: boolean;
    isChevrons?: boolean;
    skeleton?: React.ReactNode;
}

export const CardCarousel = ({
    onPrev,
    onNext,
    children,
    disabled,
    hasMore,
    hasPrev,
    loading,
    error,
    isChevrons,
    skeleton,
}: React.PropsWithChildren<ICardCarouselProps>) => {
    return (
        <div className="CardCarousel">
            <div className="CardCarousel__content">
                {!loading && (
                    <CardCarouselNav
                        isChevrons={isChevrons}
                        direction="#prev"
                        onClick={onPrev}
                        disabled={disabled || !hasPrev}
                        className="btnDisplayNone"
                    />
                )}
                {loading && (skeleton ? skeleton : <CardCarouselLoading />)}
                {error && <CardCarouselError>There was an error loading profiles</CardCarouselError>}
                {!loading && !error && children}
                {!loading && (
                    <CardCarouselNav
                        direction="#next"
                        isChevrons={isChevrons}
                        onClick={onNext}
                        disabled={disabled || !hasMore}
                        className="btnDisplayNone"
                    />
                )}
            </div>
            <div className="mobileCarousel">
                {!loading && (
                    <CardCarouselNav
                        isChevrons={isChevrons}
                        direction="#prev"
                        onClick={onPrev}
                        disabled={disabled || !hasPrev}
                        className="m-1 iconMwidth"
                    />
                )}
                {!loading && (
                    <CardCarouselNav
                        direction="#next"
                        isChevrons={isChevrons}
                        onClick={onNext}
                        disabled={disabled || !hasMore}
                        className="m-1 iconMwidth"
                    />
                )}
            </div>
        </div>
    );
};
