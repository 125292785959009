import React from 'react';

import {Card, Col} from 'react-bootstrap';

import './style.scss';

interface IGuideToChoosingSymbolsCardProps {
    iconPath: string;
    title: string;
    description: string;
}

export const GuideToChoosingSymbolsCard = (props: IGuideToChoosingSymbolsCardProps) => {
    const {iconPath, title, description} = props;

    return (
        <Col md={6} lg={6} xl={4} className="GuideToChoosingSymbolsCard">
            <Card.Body className="GuideToChoosingSymbolsCard__card-body">
                <div className="GuideToChoosingSymbolsCard__header">
                    <img className="GuideToChoosingSymbolsCard__image" src={iconPath} alt="What our symbols mean"/>
                </div>
                <div className="GuideToChoosingSymbolsCard__title">{title}</div>
                <div className="GuideToChoosingSymbolsCard__description">{description}</div>
            </Card.Body>
        </Col>
    );
};
