import {Control, Controller, FieldValues, Path} from 'react-hook-form';

import {ProjectSearchControl} from 'shared/components/form/ProjectSearchControl/ProjectSearchControl';
import {SelectedProject} from 'shared/components/form/ProjectSearchController/SelectedProject';

import './style.scss';

interface IProjectSearchControllerProps<FormType extends FieldValues> {
    control: Control<FormType>;
    name: Path<FormType>;
    userId: string;
    disabled?: boolean;
    placeholder?: string;
    controlClassName?: string;
    hideSearch?: boolean;
    hideCreate?: boolean;
    createButtonTitle?: string;
    onCreateClick?: () => void;
}

export const ProjectSearchController = <FormType extends FieldValues>({
    userId,
    name,
    control,
    disabled,
    placeholder,
    controlClassName,
    hideSearch,
    hideCreate,
    createButtonTitle,
    onCreateClick,
}: IProjectSearchControllerProps<FormType>) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value}}) => (
                <>
                    {!value && !hideSearch && (
                        <ProjectSearchControl
                            userId={userId}
                            onSelect={(project) => onChange(project.id)}
                            placeholder={placeholder}
                            disabled={disabled}
                            controlClassName={controlClassName}
                        />
                    )}
                    <SelectedProject projectId={value} onRemove={() => onChange(undefined)} />
                    {!hideCreate && (
                        <div>
                            <div
                                className="ProjectSearchController__create-button"
                                onClick={onCreateClick}
                                style={{cursor: 'pointer'}}
                            >
                                {createButtonTitle ? createButtonTitle : '+ Create New Brief'}
                            </div>
                            {/* <Link
                                to="#"
                                className="ProjectSearchController__create-button"
                                onClick={onCreateClick}
                                style={{cursor: 'pointer'}}
                            >
                                {createButtonTitle ? createButtonTitle : '+ Create New Brief'}
                            </Link> */}
                        </div>
                    )}
                </>
            )}
        />
    );
};
