import {Tab, Tabs} from 'react-bootstrap';

import PlanCard from '../PlanCard/PlanCard';
import {IPlan} from 'public/PlansPage/models';

import './PlansList.scss';

interface IPlansListProps {
    plans: IPlan[];
}

const PlansList = ({plans}: IPlansListProps) => {
    return (
        <div className="Plans">
            <Tabs defaultActiveKey="monthly" className="Plans__ClientService-tab">
                <Tab eventKey="monthly" title="Monthly" className="Plans__ClientService-tab--tabpane">
                    {plans.map((plan, index) => (
                        <PlanCard key={index} isFirst={index === 0} plan={plan} isAnnual={false}/>
                    ))}
                </Tab>
                <Tab eventKey="annually" title="Annually" className="Plans__ClientService-tab--tabpane">
                    {plans.map((plan, index) => (
                        <PlanCard key={index} isFirst={index === 0} plan={plan} isAnnual={true}/>
                    ))}
                </Tab>
            </Tabs>
        </div>
    );
};

export default PlansList;
