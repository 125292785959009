import React from 'react';

import {Accordion, Button, Card} from 'react-bootstrap';

import './style.scss';

interface FaqItemProps {
    title: string;
    eventKey: string;
    children?: React.ReactNode;
}

export const FaqItem = ({title, eventKey, children}: FaqItemProps) => {
    return (
        <Card>
            <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey={eventKey} className="FAQ__toggle">
                    {title}
                </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey}>
                <Card.Body>{children}</Card.Body>
            </Accordion.Collapse>
        </Card>
    );
};
