import {IProfile} from '../models/IProfile';
import {IProfilesPageFilters} from 'shared/state/profiles-page';
import {ServiceType} from '../models/serviceType';
import {RateType} from 'shared/models/rate-filters/rate-filters';
import {IProfileListQuery} from '../models/IProfileListQuery';
import {IProfileSummary} from 'modules/profile/models/IProfileSummary';
import {Profession} from 'modules/profile/models/profession';
import {professionValues} from 'modules/profile/constants';

interface IPartialProfile {
    firstname: string;
    lastname: string;
}

export const getFullName = ({firstname, lastname}: IPartialProfile) => `${firstname} ${lastname}`;


export const compareProfileFilters = (filters1: IProfileListQuery, filters2: IProfileListQuery) => {
    return (
        filters1.profession === filters2.profession &&
        filters1.limit === filters2.limit &&
        filters1.isPublished === filters2.isPublished &&
        filters1.isFeatured === filters2.isFeatured &&
        filters1.searchTerm === filters2.searchTerm
    );
};

export const filterProfiles = (
    profilesData: IProfile[],
    {priceRange, location, rateType, tagId, serviceType, profession, name}: IProfilesPageFilters,
): IProfile[] => {
    // filter for get is_published data
    const profiles = profilesData.filter((profile) => profile.is_published);

    // initialize the results by filtering based on "profession". If profession is undefined include all profiles
    let filteredProfiles = profiles.filter((profile) => !profession || profile.profession === profession);

    // filter based on "tagId"
    if (tagId) {
        filteredProfiles = filteredProfiles.filter((profile) => profile.tag_ids.includes(tagId));
    }

    // filter based on serviceType
    if (serviceType) {
        filteredProfiles = filteredProfiles.filter((profile) => profile.service_types.includes(serviceType));
    }

    // filter based on location
    if (location) {
        filteredProfiles = filteredProfiles.filter(
            (profile) => profile.contact_details_display.city?.toLowerCase().trim() === location.toLowerCase(),
        );
    }

    // filter based on price
    if (priceRange) {
        filteredProfiles = filteredProfiles.filter((profile) => {
            const hourlyRate = profile.service_details?.hour_rate ?? 0;
            const dailyRate = profile.service_details?.day_rate ?? 0;
            return (
                profile.service_details?.service_types?.includes(ServiceType.FixedFees) ||
                (hourlyRate >= priceRange.min && hourlyRate < priceRange.max) ||
                (dailyRate >= priceRange.min && dailyRate < priceRange.max)
            );
        });
    }

    // filter and sort based on rate
    if (rateType) {
        filteredProfiles = filteredProfiles.filter((profile) => {
            switch (rateType) {
                case RateType.Daily:
                    return !!profile.service_details?.day_rate && profile.service_details.day_rate > 0;
                case RateType.Hourly:
                    return !!profile.service_details?.hour_rate && profile.service_details.hour_rate > 0;
                case RateType.FixedFee:
                    return !!profile.service_types && profile?.service_types?.includes?.(ServiceType.FixedFees);
                default:
                    return true;
            }
        });

        filteredProfiles.sort((profileA, profileB) => {
            const hourlyRateA = profileA.service_details?.hour_rate ?? 0;
            const dailyRateA = profileA.service_details?.day_rate ?? 0;
            const hourlyRateB = profileB.service_details?.hour_rate ?? 0;
            const dailyRateB = profileB.service_details?.day_rate ?? 0;
            switch (rateType) {
                case RateType.Daily:
                    return dailyRateA - dailyRateB;
                case RateType.Hourly:
                    return hourlyRateA - hourlyRateB;
                case RateType.FixedFee:
                    return Math.min(dailyRateA, hourlyRateA) - Math.min(dailyRateB, hourlyRateB);
            }
            return Math.min(dailyRateA, hourlyRateA) - Math.min(dailyRateB, hourlyRateB);
        });
    }

    // sort based on offering fixed fees.
    // NOTE: This must happen AFTER sorting by rate
    if (rateType === RateType.FixedFee) {
        filteredProfiles.sort((profileA, profileB) => {
            const weightA = profileA.service_types?.includes(ServiceType.FixedFees) ? 0 : 1;
            const weightB = profileB.service_types?.includes(ServiceType.FixedFees) ? 0 : 1;
            return weightA - weightB;
        });
    }

    if (name) {
        filteredProfiles = filteredProfiles.filter(
            (profile) =>
                profile.firstname.toLocaleLowerCase().includes(name.toLocaleLowerCase()) ||
                profile.lastname.toLocaleLowerCase().includes(name.toLocaleLowerCase()),
        );
    }

    return filteredProfiles;
};

export const profileToProfileSummary = (profile: IProfile): IProfileSummary => {
    return {
        id: profile.id,
        firstname: profile.firstname,
        lastname: profile.lastname,
        image_id: profile.image_id,
        image_url: profile.image_url,
        is_published: profile.is_published,
        is_featured: profile.is_featured,
        role: profile.role,
    };
};

export const getProfessionTitle = (profession: Profession): string | undefined => {
    return professionValues.find(professionData => professionData.value === profession)?.title;
};
