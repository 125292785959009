import React from 'react';

import {PostKind} from 'modules/post/models/postKind';
import {PostOrder} from 'modules/post/models/postOrder';
import {usePostList} from 'modules/post/hooks/usePostList';

import {PostCard} from 'shared/components/PostCard/PostCard';
import {EmptyTable} from 'shared/components/EmptyTable/EmptyTable';

interface IPostsPageResultsProps {
    kind?: PostKind;
    searchTerm: string;
    tagId?: string;
}

export const PostsPageResults = ({kind, searchTerm, tagId}: IPostsPageResultsProps) => {
    const postList = usePostList({
        page: 0,
        filters: {
            kind,
            limit: 20,
            orderBy: PostOrder.CreatedAt,
            isPublished: true,
            searchTerm,
            tagId,
        },
    });

    return (
        <>
            {!!postList.posts.length ? (
                <div className="card-grid m-auto row d-flex">
                    <div className="card-grid w-100 row d-flex ">
                        {!!postList?.posts?.length && postList.posts.map(post => (
                            <div key={post.id} className="col-xl-4 col-md-6 animate__animated animate__fadeIn">
                                <PostCard
                                    kind={post.kind}
                                    postId={post.id}
                                    userId={post.user_id}
                                    title={post.title}
                                    summary={post.summary}
                                    tagIds={post.tag_ids}
                                    media={post.media}
                                    isTruncated={true}
                                    className={'PostCardViws'}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <EmptyTable>
                    There are no posts at this moment.
                </EmptyTable>
            )}
        </>
    );
};
