import React from 'react';

import { ErrorBoundary } from 'react-error-boundary';

import { ArticleEditorError } from 'shared/components/form/ArticleEditor/ArticleEditorError';
import { ArticleEditorControl } from 'shared/components/form/ArticleEditor/ArticleEditorControl';

interface IArticleEditorProps {
    name: string;
    grammarly: boolean;
    disabled: boolean;
    onChange?: (content: string) => void;
    autofocus: boolean;
    isInvalid?: boolean;
    defaultValue?: string;
    placeholder?: string;
}

export const ArticleEditor = (props: IArticleEditorProps) => {
    return (
        <ErrorBoundary
            fallback={(
                <ArticleEditorError />
            )}
        >
            <ArticleEditorControl {...props} />
        </ErrorBoundary>
    );
};

ArticleEditor.defaultProps = {
    grammarly: false,
    disabled: false,
    autofocus: false,
};
