import React from 'react';

import {Card} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';

import {PostKind} from 'modules/post/models/postKind';
import {IPostMedia} from 'modules/post/models/IPostMedia';
import {truncateContent} from 'shared/utils/string';

import {PostCardImage} from 'shared/components/PostCard/PostCardImage';
import {UserIdProfilePicture} from 'shared/components/UserIdProfilePicture/UserIdProfilePicture';
import {UserIdName} from 'shared/components/UserIdName/UserIdName';
import {MediaCard} from 'shared/components/MediaCard/MediaCard';
import {MediaCardTags} from 'shared/components/MediaCard/MediaCardTags/MediaCardTags';
import {MediaCardFooter} from 'shared/components/MediaCard/MediaCardFooter/MediaCardFooter';
import {WebinarPreviewModal} from 'shared/components/WebinarPreviewModal/WebinarPreviewModal';

import './style.scss';

interface IPostCardProps {
    postId: string;
    kind: PostKind;
    userId: string;
    title: string;
    summary: string;
    tagIds: string[];
    media: IPostMedia[];
    isTruncated: boolean;
    className?: string;
}

export const PostCard = ({
    postId,
    userId,
    title,
    kind,
    summary,
    tagIds,
    isTruncated,
    media,
    className,
}: IPostCardProps) => {
    const postUrl = kind === 'PODCAST' ? `/posts/podcast/${postId}` : `/posts/${postId}`;
    const authorUrl = `/profiles/${userId}`;
    const contentSize: number = 135;
    const navigate = useNavigate();

    const [IsOpenPreview, setIsOpenPreview] = React.useState<boolean>(false);
    const [PostWebinarUrl, setPostWebinarUrl] = React.useState<string>('');

    return (
        <>
            <MediaCard className={className}>
                <PostCardImage
                    className="postCard_main_div"
                    classNameImage="postCard_image"
                    url={postUrl}
                    media={media}
                    kind={kind}
                    setPostWebinarUrl={setPostWebinarUrl}
                    setIsOpenPreview={setIsOpenPreview}
                />
                <Card.Body
                    className="PostCard__card-body"
                    // onClick={() => {
                    //     if (kind === 'WEBINAR') {
                    //         const PostMediaLink = media.findIndex((value) => value.kind === 'LINK');
                    //         const urlLink = PostMediaLink !== -1 && media[PostMediaLink].name;
                    //         if (urlLink) {
                    //             setPostWebinarUrl(urlLink);
                    //             setIsOpenPreview(true);
                    //         }
                    //     } else {
                    //         navigate(postUrl);
                    //     }
                    // }}
                >
                    <div
                        onClick={() => {
                            if (kind === 'WEBINAR') {
                                const PostMediaLink = media.findIndex((value) => value.kind === 'LINK');
                                const urlLink = PostMediaLink !== -1 && media[PostMediaLink].name;
                                if (urlLink) {
                                    setPostWebinarUrl(urlLink);
                                    setIsOpenPreview(true);
                                }
                            } else {
                                navigate(postUrl);
                            }
                        }}
                        className="postCard_title_class"
                        style={{cursor: 'pointer'}}
                    >
                        {truncateContent(`${title}`, 65)}
                    </div>
                    <div
                        onClick={() => {
                            if (kind === 'WEBINAR') {
                                const PostMediaLink = media.findIndex((value) => value.kind === 'LINK');
                                const urlLink = PostMediaLink !== -1 && media[PostMediaLink].name;
                                if (urlLink) {
                                    setPostWebinarUrl(urlLink);
                                    setIsOpenPreview(true);
                                }
                            } else {
                                navigate(postUrl);
                            }
                        }}
                        className="postCard_title_margin_alternative"
                        // style={{height: '28px', minHeight: '28px'}}
                    />
                    <div
                        onClick={() => {
                            if (kind === 'WEBINAR') {
                                const PostMediaLink = media.findIndex((value) => value.kind === 'LINK');
                                const urlLink = PostMediaLink !== -1 && media[PostMediaLink].name;
                                if (urlLink) {
                                    setPostWebinarUrl(urlLink);
                                    setIsOpenPreview(true);
                                }
                            } else {
                                navigate(postUrl);
                            }
                        }}
                        className="PostCard__content"
                    >
                        <div className="PostCard__summary">
                            {isTruncated ? truncateContent(summary, contentSize) : summary}
                        </div>
                    </div>
                    <div className="tag_div_main_class">
                        <MediaCardTags tagIds={tagIds} />
                    </div>
                    <div
                        onClick={() => {
                            if (kind === 'WEBINAR') {
                                const PostMediaLink = media.findIndex((value) => value.kind === 'LINK');
                                const urlLink = PostMediaLink !== -1 && media[PostMediaLink].name;
                                if (urlLink) {
                                    setPostWebinarUrl(urlLink);
                                    setIsOpenPreview(true);
                                }
                            } else {
                                navigate(postUrl);
                            }
                        }}
                        className="PostCard_Footer_Margin_Alternative"
                    />
                    <div className="PostCard_Footer">
                        <MediaCardFooter>
                            <div
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(authorUrl);
                                }}
                                className="PostCard__author"
                            >
                                <UserIdProfilePicture
                                    id={userId}
                                    size="xs"
                                    border={false}
                                    imgClassName="article"
                                    round
                                    className="mr-2"
                                />
                                <UserIdName className="postcard_text_name" id={userId} />
                            </div>
                        </MediaCardFooter>
                    </div>
                </Card.Body>
            </MediaCard>
            <WebinarPreviewModal
                videoURL={PostWebinarUrl}
                open={IsOpenPreview}
                onHideModal={() => {
                    setIsOpenPreview(false);
                    setPostWebinarUrl('');
                }}
            />
        </>
    );
};
