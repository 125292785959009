import React from 'react';

import {Row} from 'react-bootstrap';

import {OurPeopleCard} from './OurPeopleCard';

import './style.scss';

interface ITeamMember {
    picture: string;
    name: string;
    role: string;
    description: React.ReactNode;
}

const teamMembers: ITeamMember[] = [
    {
        picture: '/assets/profilePictures/CD-76.jpg',
        name: 'Chris Dobbs',
        role: 'Barrister, Co-Founder and Chief Overlord',
        description: (
            <>
                Chris Dobbs is a corporations and commercial barrister. He can tell you whether Justice Black is likely
                to go for your scheme of arrangement and how to make ASIC turn into a playful kitten. He also tells us
                that a phoenix isn’t just a bird it is also a company and that winding up actually means winding down.
                He is particularly good at strategy and the long game. Having worked as a lawyer at ASIC, American
                Express, Gilbert & Tobin and the ASX, it is not a huge shock that he cooked up Rightful over many years.
                He comes across as too laid back for someone in advocacy but it is all a ruse. His opponents frequently
                fall for it though.
            </>
        ),
    },
    {
        picture: '/assets/profilePictures/AboutUs.rob.photo.png',
        name: 'Rob Dodd',
        role: 'CTO & Co-Founder',
        description: (
            <>
                Robert Dodd is the bona-fide tech-head of the Rightful operation. Part computer nerd, part surfer dude,
                Rob is unlikely to brag about successfully founding Wyzed and Compliance Fox, so we need to do it for
                him. Born and raised in J-berg, South Africa, Rob’s patience and humility belies his wily street-smarts.
                That coupled with his insane coding ability is an absolutely terrifying combination. Like we are
                convinced he could hack a top secret military installation if he wanted to. Not that he ever would. We
                are glad he’s on our side.
            </>
        ),
    },
    {
        picture: '/assets/profilePictures/Member-3.jpg',
        name: 'Trent Long',
        role: 'Chief Evangelist',
        description: (
            <>
                Trent Long is Rightful's chief evangelist and has been busy shedding his three piece real estate agent
                suit and working on his rugged disciple look ever since he got our phone call. Ok we know we need to
                quit it with the metaphors, but c'mon he looks like Jesus. Experienced in all things business
                development, Trent's mind works in ways we haven't even begun to understand, making connections that are
                so left field but so spot on we've learned to get out of the way. During the Covid Pandemic he pioneered
                the use of virtual reality in property sales, achieving a community centred outcome during a very
                difficult time. He is guided by an unswerving dedication to innovation and ethical business practices
                and we couldn't think of anyone better to shepperd (ok one last metaphor) our legal community.
            </>
        ),
    },
    {
        picture: '/assets/profilePictures/Member-5.jpeg',
        name: 'Amy Danek',
        role: '2IC and Head of Community',
        description: (
            <>
                Amy heads up our community initiatives like our CPD series and is the first friendly face people meet in
                our introductions channel. She knows how to get the word out whether it be through social media, video
                or email, without her we would have no friends and we know it. Extremely concientious, Amy is a
                powerhouse with incredible energy, enthusiasm and downright tenacity. We are incredibly inspired by her
                desire to change the legal industry and we hope you are too!
            </>
        ),
    },
    {
        picture: '/assets/profilePictures/Member-6.png',
        name: 'Vatsal Sonani aka sir Vatsalot',
        role: 'Wizard of Wireframes',
        description: (
            <>
                Vatsal Sonani is the one to thank for our user friendly interface. Behind every click, scroll, or tap,
                you'll find a trace of his artistic flair. Despite his experience in leading large teams, he still loves
                getting on the tools and building quality products. His obsession with detail and the little things is a
                huge part of our success as a business, as we aim to revolutionise the legal project experience. He
                earned his nickname being polite and addressing people as 'sir' alot.
            </>
        ),
    },
];

export const OurPeopleAllCard = () => {
    return (
        <>
            <div className="AboutUsPage__heading AboutUsPage__margin--t130">
                <h2 className="AboutUsPage__heading--text">Founding Team</h2>
            </div>
            <div className="about_us_row_div_container_div">
                <Row className="about_us_op_row_div">
                    {teamMembers.map((teamMember) => (
                        <OurPeopleCard
                            key={teamMember.name}
                            personImage={teamMember.picture}
                            description={teamMember.description}
                            role={teamMember.role}
                            name={teamMember.name}
                        />
                    ))}
                </Row>
            </div>
        </>
    );
};
