import React from 'react';

import {useNavbar} from 'shared/hooks/useNavbar';

import {NotFoundContent} from 'public/NotFound/NotFoundContent';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';

import './style.scss';

interface INotFoundProps {
    footer?: boolean;
    buttonUrl: string;
    buttonTitle: string;
}

export const NotFound = ({footer, buttonTitle, buttonUrl}: INotFoundProps) => {
    useNavbar(true);

    return (
        <>
            <PageHelmet title="Not Found" noindex/>
            <NotFoundContent footer={footer} buttonUrl={buttonUrl} buttonTitle={buttonTitle}/>
        </>
    );
};

NotFound.defaultProps = {
    footer: true,
};
