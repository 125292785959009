import React, {useRef} from 'react';

import {Overlay, Tooltip} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import {IFilterOption} from 'shared/models/filter-option/IFilterOption';

import {CommonPillSelectItem} from 'public/ProfileMarketplace/MarketplaceFilter/CommonPillSelect/CommonPillSelectItem';

import './style.scss';

interface ICommonPillSelectProps<ValueType> {
    id: string;
    open: boolean;
    onOpen: (open: boolean) => void;
    onChange: (value: ValueType) => void;
    label?: string;
    options: IFilterOption<ValueType>[];
    value?: ValueType;
    classNameTooltip?: string;
    hideToolTipClass?: string;
}

export const CommonPillSelect = <ValueType extends (string | number | undefined)>({
    id,
    open,
    onOpen,
    onChange,
    label,
    options,
    value,
    classNameTooltip,
    hideToolTipClass,
}: ICommonPillSelectProps<ValueType>) => {
    const targetSelectBox = useRef(null);

    const optionsClassName = classNames('CommonPillSelect__dropdown-menu', {
        'CommonPillSelect__dropdown-menu--one-item': options && options.length === 1,
    });
    const hideTooltipClassName = classNames('CommonPillSelect--hide', {
        [`${hideToolTipClass}`]: hideToolTipClass && hideToolTipClass,
    });

    const selectedOption = options.find(option => option.value === value);

    React.useEffect(() => {

        // add an event listener to close the dropdown if the user clicks outside of it
        const hideDropdownOnWindowClick = (event: MouseEvent) => {
            const target: HTMLElement | undefined = event.target as HTMLElement || undefined;
            if (
                (target && !target?.matches('.CommonPillSelect--hide')) ||
                (id === 'ResourceType' && target && !target?.matches('.CommonPillSelect--hide--ResourceType'))
            ) {
                onOpen(false);
            }
        };
        window.addEventListener('click', hideDropdownOnWindowClick);

        // Return a callback to remove the event listener each time this hook is run. This prevents having 100x active
        // event listeners in a session.
        return () => {
            window.removeEventListener('click', hideDropdownOnWindowClick);
        };

    }, [id, onOpen]);

    return (
        <div key={id} className={`CommonPillSelect ${hideTooltipClassName}`}>
            {label && <div className={`CommonPillSelect__label ${hideTooltipClassName}`}>{label}</div>}
            <div
                className={`CommonPillSelect__control ${hideTooltipClassName}`}
                ref={targetSelectBox}
                onClick={() => onOpen(!open)}
            >
                <div className={`text_for_select ${hideTooltipClassName}`}>{selectedOption?.title ?? 'Select Type'}</div>
                <FontAwesomeIcon className={`CommonPillSelect__chevron ${hideTooltipClassName}`} icon={faCaretDown}/>
            </div>
            <div className={`CommonPillSelect__Overlay--div ${hideTooltipClassName}`}>
                <Overlay target={targetSelectBox} show={open} placement="bottom">
                    {(props) => (
                        <Tooltip
                            id={`${id}__tooltip`}
                            {...props}
                            className={
                                classNameTooltip
                                    ? ` CommonPillSelect__tooltip ${classNameTooltip} ${hideTooltipClassName}`
                                    : `CommonPillSelect__tooltip ${hideTooltipClassName}`
                            }
                        >
                            <div className={`${optionsClassName} ${hideTooltipClassName}`}>
                                {options.map(option => (
                                    <CommonPillSelectItem
                                        key={option.value}
                                        active={value === option.value}
                                        onClick={() => {
                                            onChange(option.value);
                                            onOpen(!open);
                                        }}
                                        hideToolTipClass={hideToolTipClass}
                                    >
                                        {option.title}
                                    </CommonPillSelectItem>
                                ))}
                            </div>
                        </Tooltip>
                    )}
                </Overlay>
            </div>
        </div>
    );
};
