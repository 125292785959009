import axios from 'axios';

import {IHomepageCache} from 'modules/homepage/models';
import {Config} from 'config';

export const readHomepageCache = async (): Promise<IHomepageCache> => {
    const response = await axios.get(Config.homepageCacheURL, {
        withCredentials: false,
    });
    return response.data;
};
