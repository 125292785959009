import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { showLoadingPageAtom } from 'shared/state/loading-page';

export default function useLoadingPage(show: boolean) {
    const setLoadingState = useSetRecoilState(showLoadingPageAtom);

    useEffect(() => {
        setLoadingState(show);
        return () => {
            setLoadingState(false);
        };
    }, [setLoadingState, show]);
}
