import React from 'react';

import {Button, FormGroup, Form} from 'react-bootstrap';
import {
    ArrayPath,
    FieldArray,
    FieldValues,
    Path,
    UseControllerProps,
    useFieldArray,
    useWatch,
    FieldPath,
} from 'react-hook-form';

import {IFixedFeeService} from 'modules/profile/models/IServiceDetails';
import {ServiceType} from 'modules/profile/models/serviceType';

import {
    FixedFeeServicesControllerItem,
} from 'user/pages/AccountSettings/ProfileSettings/FixedFeeServicesController/FixedFeeServicesControllerItem';

interface IFixedFeeServicesControllerProps<FormType> extends UseControllerProps<FormType> {
    disabled?: boolean;
    serviceTypesName: FieldPath<FormType>;
}

export const FixedFeeServicesController = <FormType extends FieldValues>({
    disabled,
    control,
    name,
    serviceTypesName,
}: IFixedFeeServicesControllerProps<FormType>) => {
    const {fields, append, remove} = useFieldArray({
        control,
        name: name as ArrayPath<FormType>,
    });

    const limit = 6;
    const isDisableButton = fields.length >= limit || disabled;

    const serviceTypes = useWatch({
        control,
        name: serviceTypesName,
    });
    const isFixedFees = React.useMemo(() => serviceTypes.includes(ServiceType.FixedFees), [serviceTypes]);

    // hook that clears the "fixed_fee_services" array when fixed fees is unchecked.
    // this prevents errors with fixed fee items preventing the form from submitting
    React.useEffect(() => {
        if (!isFixedFees) {
            for (let index = fields.length - 1; index >= 0; index--) {
                remove(index);
            }
        }
    }, [isFixedFees, fields, remove]);

    // Only show fixed fee service options when fixed fees is selected
    if (!isFixedFees) {
        return null;
    }

    return (
        <FormGroup>
            <div>
                <Form.Label>Fixed Fee Services</Form.Label>
                <Button
                    type="button"
                    variant=""
                    onClick={() => append(({name: '', price: 0} as IFixedFeeService) as FieldArray<FormType>)}
                    className="btnAddOne"
                    style={{cursor: isDisableButton ? 'not-allowed' : 'pointer'}}
                    disabled={isDisableButton}
                >
                    +Add
                </Button>
            </div>
            {fields.map((field, index) => (
                <FixedFeeServicesControllerItem
                    key={field.id}
                    name={`${name}.${index}` as Path<FormType>}
                    index={index}
                    onDelete={() => remove(index)}
                    disabled={disabled}
                    control={control}
                />
            ))}
            {fields.length === 0 && (
                <div className="py-2 text-muted">
                    No fixed fee services
                </div>
            )}
        </FormGroup>
    );
};
