import React from 'react';

import {format} from 'date-fns';
import {Container, Nav} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import './style.scss';
import {useWindowSize} from 'shared/hooks/useWindowSize';

export const AppFooterBottom = () => {
    const currentYear: string = format(new Date(), 'yyyy');
    const windowWidth = useWindowSize();

    return (
        <Container
            fluid
            className="AppFooterBottom AppFooter__section-bottom pt-4 pt-sm-0 pb-4 pb-sm-0 py-md-2 py-xxl-4"
        >
            <div className="align-items-center text-right">
                <div
                    style={{flexDirection: windowWidth.width < 576 ? 'column' : 'row'}}
                    className="d-flex align-items-center justify-content-center text-muted"
                >
                    <div className="AppFooterBottom__terms AppFooterBottom__terms--copyright">
                        © Copyright NewLaw Chambers Pty Ltd {currentYear}
                    </div>
                    {windowWidth.width < 576 && (
                        <Nav style={{alignItems: 'center'}} defaultActiveKey="/home" className="flex-column">
                            <Nav.Link className="AppFooter__nav-link" as={Link} to="/terms">
                                Terms & Conditions
                            </Nav.Link>
                            <Nav.Link className="AppFooter__nav-link" as={Link} to="/privacy">
                                Privacy Policy
                            </Nav.Link>
                        </Nav>
                    )}

                    {windowWidth.width > 576 && (
                        <>
                            <div className="AppFooterBottom__terms AppFooterBottom__terms--copyright">
                                See our <Link to="/terms">Terms & Conditions</Link> and{' '}
                                <Link to="/privacy">Privacy Policy</Link>
                            </div>
                            <a
                                href="https://www.facebook.com/Rightfullyau"
                                className="AppFooterBottom__icon"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    className="AppFooterBottom__icon"
                                    src="/assets/homepage/Footer/Icon awesome-facebook-square.png"
                                    alt="Rightful Facebook"
                                />
                            </a>
                            <a
                                href="https://www.linkedin.com/company/therightfulway/"
                                className="AppFooterBottom__icon"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    className="AppFooterBottom__icon"
                                    src="/assets/homepage/Footer/Icon awesome-linkedin.png"
                                    alt="Rightful Linkedin"
                                />
                            </a>
                            <a
                                href="https://twitter.com/Rightfu08687756"
                                className="AppFooterBottom__icon"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    className="AppFooterBottom__icon"
                                    src="/assets/homepage/Footer/Icon awesome-twitter-square.png"
                                    alt="Rightful Twitter"
                                />
                            </a>
                        </>
                    )}
                </div>
            </div>
        </Container>
    );
};
