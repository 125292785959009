import React from 'react';

import {Container, Row} from 'react-bootstrap';

import {SlickCards} from 'public/HomePage/HomeAboutpage/SlickCards';

import './style.scss';

export const HowItWorks = () => {
    return (
        <div className="HowItWorks">
            <Container>
                <Row className="howitworks_space">
                    <div className="HowItWorks__center">How to get started</div>
                </Row>
                <Row className="d-flex">
                    <img
                        className="HowItWorks__BG-Lines"
                        src="/assets/homepage/HowItWorks/lines.png"
                        alt="Book an appointment and upload brief"
                    />
                </Row>

                <SlickCards>
                    <div className="SlideSize">
                        <div className="d-flex HowItWorks__steps HowItWorks__slide1 mb-5">
                            <img
                                className="HowItWorks__img-works"
                                src="/assets/homepage/HowItWorks/button.png"
                                alt="Book an appointment and upload brief"
                            />
                            <div className="HowItWorks__step img-works HowItWorks__white-text howitworks_count">1</div>
                        </div>
                        <div className="d-flex HowItWorks__step-subtitle text-center">
                            <span>Choose legal talent in our Network</span>
                        </div>
                        <img
                            className="img-fluid HowItWorks--slideImg"
                            src="/assets/homepage/advocate.jpg"
                            alt="Choose an advocate"
                        />
                    </div>

                    <div>
                        <div className="d-flex HowItWorks__steps mb-5 HowItWorks__slide2">
                            <img
                                className="HowItWorks__img-works"
                                src="/assets/homepage/HowItWorks/button.png"
                                alt="Book an appointment and upload brief"
                            />
                            <div className="HowItWorks__step img-works HowItWorks__white-text howitworks_count">2</div>
                        </div>
                        <div className="d-flex HowItWorks__step-subtitle text-center">
                            <span className="__whiteText">Book an appointment & upload brief</span>
                        </div>
                        <img
                            className="img-fluid HowItWorks--slideImg"
                            src="/assets/homepage/appointment.jpg"
                            alt="Book an appointment and upload brief"
                        />
                    </div>

                    <div>
                        <div className="d-flex HowItWorks__steps mb-5 HowItWorks__slide1">
                            <img
                                className="HowItWorks__img-works"
                                src="/assets/homepage/HowItWorks/button.png"
                                alt="Book an appointment and upload brief"
                            />
                            <div className="HowItWorks__step img-works HowItWorks__white-text howitworks_count">3</div>
                        </div>
                        <div className="d-flex HowItWorks__step-subtitle text-center">
                            <span>Accept quote & e-sign retainer</span>
                        </div>
                        <img
                            className="img-fluid HowItWorks--slideImg"
                            src="/assets/homepage/esign.jpg"
                            alt="Accept quote and e-sign retainer"
                        />
                    </div>
                </SlickCards>
            </Container>
        </div>
    );
};
