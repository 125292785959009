import {availabilitySettingsAtom, availabilitySettingsReadSelector} from 'modules/availability-settings/state';
import {useRecoilLoadable} from 'shared/hooks/useRecoilLoadable';

export const useAvailabilitySettingsLoadable = (profileId: string) => {
    const {data, loading, error} = useRecoilLoadable({
        state: availabilitySettingsAtom(profileId),
        loadable: availabilitySettingsReadSelector(profileId),
    });
    return {
        availabilitySettings: data,
        loading,
        error,
    };
};
