import React from 'react';

import {Button} from 'react-bootstrap';
import {Link, useParams} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons';

import {useSubscriptionLoadable} from 'modules/billing/hooks/useSubscriptionLoadable';
import {useGuaranteedAuth} from 'shared/auth/hooks/useAuth';
import {useBackgroundColor, BackgroundColor} from 'shared/hooks/useBackgroundColor';

import {StripeSignupRedirect} from 'public/StripeSignup/StripeSignupRedirect';
import {Loading} from 'shared/components/loading/Loading/Loading';
import {PageError} from 'shared/components/PageError/PageError';

import './style.scss';

export const StripeSignup = () => {
    useBackgroundColor(BackgroundColor.LightGray);

    const {tokenData} = useGuaranteedAuth();
    const {subscription, loading, error} = useSubscriptionLoadable();

    const {userId} = useParams();

    return (
        <div className="container StripeSignup">
            {tokenData.id === userId && (
                <>
                    {loading && (
                        <div className="text-center">
                            <Loading />
                            <p>Redirecting you to the payment portal...</p>
                        </div>
                    )}
                    {subscription && !!subscription.plan && (
                        <StripeSignupRedirect userId={tokenData.id} />
                    )}
                    {subscription && !subscription.plan && (
                        <div className="text-center">
                            <p className="text-muted">
                                You have not been assigned a payment plan. This link was either sent to you by mistake,
                                or the administrator has not finished setting up your payment plan yet. Please let them
                                know.
                            </p>
                            <p>Return to Home page.</p>
                            <Button as={Link} to="/">Home</Button>
                        </div>
                    )}
                    {error && (
                        <PageError description="Error reading billing info">
                            <Button as={Link} to="/">Return Home</Button>
                        </PageError>
                    )}
                </>
            )}
            {tokenData.id !== userId && (
                <div className="text-center">
                    <p className="text-muted">
                        This link was meant for a different user. Please log out and sign back in using the correct
                        account.
                    </p>
                    <Button as={Link} variant="featured" to="/logout">
                        <FontAwesomeIcon icon={faSignOutAlt} />
                        Log out
                    </Button>
                    <span className="text-muted ml-3">or</span>
                    <Button as={Link} to="/" variant="link">Return Home</Button>
                </div>
            )}
        </div>
    );
};
