import React from 'react';

import {Container, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';

import './style.scss';

export const HomePageJobs = () => {
    return (
        <div className="HomePageJobs">
            <Container>
                <div className="HomePageJobs__header">
                    <h2 className="HomePageJobs__title">Jobs Board</h2>
                </div>
                <div className="HomePageJobs__description">
                    Post a legal job to our jobs board and receive responses from legal service providers around
                    Australia directly in your chat. Maintain anonymity if confidentiality is an issue and close the job
                    once you have what you need.
                </div>
                <div className="d-flex justify-content-center">
                    <Button variant="featured" as={Link} to="/jobs" className="HomePageJobs__button">
                        View Jobs Board
                        <FontAwesomeIcon icon={faChevronRight} className="ml-4" />
                    </Button>
                </div>
            </Container>
        </div>
    );
};
