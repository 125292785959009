import React from 'react';

import {useSetRecoilState} from 'recoil';
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';
import {Button, Form} from 'react-bootstrap';

import {IProfile} from 'modules/profile/models/IProfile';
import {updateProfile} from 'modules/profile/api/profile';
import {insertProfileSelector} from 'modules/profile/state/profile-insert';
import {toastAxiosError} from 'shared/utils/error';
import {getFormDefaultsFromProfile} from 'shared/components/ProfilePageContent/shared/utils/form';
import {toast} from 'shared/utils/toast';

import {Modal} from 'shared/components/modals/Modal/Modal';
import {ModalBody} from 'shared/components/modals/Modal/ModalBody';
import {InputController} from 'shared/components/form/InputController/InputController';
import {ModalFooter} from 'shared/components/modals/Modal/ModalFooter';

interface IEditAboutUsProps {
    open: boolean;
    onHide: () => void;
    profile: IProfile;
}

const profileAboutMeForm = z.object({
    summary: z.string().max(4000).nonempty({message: "This field can't be empty"}),
});

interface IProfileAboutMeForm {
    summary?: string;
}

export const EditAboutUs = ({open, onHide, profile}: IEditAboutUsProps) => {
    const insertProfile = useSetRecoilState(insertProfileSelector);
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const {handleSubmit, reset, control, watch} = useForm<IProfileAboutMeForm>({
        resolver: zodResolver(profileAboutMeForm),
        defaultValues: getFormDefaultsFromProfile(profile),
    });

    const limit: number = 1000;
    const summary = watch('summary');

    const onSubmit = (formData: IProfileAboutMeForm) => {
        setIsSubmitting(true);
        (async () => {
            try {
                const updatedProfile = await updateProfile(profile.id, {
                    summary: formData.summary,
                });
                insertProfile(updatedProfile);
                onHide();
                reset(getFormDefaultsFromProfile(updatedProfile));
                toast.success('Profile Settings Saved', 'Your profile was updated successfully');
            } catch (e) {
                toastAxiosError(e, 'Profile Settings Error');
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    return (
        <Modal show={open} onHide={onHide} size="lg" centered isSubmitting={isSubmitting} title="About me">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <ModalBody>
                    <Form.Group controlId="About_me">
                        <InputController
                            name="summary"
                            placeholder=""
                            asType="textarea"
                            rows={14}
                            className="ProfilePageAbout__input"
                            control={control}
                            disabled={isSubmitting}
                            maxlength={limit}
                        />
                        <div
                            style={{paddingInline: '15px', fontSize: '13px', marginTop: '10px'}}
                            className="w-100 d-flex justify-content-end"
                        >
                            <span>
                                {summary?.length || 0}/{limit}
                            </span>
                        </div>
                    </Form.Group>
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="featured"
                        type="submit"
                        disabled={isSubmitting}
                        className="ProfilePageAbout__margin--t15 ProfilePageAbout__button--save-button"
                    >
                        Save
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};
