import React from 'react';
import {useSetRecoilState} from 'recoil';

import {unreadChatStatusResetSelector} from 'modules/chat/state/unread-chats';

const refreshPeriods = [
    1000,
    2000,
    4000,
    10000,
];

export const useRefreshUnreadChatStatus = () => {
    const refreshUnreadChatStatus = useSetRecoilState(unreadChatStatusResetSelector);

    return React.useCallback(() => {
        // trigger a refresh across multiple timeouts, to account for the server taking a while to refresh the unread
        // status sometimes.
        refreshPeriods.forEach(ms => {
            setTimeout(() => refreshUnreadChatStatus(null), ms);
        });
    }, [refreshUnreadChatStatus]);
};
