import React from 'react';

import {Container} from 'react-bootstrap';

import {useNavbar} from 'shared/hooks/useNavbar';
import {useIssueListLoadable} from 'modules/issue/hooks/useIssueListLoadable';

import {AppFooter} from 'shared/components/AppFooter/AppFooter';
import {DevePipelineImg} from 'shared/icons/DevePipeline';
import {FeatureComponent} from 'public/DevelopmentPipeline/FeatureComponent/FeatureComponent';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';

import './style.scss';
import {Loading} from 'shared/components/loading/Loading/Loading';
import {PageError} from 'shared/components/PageError/PageError';

const developmentPipelinePageDescription = 'You talk we listen. Our platform is constantly getting better because we never stop improving it. Here you can track the features we are building based on your feedback. If there is something you think should be on here why not send us a message on our contact form.';

export const DevelopmentPipeline = () => {
    useNavbar(true);
    const {issues, loading, error} = useIssueListLoadable();

    return (
        <div className="DevelopmentPipeline">
            <PageHelmet
                title="Development Pipeline"
                description={developmentPipelinePageDescription}
            />
            <Container>
                <div className="content-page-header">
                    <h2 className="content-page-title">Development Pipeline</h2>
                </div>
                <div className="DevelopmentPipeline__description">
                    <p className="mb-0">
                        {developmentPipelinePageDescription}
                    </p>
                </div>
            </Container>
            {loading && (
                <Container className="py-5 mt-5">
                    <Loading/>
                </Container>
            )}
            {error && (
                <PageError description="Error loading development pipeline"/>
            )}
            {issues && (
                <>
                    <Container>
                        <div className="DevelopmentPipeline__center--image">
                            <DevePipelineImg/>
                        </div>
                        <div className="content-page-header ">
                            <h2 className="content-page-title">New Features</h2>
                        </div>
                        <div className="DevelopmentPipeline__text-regular">
                            <p className="mb-0">
                                We are adding new parts to our platform everyday. Track the issues we are currently working on
                                here.
                            </p>
                        </div>
                    </Container>
                    {issues.map((issue, index) => (
                        <FeatureComponent key={issue.id} issue={issue} index={index}/>
                    ))}
                </>
            )}
            <AppFooter subscribe={true}/>
        </div>
    );
};
