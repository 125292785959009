import {IReviewListSelectorKey, reviewListSelector, reviewListReadSelector} from 'modules/review/state/review-list';
import {useRecoilLoadable} from 'shared/hooks/useRecoilLoadable';

export const useReviewListLoadable = (filters: IReviewListSelectorKey) => {
    const {data, loading, error} = useRecoilLoadable({
        state: reviewListSelector(filters),
        loadable: reviewListReadSelector(filters),
    });
    return {
        reviewList: data,
        loading,
        error,
    };
};
