import {IChatMessage} from 'modules/chat-message/models';

export enum ChatWsServerMessageType {
    NewMessage = 'NEW_MESSAGE',
    Typing = 'TYPING',
    UserOnline = 'USER_ONLINE',
    UserOffline = 'USER_OFFLINE',
    Error = 'ERROR',
}


export interface IChatWsServerMessageNewMessage {
    type: ChatWsServerMessageType.NewMessage;
    chat_id: string;
    user_id: string;
    chat_message: IChatMessage;
}

export interface IChatWsServerMessageTyping {
    type: ChatWsServerMessageType.Typing;
    chat_id: string;
    user_id: string;
    is_typing: boolean;
}

export interface IChatWsServerMessageUserOnline {
    type: ChatWsServerMessageType.UserOnline;
    user_id: string;
}

export interface IChatWsServerMessageUserOffline {
    type: ChatWsServerMessageType.UserOffline;
    user_id: string;
}

export interface IChatWsServerMessageError {
    type: ChatWsServerMessageType.Error;
    error_message: string;
}

export interface IChatWsServerMessage {
    message: IChatWsServerMessageNewMessage | IChatWsServerMessageTyping | IChatWsServerMessageError | IChatWsServerMessageUserOnline | IChatWsServerMessageUserOffline;
}
