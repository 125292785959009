import React from 'react';
import { useRecoilValue } from 'recoil';

import { tagListReadSelector } from 'modules/tag/state/tag-list';

import { Tag } from 'shared/components/Tag/Tag';

interface ITagIdProps {
    tagId: string;
    className?: string;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    badge?: true;
}

export const TagId = ({tagId, className, size, badge}: ITagIdProps) => {
    const tags = useRecoilValue(tagListReadSelector);
    const tag = tags.find(tagInState => tagInState.id === tagId);

    if (tag) {
        return (
            <Tag color={tag.color} title={tag.title} size={size} className={className} badge={badge} />
        );
    } else {
        return null;
    }
};

TagId.defaultProps = {
    size: 'sm',
};
