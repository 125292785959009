import React from 'react';

import {Link} from 'react-router-dom';
import classNames from 'classnames';

import {Edit} from 'shared/icons/EditProfile';

import './style.scss';

interface IProfilePageEditButtonProps {
    onClick: () => void;
    size?: 'sm';
}

export const ProfilePageEditButton = ({onClick, size}: IProfilePageEditButtonProps) => {
    return (
        <Link to="#" onClick={onClick}>
            <Edit
                className={classNames('ProfilePageEditButton__icon', {
                    'ProfilePageEditButton__icon--sm': size === 'sm',
                })}
            />
        </Link>
    );
};
