import {PostKind} from 'modules/post/models/postKind';
import {postKindToReadableString} from 'modules/post/utils';

export interface IPostKindFilter {
    title: string;
    value: PostKind | undefined;
}

export const postKindOptions: IPostKindFilter[] = [
    {
        title: postKindToReadableString(PostKind.Article),
        value: PostKind.Article,
    },
    {
        title: postKindToReadableString(PostKind.Webinar),
        value: PostKind.Webinar,
    },
    {
        title: postKindToReadableString(PostKind.Faq),
        value: PostKind.Faq,
    },
    {
        title: postKindToReadableString(PostKind.Podcast),
        value: PostKind.Podcast,
    },
];

export const postKindFilters: IPostKindFilter[] = [
    {
        title: 'All',
        value: undefined,
    },
    {
        title: postKindToReadableString(PostKind.Article, true),
        value: PostKind.Article,
    },
    {
        title: postKindToReadableString(PostKind.Webinar, true),
        value: PostKind.Webinar,
    },
    {
        title: postKindToReadableString(PostKind.Faq, true),
        value: PostKind.Faq,
    },
    {
        title: postKindToReadableString(PostKind.Podcast, true),
        value: PostKind.Podcast,
    },
];
