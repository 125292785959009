import React from 'react';

import {useProfileSummaryLoadable} from 'modules/profile/hooks/useProfileSummaryLoadable';
import {getFullName} from 'modules/profile/utils';

import {ProfilePicture, ProfilePictureSharedProps} from 'shared/components/ProfilePicture/ProfilePicture';

interface IUserIdProfilePictureProps extends ProfilePictureSharedProps {
    id: string;
}

export const UserIdProfilePicture = ({id, imgClassName, ...rest}: IUserIdProfilePictureProps) => {
    const {profileSummary, loading, error} = useProfileSummaryLoadable(id);

    const fullName = React.useMemo(() => {
        if (profileSummary) {
            return getFullName(profileSummary);
        } else if (error) {
            return 'Error loading user';
        } else {
            return 'Loading...';
        }
    }, [profileSummary, error]);

    return (
        <>
            {loading && (
                <ProfilePicture
                    name={fullName}
                    alt="Loading..."
                    imgClassName={imgClassName}
                    {...rest}
                />
            )}
            {error && (
                <ProfilePicture
                    alt="User not found!"
                    imgClassName={imgClassName}
                    {...rest}
                />
            )}
            {profileSummary && (
                <ProfilePicture
                    name={fullName}
                    id={profileSummary.image_id}
                    alt={fullName}
                    url={profileSummary.image_url}
                    imgClassName={imgClassName}
                    {...rest}
                />
            )}
        </>
    );
};
