import React from 'react';
import classNames from 'classnames';

import './style.scss';

interface IFixedFeesIconProps {
    size?: 'lg';
    noText?: boolean;
}

export const FixedFeesIcon = ({size, noText}: IFixedFeesIconProps) => {
    const iconClassName = classNames('FixedFeesLabel__icon', {
        'FixedFeesLabel__icon--lg': size === 'lg',
        'mr-2': !noText,
    });

    return (
        <img
            className={iconClassName}
            src="/assets/profileIcons/Group%209733.png"
            alt="Fixed Fee Icon"
        />
    );
};
