import {atom, selector} from 'recoil';

import {getCurrentUserScopes} from '../utils/get-current-user-scopes';
import {guardRecoilDefaultValue} from '../../utils/recoil';
import {Scope} from 'shared/auth/models';
import {currentUserIdAtom} from 'shared/auth/state/current-user-id';

interface ICurrentUserScopes {
    userID: string | undefined;
    scopes: Scope[];
}

export const currentUserScopesAtom = atom<ICurrentUserScopes | undefined>({
    key: 'currentUserScopesAtom',
    default: undefined,
});

export const guaranteedCurrentUserScopesSelector = selector<ICurrentUserScopes>({
    key: 'guaranteedCurrentUserScopesSelector',
    get: async ({get}) => {
        const currentUserId = get(currentUserIdAtom);
        if (!currentUserId) {
            return {
                userID: undefined,
                scopes: [],
            };
        }
        const currentUserScopes = get(currentUserScopesAtom);
        if (currentUserScopes && currentUserScopes.userID === currentUserId) {
            return currentUserScopes;
        } else {
            const scopes = await getCurrentUserScopes();
            return {
                userID: currentUserId,
                scopes,
            };
        }
    },
    set: ({set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        set(currentUserScopesAtom, newValue);
    },
});
