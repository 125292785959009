import React from 'react';

import {IArticleSection} from 'modules/help/models';

import {FileIDImage} from 'shared/components/file/FileIDImage/FileIDImage';

import './style.scss';

interface IHelpCenterArticleSectionProps {
    section: IArticleSection;
}

export const HelpCenterArticleSection = ({section}: IHelpCenterArticleSectionProps) => {
    return (
        <div className="HelpCenterArticleSection">
            <div className="HelpCenterArticleSection--heading">{section.name}</div>
            <div
                className="HelpCenterArticleSection--content"
                dangerouslySetInnerHTML={{__html: section.description ?? ''}}
            />
            {section.file_id && (
                <div className="HelpCenterArticleSection--image">
                    <FileIDImage fileId={section.file_id} alt={section.name} publicUrl={section.file_public_url}/>
                </div>
            )}
        </div>
    );
};
