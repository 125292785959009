import React from 'react';

import {Container, Table} from 'react-bootstrap';

import {useNavbar} from 'shared/hooks/useNavbar';

import {AppFooter} from 'shared/components/AppFooter/AppFooter';
import {CoolLegalLink} from './CoolLegalLink';

import './style.scss';

export const CoolLegalLinksPage = () => {
    useNavbar(true);
    return (
        <>
            <div className="CoolLegalLinks__paragraph">
                <Container>
                    <p className="CoolLegalLinks__page-title">Legal-Links</p>
                    <p className="CoolLegalLinks__page-description">
                        The world is exploding with new online legal resources all the time. Clients are getting more
                        legally savvy and searching for new ways to engage with the law. We compiled this handy list of
                        law resources for anyone wanting to take charge of their own legal destiny, or just learn what
                        the future may hold! If you think a particular resource should be on here, just let us know and
                        we'll add it.
                    </p>
                </Container>
            </div>
            <Container>
                <Table className="CoolLegalLinks__table">
                    <thead>
                        <tr>
                            <th>Resource</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <CoolLegalLink title="Lawpath" url="https://lawpath.com.au/">
                            Lawpath is a platform for people who want to do more of their legal work themselves. On the
                            platform you can do things like register a company, buy a legal template or ask for advice
                            from an online lawyer. Definitely worth having up your sleeve particularly for any small
                            business.
                        </CoolLegalLink>
                        <CoolLegalLink
                            title="Criminal Law Survival Kit"
                            url="https://www.criminallawsurvivalkit.com.au/"
                        >
                            This comprehensive summary of NSW criminal law is maintained by John Stratton SC a
                            distinguished public defender. It is a fantastic easy to follow survival guide used by many
                            criminal advocates in practice.
                        </CoolLegalLink>
                        <CoolLegalLink title="Open Law" url="https://www.openlaw.io/">
                            Open law is a smart contracts platform. What is a smart contract you ask? Imagine a world
                            where a truck driver arriving at a precise geolocation transmits a signal that the contract
                            has concluded. Smart contracts are self executing electronic contracts that revolve around
                            computer code. They may be just around the corner...
                        </CoolLegalLink>
                        <CoolLegalLink title="Sprint Law" url="https://sprintlaw.com.au/">
                            Sprint law is a new type of innovative law firm. It is focused around productising legal
                            services for people who need things 'on demand'. Their motto is 'business legals, simple and
                            online'. Check them out.
                        </CoolLegalLink>
                        <CoolLegalLink title="Immediation" url="https://www.immediation.com/">
                            Immediation is an Australian online dispute resolution platform. Their custom built
                            technology is designed to facilitate secure mediation via video calls and has built in
                            documentation to ensure the resolution is legally binding.
                        </CoolLegalLink>
                        <CoolLegalLink title="Fie Foe Firm" url="https://www.feefiefoefirm.com/au/">
                            This nifty site uses google technology to search law firm sites globally for legal articles
                            relevant to your query
                        </CoolLegalLink>
                        <CoolLegalLink title="Austlii" url="https://www.austlii.edu.au/">
                            This is an old faithful for anyone in the legal business. Search australian legislation and
                            judgments for free. An essential for anyone looking to take charge of their legal destiny.
                            Be sure to use the 'Noteup' feature to find subsequent cases that reference your search.
                        </CoolLegalLink>
                        <CoolLegalLink title="TurnipBox" url="https://turnipbox.netlify.app/">
                            The CSIRO and Data61 are two government backed Australian institutes that are building a
                            software language for law. You heard right, they are literally building a coding language to
                            replace legal rules with code. Its called TurnipBox and it has enormous applications.
                            Legislation itself could become machine executable with this new innovation.
                        </CoolLegalLink>
                        <CoolLegalLink title="Legally Yours" url="https://legallyyours.com.au/">
                            This is a great legal marketplace from Melbourne. Need a digitally savy online lawyer? Look
                            no further...
                        </CoolLegalLink>
                        <CoolLegalLink title="Legal Vision" url="https://legalvision.com.au/">
                            A pure online subscription law firm. Another example of how fixed fee value pricing can
                            work.
                        </CoolLegalLink>
                        <CoolLegalLink title="Josef Legal" url="https://joseflegal.com/">
                            Heard of legal chatbots? Just have a conversation with the bot, tell it what you want in
                            your legal document and walla! A finished legal document comes out at the end.
                        </CoolLegalLink>
                        <CoolLegalLink title="Compose Law" url="https://compose.law/">
                            A tool to instantly create legal briefs. Although its american we are hanging out for the
                            Australian version!
                        </CoolLegalLink>
                        <CoolLegalLink title="Blue Ocean Law Group" url="https://www.blueocean.law/">
                            The Blue Ocean Law Group offers tonnes of self help wizards, templates and DIY solutions.
                        </CoolLegalLink>
                        <CoolLegalLink title="ChatGPT" url="https://openai.com/blog/chatgpt/">
                            We are loving asking Google's super advanced AI our legal questions. For legal reasons, we
                            can't endorse this as giving legal advice, but it sure is a lot of fun!
                        </CoolLegalLink>
                        <CoolLegalLink title="Trial Directions" url="https://trialdirections.com.au/">
                            This is the most comprehensive site we have found on standard trial directions in criminal
                            trials. It is a must read for any criminal law advocate.
                        </CoolLegalLink>
                        <CoolLegalLink title="Practising Law" url="https://www.practisinglaw.com.au/">
                            A fantastic website by Jason Deng chock full of resources and advice for junior lawyers.
                        </CoolLegalLink>
                    </tbody>
                </Table>
            </Container>
            <AppFooter subscribe={false} />
        </>
    );
};
