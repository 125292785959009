export enum PostKind {
    Article = 'ARTICLE',
    Faq = 'FAQ',
    Webinar = 'WEBINAR',
    Podcast = 'PODCAST',
}

export enum PostFilterKind {
    All = 'ALL',
    Article = 'ARTICLE',
    Faq = 'FAQ',
    Webinar = 'WEBINAR',
    Podcast = 'PODCAST',
}
