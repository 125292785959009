import {z} from 'zod';

import {SocialProvider} from 'modules/profile/models/socialProvider';
import {ServiceType} from 'modules/profile/models/serviceType';
import {IServiceDetails} from 'modules/profile/models/IServiceDetails';
import {IContactDetails} from 'modules/profile/models/IContactDetails';
import {IProfile, Role} from 'modules/profile/models/IProfile';
import {ISocialLink} from 'modules/profile/models/ISocialLink';
import {IWorkExperience} from 'modules/profile/models/IWorkExperience';
import {ISkill} from 'modules/profile/models/ISkill';
import {Profession} from 'modules/profile/models/profession';
import {IProfileUpdate} from 'modules/profile/models/IProfileUpdate';
import {zodStringNumber} from 'shared/utils/form';

export const skillSchema = z.object({
    name: z.string().min(3),
    level: z.number().min(1),
});

export const fixedFeeServiceSchema = z.object({
    name: z.string().min(3),
    price: z.number().min(0),
});

export const workExperienceSchema = z.object({
    name: z.string().min(1),
    description: z.string().min(1),
});

export const socialLinkSchema = z.object({
    type: z.nativeEnum(SocialProvider),
    url: z.string().url({message: 'Invalid url (eg: "https://www.rightful.com.au")'}),
});

export const contactDetailsSchema = z.object({
    email: z.string().optional(),
    phone_number: z.string().optional(),
    address: z.string().optional(),
    country: z.string().optional(),
    state: z.string().optional(),
    city: z.string().optional(),
});

export const serviceDetailsSchema = z.object({
    day_rate: zodStringNumber,
    hour_rate: zodStringNumber,
    service_types: z.array(z.nativeEnum(ServiceType)),
    fixed_fee_services: z.array(fixedFeeServiceSchema),
});

export const getProfileFormSchemaForRole = (role: Role) => {
    if (role === Role.Provider || role === Role.System) {
        return z.object({
            firstname: z.string(),
            lastname: z.string(),
            title: z.string().optional(),
            contact_details: contactDetailsSchema,
            contact_details_display: contactDetailsSchema.optional(),
            profession: z.nativeEnum(Profession),
            summary: z.string().optional(),
            claim_to_fame: z.string().optional(),
            resume_id: z.string().optional().nullable(),
            service_details: serviceDetailsSchema.optional(),
            skills: z.array(skillSchema).optional(),
            work_experience: z.array(workExperienceSchema).optional(),
            social_links: z.array(socialLinkSchema).optional(),
            is_published: z.boolean(),
        });
    } else {
        return z.object({
            firstname: z.string(),
            lastname: z.string(),
            title: z.string().optional(),
            contact_details: contactDetailsSchema,
        });
    }
};

export interface IProfileSettingsForm {
    firstname: string;
    lastname: string;
    title?: string;
    contact_details: IContactDetails;
    contact_details_display: IContactDetails;
    profession: Profession;
    summary?: string;
    claim_to_fame?: string;
    resume_id?: string;
    service_details: IServiceDetails;
    service_types: ServiceType[];
    skills: ISkill[];
    work_experience: IWorkExperience[];
    social_links: ISocialLink[];
    is_published: boolean;
}

export const getServiceDetailsFormDefaults = (serviceDetails: IServiceDetails | undefined) => {
    return {
        day_rate: serviceDetails?.day_rate || 0,
        hour_rate: serviceDetails?.hour_rate || 0,
        service_types: serviceDetails?.service_types || [],
        fixed_fee_services: serviceDetails?.fixed_fee_services || [],
    };
};

export const getContactDetailsFormDefaults = (contactDetails?: IContactDetails) => {
    return {
        email: contactDetails?.email || '',
        phone_number: contactDetails?.phone_number || '',
        address: contactDetails?.address || '',
        country: contactDetails?.country || '',
        state: contactDetails?.state || '',
        city: contactDetails?.city || '',
    };
};

export const getFormDefaultsFromProfile = (profile: IProfile) => {
    return {
        firstname: profile.firstname || '',
        lastname: profile.lastname || '',
        title: profile.title || '',
        contact_details: getContactDetailsFormDefaults(profile.contact_details),
        contact_details_display: getContactDetailsFormDefaults(profile.contact_details_display),
        resume_id: profile.resume_id,
        profession: profile.profession,
        summary: profile.summary || '',
        claim_to_fame: profile.claim_to_fame || '',
        service_types: profile.service_types,
        service_details: getServiceDetailsFormDefaults(profile.service_details),
        skills: profile.skills || [],
        work_experience: profile.work_experience || [],
        social_links: profile.social_links || [],
        is_published: profile.is_published,
    };
};

export const profileSettingsFormToProfileUpdate = (role: Role, formData: IProfileSettingsForm): IProfileUpdate => {
    const profileUpdate = {
        ...formData,
    };
    if (role === Role.System || role === Role.Provider) {
        profileUpdate.resume_id = formData.resume_id ?? ''; // the server will remove it if set to an empty string
    }
    return profileUpdate;
};
