import React from 'react';

import {Modal} from 'react-bootstrap';

interface IModalHeaderProps {
    isSubmitting?: boolean;
    onHide: () => void;
}

export const ModalHeader = ({children, isSubmitting}: React.PropsWithChildren<IModalHeaderProps>) => {
    return (
        <Modal.Header className="BootstrapModal__header" closeButton={!isSubmitting}>
            <Modal.Title className="BootstrapModal__header--title">{children}</Modal.Title>
        </Modal.Header>
    );
};
