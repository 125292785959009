import React from 'react';

import classNames from 'classnames';

import './style.scss';

interface ICommonPillSelectItemProps {
    active: boolean;
    onClick: () => void;
    hideToolTipClass?: string;
}

export const CommonPillSelectItem = ({
    active,
    onClick,
    children,
    hideToolTipClass,
}: React.PropsWithChildren<ICommonPillSelectItemProps>) => {
    const className = classNames('CommonPillSelect__item CommonPillSelect--hide', {
        'CommonPillSelect__item--active': active,
        hideToolTipClass: hideToolTipClass && hideToolTipClass,
    });

    return (
        <div className={className} onClick={() => onClick()}>
            {children}
        </div>
    );
};
