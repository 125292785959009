import React from 'react';

import Select from 'react-select';

import {useBool} from 'shared/hooks/useBool';
import {useTagListLoadable} from 'modules/tag/hooks/useTagListLoadable';

import {TagControlOption} from 'shared/components/form/TagControl/TagControlOption';

import './style.scss';

export interface ITagOption {
    readonly label: string;
    readonly value: string;
    readonly variant: string;
}

interface ITagControlProps {
    value: string[];
    onChange: (value: string[]) => void;
    disabled?: boolean;
    name: string;
    className?: string;
    placeholder?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TagControl = React.forwardRef<any, ITagControlProps>(({disabled, name, onChange, value, className, placeholder}, ref) => {
    const [inputValue, setInputValue] = React.useState<string>('');
    const [isMenuOpen, setIsMenuOpen] = useBool();

    const {tags, loading, error} = useTagListLoadable();

    const options = React.useMemo<ITagOption[]>(() => {
        if (!tags) {
            return [];
        }
        return tags.map(tag => ({
            value: tag.id,
            label: tag.title,
            variant: tag.color,
        }));
    }, [tags]);

    const optionsValue = React.useMemo(() => {
        return options.filter(option => value.includes(option.value));
    }, [options, value]);

    return (
        <Select
            ref={ref}
            className={className}
            value={optionsValue}
            onChange={newValue => onChange(newValue.map(tagOption => tagOption.value))}
            isMulti
            isDisabled={disabled}
            name={name}
            inputValue={inputValue}
            onInputChange={newValue => setInputValue(newValue)}
            onMenuClose={() => setIsMenuOpen(false)}
            onMenuOpen={() => setIsMenuOpen(true)}
            menuIsOpen={isMenuOpen}
            options={options}
            placeholder={placeholder}
            classNamePrefix="TagControl"
            isLoading={loading || !!error}
            components={{
                Option: TagControlOption,
            }}
        />
    );
});
