import React from 'react';

import {AxiosError} from 'axios';

import {toast} from 'shared/utils/toast';
import {getErrorMessage} from 'shared/utils/error';
import {createPortalSession} from 'modules/billing/api';

import {Loading} from 'shared/components/loading/Loading/Loading';

import './style.scss';

interface IStripeSignupRedirectProps {
    userId: string;
}

export const StripeSignupRedirect = ({userId}: IStripeSignupRedirectProps) => {
    const [error, setError] = React.useState<AxiosError | undefined>();

    React.useEffect(() => {
        (async () => {
            try {
                const response = await createPortalSession({
                    userID: userId,
                    returnURL: window.location.href,
                });
                window.location.href = response.url;
            } catch (e) {
                toast.error('Error', getErrorMessage(e));
                setError(e);
            }
        })();
    }, [userId, setError]);

    return (
        <div>
            {!error && (
                <div className="text-center">
                    <Loading />
                    <p>Redirecting you to the payment portal...</p>
                </div>
            )}
            {error && error.isAxiosError && (
                <p>Axios Error</p>
            )}
        </div>
    );
};
