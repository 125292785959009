import React from 'react';

import classNames from 'classnames';
import {Link} from 'react-router-dom';

interface ISkillsSelectorItemProps {
    disabled?: boolean;
    onClick: () => void;
    active: boolean;
}

export const SkillsSelectorItem = ({
    onClick,
    disabled,
    active,
}: ISkillsSelectorItemProps) => {
    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        if (!disabled) {
            onClick();
        }
    };
    return (
        <Link
            to="#"
            className={classNames('ProfilePageSkillsItem__star', {
                'ProfilePageSkillsItem__star--gray': !active,
                'disabled': disabled,
            })}
            onClick={handleClick}
        />
    );
};
