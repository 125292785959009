import React from 'react';

import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStar} from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IStarRatingStarProps {
    isClickable?: boolean;
    isActive?: boolean;
    isHover?: boolean;
    onClick?: (e: React.MouseEvent<SVGElement>) => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    disabled?: boolean;
}

export const StarRatingStar = ({
    isActive,
    isHover,
    onClick,
    onMouseEnter,
    onMouseLeave,
    isClickable,
    disabled,
}: IStarRatingStarProps) => {
    const handleClick = (e: React.MouseEvent<SVGElement>) => {
        if (disabled) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            onClick?.(e);
        }
    };

    return (
        <FontAwesomeIcon
            onClick={handleClick}
            onMouseEnter={() => onMouseEnter?.()}
            onMouseLeave={() => onMouseLeave?.()}
            icon={faStar}
            className={classNames('StarRating__star', {
                'StarRating__star--clickable': isClickable,
                'StarRating__star--active': isActive,
                'StarRating__star--hover': isHover,
                'StarRating__star--disabled': disabled,
            })}
        />
    );
};
