import {atom, selector, selectorFamily} from 'recoil';

import {IProject, IProjectListQuery} from 'modules/project/models';
import {readProjectList} from 'modules/project/api';
import {CursorList} from 'shared/types/cursor-list';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/utils/recoil';

export interface IProjectListStateFilters {
    filters: IProjectListQuery;
    page: number;

    [key: string]: number | IProjectListQuery;
}

interface IProjectListAtom {
    filters: IProjectListQuery;
    projects: IProject[];
    cursors: CursorList;
    page: number;
    more: boolean;
    _resetVersion: number;

    [key: string]: number | boolean | IProject[] | CursorList | IProjectListQuery;
}

const compareFilters = (filters1: IProjectListQuery, filters2: IProjectListQuery) => {
    return (
        filters1.owner_id === filters2.owner_id &&
        filters1.created_by_id === filters2.created_by_id &&
        filters1.member_id === filters2.member_id &&
        filters1.status === filters2.status &&
        filters1.limit === filters2.limit &&
        filters1.cursor === filters2.cursor
    );
};

export const projectListAtom = atom<IProjectListAtom | undefined>({
    key: 'projectListAtom',
    default: undefined,
});

export const projectListResetAtom = atom<number>({
    key: 'projectListResetAtom',
    default: 0,
});

export const projectListSelector = selectorFamily<IProjectListAtom | undefined, IProjectListStateFilters>({
    key: 'projectListSelector',
    get: ({page, filters}) => ({get}) => {
        const atomValue = get(projectListAtom);
        const resetVersion = get(projectListResetAtom);
        if (
            atomValue &&
            atomValue.hasLoaded &&
            page === atomValue.page &&
            compareFilters(filters, atomValue.filters) &&
            atomValue._resetVersion === resetVersion
        ) {
            return {...atomValue};
        }
        return undefined;
    },
    set: () => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return null;
        }
        set(projectListAtom, {...newValue});
    },
});

export const projectListReadSelector = selectorFamily<IProjectListAtom, IProjectListStateFilters>({
    key: 'projectListReadSelector',
    get: (filters) => async ({get}) => {
        const currentValue = get(projectListSelector(filters));
        if (currentValue) {
            return currentValue;
        }

        const atomValue = get(projectListAtom);
        const resetVersion = get(projectListResetAtom);

        const result = await readProjectList({
            ...filters.filters,
            cursor: filters.page === 0 || !atomValue ? undefined : atomValue.cursors[filters.page - 1],
        });

        // add the cursor to the list of cursors so that we can paginate backwards
        const newCursors = atomValue ? Array.from(atomValue.cursors) : [];
        newCursors[filters.page] = result.next_cursor;

        return {
            filters: filters.filters,
            projects: result.projects,
            cursors: newCursors,
            page: filters.page,
            more: !!result.next_cursor,
            hasLoaded: true,
            _resetVersion: resetVersion,
        } as IProjectListAtom;
    },
});

export const projectListRemoveSelector = selector<string>({
    key: 'projectListRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        // TODO: update totals once we have "counts" in the database
        const atomValue = get(projectListAtom);
        if (atomValue) {
            set(projectListAtom, {
                ...atomValue,
                projects: atomValue.projects.filter(project => project.id !== newValue),
            });
        }
    },
});

export const projectListInsertSelector = selector<IProject>({
    key: 'projectListInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        // if the project is present in the store then update it. Otherwise, force a reload.
        const atomValue = get(projectListAtom);
        const index = atomValue?.projects?.findIndex(project => project.id === newValue.id);
        if (atomValue && typeof index !== 'undefined' && index !== -1) {
            const newProjects = Array.from(atomValue.projects);
            newProjects.splice(index, 1, newValue);
            set(projectListAtom, {
                ...atomValue,
                projects: newProjects,
            });
        } else {
            set(projectListResetAtom, get(projectListResetAtom) + 1);
        }
    },
});

export const projectListResetSelector = selector<undefined>({
    key: 'projectListResetSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        set(projectListResetAtom, get(projectListResetAtom) + 1);
    },
});
