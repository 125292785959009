import React from 'react';

import {useSetRecoilState} from 'recoil';
import {useFieldArray, useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';
import {Button, Form} from 'react-bootstrap';

import {Profession} from 'modules/profile/models/profession';
import {IProfile} from 'modules/profile/models/IProfile';
import {ServiceType} from 'modules/profile/models/serviceType';
import {SocialProvider} from 'modules/profile/models/socialProvider';
import {ISocialLink} from 'modules/profile/models/ISocialLink';
import {IFixedFeeService} from 'modules/profile/models/IServiceDetails';
import {insertProfileSelector} from 'modules/profile/state/profile-insert';
import {getContactDetailsFormDefaults} from 'shared/components/ProfilePageContent/shared/utils/form';
import {toast} from 'shared/utils/toast';
import {toastAxiosError} from 'shared/utils/error';
import {updateProfile} from 'modules/profile/api/profile';
import {professionValues} from 'modules/profile/constants';
import {getFullName} from 'modules/profile/utils';
import {formatInputValueAsNumber} from 'shared/utils/form';
import {fixedFeeServiceSchema} from 'modules/profile/form';

import {
    UpdateServicesController,
} from 'shared/components/ProfilePageContent/ProfilePageHeader/UpdateServicesController';
import {Modal} from 'shared/components/modals/Modal/Modal';
import {ResumeController} from 'shared/components/form/ResumeController/ResumeController';
import {DropdownSelectController} from 'shared/components/form/DropdownSelect/DropdownSelectController';
import {ProfilePicture} from 'shared/components/ProfilePicture/ProfilePicture';
import {ReactComponent as AddIcon} from 'shared/icons/legalmatters/cancel.svg';
import {ModalBody} from 'shared/components/modals/Modal/ModalBody';
import {
    SocialLinksController,
} from 'shared/components/ProfilePageContent/ProfilePageHeader/shared/SocialLinksController';
import {TagController} from 'shared/components/form/TagController/TagController';
import {FormController} from 'shared/components/form/FormController/FormController';
import {ModalFooter} from 'shared/components/modals/Modal/ModalFooter';
import {
    FixedFeeServicesController,
} from 'user/pages/AccountSettings/ProfileSettings/FixedFeeServicesController/FixedFeeServicesController';

interface IProfileEditModalProps {
    profile: IProfile;
    show: boolean;
    onHide: () => void;
}

const socialLinkSchema = z.object({
    type: z.nativeEnum(SocialProvider),
    url: z.optional(z.string().url({message: 'Invalid url ( eg: "https://dev.staging.rightful.com.au" )'})),
});

const profileSettingsFormSchema = z.object({
    firstname: z.string().min(1),
    lastname: z.string().min(1),
    profession: z.nativeEnum(Profession),
    country: z.string().optional().nullable(),
    state: z.string().optional().nullable(),
    city: z.string().optional().nullable(),
    tag_ids: z.array(z.string()),
    day_rate: z.number(),
    hour_rate: z.number(),
    service_types: z.array(z.nativeEnum(ServiceType)),
    resume_id: z.string().optional().nullable(),
    social_links: z.array(socialLinkSchema).optional(),
    fixed_fee_services: z.array(fixedFeeServiceSchema).optional(),
});

export interface IEditProfileModalForm {
    firstname: string;
    lastname: string;
    profession: Profession;
    country?: string | null;
    state?: string | null;
    city?: string | null;
    tag_ids: string[];
    day_rate: number;
    hour_rate: number;
    service_types: ServiceType[];
    resume_id?: string;
    social_links: ISocialLink[];
    fixed_fee_services: IFixedFeeService[];
}

const profileToFormDefaultValues = (profile: IProfile): IEditProfileModalForm => {
    return {
        hour_rate: profile.service_details?.hour_rate ?? 0,
        day_rate: profile.service_details?.day_rate ?? 0,
        fixed_fee_services: profile.service_details?.fixed_fee_services ?? [],
        city: profile.contact_details_display?.city,
        country: profile.contact_details_display?.country,
        state: profile.contact_details_display?.state,
        firstname: profile.firstname,
        lastname: profile.lastname,
        profession: profile.profession,
        social_links: profile.social_links,
        resume_id: profile.resume_id,
        service_types: profile.service_types,
        tag_ids: profile.tag_ids,
    };
};

export const ProfileEditModal = ({profile, show, onHide}: IProfileEditModalProps) => {
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const fullName = getFullName(profile);
    const insertProfile = useSetRecoilState(insertProfileSelector);

    const {
        handleSubmit,
        reset,
        control,
    } = useForm<IEditProfileModalForm>({
        resolver: zodResolver(profileSettingsFormSchema),
        defaultValues: profileToFormDefaultValues(profile),
    });

    React.useEffect(() => {
        if (show) {
            reset(profileToFormDefaultValues(profile));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    const {fields: socialLinks, append: addSocialLink, remove: removeSocialLink} = useFieldArray({
        control,
        name: 'social_links',
    });

    const onAddSocialLink = () => {
        addSocialLink({
            type: undefined,
            url: '',
        });
    };

    const onSubmit = (formData: IEditProfileModalForm) => {
        setIsSubmitting(true);

        (async () => {
            try {
                const updatedProfile = await updateProfile(profile.id, {
                    firstname: formData.firstname,
                    lastname: formData.lastname,
                    profession: formData.profession,
                    contact_details_display: {
                        ...getContactDetailsFormDefaults(profile.contact_details),
                        city: formData.city,
                        country: formData.country,
                        state: formData.state,
                    },
                    resume_id: formData.resume_id,
                    service_types: formData.service_types,
                    service_details: {
                        day_rate: formData.day_rate,
                        hour_rate: formData.hour_rate,
                        service_types: formData.service_types,
                        fixed_fee_services: formData.fixed_fee_services,
                    },
                    social_links: formData.social_links ?? [],
                    tag_ids: formData.tag_ids,
                });

                insertProfile(updatedProfile);
                onHide();
                toast.success('Profile Settings Saved', 'Your profile was updated successfully');
            } catch (e) {
                toastAxiosError(e, 'Profile Settings Error');
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            centered
            title="Profile Update"
            isSubmitting={isSubmitting}
        >
            <Form onSubmit={handleSubmit(onSubmit)}>
                <ModalBody>
                    <div className="row m-0 p-0 g-3">
                        <div className="col-12 mb-4 d-flex flex-sm-row flex-column align-items-center">
                            <div className="ProfilePageAbout__image-upload">
                                <ProfilePicture
                                    name={fullName}
                                    id={profile.image_id}
                                    url={profile.image_url}
                                    alt={fullName}
                                    size="xsm"
                                    round
                                />
                            </div>
                            <div>
                                <p className="m-0 pl-3 ProfilePageAbout__image-upload--placeholder">
                                    {getFullName(profile)}
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mb-4">
                            <FormController
                                label="First Name"
                                labelClassName="form-label--sm"
                                name="firstname"
                                control={control}
                                disabled={isSubmitting}
                                size="lg"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mb-4">
                            <FormController
                                label="Last Name"
                                labelClassName="form-label--sm"
                                name="lastname"
                                control={control}
                                disabled={isSubmitting}
                                size="lg"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mb-4">
                            <Form.Group controlId="profession">
                                <Form.Label className="form-label--sm">
                                    Profession
                                </Form.Label>
                                <DropdownSelectController
                                    control={control}
                                    name="profession"
                                    options={professionValues}
                                    block
                                    size="lg"
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mb-4">
                            <FormController
                                label="City"
                                name="city"
                                labelClassName="form-label--sm"
                                control={control}
                                disabled={isSubmitting}
                                size="lg"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mb-4">
                            <FormController
                                label="State"
                                name="state"
                                labelClassName="form-label--sm"
                                control={control}
                                disabled={isSubmitting}
                                size="lg"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mb-4">
                            <FormController
                                label="Country"
                                name="country"
                                labelClassName="form-label--sm"
                                control={control}
                                disabled={isSubmitting}
                                size="lg"
                            />
                        </div>
                        <div className="col-12 mb-4">
                            <Form.Group controlId="tag_ids">
                                <Form.Label className="form-label--sm">
                                    Tags
                                </Form.Label>
                                <TagController
                                    name="tag_ids"
                                    control={control}
                                    disabled={isSubmitting}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-6 col-12 mb-4">
                            <FormController
                                label="Hourly Price"
                                name="hour_rate"
                                labelClassName="form-label--sm"
                                control={control}
                                disabled={isSubmitting}
                                size="lg"
                                formatValue={formatInputValueAsNumber}
                            />
                        </div>
                        <div className="col-lg-6 col-12 mb-4">
                            <FormController
                                label="Per Day Price"
                                name="day_rate"
                                labelClassName="form-label--sm"
                                control={control}
                                disabled={isSubmitting}
                                size="lg"
                                formatValue={formatInputValueAsNumber}
                            />
                        </div>
                        <UpdateServicesController name="service_types" control={control} disabled={isSubmitting} />
                        <FixedFeeServicesController
                            name="fixed_fee_services"
                            serviceTypesName="service_types"
                            control={control}
                            disabled={isSubmitting}
                        />
                        <div className="col-12 mb-4">
                            <ResumeController
                                userId={profile.id}
                                control={control}
                                disabled={isSubmitting}
                                name="resume_id"
                            />
                        </div>
                        <div className="col-12 pb-4">
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <p className="m-0 ProfilePageAbout__heading--title">Social Links</p>
                                </div>
                                <div className="ProfilePageDetails__icon--add-icon">
                                    <AddIcon
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onAddSocialLink();
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            {socialLinks && socialLinks.map((socialData, indexSocial) => (
                                <SocialLinksController
                                    key={`social_links.${indexSocial}`}
                                    control={control}
                                    name={`social_links.${indexSocial}`}
                                    index={indexSocial}
                                    onDelete={() => removeSocialLink(indexSocial)}
                                    disabled={isSubmitting}
                                />
                            ))}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="featured"
                        type="submit"
                        disabled={isSubmitting}
                        className="ProfilePageAbout__margin--t15 ProfilePageAbout__button--save-button"
                    >
                        Save
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};
