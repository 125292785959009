import {selectorFamily} from 'recoil';

import {IProfile} from 'modules/profile/models/IProfile';
import {guardRecoilDefaultValue} from 'shared/utils/recoil';
import {profileLookupSelector, profileLookupReadSelector} from 'modules/profile/state/profile-lookup';
import {insertProfileSelector} from 'modules/profile/state/profile-insert';

export const currentProfileSelector = selectorFamily<IProfile | undefined, string | undefined>({
    key: 'currentProfileSelector',
    get: (profileId) => ({get}) => {
        if (!profileId) {
            return undefined;
        }
        return get(profileLookupSelector(profileId));
    },
    set: (profileId) => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue || !profileId) {
            return;
        }
        set(insertProfileSelector, newValue);
    },
});

export const currentProfileReadSelector = selectorFamily<IProfile | undefined, string | undefined>({
    key: 'currentProfileReadSelector',
    get: (profileId) => async ({get}) => {
        if (!profileId) {
            return undefined;
        }
        return get(profileLookupReadSelector(profileId));
    },
});
