import React, { useState } from 'react';

import { StarRatingStar } from 'shared/components/StarRating/StarRatingStar';

import './style.scss';

interface IStarRatingProps {
    isEditable?: boolean;
    value?: number;
    onChange?: (value: number) => void;
}

export const StarRating = ({value, onChange, isEditable}: IStarRatingProps) => {
    const numStars: number = 5;
    const [tempRating, setTempRating] = useState<number | undefined>(undefined);

    return (
        <div className="StarRating">
            {[...Array(numStars)].map((_, starIndex) => (
                <StarRatingStar
                    key={starIndex}
                    isClickable={isEditable}
                    onClick={() => isEditable && onChange?.(starIndex)}
                    onMouseEnter={() => isEditable && setTempRating(starIndex)}
                    onMouseLeave={() => isEditable && setTempRating(undefined)}
                    isActive={starIndex <= (value ?? -1)}
                    isHover={tempRating !== undefined && (starIndex <= tempRating && starIndex > (value ?? -1))}
                />
            ))}
        </div>
    );
};
