import React from 'react';

import {add, format, set} from 'date-fns';
import {Link} from 'react-router-dom';

import 'shared/components/form/MonthSelectControl/style.scss';
import classNames from 'classnames';

interface IMonthSelectControlProps {
    value: Date;
    onChange: (value: Date) => void;
    disabled?: boolean;
}

export const MonthSelectControl = ({value, onChange, disabled}: IMonthSelectControlProps) => {
    const monthYearTitle = React.useMemo(() => format(value, 'MMMM yyyy'), [value]);

    const onPrevClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        if (disabled) {
            return;
        }

        onChange(set(add(value, {months: -1}), {date: 1}));
    };

    const onNextClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        if (disabled) {
            return;
        }

        onChange(set(add(value, {months: 1}), {date: 1}));
    };

    const buttonClassName = classNames('MonthSelectControl__arrow-button', {
        'MonthSelectControl__arrow-button--disabled': disabled,
    });

    const dateClassName = classNames('ml-2 mr-2', {
        'text-muted': disabled,
    });

    return (
        <div className="d-flex align-items-center py-2 MonthSelectControl align-items-baseline">
            <Link to="#" className={buttonClassName} onClick={onPrevClick}>
                <svg width={8} height={10} viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 5L7.5 0.669872L7.5 9.33013L0 5Z" fill="#C4C4C4"/>
                </svg>
            </Link>
            <span className={dateClassName}>
                {monthYearTitle}
            </span>
            <Link to="#" className={buttonClassName} onClick={onNextClick}>
                <svg width={8} height={10} viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 5L0.5 9.33013V0.669873L8 5Z" fill="#C4C4C4"/>
                </svg>
            </Link>
        </div>
    );
};
