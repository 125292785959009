import {atom, selectorFamily} from 'recoil';

import {IArticle} from '../models';
import {readArticleList} from '../api';

export interface IArticleListStateFilters {
    categoryId?: string;
    isPublished?: boolean;
    isFeatured?: boolean;

    [key: string]: string | undefined | boolean;
}

interface IArticleListState {
    filters: IArticleListStateFilters;
    articles: IArticle[];
    resetVersion: number;
}

export const articleListResetAtom = atom<number>({
    key: 'articleListResetAtom',
    default: 1,
});

export const articleListAtom = atom<IArticleListState | undefined>({
    key: 'articleListAtom',
    default: undefined,
});

export const articleListReadSelector = selectorFamily<IArticleListState, IArticleListStateFilters>({
    key: 'articleListReadSelector',
    get: (filters) => async ({get}): Promise<IArticleListState> => {
        const atomValue = get(articleListAtom);
        const resetVersion = get(articleListResetAtom);
        if (
            atomValue &&
            atomValue.resetVersion === resetVersion &&
            JSON.stringify(filters) === JSON.stringify(atomValue.filters)
        ) {
            return atomValue;
        }
        const {articles} = await readArticleList({
            category_id: filters.categoryId,
            is_featured: filters.isFeatured,
            is_published: filters.isPublished,
            limit: 1000,
        });
        return {
            filters,
            articles,
            resetVersion,
        };
    },
});
