import React from 'react';

import {Container} from 'react-bootstrap';

import {useNavbar} from 'shared/hooks/useNavbar';

import {AppFooter} from 'shared/components/AppFooter/AppFooter';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';
import {TechProductsPageSection} from 'public/TechProductsPage/TechProductsPageSection/TechProductsPageSection';

import './style.scss';

export const TechProductsPage = () => {
    useNavbar(true);

    return (
        <div className="pt-5">
            <PageHelmet title="Tech Suite" />
            <Container>
                <h1 className="TechProductsPage__title">Tech Suite</h1>
                <div className="TechProductsPage__lead">
                    We are always happy to talk about white labelling any of the tech features of our marketplace. We
                    are working on a suite of Saas products, which are listed below.
                </div>
            </Container>
            <div className="pb-5">
                <TechProductsPageSection
                    title="Booking System & Marketplace"
                    iconSrc="/assets/icon/settings.png"
                    imageSrc="/assets/techSuite/BookingSystemMarketplace.png"
                >
                    Our booking system and marketplace is very advanced and suitable for a range of professional
                    services use cases. Each profile has customisable availability settings and automated booking
                    notifications in email and text.
                </TechProductsPageSection>
                <TechProductsPageSection
                    title="Document Generation & E-signatures"
                    iconSrc="/assets/icon/document-svgrepo.png"
                    imageSrc="/assets/techSuite/DocumentGeneration.png"
                    alignRight
                >
                    Our document generation and e-signatures tools allow busy professionals to craft their own template
                    library, easily generate documents and send them for electronic signature to clients.
                </TechProductsPageSection>
                <TechProductsPageSection
                    title="Case Chart"
                    iconSrc="/assets/icon/financial-profit.png"
                    imageSrc="/assets/techSuite/CaseChart.png"
                >
                    Our case charting tool allows detailed project mapping and costing, suitable for a range of
                    industries. In the future we intend to develop it further into a workflow automation tool that will
                    be useful for organisations wishing to ensure particular actions are performed by remote staff at
                    set milestones.
                </TechProductsPageSection>
                <TechProductsPageSection
                    title="Talent Lists"
                    iconSrc="/assets/icon/document.png"
                    imageSrc="/assets/techSuite/TalentLists.png"
                    alignRight
                >
                    Our talent lists tool allows easy recording of profile favourites. Suitable for a range of
                    industries.
                </TechProductsPageSection>
                <TechProductsPageSection
                    title="Payments"
                    iconSrc="/assets/icon/income.png"
                    imageSrc="/assets/techSuite/payments.png"
                >
                    Our payments platform allows billing, e-commerce and escrow using virtual accounts for better pay
                    security and transparency.
                </TechProductsPageSection>
            </div>
            <AppFooter subscribe={false} />
        </div>
    );
};
