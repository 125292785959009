import React from 'react';
import {Button} from 'react-bootstrap';

interface ICoolLegalLinkProps {
    title: string;
    children: React.ReactNode;
    url: string;
}

export const CoolLegalLink = ({children, title, url}: ICoolLegalLinkProps) => {
    return (
        <tr>
            <td className="CoolLegalLinks__title-col">
                {title}
            </td>
            <td className="CoolLegalLinks__description-col">
                {children}
            </td>
            <td className="CoolLegalLinks__button-col">
                <Button
                    as="a"
                    className="btn btn-md CoolLegalLinks__button"
                    variant="featured"
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                >
                    Click Here
                </Button>
            </td>
        </tr>
    );
};
