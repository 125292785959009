import React from 'react';

import {CSSTransition} from 'react-transition-group';
import {useRecoilValue} from 'recoil';
import {Image} from 'react-bootstrap';

import {useAuth} from 'shared/auth/hooks/useAuth';
import {useSignupStatus} from 'modules/profile/hooks/useSignupStatus';
import {showLoadingPageAtom} from 'shared/state/loading-page';

import './style.scss';

export const LoadingPage = () => {
    const {isLoading: loadingAuth} = useAuth();
    const {loading: loadingProfile} = useSignupStatus();
    const showLoading = useRecoilValue(showLoadingPageAtom);

    const isReady = !showLoading && !loadingAuth && !loadingProfile;

    return (
        <CSSTransition
            in={!isReady}
            timeout={300}
            classNames="LoadingPage"
            unmountOnExit={true}
        >
            <div className="LoadingPage vh-100 vw-100">
                <div className="LoadingPage__content">
                    {!isReady && (
                        <Image
                            src="/assets/logo-animated.gif"
                            className="LoadingPage__image"
                            alt="Rightful logo"
                        />
                    )}
                </div>
            </div>
        </CSSTransition>
    );
};
