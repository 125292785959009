import React from 'react';

import {IArticle} from 'modules/help/models';

import {UserIdProfilePicture} from 'shared/components/UserIdProfilePicture/UserIdProfilePicture';
import {UserIdName} from 'shared/components/UserIdName/UserIdName';
import {FormatDistance} from 'shared/components/date/FormatDistance/FormatDate';

import './style.scss';

interface IHelpCenterArticleHeaderProps {
    article: IArticle;
}

export const HelpCenterArticleHeader = ({article}: IHelpCenterArticleHeaderProps) => {
    return (
        <div className="HelpCenterArticleHeader">
            <div className="HelpCenterArticleHeader__headingBox">
                <div className="HelpCenterArticleHeader--Header">
                    {article.name}
                </div>
                <div className="HelpCenterArticleHeader__div-update">
                    Updated <FormatDistance addSuffix>{article.updated_at}</FormatDistance>
                </div>
            </div>
            <div className="HelpCenterArticleHeader--content">
                {article.description}
            </div>
            <div className="HelpCenterArticleHeader--profileName">
                <UserIdProfilePicture id={article.user_id} size="notification" round={true}/>
                <div className="HelpCenterArticleHeader--profileText">
                    Written by <UserIdName id={article.user_id}/>
                </div>
            </div>
        </div>
    );
};
