import React from 'react';

import {ReactComponent as CustomerCentricity} from 'shared/icons/aboutus/CustomerCentricity.svg';
import {ReactComponent as Goodideascome} from 'shared/icons/aboutus/Goodideascome.svg';
import {ReactComponent as OutcomeThinking} from 'shared/icons/aboutus/OutcomeThinking.svg';
import {OurGuidingPrinciplesCard} from './OurGuidingPrinciplesCard';

export const OurGuidingPrinciples = () => {
    const ourGuidingPrinciplesCardArray = [
        {
            title: 'Outcome Thinking',
            iconSVGFun: (
                <CustomerCentricity/>
            ),
            paragraphText: [
                'We do everything with the outcome in mind. We challenge the status quo, we iterate, we improve.',
            ],
        },
        {
            title: 'Customer Centricity ',
            iconSVGFun: (
                <Goodideascome/>
            ),
            paragraphText: [
                'Law is a service-industry like any other. If we aren’t serving our clients then what are we doing here?',
            ],
        },
        {
            title: 'Good ideas can come from anyone',
            iconSVGFun: (
                <OutcomeThinking/>
            ),
            paragraphText: [
                'A good idea speaks for itself. As Amit Ray says "Collaboration has no hierarchy. The Sun collaborates with soil to bring flowers to the earth."',
            ],
        },
    ];

    return (
        <>
            <div className="AboutUsPage__heading">
                <h2 className="AboutUsPage__heading--text">Our Guiding Principles</h2>
            </div>
            <div className="AboutUsPage__margin--b28">
                <p className="m-0 AboutUsPage__heading--sub-heading AboutUsPage__paragraph--text ">
                    Rightful was founded with three core principles
                </p>
            </div>
            <div className="AboutUsPage__card--group AboutUsPage__margin--t63-b122">
                {ourGuidingPrinciplesCardArray && ourGuidingPrinciplesCardArray.map((data, index) => (
                    <div key={index} className="m-4">
                        <OurGuidingPrinciplesCard
                            paragraphText={data.paragraphText}
                            title={data.title}
                            iconSVGFun={data.iconSVGFun}
                        />
                    </div>
                ))}
            </div>
        </>
    );
};
