import {atomFamily, selector, selectorFamily} from 'recoil';

import {IAvailabilitySettings} from 'modules/availability-settings/models';
import {readAvailabilitySettings} from 'modules/availability-settings/api';
import {throwWriteOnlySelectorError, guardRecoilDefaultValue} from 'shared/utils/recoil';

export const availabilitySettingsAtom = atomFamily<IAvailabilitySettings | undefined, string>({
    key: 'availabilitySettingsAtom',
    default: undefined,
});

export const availabilitySettingsReadSelector = selectorFamily<IAvailabilitySettings, string>({
    key: 'availabilitySettingsReadSelector',
    get: profileId => async ({get}) => {
        const availabilitySettings = get(availabilitySettingsAtom(profileId));
        if (availabilitySettings) {
            return availabilitySettings;
        } else {
            return await readAvailabilitySettings(profileId);
        }
    },
    set: profileId => ({set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        set(availabilitySettingsAtom(profileId), newValue);
    },
});

export const availabilitySettingsInsertSelector = selector<IAvailabilitySettings>({
    key: 'availabilitySettingsInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        set(availabilitySettingsAtom(newValue.user_id), newValue);
    },
});
