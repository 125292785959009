import {atom, selector} from 'recoil';

import {readSubscription} from '../api';
import {IBillingSubscription} from '../models';
import {guaranteedCurrentUserIdSelector} from 'shared/auth/state/current-user-id';

export const subscriptionAtom = atom<IBillingSubscription | undefined>({
    key: 'subscriptionAtom',
    default: undefined,
});

export const subscriptionReadSelector = selector<IBillingSubscription>({
    key: 'subscriptionReadSelector',
    get: async ({get}) => {
        const currentValue = get(subscriptionAtom);
        if (currentValue) {
            return currentValue;
        }

        const userID = get(guaranteedCurrentUserIdSelector);
        return await readSubscription(userID);
    },
});
