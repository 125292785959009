import React from 'react';

import {Container, Button} from 'react-bootstrap';

import {useNavbar} from 'shared/hooks/useNavbar';

import {AppFooter} from 'shared/components/AppFooter/AppFooter';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';
import {BecomeMemberFeature} from 'public/BecomeMember/BecomeMemberFeature/BecomeMemberFeature';

import './style.scss';

const becomeMemberFeaturesList: string[] = [
    'Solves marketing for lawyers – create an online presence and connect with clients',
    'Display your expertise with tags and resources such as articles, webinars and podcasts',
    'Easy appointment system, notifications and dedicated support staff',
    'Automatically generate documents and send for e-signature',
    'Generate case charts for added fee transparency',
    'Direct payments (coming soon) and more',
];

export const BecomeMember = () => {
    useNavbar(true);

    return (
        <>
            <PageHelmet
                title="Book a Demo"
                description="Are you sick and tired of the traditional approach to law? Searching for a new way to execute legal projects? If so, then Rightful may be for you. we are obsessed with improving the legal project experience for lawyers and clients. We've designed every aspect of our platform with that goal in mind. To learn just how different we are, why not book a demonstration session by clicking the button below."
            />
            <div className="BecomeMember">
                <div className="content-page-header">
                    <h2 className="content-page-title">Why Rightful?</h2>
                </div>
                <Container>
                    <div className="BecomeMember__description">
                        <p className="m-0 BecomeMember__description--text">
                            Are you sick and tired of the traditional approach to law? Searching for a new way to
                            execute legal projects? If so, then Rightful may be for you. We are obsessed with improving
                            the legal project experience for lawyers and clients. We've designed every aspect of our
                            platform with that goal in mind. To learn just how different we are, why not book a
                            demonstration session by clicking the button below.
                        </p>
                    </div>
                </Container>
                <div className="BecomeMember__headline-section">
                    <h2 className="content-page-title content-page-title--subtle">
                        The Platform is free for practitioner members during its
                        <br />
                        Beta testing phase
                    </h2>
                </div>
                <div className="content-page-header">
                    <h2 className="content-page-title">Features</h2>
                </div>
                <div className="BecomeMember__feature-list">
                    {becomeMemberFeaturesList.map((featureDescription, index) => (
                        <BecomeMemberFeature key={index}>{featureDescription}</BecomeMemberFeature>
                    ))}
                </div>
                <div className="BecomeMember__booking-button-wrapper">
                    <Button
                        as="a"
                        variant="featured"
                        className="btn-xxl-wide"
                        href="https://calendly.com/chris-2306/15min"
                        target="_blank"
                        rel="noreferrer"
                        size="lg"
                    >
                        BOOK A DEMO
                    </Button>
                </div>
            </div>
            <AppFooter />
        </>
    );
};
