import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import classNames from 'classnames';

import {IFixedFeeService} from 'modules/profile/models/IServiceDetails';
import {defaultPopperConfig} from 'shared/utils/popper-utils';

import './style.scss';

interface IFixedFeesTooltipProps {
    id: string;
    fixedFeeServices: IFixedFeeService[];
    size?: 'lg';
    className?: string;
    children: React.ReactNode;
}

export const FixedFeesTooltip = ({id, fixedFeeServices, size, className, children}: IFixedFeesTooltipProps) => {
    const tooltip = React.useMemo(() => {
        if (fixedFeeServices.length > 0) {
            return (
                <table>
                    <tbody>
                    {fixedFeeServices.map((s, i) => (
                        <tr key={i.toString()}>
                            <td className="text-left pr-3">{s.name}</td>
                            <td className="text-left">${s.price}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            );
        } else {
            return 'No details provided';
        }
    }, [fixedFeeServices]);

    return (
        <OverlayTrigger
            placement="top"
            overlay={(props) => (
                <Tooltip id={id} {...props}>
                    {tooltip}
                </Tooltip>
            )}
            popperConfig={defaultPopperConfig}
        >
            {({ref, ...triggerHandler}) => (
                <div
                    ref={ref} {...triggerHandler}
                    className={classNames('d-inline-flex align-items-center nowrap', className)}
                >
                    {children}
                </div>
            )}
        </OverlayTrigger>
    );
};
