import React from 'react';

import {Container} from 'react-bootstrap';

import {useHomepageCache} from 'modules/homepage/hooks';

import {ReviewsCarousel} from 'shared/components/ProfilePageContent/ProfilePageReviews/ReviewsCarousel';

import './style.scss';

export const HomePageReview = () => {
    const {homepageCache, loading, error} = useHomepageCache();

    return (
        <div className="HomePageReview__section">
            <Container>
                <div className="HomePageReview__mainheading">
                    <h2 className="HomePageReview__heading HomePageReview--topmargin">Genuine Feedback</h2>
                </div>
                {homepageCache && (
                    <ReviewsCarousel
                        reviews={homepageCache.featured_reviews} loading={loading}
                        error={error ? 'Error loading reviews' : undefined}
                        editable={false}
                    />
                )}
            </Container>
        </div>
    );
};
