import {components, OptionProps, GroupBase} from 'react-select';

import {ITagOption} from 'shared/components/form/TagControl/TagControl';

import {Tag} from 'shared/components/Tag/Tag';

export const TagControlOption = (props: OptionProps<ITagOption, true, GroupBase<ITagOption>>) => {
    return (
        <div>
            <components.Option {...props}>
                <Tag color={props.data.variant} title={props.label} className="d-inline-block"/>
            </components.Option>
        </div>
    );
};
