import {IFavoriteKey} from 'modules/favorite/models';
import {useRecoilLoadable} from 'shared/hooks/useRecoilLoadable';
import {favoriteLookupSelector, favoriteLookupReadSelector} from 'modules/favorite/state/favorite-lookup';
import {useGuaranteedAuth} from 'shared/auth/hooks/useAuth';

export const useFavoriteLoadable = (profileId: string) => {
    const {tokenData} = useGuaranteedAuth();
    const favoriteKey: IFavoriteKey = {
        user_id: tokenData.id,
        profile_id: profileId,
    };
    const {data, loading, error} = useRecoilLoadable({
        state: favoriteLookupSelector(favoriteKey),
        loadable: favoriteLookupReadSelector(favoriteKey),
    });
    return {
        favorite: data?.favorite,
        loading,
        error,
    };
};
