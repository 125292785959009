import axios from 'axios';

import {IChatMessage, IChatMessageBody, IChatMessageListQuery, IChatMessageListResult} from 'modules/chat-message/models';
import {getAccessTokenSilently} from 'shared/utils/token';
import {Config} from 'config';
import {getAuthHeaders} from 'shared/utils/auth';

const getURLForChatMessageAPI = (path: string) => {
    return `${Config.chatServiceURL}/chat_messages${path}`;
};

export const createChatMessage = async (ChatMessageData: IChatMessageBody): Promise<IChatMessage> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForChatMessageAPI('/'), ChatMessageData, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const readChatMessageList = async (chatMessageListQuery: IChatMessageListQuery): Promise<IChatMessageListResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForChatMessageAPI('/'), {
        params: chatMessageListQuery,
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};
