import React from 'react';

import {Button, Card} from 'react-bootstrap';

import {PostMediaKind} from 'modules/post/models/postMediaKind';
import {IPost} from 'modules/post/models/IPost';
import {formatDateOnlyReadableShort} from 'shared/utils/date';

import {PlayButtonIcon} from '../../shared/icons/VideoPlayer';

interface IPodcastCardProps {
    post: IPost;
    onOpen: (mediaURL: string) => void;
}

export const PodcastCard = ({post, onOpen}: IPodcastCardProps) => {
    const podcastFile = React.useMemo(() => {
        const podcastMedia = post.media.filter(mediaItem => mediaItem.kind === PostMediaKind.Link && mediaItem.content);
        return podcastMedia.length > 0 ? podcastMedia[0] : undefined;
    }, [post]);

    if (!podcastFile) {
        console.warn('[PodcastCard] ==> no podcast media found');
        return null;
    }

    return (
        <Card className="PodcastView__card PodcastView__margin--mt-20">
            <Card.Body className="PodcastView__card--body">
                <div className="PodcastView__Content--heading">
                    <div className="PodcastView__Content--heading--left">
                        <h2 className="m-0 p-0 PodcastView__header--title text-left">Podcast</h2>
                    </div>
                    <div className="PodcastView__Content--heading--right">
                        <p className="m-0 PodcastView__header__userDetail--name">
                            {formatDateOnlyReadableShort(post.created_at)}
                        </p>
                    </div>
                </div>
                <div>
                    <p className="m-0 pb-3 PodcastView__header--description">{post.summary}</p>
                </div>
                <div className="d-flex align-items-center">
                    <Button onClick={() => onOpen(podcastFile?.content as string)} variant="featured">
                        <div className="d-flex align-items-center">
                            <div>
                                <PlayButtonIcon />
                            </div>
                            <div>
                                <p className="m-0 pl-2">Play</p>
                            </div>
                        </div>
                    </Button>
                </div>
            </Card.Body>
        </Card>
    );
};
