import React from 'react';

import {SlickCards} from './SlickCards';
import { Fees, Paperless, Process } from 'shared/icons/Homepage';

import './style.scss';

export const HomeAboutpage = () => {
    return (
        <div className="HomeAboutpage container">
            <div className="HomeAboutpage--title">What is Rightful?</div>

            <div className="HomeAboutpage--text">
                Legal projects often pass through many hands before reaching a resolution. Choosing the right team is
                vital to the outcome. Rightful allows you to search indepth profiles of legal professionals, easily
                book appointments, manage payments and see your legal project come together in real time from one
                central online platform.
            </div>
            <div className="HomeAboutpage--cardcontain">
                <SlickCards>
                    <div className="HomeAboutpage--box">
                        <div className="d-flex justify-content-center">
                            <Process className="HomeAboutpage--icon" />
                        </div>
                        <p className="HomeAboutpage--boxLable">Improved Process</p>
                        <div className="d-flex justify-content-center">
                            <p className="HomeAboutpage--boxText">
                                We've redesigned the process with you the client in mind.
                            </p>
                        </div>
                    </div>

                    <div className="HomeAboutpage--box">
                        <div className="d-flex justify-content-center">
                            <Fees className="HomeAboutpage--icon" />
                        </div>
                        <p className="HomeAboutpage--boxLable">Transparent Fees</p>
                        <div className="d-flex justify-content-center">
                            <p className="HomeAboutpage--boxText">
                                Compare rates and fixed fees. Your legal project costed out in detailed case charts.
                            </p>
                        </div>
                    </div>

                    <div className="HomeAboutpage--box">
                        <div className="d-flex justify-content-center">
                            <Paperless className="HomeAboutpage--icon" />
                        </div>
                        <p className="HomeAboutpage--boxLable">Digital First</p>
                        <div className="d-flex justify-content-center">
                            <p className="HomeAboutpage--boxText">
                                No more killing the amazon. Legal projects managed from anywhere.
                            </p>
                        </div>
                    </div>
                </SlickCards>
            </div>
        </div>
    );
};
