import React from 'react';
import {Loading} from 'shared/components/loading/Loading/Loading';

interface ILoadingSuspenseProps {
    children: React.ReactNode;
    skeleton?: React.ReactNode;
}

export const LoadingSuspense = ({children, skeleton}: ILoadingSuspenseProps) => {
    return <React.Suspense fallback={skeleton ? skeleton : <Loading />}>{children}</React.Suspense>;
};
