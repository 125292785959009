import React from 'react';

import {Card, Col} from 'react-bootstrap';

interface Props {
    personImage: string;
    name: string;
    role: string;
    description: React.ReactNode;
}

export const OurPeopleCard = (props: Props) => {
    const {personImage, name, description, role} = props;

    return (
        <Col style={{padding: '0 !important'}}>
            <Card className="our_people_card">
                <Card.Img className="our_people_card_img" variant="top" src={personImage}/>
                <Card.Body className="card_body_class">
                    <div className="our_people_name">{name}</div>
                    <div className="our_people_role">{role}</div>
                    <div className="our_people_description">{description}</div>
                </Card.Body>
            </Card>
        </Col>
    );
};
