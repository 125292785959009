import { EffectCallback, useEffect } from 'react';

// taken from https://github.com/streamich/react-use
// licence allows it
// I'm hesitant to use the library due to the large number of contributors
const useEffectOnce = (effect: EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, []);
};

export default useEffectOnce;
