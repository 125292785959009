import React, {useRef} from 'react';

import classNames from 'classnames';

import {useOutsideClick} from 'shared/hooks/useOutsideClick';

import {ProjectMembersItem} from 'shared/components/project/ProjectMembers/ProjectMembersItem';
import {ProjectMemberShowMore} from './ProjectMemberShowMore';

import './style.scss';

interface IProjectMembersProps {
    memberIds: string[];
    size?: 'xs';
    inline?: boolean;
    disableTooltip?: boolean;
    className?: string;
    setSelectedMemberId?: React.Dispatch<React.SetStateAction<string | null>>;
    setSelectedOwnerId?: (val: string) => void;
    ownerId: string;
}

export const ProjectMembers = ({
    memberIds,
    size,
    inline,
    disableTooltip,
    className,
    setSelectedMemberId,
    setSelectedOwnerId,
    ownerId,
}: IProjectMembersProps) => {
    const dropDownRef = useRef<HTMLDivElement | null>(null);
    useOutsideClick(dropDownRef, () => setIsOpenList(false));

    const [isOpenList, setIsOpenList] = React.useState<boolean>(false);

    // sets the project owner to the first member in the list
    const ownerIndex = memberIds.findIndex(memberId => memberId === ownerId);
    const owner = memberIds[ownerIndex];
    const test = memberIds.filter(memberId => memberId !== ownerId);
    const orderedMembers = [owner, ...test];

    let firstFourMembers = orderedMembers;
    let remainingMembers: string[] = [];
    if (orderedMembers.length > 4) {
        firstFourMembers = orderedMembers.slice(0, 4);
        remainingMembers = orderedMembers.slice(4);
    }

    const openList = () => {
        setIsOpenList(!isOpenList);
    };

    return (
        <div
            className={classNames('ProjectMembers position-relative', className, {
                'ProjectMembers--inline': inline,
            })}
        >
            {firstFourMembers.map((memberId) => (
                <div
                    key={memberId}
                    onClick={() => {
                        if (setSelectedMemberId && setSelectedOwnerId) {
                            memberId === ownerId ? setSelectedOwnerId(memberId) : setSelectedMemberId(memberId);
                        }
                    }}
                >
                    <ProjectMembersItem
                        key={memberId}
                        userId={memberId}
                        size={size}
                        disableTooltip={disableTooltip}
                        isOwner={memberId === ownerId}
                    />
                </div>
            ))}

            {remainingMembers.length > 0 && (
                <div ref={dropDownRef} onClick={openList} className="ProjectMembers__item cursor-pointer">
                    <ProjectMemberShowMore size={size} remainingMembers={remainingMembers} isOpen={isOpenList} />
                </div>
            )}
        </div>
    );
};
