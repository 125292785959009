import React from 'react';

interface ICardCarouselEmptyProps {
    children: React.ReactNode;
}

export const CardCarouselEmpty = ({children}: ICardCarouselEmptyProps) => {
    return (
        <div className="CardCarousel__empty text-muted">
            {children}
        </div>
    );
};
