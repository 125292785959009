import {useRecoilLoadable} from 'shared/hooks/useRecoilLoadable';
import {postListReadSelector, postListSelector, IPostListStateFilters} from 'modules/post/state/post-list';

export const usePostListLoadable = (filters: IPostListStateFilters) => {
    const {data, loading, error} = useRecoilLoadable({
        state: postListSelector(filters),
        loadable: postListReadSelector(filters),
    });
    return {
        postList: data,
        loading,
        error,
    };
};
