import React, {useEffect, useMemo} from 'react';

import classNames from 'classnames';
import {format, isSameDay, parseISO} from 'date-fns';
import {formatInTimeZone} from 'date-fns-tz';

import {ITimeSlotAvailability} from 'modules/availability/models';

import {EmptyTable} from 'shared/components/EmptyTable/EmptyTable';

import './style.scss';

interface ITimeSelect {
    date: Date;
    onChange: (value: Date) => void;
    timeSlots: ITimeSlotAvailability[];
    value?: Date;
    disabled?: boolean;
    emptyContent?: React.ReactNode;
    setCurrentDate: (currentDate: Date) => void;
    availabilityTimezone: string;
    displayTimezone: string;
}

export const AvailableDate = ({
    date,
    onChange,
    setCurrentDate,
    timeSlots,
    value,
    disabled,
    emptyContent,
    availabilityTimezone,
    displayTimezone,
}: ITimeSelect) => {
    const availableSlots = useMemo(() => {
        const filteredSlots = timeSlots.filter((timeSlot) => isSameDay(parseISO(timeSlot.start_at), date));
        return filteredSlots.map((timeSlot) => {
            const startAt = parseISO(timeSlot.start_at);
            let title: string;
            if (displayTimezone === availabilityTimezone) {
                title = format(startAt, 'hh:mm a');
            } else {
                title = formatInTimeZone(startAt, displayTimezone, 'hh:mm a');
            }
            return {
                title: title,
                value: startAt,
                isAvailable: timeSlot.is_available,
            };
        });
    }, [timeSlots, date, displayTimezone, availabilityTimezone]);

    useEffect(() => {
        if (availableSlots.length <= 0) {
            const future = new Date(date.getTime());
            if (date === future) {
                setCurrentDate(new Date(future));
            } else {
                const nextAvailableDate = future.setDate(future.getDate() + 1);
                setCurrentDate(new Date(nextAvailableDate));
            }
        } else {
            setCurrentDate(date);
        }
    }, [availableSlots, date, setCurrentDate]);

    return (
        <div className="row d-flex justify-content-center align-content-center">
            {availableSlots.map(timeSlot => (
                <div
                    className={classNames('AvailableDate__item', {
                        'AvailableDate__item--selected': value && timeSlot.value.getTime() === value.getTime(),
                        'AvailableDate__item--not-available': !timeSlot.isAvailable,
                    })}
                    key={timeSlot.value.toString()}
                    onClick={() => onChange(timeSlot.value)}
                >
                    {timeSlot.title}
                </div>
            ))}

            {!availableSlots.length &&
                (emptyContent ? (
                    <div>{emptyContent}</div>
                ) : (
                    <EmptyTable>'There are no available times on this day.'</EmptyTable>
                ))}
        </div>
    );
};
