import React, {useRef, useState} from 'react';

import {Button, Modal, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';

import {ServiceType} from 'modules/profile/models/serviceType';
import {IProfile} from 'modules/profile/models/IProfile';
import {getFileDownloadUrl} from 'modules/file/utils';
import {getFullName, getProfessionTitle} from 'modules/profile/utils';

import {ProfilePicture} from 'shared/components/ProfilePicture/ProfilePicture';
import {TagId} from 'shared/components/TagId/TagId';
import {UploadProfilePicture} from 'shared/components/UploadProfilePicture/UploadProfilePicture';
import {ProfilePageHeaderMobile} from './ProfilePageHeaderMobile';
import {ProfileEditModal} from './ProfileEditModal';
import {ProfilePageBookingModal} from 'shared/components/ProfilePageContent/ProfilePageBookingButton/ProfilePageBookingModal';
import {FixedFeesLabel} from 'shared/components/FixedFeesLabel/FixedFeesLabel';

import './style.scss';
import {DropdownCaret} from 'shared/components/DropdownCaret/DropdownCaret';
import {createChat} from 'modules/chat/api';
import {useAuth} from 'shared/auth/hooks/useAuth';
import {createSearchParams, useNavigate, useSearchParams} from 'react-router-dom';
import {chatInsertSelector, chatListBumpToTopSelector} from 'modules/chat/state';
import {useSetRecoilState} from 'recoil';
import {ItemKind} from 'modules/item-key';
import {toast} from 'shared/utils/toast';
import {toastAxiosError} from 'shared/utils/error';
import {IconChat} from 'shared/icons/common';
import {CalendarIcon} from 'shared/icons/dashboard';
import {useOutsideClick} from 'shared/hooks/useOutsideClick';
import {Loading} from 'shared/components/loading/Loading/Loading';
import {NotAuthenticatedModalBody} from 'shared/components/modals/NotAuthenticatedModalBody/NotAuthenticatedModalBody';

interface IProfilePageHeaderProps {
    profile: IProfile;
    isEditable?: boolean;
    onEdit?: () => void;
}

export const ProfilePageHeader = ({profile, isEditable, onEdit}: IProfilePageHeaderProps) => {
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isConnectExpanded, setIsConnectExpanded] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const {isAuthenticated, tokenData} = useAuth();
    const navigate = useNavigate();
    const connectBtnContainerRef = useRef(null);

    const insertChat = useSetRecoilState(chatInsertSelector);
    const bumpChatToTop = useSetRecoilState(chatListBumpToTopSelector);

    useOutsideClick(connectBtnContainerRef, () => setIsConnectExpanded(false));

    const fullName = getFullName(profile);
    const profession = profile.profession ? getProfessionTitle(profile.profession) : null;
    let city = profile.contact_details_display?.city;
    let state = profile.contact_details_display?.state;
    const country = profile.contact_details_display?.country;

    const [showEditProfileModal, setShowEditProfileModal] = React.useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isChatUnauthenticatedModal, setIsChatUnauthenticatedModal] = useState<boolean>(false);

    if (city) {
        city += ',';
    }

    if (state) {
        state += ',';
    }

    const onEditProfileModal = () => {
        setShowEditProfileModal(true);
    };

    const showModal = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        searchParams.set('booking', 'show');
        setSearchParams(searchParams);
    };

    const handleChatClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();

        if (!isAuthenticated || !tokenData) return setIsChatUnauthenticatedModal(true);

        if (isSubmitting || profile.id === tokenData.id) return;

        setIsSubmitting(true);
        (async () => {
            try {
                const newChat = await createChat({
                    user_ids: [profile.id, tokenData.id],
                    is_active: true,
                    reference: {
                        kind: ItemKind.CHAT,
                        id: '0',
                    },
                });

                const options = {
                    pathname: '/dashboard/chat',
                    search: `?${createSearchParams({chat_id: newChat.id})}`,
                };

                insertChat(newChat);
                bumpChatToTop(newChat.id);
                navigate(options, {replace: true});
            } catch (e) {
                toastAxiosError(e, 'Create Chat');
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    return (
        <>
            <div className="ProfilePageHeader MobileView">
                <div className="ProfilePageHeader__ProfilePicture--container">
                    <ProfilePicture
                        name={fullName}
                        id={profile.image_id}
                        url={profile.image_url}
                        alt={fullName}
                        size="lg"
                        round
                        className=" ProfilePageHeader__ProfilePicture"
                    />
                    <div>{isEditable && <UploadProfilePicture userId={profile.id} />}</div>
                </div>
                <Row className="mt-5">
                    <div className="ProfilePageHeader__leftBox">
                        {profession && <div className="ProfilePageHeader__profession">{profession}</div>}
                        <h1 className="ProfilePageHeader__title2">{fullName}</h1>
                        {profile.contact_details_display && (
                            <div className="ProfilePageHeader__location">
                                <FontAwesomeIcon
                                    icon={faMapMarkerAlt}
                                    size="lg"
                                    className="ProfilePageHeader__location-icon"
                                />
                                {city} {state} {country}
                            </div>
                        )}
                        <div className="ProfilePageHeader__tags">
                            {profile.tag_ids.map((tagId) => (
                                <TagId tagId={tagId} key={tagId} size="xl" />
                            ))}
                        </div>
                    </div>
                    <div className="ProfilePageHeader__rightBox">
                        {isEditable && !isEdit ? (
                            ''
                        ) : (
                            <div ref={connectBtnContainerRef} className="ProfilePageHeader__booking">
                                <button
                                    className={`connectbtn ${isConnectExpanded && 'expanded-connectbtn'}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setIsConnectExpanded(!isConnectExpanded);
                                    }}
                                    type="button"
                                >
                                    Connect
                                    <DropdownCaret className={`${isConnectExpanded && 'expanded-DropdownCaret'}`} />
                                </button>
                                <div className={`connectbtnrow ${isConnectExpanded && 'expanded-connectbtnrow'}`}>
                                    <Button onClick={handleChatClick}>
                                        <IconChat />
                                        Message
                                    </Button>
                                    <Button onClick={showModal}>
                                        <CalendarIcon /> Book Diary
                                    </Button>
                                </div>
                            </div>
                        )}
                        {isEditable && !isEdit && (
                            <>
                                <div className=" ProfilePageHeader__booking">
                                    <img
                                        src="/assets/edit.svg"
                                        alt="edit"
                                        onClick={() => (onEdit ? onEdit?.() : onEditProfileModal())}
                                    />
                                </div>
                                <ProfileEditModal
                                    profile={profile}
                                    onHide={() => {
                                        setShowEditProfileModal(false);
                                    }}
                                    show={showEditProfileModal}
                                />
                            </>
                        )}
                        <div className="ProfilePageHeader__rate d-flex justify-content-end ">
                            {profile && profile.service_details && profile.service_details.hour_rate > 0 && (
                                <p className="mr-3 ProfilePageHeader__rateHr font-weight-bold">
                                    ${profile.service_details?.hour_rate} / hr
                                </p>
                            )}
                            {profile && profile.service_details && profile.service_details.day_rate > 0 && (
                                <p className=" ProfilePageHeader__rateDay font-weight-bold">
                                    ${profile.service_details?.day_rate} / day
                                </p>
                            )}
                        </div>
                        {profile.service_types && profile.service_types.includes(ServiceType.FixedFees) ? (
                            <FixedFeesLabel
                                id={profile.id}
                                fixedFeeServices={profile.service_details?.fixed_fee_services ?? []}
                                size="lg"
                                className="ProfilePageHeader__rate"
                            />
                        ) : (
                            <div className="ProfilePage_FixedFeeIcon ProfilePageHeader__rate"></div>
                        )}
                        {isEditable && isEdit && (
                            <div className="ProfilePageHeader__editable-controls">
                                <Button
                                    type="button"
                                    variant="default"
                                    className="mr-3"
                                    onClick={() => setIsEdit(!isEdit)}
                                >
                                    Preview
                                </Button>
                                <Button type="button" variant="featured" onClick={() => setIsEdit(!isEdit)}>
                                    Save
                                </Button>
                            </div>
                        )}
                    </div>
                </Row>
                <Row className="ProfilePageHeader__Bar "></Row>
                <Row className="ProfilePageHeader__BotBar mb-5 mt-2">
                    <div className=" col-8 ProfilePageHeader__leftBo2x mb-5 mt-2">
                        <div className="TagId">
                            {profile.service_types && profile.service_types.includes(ServiceType.FreeConsultation) ? (
                                <div className=" ml-0">
                                    <div className="d-flex justify-content-start ml-0 mr-3">
                                        <p className="">
                                            <img
                                                src="/assets/profileIcons/Icon ionic-md-checkmark-circle.png"
                                                alt="Free first consultation"
                                                className="imagesizeProfilepage"
                                            />
                                        </p>
                                        <p className=""> Free first consultation</p>
                                    </div>
                                </div>
                            ) : (
                                <div className=""></div>
                            )}

                            {profile.service_types && profile.service_types.includes(ServiceType.NoDirectBriefs) && (
                                <div className="">
                                    <div className="d-flex justify-content-start mr-3">
                                        <p className="">
                                            <img
                                                src="/assets/profileIcons/Icon ionic-ios-close-circle.png"
                                                alt="Does not accept direct briefs"
                                                className="imagesizeProfilepage"
                                            />
                                        </p>
                                        <p className=""> Does not accept direct briefs</p>
                                    </div>
                                </div>
                            )}

                            {profile.service_types && profile.service_types.includes(ServiceType.AccreditedSpecialist) && (
                                <div className=" p-0">
                                    <div className="d-flex justify-content-start mr-3">
                                        <p className="">
                                            <img
                                                src="/assets/profileIcons/Group 9658.png"
                                                alt="Accredited Specialist"
                                                className="imagesizeProfilepage2"
                                            />
                                        </p>
                                        <p className=""> Accredited Specialist</p>
                                    </div>
                                </div>
                            )}
                            {profile.service_types && profile.service_types.includes(ServiceType.UnderSupervision) && (
                                <div className=" p-0">
                                    <div className="d-flex justify-content-start mr-3">
                                        <p className="">
                                            <img
                                                src="/assets/profileIcons/A.png"
                                                alt="Under Supervision"
                                                className="imagesizeProfilepage2"
                                            />
                                        </p>
                                        <p className=""> Under Supervision</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="ProfilePageHeader__rightBo2x ProfilePageHeader__rightBo2x--px4 mt-2">
                        <div className="row d-flex justify-content-end">
                            {profile.resume_id && (
                                <p className="downlodBTN">
                                    <img src="/assets/profileIcons/Icon awesome-file-pdf.svg" alt="" className="mr-3" />
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={getFileDownloadUrl({id: profile.resume_id})}
                                    >
                                        Download Resume
                                    </a>
                                </p>
                            )}
                            {profile.social_links.map((socialId, key) => (
                                <a
                                    href={socialId.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    key={key}
                                    className="IdLinkPadd"
                                >
                                    {socialId.type === 'LINKEDIN' && socialId.url && (
                                        <img src="/assets/homepage/Footer/Icon awesome-linkedin.png" alt="Linkedin" />
                                    )}
                                    {socialId.type === 'FACEBOOK' && socialId.url && (
                                        <img
                                            src="/assets/homepage/Footer/Icon awesome-facebook-square.png"
                                            alt="Facebook"
                                        />
                                    )}
                                    {socialId.type === 'TWITTER' && socialId.url && (
                                        <img
                                            src="/assets/homepage/Footer/Icon awesome-twitter-square.png"
                                            alt="Twitter"
                                        />
                                    )}
                                </a>
                            ))}
                        </div>
                    </div>
                </Row>
            </div>
            <Modal
                show={isChatUnauthenticatedModal}
                centered
                size="lg"
                onHide={() => setIsChatUnauthenticatedModal(false)}
                animation={false}
            >
                <Modal.Header closeButton className="p-3">
                    {profile && (
                        <Modal.Title className="BookingModal__title">{getFullName(profile)}'s Chat</Modal.Title>
                    )}
                </Modal.Header>

                <React.Suspense
                    fallback={
                        <Modal.Body className="p-5">
                            <Loading />
                        </Modal.Body>
                    }
                >
                    {profile && !isAuthenticated && <NotAuthenticatedModalBody />}
                </React.Suspense>
            </Modal>
            <ProfilePageHeaderMobile
                profile={profile}
                isEditable={isEditable}
                onEdit={() => (onEdit ? onEdit?.() : onEditProfileModal())}
            />

            <ProfilePageBookingModal profile={profile} />
        </>
    );
};
