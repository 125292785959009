import React from 'react';
import {Button} from 'react-bootstrap';

import {IPlan} from 'public/PlansPage/models';

import './style.scss';
import {Link} from 'react-router-dom';

interface IPlanCardProps {
    isFirst: boolean;
    isAnnual: boolean;
    plan: IPlan;
}

const PlanCard = ({isFirst, plan, isAnnual}: IPlanCardProps) => {
    const isPopular = plan.isPopular;

    const price = React.useMemo(() => {
        if (!plan.pricing) {
            return null;
        } else if (isAnnual) {
            return plan.pricing.yearly;
        } else {
            return plan.pricing.monthly;
        }
    }, [plan.pricing, isAnnual]);

    return (
        <div
            style={{backgroundColor: isFirst ? '#F9F9F9' : isPopular ? '#0991F2' : 'white'}}
            className="PlanCardContainer"
        >
            <div className="PlanCardContainer__MostPopular">
                <p className="mb-0">{isPopular && 'Most Popular'}</p>
            </div>
            <div
                style={{backgroundColor: isFirst ? '#F9F9F9' : 'white'}}
                className={`PlanCardContainer__PlanCard ${isPopular && 'PlanCardContainer__Popular-PlanCard'}`}
            >
                <div className="PlanCardContainer__PlanCard__header">
                    <img src={plan.icon} alt="planicon"/>
                    <p style={{color: isFirst ? '#212529' : isPopular ? '#0A8BF2' : '#788B9A'}} className="mb-0">
                        {plan.name}
                    </p>
                </div>
                <div className="PlanCardContainer__PlanCard__heading">
                    <p style={{color: isPopular ? '#151C2D' : '#788B9A'}} className="mb-0">
                        {plan.description}
                    </p>
                </div>
                <div className="PlanCardContainer__PlanCard__pricing">
                    <span className="PlanCardContainer__PlanCard__pricing--currencysign">$</span>
                    <span className="PlanCardContainer__PlanCard__pricing--price">{price?.price ?? 0}</span>
                    {!!price ? (
                        <span className="PlanCardContainer__PlanCard__pricing--time">
                            {isAnnual ? '/year' : '/month'}
                        </span>
                    ) : null}
                </div>
                <div className={`${!!price ? 'PlanCardContainer__PlanCard__getstartedbtn' : ''}`}>
                    {!!price && (
                        <Link to={`/dashboard/settings/payments?select_price=${price.priceName}`} className="btn btn-block btn-featured m-auto">
                            Get started with {plan.name}
                        </Link>
                    )}
                </div>

                <ul className="PlanCardContainer__PlanCard__perkslist">
                    {plan.perks.map((perk, index) => (
                        <li key={index} className="PlanCardContainer__PlanCard__perkslist__perk">
                            <div
                                style={{
                                    backgroundColor: isPopular ? '#0A8BF2' : '#788B9A',
                                }}
                                className="PlanCardContainer__PlanCard__perkslist__perk--tickicon"
                            >
                                <img src="/assets/SVG/right.svg" alt="tickicon"/>
                            </div>
                            <p
                                style={{color: isPopular ? '#0A8BF2' : '#788B9A'}}
                                className="PlanCardContainer__PlanCard__perkslist__perk--pertext mb-0"
                            >
                                {perk}
                            </p>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default PlanCard;
