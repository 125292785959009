import React from 'react';

import {Col, Form, Row} from 'react-bootstrap';
import {FieldValues, Path, UseControllerProps} from 'react-hook-form';

import {socialProviderOptions} from 'modules/profile/constants';

import {DropdownSelectController} from 'shared/components/form/DropdownSelect/DropdownSelectController';
import {InputController} from 'shared/components/form/InputController/InputController';
import {Delete} from 'shared/icons/dashboard';

interface ISocialLinksControllerProps<FormType> extends UseControllerProps<FormType> {
    index: number;
    disabled?: boolean;
    onDelete?: () => void;
}

export const SocialLinksController = <FormType extends FieldValues>({
    disabled,
    name,
    control,
    onDelete,
}: ISocialLinksControllerProps<FormType>) => {

    return (
        <Row className="align-items-center pb-4">
            <Col lg={6} sm={6} className="mb-sm-0 mb-3">
                <Form.Group controlId="lastname">
                    <Form.Label className="form-label--sm">
                        Social Media Provider
                    </Form.Label>
                    <DropdownSelectController
                        control={control}
                        name={`${name}.type` as Path<FormType>}
                        options={socialProviderOptions}
                        block
                    />
                </Form.Group>
            </Col>
            <Col lg={5} sm={5} className="mb-sm-0 mb-3">
                <Form.Group controlId="lastname">
                    <Form.Label className="form-label--sm">
                        URL
                    </Form.Label>
                    <InputController
                        placeholder=""
                        name={`${name}.url` as Path<FormType>}
                        control={control}
                        disabled={disabled}
                    />
                </Form.Group>
            </Col>
            <Col lg={1} sm={1}>
                <Form.Group as={Col} className="mb-0 text-sm-left text-center">
                    <Delete onClick={() => onDelete?.()} className="ClodeBtn"/>
                </Form.Group>
            </Col>
        </Row>
    );
};
