import React from 'react';

import {Button, Col, Form, Row} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {format, formatDuration} from 'date-fns';

import {IProfile} from 'modules/profile/models/IProfile';
import {EventLocationType} from 'modules/event/models';
import {getFullName} from 'modules/profile/utils';
import {availabilityLocationsDefaultUtil} from 'modules/availability-settings/forms';
import {useAvailabilitySettings} from 'modules/availability-settings/hooks/useAvailabilitySettings';
import {
    getBookingModalConfirmationSchema,
    IBookingModalConfirmationForm,
    IBookingModalBookingForm,
} from 'shared/components/BookingModal/schema';

import {ProfilePicture} from 'shared/components/ProfilePicture/ProfilePicture';
import {InputController} from 'shared/components/form/InputController/InputController';
import {BookingModalConfirmationSelection} from 'shared/components/BookingModal/BookingModalConfirmationSelection';

import './style.scss';

interface IBookingModalConfirmationProps {
    formData: IBookingModalBookingForm;
    profile: IProfile;
    onSubmit: (confirmationFormData: IBookingModalConfirmationForm) => void;
    durationMinutes: number;
    disabled?: boolean;
    formReset?: number;
}

export const BookingModalConfirmation = ({
    profile,
    disabled,
    onSubmit,
    formData,
    durationMinutes,
    formReset,
}: IBookingModalConfirmationProps) => {
    const availabilitySettings = useAvailabilitySettings(profile.id);

    const defaultValues = React.useMemo(() => {
        return {
            location: availabilityLocationsDefaultUtil(availabilitySettings.locations),
            notes: '',
            address: !!availabilitySettings.locations.physical?.is_client_can_edit
                ? ''
                : availabilitySettings.locations.physical?.address ?? '',
            phone: !!availabilitySettings.locations.callback?.is_client_can_edit
                ? ''
                : availabilitySettings.locations.callback?.phone_number ?? '',
            virtual: !!availabilitySettings.locations.virtual?.is_client_can_edit
                ? ''
                : availabilitySettings.locations.virtual?.url ?? '',
        };
    }, [availabilitySettings]);

    const resolver = React.useMemo(() => {
        return zodResolver(getBookingModalConfirmationSchema(availabilitySettings));
    }, [availabilitySettings]);

    const [currentReset, setCurrentReset] = React.useState<number | undefined>(formReset);
    const {handleSubmit, control, reset, register, watch} = useForm<IBookingModalConfirmationForm>({
        resolver,
        defaultValues,
    });

    const location = watch('location');

    // reset the form whenever the formReset value changes
    React.useEffect(() => {
        if (formReset !== currentReset) {
            reset();
            setCurrentReset(formReset);
        }
    }, [formReset, currentReset, reset, setCurrentReset]);

    const onFormSubmit = handleSubmit((confirmationFormData) => {
        onSubmit(confirmationFormData);
    });

    const fullName = getFullName(profile);
    const bookingDate = format(formData.start_at, 'do MMM');
    const bookingTime = format(formData.start_at, 'HH:mm aaa');
    const bookingDuration = formatDuration({
        minutes: durationMinutes,
    });
    const bookingMessage = `Confirming your booking on ${bookingDate} at ${bookingTime} for ${bookingDuration} with ${fullName}.`;

    return (
        <Form onSubmit={onFormSubmit} noValidate className="pl-3 pr-3">
            <Row className="align-items-center border-bottom mb-2">
                <Col lg={2} className=" pb-3">
                    <ProfilePicture
                        name=""
                        id={profile.image_id}
                        url={profile.image_url}
                        alt={profile.firstname}
                        size="ld"
                        className=""
                        round
                    />
                </Col>
                <Col lg={10}>
                    <p>{bookingMessage}</p>
                </Col>
            </Row>
            <Form.Group controlId="BookingForm.notes">
                <Form.Label className="BookingModal__label text-dark mt-2">Leave a message for {fullName}</Form.Label>
                <InputController
                    asType="textarea"
                    name="notes"
                    control={control}
                    rows={5}
                    placeholder={`Anything else you would like to tell ${fullName} before the meeting?`}
                    disabled={disabled}
                />
            </Form.Group>
            <Form.Group controlId="BookingForm.location">
                <Form.Label className="BookingModal__label text-dark d-block">Meeting format</Form.Label>
                <div className="mb-3">
                    {!!availabilitySettings.locations.virtual && (
                        <Form.Check
                            type="radio"
                            inline
                            custom
                            id="BookingModal.location.virtual"
                            value={EventLocationType.Virtual}
                            label="Virtual"
                            {...register('location')}
                            disabled={disabled}
                        />
                    )}
                    {!!availabilitySettings.locations.physical && (
                        <Form.Check
                            type="radio"
                            inline
                            custom
                            id="BookingModal.location.physical"
                            value={EventLocationType.Physical}
                            label="In-Person"
                            {...register('location')}
                            disabled={disabled}
                        />
                    )}
                    {!!availabilitySettings.locations.callback && (
                        <Form.Check
                            type="radio"
                            inline
                            custom
                            id="BookingModal.location.callback"
                            value={EventLocationType.Callback}
                            label="Phone Call"
                            {...register('location')}
                            disabled={disabled}
                        />
                    )}
                </div>
                {location === EventLocationType.Virtual && (
                    <BookingModalConfirmationSelection
                        text={
                            !!availabilitySettings.locations.virtual?.is_client_can_edit
                                ? `Provide a virtual meeting link for ${profile.firstname} to use after they accept your appointment.`
                                : `You will receive an email confirmation with this virtual meeting link once ${profile.firstname} accepts your appointment.`
                        }
                        type="virtual"
                        placeholder="Enter a link to a video chat"
                        isClientCanEdit={!!availabilitySettings.locations.virtual?.is_client_can_edit}
                        control={control}
                        location={availabilitySettings.locations.virtual?.url}
                    />
                )}
                {location === EventLocationType.Physical && (
                    <BookingModalConfirmationSelection
                        text={
                            !!availabilitySettings.locations.physical?.is_client_can_edit
                                ? `Provide a location where ${profile.firstname} will meet you after they accept the appointment.`
                                : `You will meet ${profile.firstname} at this location after they accept the appointment.`
                        }
                        type="address"
                        placeholder="Enter a physical location like ‘your office’"
                        isClientCanEdit={!!availabilitySettings.locations.physical?.is_client_can_edit}
                        control={control}
                        location={availabilitySettings.locations.physical?.address}
                    />
                )}
                {location === EventLocationType.Callback && (
                    <BookingModalConfirmationSelection
                        text={
                            !!availabilitySettings.locations.callback?.is_client_can_edit
                                ? `Provide a phone number to receive a call from ${profile.firstname} after they accept the appointment.`
                                : `You will receive an email confirmaton with this phone number after ${profile.firstname} accepts the appointment.`
                        }
                        type="phone"
                        placeholder="Enter your mobile number"
                        isClientCanEdit={!!availabilitySettings.locations.callback?.is_client_can_edit}
                        control={control}
                        location={availabilitySettings.locations.callback?.phone_number}
                    />
                )}
            </Form.Group>
            <Form.Group className="text-right">
                <Button type="submit" variant="primary" className="ml-1" disabled={disabled}>
                    {disabled ? 'Booking timeslot...' : 'Book Timeslot'}
                </Button>
            </Form.Group>
        </Form>
    );
};
