import React from 'react';

import {Card} from 'react-bootstrap';

import {IPost} from 'modules/post/models/IPost';
import {PostMediaKind} from 'modules/post/models/postMediaKind';
import {getFileDownloadUrl} from 'modules/file/utils';

import {UserIdProfilePicture} from 'shared/components/UserIdProfilePicture/UserIdProfilePicture';
import {TagId} from 'shared/components/TagId/TagId';
import {UserIdName} from 'shared/components/UserIdName/UserIdName';
import {HtmlComponent} from 'shared/components/HtmlContent/HtmlContent';

interface IPodCastViewHeaderProp {
    post: IPost;
}

export const PodCastViewHeader = ({post}: IPodCastViewHeaderProp) => {
    const imageMedia = post.media.find(
        (mediaItem) => mediaItem.kind === PostMediaKind.Image || mediaItem.kind === PostMediaKind.ImageUrl,
    );

    let imageUrl: string | undefined;
    if (imageMedia?.kind === PostMediaKind.Image && imageMedia?.file_id && imageMedia?.name) {
        imageUrl = getFileDownloadUrl({
            id: imageMedia.file_id,
            filename: imageMedia.name,
        });
    } else if (imageMedia?.kind === PostMediaKind.ImageUrl) {
        imageUrl = imageMedia?.content;
    }

    return (
        <Card className="PodcastView__card">
            <Card.Body className="PodcastView__card--body">
                <div className="PodcastView__header">
                    <div className="PodcastView__header--Image">
                        {imageUrl && (
                            <img src={imageUrl} alt="podcast poster" className="PodcastView__header--Image--poster" />
                        )}
                        {!imageUrl && <div className="PodcastView__header--Image--poster--none" />}
                    </div>
                    <div className="PodcastView__header__Details">
                        <div className="PodcastView__header--row-TU">
                            <div className="PodcastView__header--row-tag">
                                <div className="PodcastView__header--row-tagcontainer row m-0 ">
                                    {post.tag_ids.map((tagId) => (
                                        <TagId
                                            tagId={tagId}
                                            key={tagId}
                                            size={'xl'}
                                            className="MediaCard__tag PodcastView__tag"
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className="PodcastView__header__userDetail">
                                <div className="PodcastView__header__userDetail--image">
                                    <UserIdProfilePicture
                                        id={post.user_id}
                                        size="xsm"
                                        className="PostView__content--user-detail--pImage"
                                        round
                                    />
                                </div>
                                <div className="PodcastView__header__userDetail--name">
                                    <UserIdName className="PodcastView__header__userDetail--name" id={post.user_id} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <h2 className="m-0 PodcastView__header--title">{post.title}</h2>
                        </div>
                        <div>
                            <HtmlComponent className="PostView__content--html m-0">{post.content}</HtmlComponent>
                        </div>

                        <div className="PodcastView__header__userDetail display--block">
                            <div className="PodcastView__header__userDetail--image">
                                <UserIdProfilePicture
                                    id={post.user_id}
                                    size="xsm"
                                    className="PostView__content--user-detail--pImage"
                                    round
                                />
                            </div>
                            <div className="PodcastView__header__userDetail--name">
                                <UserIdName className="PodcastView__header__userDetail--name" id={post.user_id} />
                            </div>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};
