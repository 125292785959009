import React from 'react';

import {ModalFooter as BootstrapModalFooter} from 'react-bootstrap';

interface IModalFooterProps {
    children?: React.ReactNode,
}

export const ModalFooter = ({children}: IModalFooterProps) => {
    return (
        <BootstrapModalFooter>
            {children}
        </BootstrapModalFooter>
    );
};
