import React, {useState} from 'react';

import {Modal} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {IReviewForm, reviewForm} from 'modules/review/forms';
import {useGuaranteedAuth} from 'shared/auth/hooks/useAuth';
import {profileLookupReadSelector} from 'modules/profile/state/profile-lookup';
import {createReview} from 'modules/review/api';
import {toastAxiosError} from 'shared/utils/error';
import {toast} from 'shared/utils/toast';
import {reviewListInsertSelector} from 'modules/review/state/review-list';
import {useProfile} from 'modules/profile/hooks/useProfile';

import {ReviewModalForm} from 'shared/components/review/ReviewModalForm/ReviewModalForm';

interface ICreateReviewProps {
    show: boolean;
    onHide: () => void;
    userId: string;
}

export const CreateReviewModal = ({show, onHide, userId}: ICreateReviewProps) => {
    const {tokenData} = useGuaranteedAuth();
    const authorProfile = useProfile(tokenData.id);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const insertReview = useSetRecoilState(reviewListInsertSelector);

    const {handleSubmit, reset, control} = useForm<IReviewForm>({
        resolver: zodResolver(reviewForm),
    });

    React.useEffect(() => {
        if (!show) {
            reset();
        }
    }, [show, reset]);

    const handleHide = () => {
        if (!isSubmitting) {
            onHide();
        }
    };

    const onSubmit = handleSubmit((formData) => {
        (async () => {
            setIsSubmitting(true);
            try {
                const review = await createReview({
                    user_id: userId,
                    author_id: authorProfile.id,
                    author_name: formData.author_name,
                    author_profession: authorProfile.profession,
                    rating: formData.rating,
                    content: formData.content,
                });
                insertReview(review);
                reset();
                handleHide();
                toast.success('Review Added', 'The review was added successfully');
            } catch (e) {
                toastAxiosError(e, 'Create Review Error');
            } finally {
                setIsSubmitting(false);
            }
        })();
    });

    return (
        <Modal show={show} onHide={handleHide} centered size="lg">
            <Modal.Header closeButton={!isSubmitting}>
                <Modal.Title>
                    Add Review
                </Modal.Title>
            </Modal.Header>
            <ReviewModalForm onSubmit={onSubmit} control={control} isSubmitting={isSubmitting}/>
        </Modal>
    );
};
