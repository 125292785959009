import React from 'react';

import {ErrorBoundary} from 'react-error-boundary';

import {PostKind} from 'modules/post/models/postKind';

import {ResourcesPageError} from 'shared/components/ResourcesPageError/ResourcesPageError';
import {PostsPageResults} from 'public/ResourcesPage/PostsPage/PostsPageResults';
import {LoadingSuspense} from 'shared/components/loading/LoadingSuspense/LoadingSuspense';
import PostCardSkeleton from 'shared/components/PostCard/PostCardSkeleton';

interface IResourcePostsProps {
    kind?: PostKind;
    searchTerm: string;
    tagId?: string;
}

export const PostsPage = ({kind, searchTerm, tagId}: IResourcePostsProps) => {
    return (
        <LoadingSuspense
            skeleton={
                <div className="card-grid m-auto row d-flex">
                    <div className="card-grid w-100 row d-flex ">
                        {[...Array(6)].map((_, index) => (
                            <div key={index} className="col-xl-4 col-md-6 animate__animated animate__fadeIn">
                                <React.Fragment>
                                    <PostCardSkeleton />
                                </React.Fragment>
                            </div>
                        ))}
                    </div>
                </div>
            }
        >
            <ErrorBoundary fallback={<ResourcesPageError />} resetKeys={[kind]}>
                <PostsPageResults kind={kind} searchTerm={searchTerm} tagId={tagId} />
            </ErrorBoundary>
        </LoadingSuspense>
    );
};
