import {atom, selector} from 'recoil';

import {ICategory} from '../models';
import {readCategoryList} from '../api';

interface ICategoryListState {
    categories: ICategory[];
    resetVersion: number;
}

export const categoryListResetAtom = atom<number>({
    key: 'categoryListResetAtom',
    default: 1,
});

export const categoryListAtom = atom<ICategoryListState | undefined>({
    key: 'categoryListAtom',
    default: undefined,
});

export const categoryListReadSelector = selector<ICategoryListState>({
    key: 'categoryListReadSelector',
    get: async ({get}) => {
        const atomValue = get(categoryListAtom);
        const resetVersion = get(categoryListResetAtom);
        if (atomValue && atomValue.resetVersion === resetVersion) {
            return atomValue;
        }
        const {categories} = await readCategoryList({
            limit: 1000,
        });
        return {
            categories,
            resetVersion,
        };
    },
});
