import React from 'react';

import {Link} from 'react-router-dom';

import {IReview} from 'modules/review/models';
import {useBool} from 'shared/hooks/useBool';

import {Dropdown} from 'react-bootstrap';
import {UpdateReviewModal} from 'shared/components/review/UpdateReviewModal/UpdateReviewModal';
import {DeleteReviewModal} from 'shared/components/review/DeleteReviewModal/DeleteReviewModal';

import './style.scss';
import {useWindowSize} from 'shared/hooks/useWindowSize';
import {CustomToggle} from 'shared/components/CustomToggle/CustomToggle';
import {Delete, Editpen, Threedots} from 'shared/icons/Homepage';

interface IReviewCardControlsProps {
    reviewId: string;
    authorId?: string;
    userId: string;
    reviewDetails: IReview;
}

export const ReviewCardControls = ({reviewId, reviewDetails}: IReviewCardControlsProps) => {
    const {width} = useWindowSize();
    const [showDeleteModal, setShowDeleteModal] = useBool();
    const [showUpdateModal, setShowUpdateModal] = useBool();

    const onUpdateClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setShowUpdateModal(true);
    };

    const onDeleteClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setShowDeleteModal(true);
    };

    return (
        <>
            <div className="ReviewCardControls">
                {/* <Link to="#" onClick={onUpdateClick}>
                        <Editpen className="ReviewCardControls__icon" />
                    </Link>
                    <Link to="#" onClick={onDeleteClick}>
                        <img src="/assets/SVG/bin.svg" alt="bin" className="ReviewCardControls__icon" />
                    </Link> */}

                <Dropdown drop="left">
                    <Dropdown.Toggle
                        as={CustomToggle}
                        // id="ReviewCardControlsDropdown.toggle"
                        className="ReviewCardControls__drop"
                    >
                        <Threedots className="ReviewCardControls__icon1" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="ReviewCardControls__dropdown-menu">
                        <Dropdown.Item to="#" onClick={onUpdateClick} className="ReviewCardControls__dropdown-item">
                            <Editpen className="ReviewCardControls__icon" />
                            Edit
                        </Dropdown.Item>
                        <Dropdown.Item to="#" onClick={onDeleteClick} className="ReviewCardControls__dropdown-item">
                            <Delete className="ReviewCardControls__icon" />
                            Delete
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <UpdateReviewModal show={showUpdateModal} review={reviewDetails} onHide={() => setShowUpdateModal(false)} />
            <DeleteReviewModal reviewId={reviewId} show={showDeleteModal} onClose={() => setShowDeleteModal(false)} />
        </>
    );
};
