import React, {useEffect, useRef, useState} from 'react';

import Slick, {Settings} from 'react-slick';
import {Link} from 'react-router-dom';

import {Spinner} from 'shared/components/Spinner/Spinner';
import {TagId} from 'shared/components/TagId/TagId';

import './style.scss';

interface IMediaCardTagsProps {
    tagIds: string[];
}

export const MediaCardTags = ({tagIds}: IMediaCardTagsProps) => {
    const sliderRef = useRef<Slick | null>(null);
    const [slidePosition, setSlidePosition] = useState<number>(0);

    const slickSettings: Settings = {
        draggable: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 3,
        infinite: false,
        variableWidth: true,
        className: 'slider variable-width MediaCardSlider__carousel',
        beforeChange: (prev, next) => {
            setSlidePosition(next);
        },
    };

    const onPrevClick = (e: React.MouseEvent) => {
        e.preventDefault();
        sliderRef.current?.slickPrev();
    };

    const onNextClick = (e: React.MouseEvent) => {
        e.preventDefault();
        sliderRef.current?.slickNext();
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="MediaCard__tags">
            <React.Suspense fallback={<Spinner size="sm" variant="primary" />}>
                <div className="d-flex align-items-center flex-row justify-content-between">
                    {slidePosition !== 0 && (
                        <Link
                            to="#"
                            className="text-dark MediaCardSlider__nav MediaCardSlider__nav--prev"
                            onClick={onPrevClick}
                        >
                            <img
                                style={{
                                    rotate: '180deg',
                                }}
                                src={'/assets/next-dark-icon.svg'}
                                alt="nextIcon"
                            />
                        </Link>
                    )}
                    <Slick ref={sliderRef} {...slickSettings}>
                        {tagIds.map((tagId) => (
                            <TagId
                                key={tagId}
                                tagId={tagId}
                                className="MediaCard__tag"
                                size={windowWidth < 426 ? 'sm' : 'xl'}
                            />
                        ))}
                    </Slick>
                    {slidePosition <= tagIds.length - 3 && (
                        <Link
                            to="#"
                            className="text-dark MediaCardSlider__nav MediaCardSlider__nav--next"
                            onClick={onNextClick}
                        >
                            <img src={'/assets/next-dark-icon.svg'} alt="nextIcon" />
                        </Link>
                    )}
                </div>
            </React.Suspense>
        </div>
    );
};
