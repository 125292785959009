import React from 'react';

import {Link} from 'react-router-dom';

import {IArticle} from 'modules/help/models';

import {UserIdName} from 'shared/components/UserIdName/UserIdName';
import {UserIdProfilePicture} from 'shared/components/UserIdProfilePicture/UserIdProfilePicture';
import {FormatDistance} from 'shared/components/date/FormatDistance/FormatDate';

import './style.scss';

interface IArticleCardProps {
    article: IArticle;
}

export const ArticleCard = ({article}: IArticleCardProps) => {

    return (
        <Link
            className="ArticleCard"
            to={`/help-center/${article.category_id}/${article.id}`}
        >
            <div className="ArticleCard ArticleCard--box">
                <div className="ArticleCard--mainBox">
                    <div className="ArticleCard__headingBox">
                        <div className="ArticleCard--heading">{article.name}</div>
                        <div className="ArticleCard__update-text">
                            Updated <FormatDistance addSuffix>{article.updated_at}</FormatDistance>
                        </div>
                    </div>
                    <div className="ArticleCard--description">{article.description}</div>
                    <div className="ArticleCard--profileName">
                        <UserIdProfilePicture id={article.user_id} size="notification" round={true}/>
                        <div className="ArticleCard--profileText">
                            Written by <UserIdName id={article.user_id}/>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
};
