import React from 'react';

import {useGuaranteedAuth} from 'shared/auth/hooks/useAuth';
import {useNavbar} from 'shared/hooks/useNavbar';
import {useProfile} from 'modules/profile/hooks/useProfile';

import {AppFooter} from 'shared/components/AppFooter/AppFooter';
import {ProfilePageContent} from 'shared/components/ProfilePageContent/ProfilePageContent';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';

import './style.scss';

export const ProfileEdit = () => {
    const {tokenData} = useGuaranteedAuth();
    const profile = useProfile(tokenData.id);
    useNavbar(true);

    return (
        <div className="ProfileEdit">
            <PageHelmet noindex title="My Profile" />
            <div className="mb-5">
                <ProfilePageContent profile={profile} isEditable />
            </div>
            <AppFooter bg="dark" />
        </div>
    );
};
