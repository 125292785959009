import React from 'react';

import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

type DropdownToggle = typeof Dropdown['Toggle']['prototype'];

type CustomToggleProps = {
    className?: string;
    onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

export const CustomToggle = React.forwardRef<DropdownToggle, CustomToggleProps>(({children, className, onClick}, ref) => (
    <Link
        className={className}
        to="#"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </Link>
));
