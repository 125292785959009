import React, {useEffect, useRef, useState} from 'react';

import {Button, Modal, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';

import {IProfile} from 'modules/profile/models/IProfile';
import {ServiceType} from 'modules/profile/models/serviceType';
import {getFileDownloadUrl} from 'modules/file/utils';
import {getFullName, getProfessionTitle} from 'modules/profile/utils';

import {ProfilePicture} from 'shared/components/ProfilePicture/ProfilePicture';
import {TagId} from 'shared/components/TagId/TagId';
import {UploadProfilePicture} from 'shared/components/UploadProfilePicture/UploadProfilePicture';

import './style.scss';
import {IconChat} from 'shared/icons/common';
import {CalendarIcon} from 'shared/icons/dashboard';
import {DropdownCaret} from 'shared/components/DropdownCaret/DropdownCaret';
import {createSearchParams, useNavigate, useSearchParams} from 'react-router-dom';
import {useAuth} from 'shared/auth/hooks/useAuth';
import {chatInsertSelector, chatListBumpToTopSelector} from 'modules/chat/state';
import {useSetRecoilState} from 'recoil';
import {createChat} from 'modules/chat/api';
import {toast} from 'shared/utils/toast';
import {ItemKind} from 'modules/item-key';
import {toastAxiosError} from 'shared/utils/error';
import {useOutsideClick} from 'shared/hooks/useOutsideClick';
import {useWindowSize} from 'shared/hooks/useWindowSize';
import {MediaCardTags} from 'shared/components/MediaCard/MediaCardTags/MediaCardTags';
import {NotAuthenticatedModalBody} from 'shared/components/modals/NotAuthenticatedModalBody/NotAuthenticatedModalBody';
import {Loading} from 'shared/components/loading/Loading/Loading';

interface IProfilePageHeaderProps {
    profile: IProfile;
    isEditable?: boolean;
    onEdit?: () => void;
}

export const ProfilePageHeaderMobile = ({profile, isEditable, onEdit}: IProfilePageHeaderProps) => {
    const [isEdit, setIsEdit] = React.useState<boolean>(false);
    const fullName = getFullName(profile);
    const profession = profile.profession ? getProfessionTitle(profile.profession) : null;
    let city = profile.contact_details_display?.city;
    let state = profile.contact_details_display?.state;
    const country = profile.contact_details_display?.country;
    const [isConnectExpanded, setIsConnectExpanded] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const {isAuthenticated, tokenData} = useAuth();
    const navigate = useNavigate();
    const connectBtnContainerRef = useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isChatUnauthenticatedModal, setIsChatUnauthenticatedModal] = useState<boolean>(false);

    const insertChat = useSetRecoilState(chatInsertSelector);
    const bumpChatToTop = useSetRecoilState(chatListBumpToTopSelector);
    useOutsideClick(connectBtnContainerRef, () => setIsConnectExpanded(false));

    if (city) {
        city += ',';
    }

    if (state) {
        state += ',';
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const {width} = useWindowSize();

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const showModal = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        searchParams.set('booking', 'show');
        setSearchParams(searchParams);
    };

    const handleChatClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();

        if (!isAuthenticated || !tokenData) return setIsChatUnauthenticatedModal(true);

        if (isSubmitting || profile.id === tokenData.id) return;

        setIsSubmitting(true);
        (async () => {
            try {
                const newChat = await createChat({
                    user_ids: [profile.id, tokenData.id],
                    is_active: true,
                    reference: {
                        kind: ItemKind.CHAT,
                        id: '0',
                    },
                });

                const options = {
                    pathname: '/dashboard/chat',
                    search: `?${createSearchParams({chat_id: newChat.id})}`,
                };

                insertChat(newChat);
                bumpChatToTop(newChat.id);
                navigate(options, {replace: true});
            } catch (e) {
                toastAxiosError(e, 'Create Chat');
            } finally {
                setIsSubmitting(false);
            }
        })();
    };
    const filters = profile.service_types;

    const filteredArray = filters.filter((item) => item !== 'FIXED_FEES');
    return (
        <>
            <div className="ProfilePageHeader MobilezViewShow">
                <div
                    style={{paddingTop: isEditable && width < 550 ? '20px' : '0px'}}
                    className="ProfilePageHeader__profiledetails"
                >
                    <ProfilePicture
                        name={fullName}
                        id={profile.image_id}
                        url={profile.image_url}
                        alt={fullName}
                        size="Mobile"
                        round
                        className="ProfilePageHeader__ProfilePicture"
                    />

                    <Row style={{marginTop: '20px', minHeight: '130px'}}>
                        <div className="ProfilePageHeader__leftBox">
                            {profession && <div className="ProfilePageHeader__profession">{profession}</div>}
                            <h1 className="ProfilePageHeader__title2">{fullName}</h1>

                            {profile.contact_details_display && (
                                <div className="ProfilePageHeader__location">
                                    <FontAwesomeIcon
                                        icon={faMapMarkerAlt}
                                        size="lg"
                                        className="ProfilePageHeader__location-icon"
                                    />
                                    {city} {state} {country}
                                </div>
                            )}
                            <div>{isEditable && <UploadProfilePicture userId={profile.id} />}</div>
                            {width > 450 && (
                                <>
                                    <div className="ProfilePageHeader__rate d-flex justify-content-start flex-wrap">
                                        {profile &&
                                            profile.service_details &&
                                            profile.service_details.hour_rate > 0 && (
                                                <p className="m-0 p-0 mr-3 ProfilePageHeader__rateHr font-weight-bold">
                                                    ${profile.service_details?.hour_rate} / hr
                                                </p>
                                            )}
                                        {profile && profile.service_details && profile.service_details.day_rate > 0 && (
                                            <p className="m-0 p-0 ProfilePageHeader__rateDay font-weight-bold">
                                                ${profile.service_details?.day_rate} / day
                                            </p>
                                        )}
                                    </div>

                                    {profile.service_types && profile.service_types.includes(ServiceType.FixedFees) && (
                                        <div>
                                            <p className="ProfilePageHeader__rate FixedFee">
                                                <img
                                                    className="ProfilePage_FixedFeeIcon"
                                                    src="/assets/profileIcons/Group 9733.png"
                                                    alt="Fixed Fee Icon"
                                                />
                                                Fixed Fee Options
                                            </p>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>

                        {isEditable && (
                            <div className="ProfilePageHeader__rightBox">
                                {!isEdit && (
                                    <div className="ProfilePageHeader__editable-controls">
                                        <img src="/assets/edit.svg" alt="edit" onClick={() => onEdit?.()} />
                                    </div>
                                )}
                                {isEdit && (
                                    <div className="ProfilePageHeader__editable-controls">
                                        <Button
                                            type="button"
                                            variant="default"
                                            className="mr-3"
                                            onClick={() => setIsEdit(!isEdit)}
                                        >
                                            Preview
                                        </Button>
                                        <Button type="button" variant="featured" onClick={() => setIsEdit(!isEdit)}>
                                            Save
                                        </Button>
                                    </div>
                                )}
                            </div>
                        )}
                    </Row>
                </div>
                {width < 450 && (
                    <div className="ProfilePageHeader__fee">
                        <>
                            {profile.service_types && profile.service_types.includes(ServiceType.FixedFees) && (
                                <p className="ProfilePageHeader__rate FixedFee">
                                    <img
                                        className="ProfilePage_FixedFeeIcon"
                                        src="/assets/profileIcons/Group 9733.png"
                                        alt="Fixed Fee Icon"
                                    />
                                    Fixed Fee Options
                                </p>
                            )}
                            <div className="ProfilePageHeader__rate d-flex justify-content-start flex-wrap">
                                {profile && profile.service_details && profile.service_details.hour_rate > 0 && (
                                    <p className="m-0 p-0 mr-3 ProfilePageHeader__rateHr font-weight-bold">
                                        ${profile.service_details?.hour_rate} / hr
                                    </p>
                                )}
                                {profile && profile.service_details && profile.service_details.day_rate > 0 && (
                                    <p className="m-0 p-0 ProfilePageHeader__rateDay font-weight-bold">
                                        ${profile.service_details?.day_rate} / day
                                    </p>
                                )}
                            </div>
                        </>
                    </div>
                )}
                {width > 450 ? (
                    <Row className="bageName">
                        <div className="ProfilePageHeader__tags">
                            {profile.tag_ids.map((tagId) => (
                                <TagId
                                    key={tagId}
                                    tagId={tagId}
                                    size={windowWidth < 1050 ? 'sm' : 'xl'}
                                    className="TagMobile"
                                />
                            ))}
                        </div>
                    </Row>
                ) : (
                    <div className="bageName___media_card">
                        <MediaCardTags tagIds={profile.tag_ids} />
                    </div>
                )}

                <Row className="ProfilePageHeader__Bar " />
                {width > 450 ? (
                    <>
                        <Row className="ProfilePageHeader__BotBar mt-2">
                            <div className="ProfilePageHeader__leftBo2x  mt-2">
                                <div className="row">
                                    {profile.service_types &&
                                        profile.service_types.includes(ServiceType.FreeConsultation) && (
                                            <div className="col-xs-12 ml-0 mr-2">
                                                <div className=" d-flex justify-content-start ml-0 ">
                                                    <p className="TagLinesImg">
                                                        <img
                                                            src="/assets/profileIcons/Icon ionic-md-checkmark-circle.png"
                                                            alt="Free first consultation"
                                                            className="imagesizeProfilepage"
                                                        />
                                                    </p>
                                                    <p className="TagLines"> Free first consultation</p>
                                                </div>
                                            </div>
                                        )}

                                    {profile.service_types &&
                                        profile.service_types.includes(ServiceType.NoDirectBriefs) && (
                                            <div className="col-xs-12 mr-1">
                                                <div className=" d-flex justify-content-start">
                                                    <p className="TagLinesImg">
                                                        <img
                                                            src="/assets/profileIcons/Icon ionic-ios-close-circle.png"
                                                            alt="Does not accept direct briefs"
                                                            className="imagesizeProfilepage"
                                                        />
                                                    </p>
                                                    <p className="TagLines"> Does not accept direct briefs</p>
                                                </div>
                                            </div>
                                        )}

                                    {profile.service_types &&
                                        profile.service_types.includes(ServiceType.AccreditedSpecialist) && (
                                            <div className="col-xs-12 p-0 mr-1">
                                                <div className="d-flex justify-content-start">
                                                    <p className="TagLinesImg">
                                                        <img
                                                            src="/assets/profileIcons/Group 9658.png"
                                                            alt="imagesizeProfilepage2"
                                                            className="imagesizeProfilepage2"
                                                        />
                                                    </p>
                                                    <p className="TagLines"> Accredited Specialist</p>
                                                </div>
                                            </div>
                                        )}

                                    {profile.service_types &&
                                        profile.service_types.includes(ServiceType.UnderSupervision) && (
                                            <div className="col-xs-12 p-0 mr-1">
                                                <div className="d-flex justify-content-start">
                                                    <p className="TagLinesImg">
                                                        <img
                                                            src="/assets/profileIcons/A.png"
                                                            alt="imagesizeProfilepage2"
                                                            className="imagesizeProfilepage2"
                                                        />
                                                    </p>
                                                    <p className="TagLines"> Under Supervision</p>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>

                            <div className="ProfilePageHeader__rightBo2x mt-2">
                                <div className="row d-flex justify-content-end">
                                    {width > 450 && (
                                        <div className="col-12 mb-2">
                                            {profile.social_links.map((socialId, key) => (
                                                <a
                                                    href={socialId.url}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    key={key}
                                                    className="IdLinkPadd"
                                                >
                                                    {socialId.type === 'LINKEDIN' && (
                                                        <img
                                                            src="/assets/homepage/Footer/Icon awesome-linkedin.png"
                                                            alt="Linkedin"
                                                        />
                                                    )}
                                                    {socialId.type === 'FACEBOOK' && (
                                                        <img
                                                            src="/assets/homepage/Footer/Icon awesome-facebook-square.png"
                                                            alt="Facebook"
                                                        />
                                                    )}
                                                    {socialId.type === 'TWITTER' && (
                                                        <img
                                                            src="/assets/homepage/Footer/Icon awesome-twitter-square.png"
                                                            alt="Twitter"
                                                        />
                                                    )}
                                                </a>
                                            ))}
                                        </div>
                                    )}
                                    {profile.resume_id && (
                                        <p className="downlodBTN col-12">
                                            <img
                                                src="/assets/profileIcons/Icon awesome-file-pdf.svg"
                                                alt=""
                                                className="mr-3 ml-3"
                                            />
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href={getFileDownloadUrl({id: profile.resume_id})}
                                            >
                                                Download Resume
                                            </a>
                                        </p>
                                    )}
                                </div>
                            </div>
                        </Row>
                    </>
                ) : (
                    <>
                        <div
                            className={` ${
                                filteredArray.length === 3 || filteredArray.length === 1
                                    ? ' ProfilePageHeader__bottomOdd'
                                    : 'ProfilePageHeader__bottomEven'
                            } `}
                        >
                            <>
                                {profile.service_types && profile.service_types.includes(ServiceType.FreeConsultation) && (
                                    <div
                                        style={{padding: '15px'}}
                                        className="col-xs-12 ml-0 ProfilePageHeader__services"
                                    >
                                        <div className="d-flex flex-column justify-content-start align-center ml-0">
                                            <p className="TagLinesImg">
                                                <img
                                                    src="/assets/profileIcons/Icon ionic-md-checkmark-circle.png"
                                                    alt="Free first consultation"
                                                    className="imagesizeProfilepage"
                                                />
                                            </p>
                                            <p className="TagLines"> Free first consultation</p>
                                        </div>
                                    </div>
                                )}
                            </>
                            <>
                                {profile.service_types && profile.service_types.includes(ServiceType.NoDirectBriefs) && (
                                    <div style={{padding: '15px'}} className="col-xs-12 ProfilePageHeader__services">
                                        <div className=" d-flex flex-column justify-content-start align-center">
                                            <p className="TagLinesImg">
                                                <img
                                                    src="/assets/profileIcons/Icon ionic-ios-close-circle.png"
                                                    alt="Does not accept direct briefs"
                                                    className="imagesizeProfilepage"
                                                />
                                            </p>
                                            <p className="TagLines"> Does not accept direct briefs</p>
                                        </div>
                                    </div>
                                )}
                            </>
                            <>
                                {profile.service_types &&
                                    profile.service_types.includes(ServiceType.AccreditedSpecialist) && (
                                        <div
                                            style={{padding: '15px'}}
                                            className="col-xs-12 ProfilePageHeader__services"
                                        >
                                            <div className="d-flex flex-column justify-content-start align-center">
                                                <p className="TagLinesImg">
                                                    <img
                                                        src="/assets/profileIcons/Group 9658.png"
                                                        alt="imagesizeProfilepage2"
                                                        className="imagesizeProfilepage2"
                                                    />
                                                </p>
                                                <p className="TagLines"> Accredited Specialist</p>
                                            </div>
                                        </div>
                                    )}
                            </>
                            <>
                                {profile.service_types && profile.service_types.includes(ServiceType.UnderSupervision) && (
                                    <div style={{padding: '15px'}} className="col-xs-12 ProfilePageHeader__services">
                                        <div className="d-flex flex-column justify-content-start align-center">
                                            <p className="TagLinesImg">
                                                <img
                                                    src="/assets/profileIcons/A.png"
                                                    alt="imagesizeProfilepage2"
                                                    className="imagesizeProfilepage2"
                                                />
                                            </p>
                                            <p className="TagLines"> Under Supervision</p>
                                        </div>
                                    </div>
                                )}
                            </>
                        </div>
                        {profile.resume_id && (
                            <div className="mt-2">
                                <div className="d-flex ">
                                    <p className="downlodBTN col-12 pt-2">
                                        <img
                                            src="/assets/profileIcons/Icon awesome-file-pdf.svg"
                                            alt=""
                                            className="mr-3 ml-3"
                                        />
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={getFileDownloadUrl({id: profile.resume_id})}
                                        >
                                            Resume
                                        </a>
                                    </p>
                                </div>
                            </div>
                        )}
                    </>
                )}

                {isEditable && !isEdit ? (
                    ''
                ) : (
                    <Row className="availabilityBtn">
                        <div ref={connectBtnContainerRef} className="ProfilePageHeader__booking">
                            <button
                                className={`connectbtn ${isConnectExpanded && 'expanded-connectbtn'}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setIsConnectExpanded(!isConnectExpanded);
                                }}
                                type="button"
                            >
                                Connect
                                <DropdownCaret className={`${isConnectExpanded && 'expanded-DropdownCaret'}`} />
                            </button>
                            <div className={`connectbtnrow ${isConnectExpanded && 'expanded-connectbtnrow'}`}>
                                <Button onClick={handleChatClick}>
                                    <IconChat />
                                    Message
                                </Button>

                                <Button className="btn_bookdiary" onClick={showModal}>
                                    <CalendarIcon /> Book Diary
                                </Button>
                            </div>
                        </div>
                    </Row>
                )}
            </div>
            <Modal
                show={isChatUnauthenticatedModal}
                centered
                size="lg"
                onHide={() => setIsChatUnauthenticatedModal(false)}
                animation={false}
            >
                <Modal.Header closeButton className="p-3">
                    {profile && (
                        <Modal.Title className="BookingModal__title">{getFullName(profile)}'s Chat</Modal.Title>
                    )}
                </Modal.Header>

                <React.Suspense
                    fallback={
                        <Modal.Body className="p-5">
                            <Loading />
                        </Modal.Body>
                    }
                >
                    {profile && !isAuthenticated && <NotAuthenticatedModalBody />}
                </React.Suspense>
            </Modal>
        </>
    );
};
