import {atom, selector} from 'recoil';

import {readTagList} from 'modules/tag/api';
import {ITag} from 'modules/tag/models';
import {guardRecoilDefaultValue} from 'shared/utils/recoil';
import {homepageCacheAtom} from 'modules/homepage/state';

export const tagListAtom = atom<ITag[] | undefined>({
    key: 'tagAtom',
    default: undefined,
});

export const tagListSelector = selector<ITag[] | undefined>({
    key: 'tagListSelector',
    get: ({get}) => {
        const atomValue = get(tagListAtom);
        if (atomValue) {
            return atomValue;
        }

        const homepageCache = get(homepageCacheAtom);
        if (homepageCache) {
            return homepageCache.tags;
        }

        return undefined;
    },
    set: ({set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(tagListAtom, newValue);
    },
});

export const tagListReadSelector = selector<ITag[]>({
    key: 'tagListReadSelector',
    get: async ({get}) => {
        const atomValue = get(tagListSelector);
        if (atomValue) {
            return atomValue;
        }
        const tagListResult = await readTagList();
        return tagListResult.tags;
    },
});
