import React from 'react';

import classNames from 'classnames';

import {IIssue} from 'modules/issue/models';
import {getFileDownloadUrl} from 'modules/file/utils';

interface IFeatureComponentProps {
    issue: IIssue;
    index: number;
}

export const FeatureComponent = ({issue, index}: IFeatureComponentProps) => {
    const imageUrl = issue.image_id ? getFileDownloadUrl({id: issue.image_id}) : undefined;

    const featureStatusClassname = classNames('DevelopmentPipeline__feature--tag', {
        'DevelopmentPipeline__feature--tag--green': issue.status === 'OPEN',
        'DevelopmentPipeline__feature--tag--red': issue.status === 'CLOSED',
    });

    const isLeft = index % 2 !== 0;

    const imageSection = (
        <div className="DevelopmentPipeline__feature--image-section">
            <div className="DevelopmentPipeline__feature--image-box">
                <div
                    className={classNames('DevelopmentPipeline__feature--image', {
                        'DevelopmentPipeline__feature--image--right-top': isLeft,
                        'DevelopmentPipeline__feature--image--left-top': !isLeft,
                    })}
                >
                    <div
                        style={{backgroundImage: `url('${imageUrl}')`}}
                        className="DevelopmentPipeline__image"
                    />
                </div>
                <div
                    className={classNames('DevelopmentPipeline__feature--gray-box', {
                        'DevelopmentPipeline__feature--gray-box--left-bottom': isLeft,
                        'DevelopmentPipeline__feature--gray-box--right-bottom': !isLeft,
                    })}
                />
            </div>
        </div>
    );

    return (
        <div className="DevelopmentPipeline__feature">
            {isLeft && imageSection}
            <div className="DevelopmentPipeline__feature--info-section">
                <div className="DevelopmentPipeline__feature__info">
                    <div>
                        <p className={featureStatusClassname}>{issue.status}</p>
                    </div>
                    <div className="DevelopmentPipeline__text-medium">
                        <p className="mb-3">{issue.title}</p>
                    </div>
                    <div className="DevelopmentPipeline__text-regular">
                        <p className="mb-0">{issue.description}</p>
                    </div>
                </div>
            </div>
            {!isLeft && imageSection}
        </div>
    );
};
